import React, { useMemo } from "react";
import _ from "lodash";
import { Rate } from "@trnsact/ntp-sdk";
import { Addons } from "./Addons";
import { Fields } from "./Fields";
import { MarkupType, ProposalProductCardProps } from "../../../../../types";
import { vendorsLogoByType } from "../../../../../constants";
import { ProposalCardContainer } from "../cardContainer/ProposalCardContainer";
import { useSelector } from "react-redux";
import { deskingSelectors } from "modules/desking/model/selectors";
import { ProposalProduct, ProposalProductAddon } from "@trnsact/trnsact-shared-types/dist/generated";
import { FormattedLossCode } from "modules/desking/lib/lossCodesFormatter";

export const Card = ({
  mode,
  product,
  type = "simple",
  proposalProductConfiguration,
  updateProductConfiguration,
}: ProposalProductCardProps) => {
  const products = useSelector(deskingSelectors.proposalsProducts) as ProposalProduct[];

  const addons = proposalProductConfiguration?.addons ?? [];

  const { deductibleOptions, mileageOptions, addonsOptions } = useMemo(() => {
    let data: { API_RESPONSE_DATA: Rate } =
      product?.aftermarketProduct?.criteria?.[0]?.event?.params?.AFTERMARKET_PRODUCT_VENDOR_API_DATA;

    if (!data) {
      const productFromApi = products.find(p => p.proposalProductId === product.proposalProductId);
      if (productFromApi) {
        data = productFromApi.aftermarketProduct?.criteria?.[0]?.event.params.AFTERMARKET_PRODUCT_VENDOR_API_DATA;
      }
    }

    const accessKey = `${proposalProductConfiguration?.coverage?.termMonths}-${proposalProductConfiguration?.coverage?.termOdometer}`;

    return {
      deductibleOptions: _.uniqBy(data?.API_RESPONSE_DATA.terms, term => term.deductible.amount).map(
        term => term.deductible.amount
      ),
      mileageOptions:
        proposalProductConfiguration?.deductible || proposalProductConfiguration?.deductible === 0
          ? data?.API_RESPONSE_DATA.terms.filter(
              term => term.deductible.amount === proposalProductConfiguration?.deductible
            )
          : [],
      addonsOptions: data?.API_RESPONSE_DATA.terms.reduce<any>((acc, data) => {
        const key = `${data.termMonths}-${data.termOdometer}`;

        acc[key] = data;

        return acc;
      }, {})?.[accessKey],
    };
  }, [product, proposalProductConfiguration]);

  const handleDeductibleChange = (selectedAmount: number) => {
    updateProductConfiguration({
      coverage: null,
      deductible: selectedAmount,
    });
  };

  const handleMileageChange = (selectedTerm: any) => {
    const addonsCost = addons.reduce((acc: number, addon: any) => acc + addon.cost, 0);

    const markupToUse = proposalProductConfiguration?.markup;
    let retailCost = selectedTerm?.dealerCost ?? 0;

    if (markupToUse) {
      if (markupToUse.type === MarkupType.Percentage) {
        retailCost = _.round((selectedTerm?.dealerCost + addonsCost) * (1 + markupToUse.markup / 100), 2);
      } else {
        retailCost = selectedTerm?.dealerCost + addonsCost + markupToUse.markup;
      }
    }

    updateProductConfiguration({
      coverage: selectedTerm,
      retailCost,
      cost: (selectedTerm?.dealerCost ?? 0) + addonsCost,
      markup: markupToUse ? markupToUse : { type: MarkupType.Percentage, markup: 0 },
    });
  };

  const handleCheckboxChange = (formattedLossCodes: FormattedLossCode[]) => {
    if (!proposalProductConfiguration?.coverage) return;

    let nextAddons = [...addons];

    formattedLossCodes.forEach(lossCode => {
      const lossCodeIndex = nextAddons.findIndex((addon: any) => addon.title === lossCode.title);

      if (lossCodeIndex === -1 && lossCode.value === true) {
        nextAddons = [...nextAddons, lossCode];
      } else {
        nextAddons = nextAddons.filter(
          (addon: any) => addon.title !== lossCode.title || (addon.title !== lossCode.title && lossCode.value === false)
        );
      }
    });

    const addonsCost = nextAddons.reduce((acc: number, addon: any) => acc + addon.cost, 0);

    const newCost = (proposalProductConfiguration?.coverage.dealerCost ?? 0) + addonsCost;

    let newRetailCost = newCost;

    const markupToUse = proposalProductConfiguration?.markup;

    if (markupToUse) {
      if (markupToUse.type === MarkupType.Percentage) {
        newRetailCost = _.round(newRetailCost * (1 + markupToUse.markup / 100), 2);
      } else {
        newRetailCost = newRetailCost + markupToUse.markup;
      }
    }

    updateProductConfiguration({
      cost: newCost,
      markup: markupToUse,
      retailCost: newRetailCost,
      addons: nextAddons,
    });
  };

  return (
    <ProposalCardContainer
      mode={mode}
      type={type}
      proposalProduct={product}
      logo={vendorsLogoByType["NTP"]}
      productConfiguration={proposalProductConfiguration}
      updateProductConfiguration={updateProductConfiguration}
    >
      <Fields
        disableAllFields={false}
        uniqueDeductibles={deductibleOptions}
        filteredMileageOptions={mileageOptions}
        handleMileageChange={handleMileageChange}
        configuration={proposalProductConfiguration}
        handleDeductibleChange={handleDeductibleChange}
      />

      {addonsOptions && (
        <Addons addons={addons} addonsOptions={addonsOptions} handleCheckboxChange={handleCheckboxChange} />
      )}
    </ProposalCardContainer>
  );
};
