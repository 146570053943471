import React, { FC } from "react";
import { RulesEngineProvider } from "../../../context/RulesEngineProvider";
import { GAIGProductCard, NTPProductCard, SimpleProductCard } from "./products";
import { AftermarketProductTypes, ProposalProductCardProps } from "../../../types";

export const ProposalProductsCard = (props: ProposalProductCardProps) => {
  const productsCards: Record<AftermarketProductTypes, FC<ProposalProductCardProps>> = {
    [AftermarketProductTypes.Ntp]: NTPProductCard,
    [AftermarketProductTypes.Gaig]: GAIGProductCard,
    [AftermarketProductTypes.Simple]: SimpleProductCard,
  };

  const Card =
    productsCards[props.product.aftermarketProduct?.aftermarketVendorApiChannel ?? AftermarketProductTypes.Simple];

  return (
    <RulesEngineProvider>
      <Card {...props} />
    </RulesEngineProvider>
  );
};
