import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  LinearProgress,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useModal } from "../../../../hooks/useModal";
import { InputField } from "../../../../components/form";
import { useForm } from "react-hook-form";
import { ModalsKeys } from "../../../../global";
import { AddDealerFormInputs } from "modules/aftermarketPartner/types";
import { useMutation } from "@apollo/react-hooks";
import { LINK_NEW_PARTNER } from "modules/aftermarketPartner/api";
import { formatPhoneInput } from "formatters";
import { sleep } from "utils/sleep";

export const AddDealerDialog = () => {
  const dispatch = useDispatch();

  const account = useSelector((state: any) => state.account);
  const isNTPAccount = useSelector((state: any) => state.aftermarketPartner.isNTPAccount);

  const { isOpen, data, handleClose } = useModal(ModalsKeys.AftermarketPartnerAddDealerDialog);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [linkNewPartner] = useMutation(LINK_NEW_PARTNER);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<AddDealerFormInputs>({
    defaultValues: {
      name: "",
      /*       numberOfQuotesYearly: "",
      numberOfContractsYearly: "",
      summaryOfRelationship: "", */
      contact: {
        firstName: "",
        lastName: "",
        title: "",
        email: "",
        phone: "",
      },
    },
  });

  const handleSave = async (formData: AddDealerFormInputs) => {
    try {
      setIsLoading(true);
      await linkNewPartner({
        variables: {
          input: {
            name: formData.name,
            dealerNumber: formData.dealerNumber,
            //summaryOfRelationship: String,
            partnerAccountDynamicsId: account.dynamicsAccountId,
            partnerProfileDynamicsId: account.partner.partnerDynamicsProfileId,
            contact: formData.contact,
          },
        },
      });
      await sleep(); // to be sure the new partner link was created in PG DB before making refetch
      handleClose();
      data.refetchPartnerLinks();
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth="sm" fullWidth={true}>
      <DialogTitle>
        <Typography component="span" variant="h5" style={{ fontWeight: 500 }}>
          Add Dealer
        </Typography>
      </DialogTitle>

      <DialogContent>
        <DialogContentText></DialogContentText>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <InputField
              control={control}
              label="Dealer Name"
              name="name"
              rules={{
                required: true,
              }}
              inputProps={{
                required: true,
                error: !!errors.name,
                helperText: errors.name && "Required",
              }}
            />
          </Grid>
          {isNTPAccount && (
            <Grid item xs={12}>
              <InputField
                control={control}
                label="Dealer ID"
                name="dealerNumber"
                tooltip="Dealer’s Unique ID (API-Integrated Partners)"
              />
            </Grid>
          )}
          {/*           <Grid item xs={6}>
            <InputField
              control={control}
              label="Number of Quotes Yearly"
              name="numberOfQuotesYearly"
              inputProps={{ type: "number" }}
            />
          </Grid>
          <Grid item xs={6}>
            <InputField
              control={control}
              label="Number of Contracts Yearly"
              name="numberOfContractsYearly"
              inputProps={{ type: "number" }}
            />
          </Grid>
          <Grid item xs={12}>
            <InputField control={control} label="Summary of Relationship" name="summaryOfRelationship" />
          </Grid> */}
          <Grid item xs={12} style={{ paddingBottom: 0 }}>
            <Box mt={3}>
              <Typography component="span" variant="body1" style={{ fontWeight: 500 }}>
                Primary Contact
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <InputField
              control={control}
              label="First Name"
              name="contact.firstName"
              rules={{
                required: true,
              }}
              inputProps={{
                required: true,
                error: !!errors.contact?.firstName,
                helperText: errors.contact?.firstName && "Required",
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <InputField
              control={control}
              label="Last Name"
              name="contact.lastName"
              rules={{
                required: true,
              }}
              inputProps={{
                required: true,
                error: !!errors.contact?.lastName,
                helperText: errors.contact?.lastName && "Required",
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <InputField control={control} label="Title" name="contact.title" />
          </Grid>
          <Grid item xs={12}>
            <InputField
              control={control}
              label="Email"
              name="contact.email"
              rules={{
                required: true,
              }}
              inputProps={{
                required: true,
                error: !!errors.contact?.email,
                helperText: errors.contact?.email && "Required",
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <InputField control={control} label="Phone" name="contact.phone" formatter={formatPhoneInput} />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        {!isLoading ? (
          <>
            <Button onClick={handleClose} color="primary" variant="outlined">
              Cancel
            </Button>
            <Button onClick={handleSubmit(handleSave)} color="primary" variant="contained">
              Save
            </Button>
          </>
        ) : (
          <LinearProgress style={{ width: "100%" }} />
        )}
      </DialogActions>
    </Dialog>
  );
};
