import React, { ReactNode, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";
import _ from "lodash";
import { useLazyQuery, useQuery } from "@apollo/react-hooks";
import { DocumentContext } from "../../../contexts/DocumentContext";
import { Box, Button, CircularProgress, Grid, Modal, Paper, Snackbar, Typography } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
import { CustomerSummaryCard_V2 } from "../CustomerSummaryCard_V2";
import ProgressStepper from "../ProgressStepper";
import ContactCard from "../ContactCard";
import NotesCard from "../NotesCard";
import { checkIfIndividualCreditApp, convertToDate, getNested } from "../../../utils";
import { InvoiceEquipmentDetailsCard } from "pages/VendorOpportunity/InvoiceEquipmentDetailsCard/InvoiceEquipmentDetailsCard";
import { connect, useDispatch, useSelector } from "react-redux";
import { setRefetch, setValue } from "../../../redux/actions";
import LenderMatchingEngine from "../LenderMatchingEngine";
import DeliveryTimelineCard from "../DeliveryDetailCard";
import { VOChecklistCard } from "../VOChecklistCard";
import ScoreCard from "../ScoreCard";
import { CHAT_V2_SET_CREDIT_APP, CHAT_V2_TASKS, SET_CHAT_RESET } from "../../../redux/types";
import {
  Q_CREDIT_APP,
  Q_VENDOR_PROFILE,
  Q_VO,
  QUERY_ACTIVE_OCA_TEMPLATE,
  QUERY_PORTAL_CONFIGURATION,
} from "../../../queries/common";
import SendDocsModal from "../SendDocsModal";
import Agreements from "../Agreements";
import { portalConfigurationTypes } from "../../Prequal/constants";
import {
  assignFieldsOCAConfigFromActiveTemplate,
  isItemHasRestrictionForRole,
} from "../../../services/creditAppModalService";
import { getDefaultPortalConfigurationByType, rolesSettingsKeys } from "../../../services/pcwService";
import { CommonDataContext } from "../../../contexts/CommonDataContext";
import { theme } from "../../../theme";
import { LenderWaterfallResults } from "../LenderWaterfallResults";
import {
  AGREEMENTS,
  DOC_SETS,
  Q_EQUIPMENT,
  Q_INVOICE_DOC,
  Q_USER_DOCS,
  QUERY_TASKS,
  QUERY_VENDOR_PROFILES_ASSOCIATED_TO_LENDER,
  START_FAST_TRACK_PROCESS,
} from "./queres";
import { deliveryTimelineAPIKey, invoiceDetailAPIKey } from "./constants";
import { VendorOpportunityTabs } from "./types";
import { Breadcrumbs } from "../../../components/Breadcrumbs/Breadcrumbs";
import { VOSpecificTasks_V2 } from "../../../components/VOTaskList_V2/ui";
import { VODocuments_V2 } from "../../../components/VODocuments_V2";
import { CardContainer } from "../../../components/shared/CardContainer/CardContainer";
import { VendorLogoImg } from "../../../components/shared/VendorLogoImg";
import { VOSyndicationsList_V2 } from "../../../components/VOSyndicationsList_V2/ui";
import { VendorContactRole } from "@trnsact/trnsact-shared-types";
import { TabsContainer } from "../../../components/shared/TabsContainer/TabsContainer";
import { CardBackground } from "../../../components/CardBackground/CardBackground";
import { ModalsKeys, Nullable, PageTypes, TabsOption } from "global";
import { useViewTypeContext } from "../../../contexts/contentViewType";
import { portalConfigurationsSelectors } from "../../../redux/portalConfigurationReducer";
import { logError } from "utils/logger";
import { useModal } from "hooks/useModal";
import { useNotifications } from "modules/notification";
import { ConfigurationPanel, ProposalsTable } from "modules/desking";
import { CreditApplicationDetailsFullView } from "modules/creditApplicationDetails";
import { collectInvoiceFromVO, useInvoice, CreateUpdateInvoiceModal } from "modules/invoice";
import { HealthCheckCard } from "modules/vendorOpportunity";
import { ModalsContainer } from "components/shared/Modals";
import { calculateDaysAfterLastRecertification } from "modules/creditApplicationDetailsActions/lib";
import { DCR_API_BASE_SERVER_URL } from "config";
import axios from "axios";
import NewCreditAppModal from "../NewCreditAppModal";
import { CreditLineTab } from "./tabs/CreditLineTab";

const Details = ({ match, reduxSetValue, account, vp, userProfile, refetch, setRefetch, chat }: any) => {
  const voId = match.params.id;

  const { contentViewType, isDesktop } = useViewTypeContext();
  const { showNotification } = useNotifications();

  const { push, replace } = useHistory();

  const classes = useStyles();

  const dispatch = useDispatch();
  const creditSubmissionsData = useSelector((state: any) => state.creditSubmissions);
  const isUserHaveAccessToPrescreenResults = useSelector((state: any) =>
    portalConfigurationsSelectors.checkIfUserHaveAccessToPrescreenResults(state, userProfile)
  );

  const [lastDocument, setLastDocument] = useState<any>();
  const [invoiceDoc, setInvoiceDoc] = useState<any>(false);
  const [userDocs, setUserDocs] = useState<any>(false);
  const [vendorProfile, setVendorProfile] = useState<any>();
  const [show, setShow] = useState<any>(false);
  const [vo, setVo] = useState<Nullable<any>>(null);
  const [applicationStage, setApplicationStage] = useState<any>("");
  const [invoiceStatus, setInvoiceStatus] = useState<any>("");
  const [dateString, setDateString] = useState<any>("");
  const [creditApp, setCreditApp] = useState<any>();
  const [ocaTemplateToUse, setOcaTemplateToUse] = useState<any>(null);
  const [isLegacyOCA, setIsLegacyOCA] = useState<boolean>(false);
  const [customerName, setCustomerName] = useState<any>("");
  const [invoiceDetailTableBody, setInvoiceDetailTableBody] = useState<any>([]);
  const [deliveryTimeLineTableBody, setDeliveryTimeLineTableBody] = useState<any>([]);
  const [handleSubmitToLenderByProfileId, setHandleSubmitToLenderByProfileId] = useState<any>(null);
  const [contactsTableBody, setContactsTableBody] = useState<any>([]);
  const [openDocSetModal, setOpenDocSetModal] = useState<any>(false);
  const [tasksLoading, setTasksLoading] = useState<any>(true);

  const [openEquipmentInputModal, setOpenEquipmentInputModal] = useState<any>(false);
  const [invoiceEquipmentTabPage, setInvoiceEquipmentTabPage] = useState<any>(0);
  const [fastTrackProcessStarted, setFastTrackProcessStarted] = useState<any>(false);
  const [fastTrackSnackBarClosed, setFastTrackSnackBarClosed] = useState<any>(false);
  const [CADMPortalConfiguration, setCADMPortalConfiguration] = useState<any>(null);
  const [portalConfigurationsByType, setPortalConfigurationsByType] = useState<any>();
  const [creditSubmissionForScorecard, setCreditSubmissionForScorecard] = useState<any>(null);

  const [isScorecardHiddenForUserRole, setIsScorecardHiddenForUserRole] = useState<any>(true);
  const [isHealthCheckHiddenForUserRole, setIsHealthCheckHiddenForUserRole] = useState<any>(true);
  const [isCreditReportsHiddenForUserRole, setIsCreditReportsHiddenForUserRole] = useState<any>(true);
  const [isCADMEditingDisabledForUserRole, setIsCADMEditingDisabledForUserRole] = useState<any>(true);
  const [isVOChecklistHidden, setIsVOChecklistHidden] = useState<any>(true);
  const [isIndividualCreditApp, setIsIndividualCreditApp] = useState<boolean>(false);

  const [openLegacyCreditAppModal, setOpenLegacyCreditAppModal] = useState<boolean>(false);
  const [legacyPDFData, setLegacyPDFData] = useState<any>();

  const [vendorProfilesAssociatedToLender, setVendorProfilesAssociatedToLender] = useState<any>();
  const [voLoaded, setVoLoaded] = useState<any>(false);
  const [taskId, setTaskId] = useState<any>(null);
  const [creditAppOwners, setCreditAppContacts] = useState<any>(null);

  const [allConfigurationsLoaded, setAllConfigurationsLoaded] = useState(false);

  const { isLenderUser } = useContext(CommonDataContext);

  const queryParams = new URLSearchParams(window.location.search);
  const startFastTrackProcessQueryParam = queryParams.get("startFastTrack");
  let startTaskId = queryParams.get("taskId");

  useEffect(() => {
    if (!voLoaded) reduxSetValue("vo", {});
    else setVoLoaded(false);
  }, [voLoaded]);

  const [startFastTrackProcess] = useLazyQuery(START_FAST_TRACK_PROCESS, {
    context: { authRequired: true },
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (startFastTrackProcessQueryParam === "true" && !fastTrackProcessStarted) {
      setFastTrackProcessStarted(true);
      if (queryParams.has("startFastTrack")) {
        queryParams.delete("startFastTrack");
        replace({
          search: queryParams.toString(),
        });
      }
      const amountRequested = queryParams.get("amount");
      const equipmentType = queryParams.get("type");
      const equipmentHours = queryParams.get("hours");

      const fastTrackProcessInputs: any = {
        variables: {
          vendorOpportunityDatabaseId: voId,
        },
        context: { authRequired: true },
      };

      if (amountRequested || equipmentType || equipmentHours) {
        fastTrackProcessInputs.fastTrackInputs = {};
        if (amountRequested) {
          fastTrackProcessInputs.fastTrackInputs.amountRequested = amountRequested;
        }
        if (equipmentType) {
          fastTrackProcessInputs.fastTrackInputs.equipmentType = equipmentType;
        }
        if (equipmentHours) {
          fastTrackProcessInputs.fastTrackInputs.equipmentHours = equipmentHours;
        }
      }

      startFastTrackProcess(fastTrackProcessInputs);

      queryParams.delete("amount");
      queryParams.delete("type");
      queryParams.delete("hours");
      console.log("STARTING FAST TRACK PROCESS");
    }
  }, [account]);

  const handleOpenDocSetModal = () => {
    setOpenDocSetModal(true);
  };
  const handleCloseDocSetModal = () => {
    setOpenDocSetModal(false);
  };
  const onTasksLoaded = (loadedInfo: any) => {
    setTasksLoading(loadedInfo.loading);
    dispatch({ type: CHAT_V2_TASKS, payload: loadedInfo.tasks });
  };

  const [getVendorProfile, { data: vpData }] = useLazyQuery(Q_VENDOR_PROFILE, {
    context: { autRequired: true },
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
  });

  const [
    getVendorProfilesAssociatedToLender,
    { data: vendorProfilesAssociatedToLenderData, loading: loadingVendorProfilesAssociatedToLender },
  ] = useLazyQuery(QUERY_VENDOR_PROFILES_ASSOCIATED_TO_LENDER, {
    context: { autRequired: true },
    fetchPolicy: "cache-and-network",
  });

  const { data: creditAppData, refetch: creditAppRefetch } = useQuery(Q_CREDIT_APP, {
    variables: {
      VOId: voId,
    },
    fetchPolicy: "no-cache",
  });

  const { data: tasksData, refetch: refetchTasks } = useQuery(QUERY_TASKS, {
    variables: {
      VOId: voId,
      csId: null,
      limit: 100,
    },
    context: { authRequired: true },
    fetchPolicy: "no-cache",
  });

  const [
    getPortalConfigurations,
    { data: portalConfigurationData, loading: portalConfigurationDataLoading, called: portalConfigurationDataCalled },
  ] = useLazyQuery(QUERY_PORTAL_CONFIGURATION, {
    context: { authRequired: true },
    fetchPolicy: "no-cache",
  });

  const [
    getCADMPortalConfigurations,
    {
      data: CADMPortalConfigurationData,
      loading: CADMPortalConfigurationDataLoading,
      called: CADMPortalConfigurationDataCalled,
    },
  ] = useLazyQuery(QUERY_PORTAL_CONFIGURATION, {
    context: { authRequired: true },
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (
      portalConfigurationDataCalled &&
      CADMPortalConfigurationDataCalled &&
      !(portalConfigurationDataLoading || CADMPortalConfigurationDataLoading)
    ) {
      setAllConfigurationsLoaded(true);
    }
  }, [
    portalConfigurationDataCalled,
    CADMPortalConfigurationDataCalled,
    portalConfigurationDataLoading,
    CADMPortalConfigurationDataLoading,
  ]);

  const [getActiveOcaTemplate, { data: activeOCATemplateData }] = useLazyQuery(QUERY_ACTIVE_OCA_TEMPLATE, {
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (chat.targetVoId !== voId) {
      dispatch({ type: SET_CHAT_RESET });
    }

    creditAppRefetch();
    if (window) window.dispatchEvent(new CustomEvent("voUpdated", { detail: { updated: false } }));
  }, []);

  useEffect(() => {
    if (isLenderUser && _.some(account)) {
      getVendorProfilesAssociatedToLender({
        variables: {
          dynamicsAccountId: account.dynamicsAccountId,
        },
      });
    }
  }, [isLenderUser, account]);

  useEffect(() => {
    if (_.isEmpty(vendorProfilesAssociatedToLenderData)) {
      return;
    }
    setVendorProfilesAssociatedToLender(vendorProfilesAssociatedToLenderData.vendorProfilesByDynamicsLenderAccountId);
  }, [vendorProfilesAssociatedToLenderData]);

  const { data: userDocsData, refetch: userDocsRefetch, loading: userDocsLoading, error: userDocsError } = useQuery(
    Q_USER_DOCS,
    {
      variables: {
        VOId: voId,
        docId: null,
      },
    }
  );
  const { data: invoiceDocData, refetch: invoiceDocRefetch } = useQuery(Q_INVOICE_DOC, {
    variables: {
      VOId: voId,
    },
  });

  const { data: docSets } = useQuery(DOC_SETS, {
    variables: {
      accountId: account.id,
    },
  });

  const { data: agreements, refetch: agreementsRefetch } = useQuery(AGREEMENTS, {
    variables: {
      VOId: voId,
    },
  });

  const { data: equipmentData, refetch: equipmentDataRefetch, loading: equipmentLoading } = useQuery(Q_EQUIPMENT, {
    variables: {
      VOId: voId,
    },
  });

  useEffect(() => {
    if (_.isEmpty(creditAppData?.creditApplication)) {
      return;
    }

    const { creditApplication } = creditAppData;

    dispatch({
      type: CHAT_V2_SET_CREDIT_APP,
      payload: creditApplication,
    });
    reduxSetValue("creditApp", creditApplication);
    setCreditApp(creditApplication);

    const currentCreditAppOcaTemplate = creditApplication.ocaTemplate;
    let vpIdToGetCADMPortalConfigurations;
    if (currentCreditAppOcaTemplate) {
      // Credit app could be submitted from both the lender's and dealer's templates
      vpIdToGetCADMPortalConfigurations = currentCreditAppOcaTemplate.vendorProfileId;
      setOcaTemplateToUse(currentCreditAppOcaTemplate);
      setIsLegacyOCA(false);
    } else {
      // For OCAv3, currentCreditAppOcaTemplate will be empty, so we want to use current active OCA template
      vpIdToGetCADMPortalConfigurations = account.vendorProfileId;
      getActiveOcaTemplate({
        variables: {
          vendorProfileId: account.vendorProfileId,
          isActive: true,
        },
      });
      setIsLegacyOCA(true);
    }

    getCADMPortalConfigurations({
      variables: {
        vendorProfileId: vpIdToGetCADMPortalConfigurations,
        types: [portalConfigurationTypes.CADMIndividual, portalConfigurationTypes.CADMCommercial],
      },
    });

    const creditAppContactsToSet = _([
      ...[creditApplication.primaryContact],
      ...(creditApplication.creditApplicationOwner ?? []),
    ])
      .compact()
      .uniqBy("ownerPgId")
      .value();
    setCreditAppContacts(creditAppContactsToSet);
  }, [creditAppData]);

  useEffect(() => {
    if (!!account && !!account.vendorProfileId) {
      if (!vpData) {
        getVendorProfile({
          variables: {
            id: account.vendorProfileId,
          },
          context: { authRequired: true },
        });
      }
    }
    if (vpData) {
      reduxSetValue("vp", vpData.vendorProfile);
      reduxSetValue("lp", vpData.vendorProfile.lenderProfiles);
      setVendorProfile(vpData.vendorProfile);
    }

    if (startTaskId && document.getElementById("task-list")) {
      document?.getElementById("task-list")?.scrollIntoView({ behavior: "smooth", block: "center" });

      console.log("START TASK ID", startTaskId);

      setTimeout(() => {
        setTaskId(startTaskId);
      }, 1000);
      queryParams.delete("taskId");
      replace({
        search: queryParams.toString(),
      });
    }
  }, [getVendorProfile, account, vpData, reduxSetValue]);

  useEffect(() => {
    if (userDocsData !== null && userDocsData !== undefined && userDocsData.documents !== null) {
      setUserDocs(userDocsData);
    }
  }, [userDocsData]);

  useEffect(() => {
    if (invoiceDocData !== null && invoiceDocData !== undefined) {
      if (invoiceDocData.invoiceDoc && invoiceDocData.invoiceDoc.length) {
        const date = invoiceDocData.invoiceDoc[0].createdDateTime;
        invoiceDocData.invoiceDoc[0].date = moment(date).format("ll hh:mma");
        setInvoiceDoc(invoiceDocData.invoiceDoc[0]);
      } else {
        setInvoiceDoc(false);
      }
    }
  }, [invoiceDocData]);

  const refetchDocs = () => {
    userDocsRefetch();
    invoiceDocRefetch();
  };

  const BoldText = ({ text }: any) => {
    return <b>{text}</b>;
  };

  const [getVo, { data: voData, loading: voLoading, refetch: voRefetch, error: voError }] = useLazyQuery(Q_VO, {
    context: { authRequired: true },
    fetchPolicy: "no-cache",
    variables: { voId },
  });

  useEffect(() => {
    const parseTableData = ({ label, apiKey, format, checkIfExists }: any) => {
      const rowData = getNested(vo, apiKey);
      if (checkIfExists && (!rowData || parseInt(rowData) === 0)) {
        return undefined;
      }

      if (rowData) {
        if (format === "upperCase") {
          let formattedString;
          if (rowData.includes("_")) {
            formattedString = vo[apiKey]
              .toLowerCase()
              .split("_")
              .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
              .join(" ");
          } else {
            formattedString = rowData
              .toLowerCase()
              .split(" ")
              .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
              .join(" ");
          }
          if (rowData.includes("delivered_pending_da")) {
            formattedString = "Delivered (Pending Acceptance)";
          }
          if (rowData.includes("da_complete")) {
            formattedString = "Customer Accepted";
          }
          return [label, formattedString];
        } else if (format === "months") {
          const formattedString = (
            <div style={{ display: "flex", width: "100%", justifyContent: "flex-end" }}>{rowData} months</div>
          );
          return [label, formattedString];
        } else if (format === "currency") {
          const formattedString = (
            <div style={{ display: "flex", width: "100%", justifyContent: "flex-end" }}>
              {new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(rowData)}
            </div>
          );
          return [label, formattedString];
        } else if (format === "date") {
          let inputDate = new Date(rowData + "T00:00:00");
          const getDay = inputDate.getDate();
          const getMonth = inputDate.getMonth() + 1; //Months are zero based
          const getYear = inputDate.getFullYear();
          const outputDate = getMonth + "-" + getDay + "-" + getYear;
          return [label, outputDate];
        } else {
          return [label, rowData];
        }
      } else {
        return [label, null];
      }
    };

    if (!!voData && !!voData.vendorOpportunity) {
      setVo(voData.vendorOpportunity);
      reduxSetValue("vo", vo);
      setVoLoaded(true);
      setTimeout(() => {
        if (window) window.dispatchEvent(new CustomEvent("voUpdated", { detail: { updated: true } }));
      }, 1000);
    } else {
      getVo();
    }

    if (!!vo && Object.keys(vo).length) {
      /*       const appStage =
        vo.applicationStage === "pre_qualified"
          ? "prequalified"
          : "dealer_cancelled"
          ? "dealer cancelled"
          : "customer_cancelled"
          ? "customer cancelled"
          : vo.applicationStage; */

      let appStage;
      switch (vo.applicationStage) {
        case "pre_qualified":
          appStage = "prequalified";
          break;
        case "dealer_cancelled":
          appStage = "dealer cancelled";
          break;
        case "customer_cancelled":
          appStage = "customer cancelled";
          break;
        default:
          appStage = vo.applicationStage;
      }

      setApplicationStage(appStage);
      setInvoiceStatus(vo.invoiceStatus);

      let invoiceDetailTableBodySource = invoiceDetailAPIKey.map(parseTableData).filter(i => i);
      invoiceDetailTableBodySource.forEach((item: any) => (item[0] = <BoldText text={item[0]} />));
      const deliveryTimeLineTableBodySource = deliveryTimelineAPIKey.map(i => parseTableData(i));
      deliveryTimeLineTableBodySource.forEach((item: any) => (item[0] = <BoldText text={item[0]} />));

      let contactsTableBodySource = [];

      contactsTableBodySource.push([
        <BoldText text="Sales Rep" />,
        vo.salesRepresentative ? (
          <span
            style={{ color: theme.palette.primary.main, cursor: "pointer" }}
            onClick={() => handleContactCardClick(vo?.salesRepresentative?.dynamicsContactId)}
          >
            {vo.salesRepresentative.fullName}
          </span>
        ) : (
          "N/A"
        ),
      ]);

      if (vo.salesManager) {
        contactsTableBodySource.push([
          <BoldText text="Sales Manager" />,
          <span
            style={{ color: theme.palette.primary.main, cursor: "pointer" }}
            onClick={() => handleContactCardClick(vo.salesManager ? vo.salesManager.dynamicsContactId : null)}
          >
            {vo.salesManager ? vo.salesManager.fullName : null}
          </span>,
        ]);
      }

      contactsTableBodySource.push([
        <BoldText text="Finance Manager" />,
        vo.financeManager ? (
          <span
            style={{ color: theme.palette.primary.main, cursor: "pointer" }}
            onClick={() => handleContactCardClick(vo.financeManager.dynamicsContactId)}
          >
            {vo.financeManager.fullName}
          </span>
        ) : (
          "N/A"
        ),
      ]);

      const handleContactCardClick = (id: any) => {
        if (!id) return;
        push(`/profile/${id}`);
      };
      contactsTableBodySource.forEach(item => (item[0] = <BoldText onClick={handleContactCardClick} text={item[0]} />));
      setContactsTableBody([...contactsTableBodySource]);

      const createdOn = new Date(Date.parse(vo.createdOn));
      setDateString(convertToDate(createdOn));
      setCustomerName(_.get(vo, "potentialCustomer.name", ""));
      setInvoiceDetailTableBody(invoiceDetailTableBodySource);
      setDeliveryTimeLineTableBody(deliveryTimeLineTableBodySource);
      setContactsTableBody(contactsTableBodySource);

      setShow(true);
    }
  }, [getVo, voData, vo, reduxSetValue]);

  useEffect(() => {
    if (
      _.some(equipmentData?.equipments) &&
      ((invoiceDetailTableBody[0] && invoiceDetailTableBody[0][0]?.props.text === "Equipment Cost (Subtotal)") ||
        (invoiceDetailTableBody[1] && invoiceDetailTableBody[1][0]?.props.text === "Equipment Cost (Subtotal)"))
    ) {
      let equipmentsInvoiceTable = [...invoiceDetailTableBody];
      const index = equipmentsInvoiceTable.findIndex(i => i[0].props.text === "Equipment Cost (Subtotal)");
      equipmentsInvoiceTable = equipmentsInvoiceTable.filter(i => i[0].props.text !== "Equipment Cost (Subtotal)");

      equipmentData.equipments.forEach((equipment: any) => {
        const title = `${equipment.year} ${equipment.make} ${equipment.model}${
          equipment.quantity > 1 ? " (Qty: " + equipment.quantity + ")" : ""
        }`;
        equipmentsInvoiceTable.splice(index, 0, [
          <BoldText text={title} />,
          <div style={{ display: "flex", width: "100%", justifyContent: "flex-end" }}>
            {new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(equipment.retailPrice)}
          </div>,
        ]);
      });
      setInvoiceDetailTableBody(equipmentsInvoiceTable);
    }
  }, [equipmentData, invoiceDetailTableBody]);

  useEffect(() => {
    const vendorProfileId = _.get(account, "vendorProfileId");
    if (!vendorProfileId) {
      return;
    }

    getPortalConfigurations({
      variables: {
        vendorProfileId,
        types: [
          portalConfigurationTypes.scorecard,
          portalConfigurationTypes.healthCheck,
          portalConfigurationTypes.creditReports,
          portalConfigurationTypes.documents,
          portalConfigurationTypes.voManagement,
          portalConfigurationTypes.voChecklistTemplate,
        ],
      },
    });
  }, [account]);

  useEffect(() => {
    if (_.isEmpty(activeOCATemplateData) || !!ocaTemplateToUse) {
      return;
    }
    setOcaTemplateToUse(_.first(activeOCATemplateData.getOcaTemplatesByVendorProfile));
  }, [activeOCATemplateData]);

  useEffect(() => {
    if (!CADMPortalConfigurationData || !creditAppData || !ocaTemplateToUse) {
      return;
    }
    const applicationType = _.get(creditAppData, "creditApplication.applicationType");
    const portalConfigurations = _.get(CADMPortalConfigurationData, "portalConfigurations") || [];
    const isIndividualCreditApp = checkIfIndividualCreditApp(applicationType);
    const CADMConfigurationTypeToUse = isIndividualCreditApp
      ? portalConfigurationTypes.CADMIndividual
      : portalConfigurationTypes.CADMCommercial;
    const CADMConfigurationToUse = _.find(portalConfigurations, { type: CADMConfigurationTypeToUse });
    if (_.some(CADMConfigurationToUse)) {
      assignFieldsOCAConfigFromActiveTemplate(CADMConfigurationToUse.jsonDefinition, ocaTemplateToUse.jsonDefinition);
    }
    setCADMPortalConfiguration(CADMConfigurationToUse);
    setIsIndividualCreditApp(isIndividualCreditApp);
  }, [CADMPortalConfigurationData, creditAppData, ocaTemplateToUse]);

  useEffect(() => {
    if (!portalConfigurationData || !creditAppData) {
      return;
    }
    const portalConfigurations = _.get(portalConfigurationData, "portalConfigurations") || [];
    const portalConfigurationsToSetByDefault = [
      portalConfigurationTypes.documents,
      portalConfigurationTypes.voChecklistTemplate,
    ];
    _.forEach(portalConfigurationsToSetByDefault, portalConfigurationType => {
      if (!_.find(portalConfigurations, { type: portalConfigurationType })) {
        portalConfigurations.push(
          getDefaultPortalConfigurationByType(portalConfigurationType, account.vendorProfileId)
        );
      }
    });
    setPortalConfigurationsByType(_.keyBy(portalConfigurations, "type"));
  }, [portalConfigurationData, creditAppData]);

  useEffect(() => {
    const scorecardPortalConfiguration = _.get(portalConfigurationsByType, portalConfigurationTypes.scorecard);
    if (_.some([creditSubmissionsData, scorecardPortalConfiguration], _.isEmpty)) {
      if (vo && vo.fastTrackResults && vo.fastTrackResults[0] && vo.fastTrackResults[0].inputs) {
        console.log("Got scorecard data from VO");
      } else {
        return;
      }
    }
    const creditSubmissions = _.values(creditSubmissionsData);
    const scorecardLenderProfileId = _.get(scorecardPortalConfiguration, "jsonDefinition.config.lenderProfileId");
    const scorecardUserRolesHidden = _.get(scorecardPortalConfiguration, "jsonDefinition.config.rolesSettings.hidden");
    const creditSubmissionForScorecard = _.find(
      creditSubmissions,
      cs => cs.lenderProfileId === scorecardLenderProfileId && _.some(cs.fastTrackResults)
    );
    setCreditSubmissionForScorecard(creditSubmissionForScorecard);
    setIsScorecardHiddenForUserRole(_.includes(scorecardUserRolesHidden, userProfile.vendorContactRole));
  }, [creditSubmissionsData, portalConfigurationsByType]);

  useEffect(() => {
    if (_.isEmpty(portalConfigurationsByType)) {
      return;
    }

    const isHealthCheckHiddenForUserRole = isItemHasRestrictionForRole(
      _.get(portalConfigurationsByType, `${portalConfigurationTypes.healthCheck}.jsonDefinition`),
      rolesSettingsKeys.hidden,
      userProfile.vendorContactRole
    );

    const isCreditReportsHiddenForUserRole = isItemHasRestrictionForRole(
      _.get(portalConfigurationsByType, `${portalConfigurationTypes.creditReports}.jsonDefinition`),
      rolesSettingsKeys.hidden,
      userProfile.vendorContactRole
    );

    const isCADMEditingDisabled = isItemHasRestrictionForRole(
      _.get(portalConfigurationsByType, `${portalConfigurationTypes.voManagement}.jsonDefinition`),
      rolesSettingsKeys.cadmReadOnly,
      userProfile.vendorContactRole
    );

    const isVOChecklistHidden =
      _.get(
        portalConfigurationsByType,
        `${portalConfigurationTypes.voChecklistTemplate}.jsonDefinition.config.hidden`
      ) === true;

    setIsHealthCheckHiddenForUserRole(isHealthCheckHiddenForUserRole);
    setIsCreditReportsHiddenForUserRole(isCreditReportsHiddenForUserRole);
    setIsCADMEditingDisabledForUserRole(isCADMEditingDisabled);
    setIsVOChecklistHidden(isVOChecklistHidden);
  }, [portalConfigurationsByType]);

  useEffect(() => {
    console.log(`Executing submit to ${handleSubmitToLenderByProfileId}`);
  }, [handleSubmitToLenderByProfileId]);

  if (refetch.vo) {
    setRefetch("vo", false);
    voRefetch();
  }

  if (refetch.creditApp) {
    setRefetch("creditApp", false);
    creditAppRefetch();
  }

  let showHealthCheck = false;

  if (vendorProfile && vendorProfile.ofacRedFlagComplianceAccess) {
    showHealthCheck = true;
  }

  const showCreditLineCard = Boolean(
    (creditApp && creditApp.creditLineAmountRequested > 0) ||
      (vendorProfile && vendorProfile.amountRequested === "equipPlusCreditLineOptions")
  );

  const isCashSale = vo?.entityType === "CASH_SALE";

  const callbackSubmissionFinished = (dynamicsCreditSubmissionId: any) => {
    console.log(`Finished submission with ID ${dynamicsCreditSubmissionId}`);
    //Send mutation to update Lender Waterfall
    setHandleSubmitToLenderByProfileId(null);
  };

  const handleSubmitLenderWaterfall = (profileId: string) => {
    setHandleSubmitToLenderByProfileId(profileId);
  };

  const handleLegacyCreditAppRequest = async () => {
    const url = `${DCR_API_BASE_SERVER_URL}/create-pdf?transaction_id=${vo.transactionId}`;
    const data = {
      vendorProfile,
      account: { account, userProfile },
      userProfile: userProfile,
      download: false,
    };
    const config: any = {
      responseType: "json",
      headers: {
        "Content-Type": "application/json",
        Accept: "text/html",
      },
    };
    return axios
      .post(url, data, config)
      .then(response => {
        setLegacyPDFData(response.data);
        setOpenLegacyCreditAppModal(true);
      })
      .catch(error => console.log(error));
  };

  // Submissions tab

  const isDataExist = !!vp && !!userProfile;
  const isSubmiossionsAvailableForCurrentUserRole = [
    VendorContactRole.CreditMgr,
    VendorContactRole.SalesMgr,
    VendorContactRole.Admin,
    VendorContactRole.Executive,
  ].includes(userProfile.vendorContactRole);

  const isRenderSubmissionsTab =
    (isDataExist && !isCashSale && vp.showLenderSubmission === "all_roles") ||
    vp.showLenderSubmission === "all_roles_full_access" ||
    (vp.showLenderSubmission === "credit_manager_only" &&
      userProfile.vendorContactRole === VendorContactRole.CreditMgr) ||
    (vp.showLenderSubmission === "all_managers" && isSubmiossionsAvailableForCurrentUserRole && !_.isEmpty(creditApp));

  // Proposals tab

  const isRenderVOProposalsTab =
    !!account &&
    !!creditAppData?.creditApplication &&
    (vp?.showProposal === "show" || vp?.showProposal === "show_base_gbb") &&
    (userProfile.vendorContactRole === VendorContactRole.CreditMgr ||
      userProfile.vendorContactRole === VendorContactRole.Admin);

  const isCreditAppNeedsRecertification: boolean =
    creditApp &&
    vp &&
    vp.requireCreditAppRecertification &&
    vp.daysToCreditAppRecertification &&
    calculateDaysAfterLastRecertification(creditApp) >= vp.daysToCreditAppRecertification;

  const [pageContentViewType, setPageContentViewType] = useState<"full" | "details">("full");

  const { handleUpdateInvoice, isInvoiceUpdating } = useInvoice();
  const { handleOpen: handleOpenUpdateInvoiceModal } = useModal(ModalsKeys.CreateUpdateInvoiceDialog);

  const updateInvoice = () => {
    handleOpenUpdateInvoiceModal({
      invoice: collectInvoiceFromVO(vo),
      onSubmit: async invoiceFormValues => {
        try {
          const { netTrade, netFinanceAmount, ...fieldsObj } = invoiceFormValues;

          await handleUpdateInvoice({
            fieldsObj,
            VOId: vo.vendorOpportunityId,
            entityId: vo.dynamicsVendorOpportunityId,
          });

          await voRefetch();

          showNotification("Invoice updated!");
        } catch (error) {
          logError(error);
          showNotification("Invoice not updated!", { type: "error" });
        }
      },
    });
  };

  const isRenderCreditLineTab =
    showCreditLineCard ||
    vp.showDocMgt === 2 ||
    vp.showTasks === "show" ||
    (showHealthCheck && creditApp?.creditApplicationOwner?.length > 0 && !isHealthCheckHiddenForUserRole);

  const tabs: TabsOption<VendorOpportunityTabs>[] = [
    {
      label: "General",
      value: VendorOpportunityTabs.General,
      isVisible: true,
      component: (
        <Grid container spacing={1}>
          {!isCashSale && (
            <Grid item xs={12}>
              <ProgressStepper />
            </Grid>
          )}

          <Grid item sm={12} md={6} style={{ width: "100%" }}>
            <CustomerSummaryCard_V2
              goToCADView={() => {
                setPageContentViewType("details");
              }}
              contentViewType={contentViewType}
              vo={vo}
              refetchVO={voRefetch}
              equipmentData={equipmentData}
              refetchEquipment={equipmentDataRefetch}
              equipmentLoading={equipmentLoading}
              invoiceEquipmentTabPage={invoiceEquipmentTabPage}
              creditApplication={creditApp}
              vp={vendorProfile}
              userProfile={userProfile}
              account={account}
              refetchCreditApp={creditAppRefetch}
              userDocs={userDocs}
              userDocsRefetch={userDocsRefetch}
              isIndividualCreditApp={isIndividualCreditApp}
              tasksData={tasksData}
              portalConfigurationDataLoading={portalConfigurationDataLoading || CADMPortalConfigurationDataLoading}
              isCreditReportsHiddenForUserRole={isCreditReportsHiddenForUserRole}
              isCreditAppNeedsRecertification={isCreditAppNeedsRecertification}
              creditAppOwners={creditAppOwners}
              refetchDocs={refetchDocs}
            />
          </Grid>

          {!!account && !!vo && (
            <Grid item sm={12} md={6} style={{ width: "100%" }}>
              <InvoiceEquipmentDetailsCard
                vo={vo}
                account={account}
                vendorProfile={vp}
                creditApp={creditApp}
                refetchVO={voRefetch}
                invoiceDoc={invoiceDoc}
                updateInvoice={updateInvoice}
                equipmentData={equipmentData}
                invoiceStatus={invoiceStatus}
                refetchDocs={() => refetchDocs()}
                refetchCreditApp={creditAppRefetch}
                refetchEquipment={equipmentDataRefetch}
                openEquipmentInputModal={openEquipmentInputModal}
                isInvoiceUpdating={voLoading || isInvoiceUpdating}
                setOpenEquipmentInputModal={setOpenEquipmentInputModal}
                vendorProfilesAssociatedToLender={vendorProfilesAssociatedToLender}
                loadingVendorProfilesAssociatedToLender={loadingVendorProfilesAssociatedToLender}
              />
            </Grid>
          )}

          {showHealthCheck && creditApp?.creditApplicationOwner?.length > 0 && !isHealthCheckHiddenForUserRole && (
            <Grid item xs={12}>
              <HealthCheckCard
                creditApp={creditApp}
                accountId={account.id}
                full={!showCreditLineCard}
                userName={userProfile.fullName}
                refetchCreditApp={creditAppRefetch}
                softPullInDealerPortal={vendorProfile.softPullInDealerPortal}
              />
            </Grid>
          )}

          {!isScorecardHiddenForUserRole && (
            <Grid item xs={12}>
              <ScoreCard
                cs={creditSubmissionForScorecard}
                vo={vo}
                scorecardConfig={_.get(
                  portalConfigurationsByType,
                  `${portalConfigurationTypes.scorecard}.jsonDefinition.config`
                )}
                userProfile={userProfile}
              />
            </Grid>
          )}

          {!vp ? (
            <Grid item xs={12}>
              no VendorProfile loaded
            </Grid>
          ) : vp.dcrPrescreenStatus === "804790001" ||
            vp.dcrPrescreenStatus === "804790002" ||
            vp.dcrPrescreenStatus === "804790003" ? (
            <Grid item xs={12}>
              <LenderMatchingEngine />
            </Grid>
          ) : (
            <div />
          )}

          {!!account && !!vo && (
            <Grid item xs={12} md={6}>
              <DeliveryTimelineCard
                vo={vo}
                refetchVO={voRefetch}
                contentViewType={contentViewType}
                deliveryTimeLineTableBody={deliveryTimeLineTableBody}
              />
            </Grid>
          )}

          {!!account && !!vo && !!vo?.potentialCustomer && (
            <Grid item xs={12} md={6}>
              <ContactCard
                vo={vo}
                refetchVO={voRefetch}
                creditApp={creditApp}
                contentViewType={contentViewType}
                contactsTableBody={contactsTableBody}
                setContactsTableBody={setContactsTableBody}
                multiLocationManagement={vp.multiLocationManagement}
              />
            </Grid>
          )}

          <Snackbar
            autoHideDuration={null}
            onClose={() => setFastTrackSnackBarClosed(true)}
            open={fastTrackProcessStarted && !fastTrackSnackBarClosed}
            anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
          >
            <MuiAlert elevation={6} color="success">
              This deal has been fast tracked! Check your email periodically for updates!
            </MuiAlert>
          </Snackbar>

          {vp && vp.showNotes && (
            <Grid item xs={12}>
              <NotesCard vo={vo} userProfile={userProfile} />
            </Grid>
          )}

          {vp.docusignDocMgmt === "show" &&
            (vo?.applicationStage === "approved" || vo?.applicationStage === "pre-approved") && (
              <Grid item xs={12}>
                <Agreements
                  vo={vo}
                  handleOpen={handleOpenDocSetModal}
                  agreementsRefetch={agreementsRefetch}
                  agreements={agreements ? agreements.agreements : null}
                />
              </Grid>
            )}

          {!vp ? "no VendorProfile loaded" : !!vp && vp.showChat === "show" ? null : null}
        </Grid>
      ),
    },
    {
      label: "Credit Line",
      isVisible: isRenderCreditLineTab,
      value: VendorOpportunityTabs.CreditLine,
      component: (
        <CreditLineTab
          vo={vo}
          creditApp={creditApp}
          accountId={account.id}
          vendorProfile={vendorProfile}
          userName={userProfile.fullName}
          creditAppRefetch={creditAppRefetch}
          permissions={{
            creditLine: showCreditLineCard,
            docs: vp.showDocMgt === 2,
            tasks: vp.showTasks === "show",
            healthCheck:
              showHealthCheck && creditApp?.creditApplicationOwner?.length > 0 && !isHealthCheckHiddenForUserRole,
          }}
        />
      ),
    },
    {
      label: "Tasks",
      value: VendorOpportunityTabs.Tasks,
      isVisible: true,
      component: (
        <>
          {!vp ? (
            <div id="task-list"></div>
          ) : (
            vp.showTasks === "show" && (
              <Grid item xs={12}>
                <div id="task-list">
                  <VOSpecificTasks_V2
                    vo={vo}
                    taskId={taskId}
                    conversations={null}
                    refetchConversations={() => {}}
                    conversationsLoading={false}
                    voId={voId}
                    account={account}
                    userProfile={userProfile}
                    onTasksLoaded={onTasksLoaded}
                    handleConversationCreation={() => {}}
                    documents={userDocs?.documents}
                    documentsPortalConfiguration={_.get(portalConfigurationsByType, portalConfigurationTypes.documents)}
                    refetchDocs={refetchDocs}
                  />
                </div>
              </Grid>
            )
          )}

          {vo && !isVOChecklistHidden && (
            <Grid item xs={12}>
              <VOChecklistCard voId={vo?.vendorOpportunityId} voChecklist={vo?.checklist} refetchVO={voRefetch} />
            </Grid>
          )}
        </>
      ),
    },
    {
      label: "Documents",
      value: VendorOpportunityTabs.Documents,
      isVisible: true,
      component: allConfigurationsLoaded ? (
        <>
          <Grid item xs={12}>
            {!!vp && vp.showDocMgt === 2 && (
              <VODocuments_V2
                vo={vo}
                userDocs={userDocs}
                tasksData={tasksData}
                refetchDocs={refetchDocs}
                userDocsError={userDocsError}
                userDocsLoading={userDocsLoading}
                refetchCreditApp={creditAppRefetch}
                CADMPortalConfiguration={CADMPortalConfiguration}
                showDcrCreditAppRow={vo?.transactionStage !== "Lead"}
                isCADMEditingDisabled={isCADMEditingDisabledForUserRole}
                documentsPortalConfiguration={_.get(portalConfigurationsByType, portalConfigurationTypes.documents)}
                portalConfigurationDataLoading={portalConfigurationDataLoading || CADMPortalConfigurationDataLoading}
              />
            )}
          </Grid>

          {!!creditAppData && !!creditAppData.creditApplication && (
            <SendDocsModal
              vo={vo}
              account={account}
              userProfile={userProfile}
              open={openDocSetModal}
              handleClose={handleCloseDocSetModal}
              agreementsRefetch={agreementsRefetch}
              docSets={docSets ? docSets.docSets : null}
              creditApp={creditAppData.creditApplication}
            />
          )}
        </>
      ) : (
        <CircularProgress />
      ),
    },
    {
      label: "Submissions",
      value: VendorOpportunityTabs.Submissions,
      isVisible: isRenderSubmissionsTab,
      component: (
        <Grid container spacing={1}>
          {isUserHaveAccessToPrescreenResults && (
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <LenderWaterfallResults
                accountId={account.id}
                vendorOpportunityId={voId}
                lenderProfiles={vp.lenderProfiles}
                creditSubmissions={creditSubmissionsData}
                handleSubmitToLenderByProfileId={handleSubmitLenderWaterfall}
              />
            </Grid>
          )}

          {isDataExist && !isCashSale ? (
            vp.showLenderSubmission === "all_roles" ||
            vp.showLenderSubmission === "all_roles_full_access" ||
            (vp.showLenderSubmission === "credit_manager_only" &&
              userProfile.vendorContactRole === VendorContactRole.CreditMgr) ||
            (vp.showLenderSubmission === "all_managers" && isSubmiossionsAvailableForCurrentUserRole) ? (
              <>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  {!_.isEmpty(creditApp) ? (
                    <VOSyndicationsList_V2
                      setInvoiceEquipmentTabPage={setInvoiceEquipmentTabPage}
                      equipmentData={equipmentData}
                      setOpenEquipmentInputModal={setOpenEquipmentInputModal}
                      portalConfiguration={CADMPortalConfiguration}
                      handleSubmitToLenderByProfileId={handleSubmitToLenderByProfileId}
                      callbackSubmissionFinished={callbackSubmissionFinished}
                    />
                  ) : null}
                </Grid>
              </>
            ) : (
              <></>
            )
          ) : (
            <></>
          )}
        </Grid>
      ),
    },
    {
      label: "Desking",
      value: VendorOpportunityTabs.Desking,
      isVisible: true,
      disabled: !creditAppData,
      component: (
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <CardContainer>
              <ConfigurationPanel
                vo={vo}
                accountId={account.id}
                invoice={collectInvoiceFromVO(vo)}
                creditApplication={creditAppData?.creditApplication}
                updateInvoiceAction={
                  <Button size="small" variant="contained" disabled={isInvoiceUpdating} onClick={updateInvoice}>
                    {isInvoiceUpdating ? "Updating" : "Edit"}
                  </Button>
                }
                onEquipmentCreated={async () => {
                  showNotification("Equipment created!");
                  await voRefetch();
                }}
                onEquipmentUpdated={async () => {
                  showNotification("Equipment updated!");
                  await voRefetch();
                }}
                onEquipmentRemoved={async () => {
                  showNotification("Equipment Removed!");
                  await voRefetch();
                }}
              />
            </CardContainer>
          </Grid>

          {isRenderVOProposalsTab && (
            <Grid item xs={12}>
              <ProposalsTable vendorGUID={account.dynamicsAccountId} />
            </Grid>
          )}
        </Grid>
      ),
    },
  ];

  const render = {
    full: (
      <>
        {show ? (
          <DocumentContext.Provider value={{ lastDoc: lastDocument, documents: [], setLastDocument }}>
            <Box className={classes.pageContainer}>
              <Paper className={classes.head} elevation={0}>
                <Box className={classes.headContent}>
                  <Typography variant="h5">
                    Opportunity Details: {creditAppData?.creditApplication?.businessName}
                  </Typography>

                  {isDesktop && (
                    <Breadcrumbs
                      currentValue="Detail"
                      config={[
                        { label: "Opportunity View", value: "list", onClick: () => push("/vo") },
                        { label: "Detail", value: "Detail", onClick: () => {} },
                      ]}
                    />
                  )}
                </Box>

                {vo?.partnerLogo && <VendorLogoImg partnerLogo={vo.partnerLogo} />}
              </Paper>

              <TabsContainer<VendorOpportunityTabs>
                tabs={tabs}
                TabsLabelWrapper={CardBackground}
                contentViewType={contentViewType}
              />
            </Box>

            {!vp && "no VendorProfile loaded"}
          </DocumentContext.Provider>
        ) : (
          <>
            {voLoading && <CircularProgress />}
            {voError && <>Loading Error...</>}
          </>
        )}
      </>
    ),
    details: (
      <CreditApplicationDetailsFullView
        backToCommonView={() => {
          setPageContentViewType("full");
        }}
        creditApplicationState={{
          vo,
          account,
          isLegacyOCA,
          refetchTasks,
          equipmentData,
          vendorProfile: vp,
          refetchVO: voRefetch,
          pageType: PageTypes.VendorOpportunities,
          refetchCreditAppOuter: creditAppRefetch,
          isEditingDisabled: isCADMEditingDisabledForUserRole,
          vendorOpportunityTasks: tasksData?.vendorOpportunityTask,
          creditAppModalConfig: CADMPortalConfiguration?.jsonDefinition,
          isCreditAppNeedsRecertification: isCreditAppNeedsRecertification,
          creditAppOwners: creditAppOwners,
          applicant: creditAppData?.creditApplication?.businessName || vo?.potentialCustomer?.name,
          handleLegacyCreditAppRequest,
        }}
      />
    ),
  };

  const modalsWithKeys: Partial<Record<ModalsKeys, ReactNode>> = {
    [ModalsKeys.CreateUpdateInvoiceDialog]: <CreateUpdateInvoiceModal />,
  };

  return (
    <>
      {render[pageContentViewType]}

      {/* Legacy Modal (CADMv1) */}
      <Modal
        open={openLegacyCreditAppModal}
        onClose={() => setOpenLegacyCreditAppModal(false)}
        aria-labelledby="Credit Application"
        aria-describedby="Summary"
      >
        <>
          {!!legacyPDFData && (
            <NewCreditAppModal
              {...legacyPDFData}
              {...vo}
              contactEmail={legacyPDFData.businessEmail}
              softPullInDealerPortal={vendorProfile.softPullInDealerPortal}
              refetchCreditApp={() => {}}
              handleRefetchCreditAppRequest={() => {}}
            />
          )}
        </>
      </Modal>

      <ModalsContainer modalsWithKeys={modalsWithKeys} />
    </>
  );
};

const mapStateToProps = (state: any) => ({
  account: state.account,
  userProfile: state.userProfile,
  vp: state.vp,
  refetch: state.refetch,
  chat: state.chat,
  creditApp: state.creditApp,
  refetchCreditApp: state.refetchCreditApp,
});

const mapDispatchToProps = {
  reduxSetValue: setValue,
  setRefetch,
};

export default connect(mapStateToProps, mapDispatchToProps)(Details);

const useStyles = makeStyles({
  pageContainer: {
    minHeight: "100vh",
    gap: "0.3rem",
    display: "flex",
    flexDirection: "column",
  },
  head: {
    display: "flex",
    justifyContent: "space-between",
    padding: "1.5rem 1.5rem 0.5rem 1.5rem",
  },
  headContent: {
    gap: "0.5rem",
    display: "flex",
    flexDirection: "column",
  },
});
