import React, { useEffect } from "react";
import { ModalsKeys } from "global";
import { Box } from "@material-ui/core";
import { logError } from "utils/logger";
import { useModal } from "hooks/useModal";
import { blue } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { invoiceCalculations } from "modules/invoice";
import { FormProvider, useForm } from "react-hook-form";
import { useNotifications } from "modules/notification";
import { useLazyQuery, useMutation, useQuery } from "@apollo/react-hooks";
import { PanelForm } from "./PanelForm";
import { PanelFormActions } from "./PanelFormActions";
import { CREATE_PROPOSAL_V2, GET_EQUIPMENT, GET_FINANCE_PROGRAMS } from "../../../api";
import { Equipment, FinanceProgramFormValues, SubmitOptions, UserActionsEvents } from "../../../types";
import { collectDataForCreateProposal, equipmentMileageFormatter, getDefaultValues } from "../../../lib";
import {
  CreateProposalResponse,
  CreateProposalVariables,
  EquipmentsResponse,
  EquipmentsVariables,
  FinanceProgramsResponse,
  FinanceProgramsVariables,
} from "../../../api/types";
import { equipmentDependentFields } from "../../../constants";
import { deskingActions, deskingSelectors } from "../../../model";

export const PanelFormContainer = ({
  vo,
  invoice,
  accountId,
  creditApplication,
  onEquipmentCreated,
  onEquipmentUpdated,
  onEquipmentRemoved,
  updateInvoiceAction,
}: UserActionsEvents) => {
  const classes = useStyles();

  const { handleOpen } = useModal(ModalsKeys.DeskingConfirmSaveDraftDialog);
  const { showNotification } = useNotifications();

  const dispatch = useDispatch();
  const menuByTerms = useSelector(deskingSelectors.menuByTerms);
  const proposalProductConfigurationsInMenu = useSelector(deskingSelectors.proposalProductConfigurationsInMenu);

  dispatch(deskingActions.setCreditAppLocationData({ creditAppLocationData: creditApplication?.location }));

  const { data: equipmentData, refetch: refetchEquipmentData } = useQuery<
    EquipmentsResponse<Equipment>,
    Partial<EquipmentsVariables>
  >(GET_EQUIPMENT, {
    skip: !vo.vendorOpportunityId,
    variables: { VOId: vo.vendorOpportunityId },
    onCompleted(response) {
      if (!Array.isArray(response?.equipments)) return;
      dispatch(deskingActions.setEquipmentData({ equipments: response.equipments }));
    },
  });

  const form = useForm<FinanceProgramFormValues>({
    defaultValues: getDefaultValues(vo, creditApplication, equipmentData?.equipments ?? []),
  });

  const [getEquipmentDataById] = useLazyQuery<EquipmentsResponse, EquipmentsVariables>(GET_EQUIPMENT, {
    onCompleted(response) {
      const equipment = response?.equipments?.[0];

      form.setValue("equipment.selectedEquipment", equipment?.equipmentId);
      form.setValue(`equipment.mileage`, equipmentMileageFormatter(equipment?.mileage, "toView"));

      equipmentDependentFields.forEach(field => form.setValue(`equipment.${field}`, equipment?.[field] ?? ""));

      dispatch(deskingActions.setIsNeedToAutoFillProductsDynamicFields());
      dispatch(deskingActions.setCurrentEquipmentData({ equipmentId: equipment?.equipmentId }));
    },
  });

  useQuery<FinanceProgramsResponse, FinanceProgramsVariables>(GET_FINANCE_PROGRAMS, {
    skip: !accountId,
    variables: { accountId },
    onCompleted(response) {
      if (!Array.isArray(response?.financePrograms)) return;
      dispatch(deskingActions.setFinancePrograms({ programs: response.financePrograms }));
    },
  });

  const [onCreateProposal, { loading: isProposalLoading }] = useMutation<
    CreateProposalResponse,
    CreateProposalVariables
  >(CREATE_PROPOSAL_V2);

  const handleCreateProposal = async (variables: CreateProposalVariables) => {
    try {
      const { data } = await onCreateProposal({ variables });

      if (data?.createProposalV2?.id) {
        showNotification("Proposal created!");
        dispatch(deskingActions.setProposalCreated({ isCreated: true }));
      } else {
        showNotification(
          "Proposal not created, Buy Rate, Amount Financed, and/or Term are missing, please check and try again!",
          {
            type: "warning",
            duration: 5000,
          }
        );
      }
    } catch (error) {
      logError(error);
    } finally {
      dispatch(deskingActions.setProposalCreated({ isCreated: false }));
    }
  };

  const handleSubmitForm = async ({ isDraft }: SubmitOptions) => {
    const formData = form.getValues();
    const paymentOptions = collectDataForCreateProposal(formData, menuByTerms, proposalProductConfigurationsInMenu);

    const variables: CreateProposalVariables = {
      input: {
        paymentOptions,
        sendProposal: !isDraft,
        proposalMenuId: formData.financeQuote?.menu,
        vendorOpportunityId: vo.vendorOpportunityId,
        financeProgramId: formData.financeQuote?.program,
        settings: {
          view: {
            isShowProductDetails: formData.displaySettings.productDetails,
            isShowProductPrice: formData.displaySettings.productPrice,
          },
        },
      },
    };

    if (isDraft) handleOpen({ onSaveAsDraft: () => handleCreateProposal(variables) });
    else await handleCreateProposal(variables);
  };

  // TODO: Not the best solution, need to decide on synchronization
  useEffect(() => {
    if (!invoice) return;

    const { invoiceTotal, netFinanceAmount, netTrade, totalFees } = invoiceCalculations.byInvoice(invoice);

    form.setValue("saleDetails.fees", totalFees);
    form.setValue("saleDetails.netRate", netTrade);
    form.setValue("saleDetails.invoiceTotal", invoiceTotal);
    form.setValue("saleDetails.financeAmount", netFinanceAmount);
    form.setValue("saleDetails.downPayment", invoice.downPayment);
    form.setValue("saleDetails.salesTax", invoice.salesTaxOnInvoice);
    form.setValue("saleDetails.equipmentSubtotal", invoice.equipmentCost);
  }, [invoice]);

  return (
    <Box className={classes.formContainer}>
      <FormProvider {...form}>
        <PanelForm
          vo={vo}
          accountId={accountId}
          creditApplication={creditApplication}
          updateInvoiceAction={updateInvoiceAction}
          onEquipmentCreated={async equipmentId => {
            await refetchEquipmentData();
            if (onEquipmentCreated) await onEquipmentCreated(equipmentId);

            getEquipmentDataById({ variables: { VOId: vo.vendorOpportunityId, id: equipmentId } });
          }}
          onEquipmentUpdated={async equipmentId => {
            await refetchEquipmentData();
            if (onEquipmentUpdated) await onEquipmentUpdated(equipmentId);

            dispatch(deskingActions.setIsNeedToAutoFillProductsDynamicFields());
          }}
          onEquipmentRemoved={async equipmentId => {
            await refetchEquipmentData();
            if (onEquipmentRemoved) await onEquipmentRemoved(equipmentId);
          }}
        />
      </FormProvider>

      <PanelFormActions onSubmitForm={handleSubmitForm} isProposalLoading={isProposalLoading} />
    </Box>
  );
};

const useStyles = makeStyles(({ palette: { primary } }) => ({
  formContainer: {
    gap: "16px",
    display: "flex",
    flexDirection: "column",

    "& .productName": {
      textTransform: "capitalize",
    },

    "& .row": {
      gap: "8px",
      display: "flex",

      "& > *": {
        flex: 1,
      },
    },

    "& .section": {
      gap: "6px",
      display: "flex",
      flexDirection: "column",

      "& .actions": {
        gap: "2px",
        display: "flex",
        alignItems: "center",
      },

      "& .sectionHeader": {
        display: "flex",
        minHeight: "56px",
        borderRadius: "2px",
        position: "relative",
        padding: "13px 16px",
        alignItems: "center",
        backgroundColor: blue["50"],
        justifyContent: "space-between",

        "& .collapse": {
          gap: "8px",
          display: "flex",
          alignItems: "center",
        },

        "& .actionPanel": {
          gap: "8px",
          display: "flex",
          alignItems: "center",
        },

        "& .MuiButton-contained": {
          borderRadius: "var(--borderRadius, 4px)",
          background: "var(--common-white_states-main, #FFF)",
          boxShadow:
            "0px 1px 5px 0px rgba(0, 0, 0, 0.12), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.20)",

          "& > span.MuiButton-label": {
            color: primary.main,
          },

          "&.Mui-disabled": {
            boxShadow: "none",

            "& > span.MuiButton-label": {
              color: primary.light,
            },
          },
        },
      },

      "& .sectionFields": {
        gap: "1rem",
        display: "flex",
        flexDirection: "column",
      },
    },
  },
}));
