import _ from "lodash";
import { FACT_NAMES_BOOLEAN, FACT_NAMES_DATE, FACT_NAMES_NUMERIC, FACT_NAMES_STRING } from "@trnsact/business-criteria";
import { ProposalProduct } from "@trnsact/trnsact-shared-types/dist/generated";
import { Criteria, Fact, FactToCheck, FactToCheckTypes, ProposalProductCardModes } from "../types";
import { equipmentFactsForSimpleProductToNotShowOnMenuConstructor } from "../constants/proposalProduct";

export function collectFactsToCheckFromProduct(
  product: ProposalProduct,
  mode: ProposalProductCardModes = ProposalProductCardModes.Desking
): FactToCheck[] {
  const productRules = (product?.aftermarketProduct?.criteria ?? []) as Criteria[];

  if (!productRules.length) return [];

  const factsToCheck: FactToCheck[] = [];
  const addedFacts = new Set<Fact>();

  const factTypeMap: Record<string, FactToCheckTypes> = {
    ...Object.fromEntries(Object.keys(FACT_NAMES_BOOLEAN).map(key => [key, "boolean"])),
    ...Object.fromEntries(Object.keys(FACT_NAMES_NUMERIC).map(key => [key, "numeric"])),
    ...Object.fromEntries(Object.keys(FACT_NAMES_DATE).map(key => [key, "numeric"])),
    ...Object.fromEntries(Object.keys(FACT_NAMES_STRING).map(key => [key, "string"])),
  };

  productRules.forEach(({ conditions }) => {
    conditions?.formRules?.forEach(rule => {
      if (!rule.requiredForQuote) return;

      const factType = factTypeMap[rule.fact] ?? factTypeMap[rule.factName ?? ""];

      if (!factType) return;

      if (
        mode === ProposalProductCardModes.Constructor &&
        equipmentFactsForSimpleProductToNotShowOnMenuConstructor.includes(rule.fact ?? rule.factName ?? "")
      ) {
        return;
      }

      if (addedFacts.has(rule.fact)) {
        if (factType === "string" && rule.exactMatch) {
          const factIndex = factsToCheck.findIndex(fact => fact.factKey === rule.fact);
          const existingOptions = factsToCheck[factIndex].options ?? [];

          factsToCheck[factIndex].options = _.uniq([
            ...existingOptions,
            ...(Array.isArray(rule.exactMatch) ? rule.exactMatch : [rule.exactMatch]),
          ]);
        }
      } else {
        factsToCheck.push({
          factKey: rule.fact,
          type: factType,
          rule,
          options:
            factType === "string" && rule.exactMatch
              ? Array.isArray(rule.exactMatch)
                ? rule.exactMatch
                : [rule.exactMatch]
              : null,
        });
        addedFacts.add(rule.fact);
      }
    });
  });

  return factsToCheck;
}
