import { ReactNode } from "react";

export type UserProfile = {
  id: string;
  email: string | null;
  dynamicsContactId: string | null;
  firstName: string | null;
  lastName: string | null;
  fullName: string | null;
  phoneNumber: string | null;
  mobilePhoneNumber: string | null;
  mugshot: string | null;
  title: string | null;
  accountId: string | null;
};

export type Account = {
  id: string;
  dynamicsAccountId: string | null;
  vendorProfileId: string | null;
  primaryContactId: string | null;
  name: string | null;
  address: string | null;
  phone: string | null;
  primaryContactName: string | null;
  primaryContactPhone: string | null;
  primaryContactEmail: string | null;
};

export type LenderProfile = {
  id: string;
  hasActiveUser: boolean;
  lenderName: string;
  lenderLogo: string | null;
  showCustomApp: boolean;
  draftApplicationsEnabled: boolean;
  pdfTemplate: string;
  submissionMethod: string;
  lenderProfileId: string;
  lenderAccountId: string;
  lenderContactId: string | null;
  lenderChannelCode: number;
  lenderTypeCode: number;
  dealerRelationship: string | null;
  submissionReviewShowMappingModal: string;
  insuranceRequirements: string | null;
  lenderUserProfile: UserProfile | null;
  lenderAccount: Account | null;
};

export enum PortalConfigurationRolesSettingsKeys {
  Masked = "masked",
  Hidden = "hidden",
  ReadOnly = "readOnly",
  HiddenOnPDF = "hiddenOnPDF",
  ReportsHidden = "reportsHidden",
  AssignedOnly = "assignedOnly",
  LocationBased = "locationBased",
  DocumentsByTypeSettingsHidden = "documentsByTypeSettingsHidden",
  NotificationsManagementHidden = "notificationsManagementHidden",
  CadmReadOnly = "cadmReadOnly",
  DisallowVoСreation = "disallowVoСreation",
  // Prescreen
  PrescreenType = "prescreenType",
  HiddenPrescreenPage = "hiddenPrescreenPage",
  HiddenPrescreenResults = "hiddenPrescreenResults",
  HiddenPrescreenSubmissionAccess = "hiddenPrescreenSubmissionAccess",
}

export type RouteConfig = {
  id: string;
  showInSidebarNav: boolean;
  exact: boolean;
  path: string; // always used in path for <Route /> component
  dynamicPath?: string; // used for dynamic path on sidebar nav item
  name: string;
  component: any /* ReactNode */;
  icon?: any /* ReactNode */;
  type?: string;
  fastTrackOption?: boolean;
  isNewFeature?: boolean;
  action?: Function;
  isVisible: Function;
};

export interface ProposalProductsDialogModalData {
  products: any;
  selectedProposalsProductsIds: string[];
  onConfirm: (selectedProducts: any) => void;
}

export interface ReferenceTaskCompletionDataItem {
  fieldKey: string;
  value: string;
  label: string;
}

export interface ReferenceTaskCompletionData {
  taskId: string;
  data: ReferenceTaskCompletionDataItem[];
}
