import { Nullable } from "global";
import { useQuery } from "react-apollo";
import { GET_VENDOR_OPPORTUNITY_DOCUMENTS } from "../api";
import { VODocumentsResponse, VODocumentsVariables } from "../api/types";

interface Options {
  VOId: string;
  documentId?: Nullable<string>;
}

export function useVODocs({ documentId = null, VOId }: Options) {
  const { data, refetch: fetchVODocs, loading } = useQuery<VODocumentsResponse, VODocumentsVariables>(
    GET_VENDOR_OPPORTUNITY_DOCUMENTS,
    {
      variables: { VOId, docId: documentId },
    }
  );

  return {
    fetchVODocs,
    isVODocsLoading: loading,
    docs: data?.documents ?? [],
  };
}
