import { gql } from "apollo-boost";

export const GET_VENDOR_OPPORTUNITY_DOCUMENTS = gql`
  query($docId: ID, $VOId: ID) {
    documents(docId: $docId, VOId: $VOId) {
      documentId
      vendorOpportunityId
      source
      docType
      docDescription
      createdBy
      createdDateTime
      docName
      documentCreditSubmission {
        creditSubmissionId
        lenderAccess
      }
    }
  }
`;
