import React from "react";
import { Chip, makeStyles } from "@material-ui/core";
import { AftermarketProductCategory, AftermarketProductType } from "@trnsact/trnsact-shared-types";

interface Props {
  productCategory: AftermarketProductCategory;
  productType: AftermarketProductType;
}

export const ProposalProductsCardTypeChip = ({ productCategory, productType }: Props) => {
  const classes = useStyles();

  const chipClassByType = {
    [AftermarketProductCategory.Gap]: classes.gap,
    [AftermarketProductCategory.Roadside]: classes.roadside,
    [AftermarketProductCategory.ServiceContract]: classes.serviceContract,
    [AftermarketProductCategory.Insurance]: classes.insurance,
    [AftermarketProductCategory.None]: "",
    [AftermarketProductType.Addon]: "",
  };

  if (productType === AftermarketProductType.Addon) {
    return (
      <Chip
        size="small"
        label="Addon"
        style={{ width: "fit-content" }}
        className={chipClassByType?.[productType] ?? ""}
      />
    );
  }

  return (
    <Chip
      size="small"
      label={productCategory.replace(/_/g, " ")}
      style={{ width: "fit-content" }}
      className={chipClassByType?.[productCategory] ?? ""}
    />
  );
};

const useStyles = makeStyles(() => ({
  insurance: {
    backgroundColor: "#4E89F3",
    color: "#FFFFFF",
  },
  gap: {
    backgroundColor: "#F564A9",
    color: "#FFFFFF",
  },
  roadside: {
    backgroundColor: "#3CC37A",
    color: "#FFFFFF",
  },
  serviceContract: {
    backgroundColor: "#FFB64E",
    color: "#FFFFFF",
  },
}));
