import { PaymentOptionsConfigurationTermsAndRatesInput } from "@trnsact/trnsact-shared-types";
import {
  Markup,
  CalculatePaymentVariables,
  FinanceProgramFormValues,
  MarkupMethods,
  Structures,
  CalculatePaymentMenuVariables,
} from "../types";
import { prepareMenuOptionsForAPI } from "./prepareMenuOptionsForAPI";
import { Nullable, ValueOf } from "../../../global";

interface ConvertParams {
  proposalMenu: any;
  proposalMenuByCurrentTerm: any | null;
  proposalProductConfigurationsInMenu: any;
  program: ValueOf<FinanceProgramFormValues, "financeQuote">;
  saleDetails: ValueOf<FinanceProgramFormValues, "saleDetails">;
  financeProgramDetails: ValueOf<FinanceProgramFormValues, "financeProgramDetails">;
}

export function convertProgramToCalculateVariables({
  program,
  saleDetails,
  proposalMenu,
  financeProgramDetails,
  proposalMenuByCurrentTerm,
  proposalProductConfigurationsInMenu,
}: ConvertParams): CalculatePaymentVariables {
  const products = proposalMenuByCurrentTerm?.menu ?? proposalMenu;

  const prepareMenu: CalculatePaymentMenuVariables = {
    name: proposalMenu?.name,
    description: proposalMenu?.description,
    menuOptions: prepareMenuOptionsForAPI(
      products?.menuOptions,
      financeProgramDetails,
      proposalProductConfigurationsInMenu
    ),
  };

  const terms: PaymentOptionsConfigurationTermsAndRatesInput[] = [];

  if (program.specifyRate && program.specifyRates.length > 0) {
    terms.push(
      ...program.specifyRates.map((termValue: any) => ({ term: Number(termValue.term), rate: +termValue.rate }))
    );

    if (program.customTermTemporaryValue) {
      terms.push({
        term: +program.customTermTemporaryValue,
        rate: 0,
      });
    }
  } else {
    terms.push(
      ...[...program.terms, program.customTermTemporaryValue]
        .filter(Boolean)
        .map(t => ({ term: Number(t), rate: +program.commonRate }))
    );
  }

  let dealerMarkup: Nullable<Markup> = null;

  if (program.structure !== Structures.None) {
    if (program.markupMethod === MarkupMethods.MarkupPercentage) {
      dealerMarkup = { identifier: "Dealer Markup", markupPercentage: Number(program.markupRate) };
    } else if (program.markupMethod === MarkupMethods.MarkupDollar) {
      dealerMarkup = { identifier: "Dealer Markup", markupFlat: Number(program.markupRate) };
    }
  }

  return {
    salesTaxPercentage: 0,
    numberOfAdvancePayments: Number(program.advancePayment),
    financeProgramType: program.programType,
    rateType: program.rateType,
    financeProgramName: program.programName,
    amountFinanced: saleDetails.financeAmount,
    downPaymentAmount: saleDetails.downPayment,
    residualPercentage: Number(program.residual),
    menu: proposalMenu ? prepareMenu : undefined,
    customerRate:
      program.structure !== Structures.None && program.markupMethod === MarkupMethods.CustomerRate
        ? Number(program.markupRate)
        : null,
    dealerMarkup,
    paymentPeriodTiming: program.paymentCalculationMethod,
    markups: [{ identifier: "Lender Markup", markupPercentage: 0 }],
    terms,
    flatFees: program.fee.map(feeValue => ({
      identifier: feeValue.identifier,
      financed: feeValue.financed,
      fee: Number(feeValue.fee),
    })),
    daysToFirstPayment: Number(program.daysToFirstPayment),
  };
}
