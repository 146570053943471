import React from "react";
import { ValueOf } from "global";
import { Box } from "@material-ui/core";
import { useSelector } from "react-redux";
import { Controller, useFormContext } from "react-hook-form";
import { FormInputSelect, InputField } from "components/form";
import { deskingSelectors } from "../../../../model";
import { FormSectionHeader } from "../../../components";
import { creditAppSelectors } from "../../../../../../redux/creditAppReducer";
import { FinanceProgramFormValues, FormSectionProps } from "../../../../types";

export const Customer = ({ sectionName, onUpdateCollapse }: FormSectionProps) => {
  const { control, setValue } = useFormContext<FinanceProgramFormValues>();

  const contacts = useSelector(creditAppSelectors.contacts);
  const contactSelectOptions = useSelector(creditAppSelectors.contactSelectOptions);
  const isSectionOpen = useSelector((state: any) => deskingSelectors.isSectionOpen(state, sectionName));

  const handleChangeCustomer = (value: string) => {
    const contact = contacts.find((owner: any) => owner.ownerPgId === value);

    const dependentFields: (keyof ValueOf<Pick<FinanceProgramFormValues, "customer">, "customer">)[] = [
      "email",
      "phone",
      "firstAndLastName",
    ];

    setValue("customer.selectedCustomer", value);
    dependentFields.forEach(field => setValue(`customer.${field}`, contact?.[field] ?? ""));
  };

  return (
    <Box className="section">
      <FormSectionHeader
        name="Customer"
        sectionName={sectionName}
        isSectionOpen={isSectionOpen}
        onUpdateCollapse={onUpdateCollapse}
      />

      <Box className="sectionFields">
        <Controller
          control={control}
          name="customer.selectedCustomer"
          render={({ field }) => (
            <FormInputSelect
              variant="standard"
              label="Select contact"
              options={contactSelectOptions}
              {...field}
              onChange={handleChangeCustomer}
            />
          )}
        />

        <InputField
          control={control}
          label="Customer Name"
          name="customer.firstAndLastName"
          inputProps={{ disabled: true }}
        />

        <Box className="row">
          <InputField label="Email" control={control} name="customer.email" inputProps={{ disabled: true }} />

          <InputField control={control} label="Phone Number" name="customer.phone" inputProps={{ disabled: true }} />
        </Box>
      </Box>
    </Box>
  );
};
