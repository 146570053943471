import { useFormContext, useWatch } from "react-hook-form";
import { FinanceProgramFormValues, PaymentOptionTerm } from "../types";
import { useCallback, useEffect, useMemo, useState } from "react";

export function useFinanceProgramTerms() {
  const { control } = useFormContext<FinanceProgramFormValues>();
  const [financeQuoteTerms, customTermTemporaryValue] = useWatch({
    control,
    name: ["financeQuote.terms", "financeQuote.customTermTemporaryValue"],
  });

  const terms = [...financeQuoteTerms, customTermTemporaryValue].filter(Boolean) as string[];

  terms.sort((a, b) => +b - +a);

  const [currentTerm, setCurrentTerm] = useState<PaymentOptionTerm>(() => ({
    index: 0,
    term: terms[0],
  }));

  const termsOptions = useMemo(() => {
    const nextTermValue = terms[terms.findIndex(term => term === currentTerm.term) - 1];
    const backTermValue = terms[terms.findIndex(term => term === currentTerm.term) + 1];

    const isCurrentTermExistInTerms = terms.includes(currentTerm.term);

    if (!isCurrentTermExistInTerms) {
      setCurrentTerm({
        index: terms.length - 1,
        term: terms[terms.length - 1],
      });
    }

    return {
      isForwardDisabled: currentTerm.index <= 0,
      isBackwardDisabled: terms.length <= currentTerm.index + 1,
      forwardTooltip: nextTermValue ? `Go to ${nextTermValue}` : "",
      backwardTooltip: backTermValue ? `Back to ${backTermValue}` : "",
    };
  }, [terms, currentTerm]);

  useEffect(() => {
    const index = terms.findIndex(term => term === currentTerm.term);

    if (index !== currentTerm.index) {
      setCurrentTerm(prev => ({
        index,
        term: prev.term,
      }));
    }
  }, [terms, currentTerm]);

  useEffect(() => {
    if (customTermTemporaryValue) {
      const lessValueIndex = financeQuoteTerms.findIndex(term => Number(term) < Number(customTermTemporaryValue) + 1);

      setCurrentTerm({
        term: customTermTemporaryValue,
        index: lessValueIndex === -1 ? 0 : lessValueIndex,
      });
    } else {
      setCurrentTerm({
        index: 0,
        term: terms[0],
      });
    }
  }, [customTermTemporaryValue]);

  const handleUpdateTerm = useCallback(
    (action: "add" | "sub") => {
      const actionMapper = {
        sub: currentTerm.index + 1,
        add: currentTerm.index - 1,
      };

      setCurrentTerm({
        index: actionMapper[action],
        term: terms[actionMapper[action]],
      });
    },
    [terms, currentTerm]
  );

  return {
    handleUpdateTerm,
    currentTerm: currentTerm.term,
    ...termsOptions,
  };
}
