import React, { ReactNode } from "react";
import clsx from "clsx";
import { ContentViewType, Nullable } from "global";
import { makeStyles } from "@material-ui/core/styles";
import { Invoice, InvoiceDetails } from "modules/invoice";
import { Box, Button, Typography } from "@material-ui/core";
import { isDesktop, isMobile } from "utils/contentViewType";
import { InvoiceQuoteUploadModal } from "../../../../../components/InvoiceQuoteUploadModal_V2";
import { UploadedFileView } from "../../../../../components/shared/UploadedFileView/UploadedFileView";

interface Props {
  invoiceDoc: any;
  refetchDocs: any;
  handleChangeDoc: any;
  isLoading?: boolean;
  handleDeactivateDoc: any;
  invoice: Nullable<Invoice>;
  handleInvoiceDownload: any;
  contentViewType: ContentViewType;
  handleOpenInvoiceDetailModal: any;
  handleCloseUploadInvoiceModal: any;
  equipmentDetails?: Nullable<ReactNode>;
}

export const InvoiceTab = ({
  invoice,
  invoiceDoc,
  refetchDocs,
  contentViewType,
  handleChangeDoc,
  equipmentDetails,
  isLoading = false,
  handleDeactivateDoc,
  handleInvoiceDownload,
  handleOpenInvoiceDetailModal,
  handleCloseUploadInvoiceModal,
}: Props) => {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.container}>
        <Box
          className={clsx(classes.panel, {
            [classes.panelDesktop]: isDesktop(contentViewType),
            [classes.panelMobile]: isMobile(contentViewType),
          })}
        >
          <Typography component="span" variant="subtitle2">
            Details
          </Typography>

          <Button size="small" disabled={isLoading} onClick={handleOpenInvoiceDetailModal}>
            {isLoading ? "Updating..." : "Edit details"}
          </Button>
        </Box>

        {invoice && (
          <InvoiceDetails
            invoice={invoice}
            isLoading={isLoading}
            contentViewType={contentViewType}
            equipmentDetails={equipmentDetails}
          />
        )}

        <Box className={classes.uploadPanel}>
          <Typography component="span" variant="subtitle2">
            Upload Invoice
          </Typography>

          {!invoiceDoc ? (
            <Box className={classes.emptyDocView}>
              <Typography variant="body2" color="textSecondary">
                The invoice/quote is not uploaded yet
              </Typography>

              <InvoiceQuoteUploadModal
                refetchDoc={refetchDocs}
                handleChangeDoc={handleChangeDoc}
                handleOpenInvoiceModal={handleOpenInvoiceDetailModal}
                handleCloseUploadInvoiceModal={handleCloseUploadInvoiceModal}
              />
            </Box>
          ) : (
            <UploadedFileView
              type={invoiceDoc?.type}
              name={invoiceDoc?.name}
              uploadedBy={invoiceDoc?.createdBy}
              onDownload={handleInvoiceDownload}
              uploadedAt={invoiceDoc?.createdDateTime}
              onDelete={() => handleDeactivateDoc(invoiceDoc.documentId)}
            />
          )}
        </Box>
      </Box>
    </>
  );
};

const useStyles = makeStyles({
  container: {
    gap: "12px",
    display: "flex",
    marginTop: "16px",
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  panel: {
    display: "flex",
    justifyContent: "space-between",
  },
  panelDesktop: {
    alignItems: "center",
    flexDirection: "row",
  },
  panelMobile: {
    gap: "4px",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  uploadPanel: {
    gap: "8px",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  emptyDocView: {
    gap: "8px",
    display: "flex",
    flexDirection: "column",
  },
});
