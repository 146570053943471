import React from "react";
import { Box, Typography } from "@material-ui/core";
import { PaymentOptionsInfoItem } from "../PaymentOptionsInfoItem";
import { formatCurrency } from "../../../../../utils";
import { makeStyles } from "@material-ui/core/styles";
import { grey } from "@material-ui/core/colors";

interface Props {
  payment: any;
}

export const PaymentOptionsInfo = ({ payment }: Props) => {
  const classes = useStyles();

  const profitTypes = [
    {
      label: "Finance Markup",
      value: payment?.dealerMarkup,
      formatter: (value: any) => (value ? `${value}%` : "0.00%"),
    },
    {
      label: "Customer Rate",
      value: payment?.customerRate,
      formatter: (value: any) => (value ? `${value}%` : "0.00%"),
    },
    {
      label: "Amount Due Today",
      value: payment?.amountDueToday,
      formatter: (value: any) => formatCurrency(value ?? "0", true),
    },
    {
      label: "Cost of Deferred Payments",
      value: payment?.costOfDeferredPayments,
      formatter: (value: any) => formatCurrency(value ?? "0", true),
    },
  ];

  const summaryRenderData = [
    {
      label: "Finance Profit",
      value: payment?.financeProfit,
      formatter: (value: any) => formatCurrency(value ?? "0", true),
    },
  ];

  return (
    <Box className={classes.summary}>
      <Box className="panel">
        <Typography component="span" variant="subtitle2">
          F&I Profit Detail
        </Typography>
      </Box>

      <Box className="content">
        {profitTypes.map(({ value, label, formatter }) => (
          <PaymentOptionsInfoItem key={label} label={label} value={value} formatter={formatter} />
        ))}
      </Box>

      <Box className="profitSummary">
        <Typography component="span" variant="subtitle2">
          Profit Summary
        </Typography>

        <Box className="profitSummaryContent">
          <Box className="summaryList">
            {summaryRenderData.map(({ value, label, formatter }) => (
              <Box key={label} className="summaryListItem">
                <Typography component="span" variant="subtitle2" color="textSecondary">
                  {label}
                </Typography>
                <Typography component="span" variant="body2" color="textSecondary">
                  {formatter ? formatter(value) : value}
                </Typography>
              </Box>
            ))}
          </Box>

          <Box className="totalProfit">
            <Typography component="span" variant="subtitle1">
              Total Profit
            </Typography>
            <Typography component="span" variant="subtitle1">
              {formatCurrency(payment?.totalProfit ?? "0", true)}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles({
  summary: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",

    "& .panel": {
      padding: "8px",
      display: "flex",
      borderRadius: "2px",
      alignItems: "center",
      backgroundColor: grey["A100"],
    },

    "& .content": {
      gap: "6px",
      display: "grid",
      gridTemplateColumns: "repeat(3, 1fr)",

      "& .infoItem": {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      },
    },

    "& .profitSummary": {
      display: "flex",
      flexDirection: "column",

      "& .profitSummaryContent": {
        "& .summaryList": {
          "& .summaryListItem": {
            gap: "0.75rem",
            display: "flex",
            padding: "0.5rem 0",
            alignItems: "center",
            justifyContent: "flex-start",

            "& > *": {
              flex: 1,
            },
          },
        },

        "& .totalProfit": {
          gap: "0.75rem",
          display: "flex",
          padding: "0.75rem 0",
          alignItems: "center",
          borderTop: "2px solid black",
          justifyContent: "flex-start",

          "& > *": {
            flex: 1,
          },
        },
      },

      "& .summaryListItem:not(:last-child)": {
        borderBottom: "1px solid #ccc",
      },
    },
  },
});
