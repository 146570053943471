import { gql } from "apollo-boost";

export const TRIGGER_CREDIT_REPORT_BUSINESS_PULL = gql`
  mutation PullBusinessCreditReport($input: TriggerCreditReportBusinessPullInput!) {
    triggerCreditReportBusinessPull(input: $input) {
      score
      reportType
      errorDescription
      externalReportId
      vendorOpportunityId
      machineReadableReport
      creditReportBusinessId
      humanReadableReportDocumentId
    }
  }
`;
