import { gql } from "apollo-boost";

export const Q_USER_DOCS = gql`
  query($docId: ID, $VOId: ID) {
    documents(docId: $docId, VOId: $VOId) {
      documentId
      vendorOpportunityId
      source
      docType
      docDescription
      createdBy
      createdDateTime
      docName
      documentCreditSubmission {
        creditSubmissionId
        lenderAccess
      }
    }
  }
`;

export const Q_INVOICE_DOC = gql`
  query($VOId: ID) {
    invoiceDoc(VOId: $VOId) {
      documentId
      vendorOpportunityId
      source
      docType
      docDescription
      createdBy
      createdDateTime
      docName
      createdDateTime
    }
  }
`;

export const DOC_SETS = gql`
  query($accountId: ID) {
    docSets(accountId: $accountId) {
      name
      docusignTemplateIds
      approvalTerms
      description
      status
      active
      createdBy
      docSetId
    }
  }
`;

export const AGREEMENTS = gql`
  query($VOId: ID) {
    agreements(VOId: $VOId) {
      agreementId
      docusignEnvelopeId
      name
      agreementStatus
      signedOn
      owner
      accountId
      vendorOpportunityId
      createdDateTime
    }
  }
`;

export const Q_EQUIPMENT = gql`
  query Equipments($id: ID, $VOId: ID) {
    equipments(id: $id, VOId: $VOId) {
      equipmentId
      vendorOpportunityId
      quantity
      newUsed
      year
      make
      model
      serialNumberVin
      additionalDescription
      stockId
      truckClass
      attachments
      wholesaleCost
      totalWholesaleCost
      retailPrice
      mileage
      assetTypeStr
      classType
      cabType
      term
      lenderProduct
      loanType
      hours
      type
      tradeInValue
      amountOwedOnTradeIn
      equipmentIdx
    }
  }
`;

export const C_HISTORY = gql`
  query getConversationsForVendorOpportunity($vendorOpportunityId: ID!) {
    getConversationsForVendorOpportunity(vendorOpportunityId: $vendorOpportunityId) {
      sid
      vendorOpportunityId
      participants {
        sid
        identity
        userProfileId
        notifyByEmail
        notifyBySMS
        mugshot
        email
        mobilePhoneNumber
        notifyByWhatsapp
        isDealer
        isLender
        isApplicant
      }
      messages {
        participantSid
        userProfileId
        body
        author
        delivery {
          total
          read
          sent
          delivered
          failed
          undelivered
        }
      }
      task {
        regarding
      }
      topic
      createdAt
      taskId
    }
  }
`;

export const START_FAST_TRACK_PROCESS = gql`
  query($vendorOpportunityDatabaseId: ID!, $fastTrackInputs: JSON) {
    startFastTrackProcess(vendorOpportunityDatabaseId: $vendorOpportunityDatabaseId, fastTrackInputs: $fastTrackInputs)
  }
`;

export const QUERY_TASKS = gql`
  query VendorOpportunityTask($VOId: ID!, $limit: Int) {
    vendorOpportunityTask(VOId: $VOId, limit: $limit) {
      taskId
      vendorOpportunityId
      regarding
      status
      assignedToUserProfileId
      assignedToAccountId
      assignedByUserProfileId
      assignedByAccountId
      notes
      priority
      createdBy
      completedDateTime
      dueDateTime
      createdDateTime
      assignedByUser
      assignedByAccount
      assignedToUser
      assignedToAccount
      notifyByEmail
      notifyByText
      notifyAssignorOnReminder
      notificationContactIds
      lastReminderSent
      nextReminder
      assignedToApplicant
      assignedBySystem
      ownerPgId
      taskItems {
        taskItemId
        type
        docType
        taskAssignorNotes
        taskAssigneeNotes
        doc {
          documentId
          vendorOpportunityId
          source
          docType
          docDescription
          createdBy
          docName
        }
      }
      reference {
        referenceId
        tasksCompletionData
      }
    }
  }
`;

export const QUERY_VENDOR_PROFILES_ASSOCIATED_TO_LENDER = gql`
  query vendorProfilesByDynamicsLenderAccountId($dynamicsAccountId: ID!) {
    vendorProfilesByDynamicsLenderAccountId(dynamicsAccountId: $dynamicsAccountId) {
      id
      dynamicsId
      name
    }
  }
`;
