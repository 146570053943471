import { useCallback } from "react";
import { useMutation } from "react-apollo";
import { logError } from "utils/logger";
import { UPDATE_INVOICE } from "../api";
import { UpdateInvoiceResponse, UpdateInvoiceVariables } from "../api/types";

export function useInvoice() {
  const [updateInvoice, { loading }] = useMutation<UpdateInvoiceResponse, Partial<UpdateInvoiceVariables>>(
    UPDATE_INVOICE
  );

  const handleUpdateInvoice = useCallback(
    async (variables: Partial<UpdateInvoiceVariables>) => {
      try {
        await updateInvoice({ variables });
      } catch (error) {
        logError(error);
      }
    },
    [updateInvoice]
  );

  return {
    handleUpdateInvoice,
    isInvoiceUpdating: loading,
  };
}
