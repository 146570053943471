import React, { Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, Tooltip } from "@material-ui/core";
import { ProposalProduct } from "@trnsact/trnsact-shared-types/src/generated";
import { useMenuOptionTerms } from "../../../hooks";
import { TermsStepperManager } from "../TermsStepperManager";
import { deskingActions, deskingSelectors } from "../../../model";
import { ProposalProductsCard, ProposalProductsCards } from "../index";
import { ProductConfig, ProposalProductCardModes } from "../../../types";

export const MenuCards = () => {
  const dispatch = useDispatch();

  const { currentTerm, ...termsManagementProps } = useMenuOptionTerms();

  const classes = useStyles();

  const menuByTerms = useSelector(deskingSelectors.menuByTerms);
  const selectedMenuOption = useSelector(deskingSelectors.selectedMenuOption);
  const isNeedRunJsonEngine = useSelector(deskingSelectors.isNeedRunJsonEngine);
  const selectedProducts = useSelector(deskingSelectors.selectedProposalsProductsArray);
  const menuOptionsGroupedByName = useSelector(deskingSelectors.menuOptionsGroupedByName);
  const proposalProductConfigurations = useSelector(deskingSelectors.proposalProductConfigurations);
  const proposalProductConfigurationsInMenu = useSelector(deskingSelectors.proposalProductConfigurationsInMenu);
  const selectedProductsFactsForCheck = useSelector(deskingSelectors.selectedProductsFactsForCheck);

  const isShowAddToAllOptions = !!selectedProducts?.length && !!Object.keys(menuByTerms ?? []).length;

  const handleAddToAllOptions = () => {
    dispatch(deskingActions.addSelectedProductsToMenu());
  };

  const handelUpdateConfig = (productId: string, updateValues: Partial<ProductConfig>) => {
    dispatch(
      deskingActions.updateProductConfiguration({
        productId,
        data: updateValues,
      })
    );
  };

  const handelUpdateConfigInMenu = (productId: string, menuName: string, updateValues: Partial<ProductConfig>) => {
    dispatch(
      deskingActions.updateProductConfigurationInMenu({
        menuName,
        productId,
        data: updateValues,
      })
    );
  };

  return (
    <ProposalProductsCards>
      {selectedProducts.map(product => (
        <ProposalProductsCard
          type="simple"
          product={product}
          mode={ProposalProductCardModes.Desking}
          isNeedRunJsonEngine={isNeedRunJsonEngine}
          key={`simple-${product.proposalProductId}`}
          factsToCheck={selectedProductsFactsForCheck[product.proposalProductId]}
          proposalProductConfiguration={proposalProductConfigurations[product.proposalProductId] ?? {}}
          updateProductConfiguration={updateValues => handelUpdateConfig(product.proposalProductId, updateValues)}
        />
      ))}

      {isShowAddToAllOptions && (
        <Tooltip title="Adds all products & their current configuration to every available menu option in the template">
          <Button variant="outlined" onClick={handleAddToAllOptions}>
            Apply Product(s) to All Options
          </Button>
        </Tooltip>
      )}

      {Object.entries(
        selectedMenuOption ? { [selectedMenuOption.name]: selectedMenuOption } : menuOptionsGroupedByName
      ).map(([menuName, option]) => (
        <Fragment key={menuName}>
          {!!option?.products.length && (
            <Box className={classes.container}>
              <TermsStepperManager
                label={menuName}
                styles={{
                  color: option?.titleColor,
                  backgroundColor: option?.titleBgColor,
                }}
                {...termsManagementProps}
              />

              {option?.products?.map((product: ProposalProduct) => (
                <ProposalProductsCard
                  type="forEdit"
                  product={product}
                  mode={ProposalProductCardModes.Desking}
                  isNeedRunJsonEngine={isNeedRunJsonEngine}
                  key={`forEdit-${product.proposalProductId}`}
                  factsToCheck={selectedProductsFactsForCheck[product.proposalProductId]}
                  proposalProductConfiguration={
                    proposalProductConfigurationsInMenu?.[menuName]?.[product.proposalProductId] ?? {}
                  }
                  updateProductConfiguration={updateValues =>
                    handelUpdateConfigInMenu(product.proposalProductId, menuName, updateValues)
                  }
                />
              ))}
            </Box>
          )}
        </Fragment>
      ))}
    </ProposalProductsCards>
  );
};

const useStyles = makeStyles({
  container: {
    gap: "8px",
    display: "flex",
    flexDirection: "column",
  },
});
