import React from "react";
import { Grid, IconButton, makeStyles, Tooltip } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import clsx from "clsx";
import { ProposalMenu } from "@trnsact/trnsact-shared-types";

interface Props {
  proposalMenu: ProposalMenu;
  onEditProposalMenu: (proposalMenuToDelete: ProposalMenu) => void;
  onDeleteProposalMenu?: (proposalMenuToDelete: ProposalMenu) => void;
}

export const ActionsCell = ({ proposalMenu, onEditProposalMenu, onDeleteProposalMenu }: Props) => {
  const classes = useStyles();

  return (
    <Grid container spacing={2}>
      <Grid item>
        <Tooltip title="Edit Menu Template" placement="top">
          <IconButton
            size="small"
            className={clsx(classes.actionBtn, classes.actionBtnEdit)}
            onClick={() => onEditProposalMenu(proposalMenu)}
          >
            <EditIcon color="primary" />
          </IconButton>
        </Tooltip>
      </Grid>
      {onDeleteProposalMenu && (
        <Grid item>
          <Tooltip title="Delete Menu Template" placement="top">
            <IconButton
              size="small"
              className={clsx(classes.actionBtn, classes.actionBtnDelete)}
              onClick={() => onDeleteProposalMenu(proposalMenu)}
            >
              <DeleteOutlineIcon color="error" />
            </IconButton>
          </Tooltip>
        </Grid>
      )}
    </Grid>
  );
};

const useStyles = makeStyles(({ palette }) => ({
  actionBtn: {
    borderRadius: "4px",
  },
  actionBtnEdit: {
    border: `1px solid ${palette.primary.main}`,
  },
  actionBtnDelete: {
    border: `1px solid ${palette.error.main}`,
  },
}));
