import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@material-ui/core";
import { useModal } from "../../../../hooks/useModal";
import { useForm } from "react-hook-form";
import { MenuTemplateSaveAsFormInputs } from "modules/aftermarketMenuConstructor/types";
import { menuConstructorActions, menuConstructorSelectors } from "modules/aftermarketMenuConstructor/model";
import { ProposalMenu } from "@trnsact/trnsact-shared-types";
import { ModalsKeys } from "../../../../global";
import { useAftermarketPermissions } from "modules/aftermarketMenuConstructor/hooks/useAftermarketPermissions";
import { InputField } from "components/form";

export const SaveAsConfirmationDialog = () => {
  const dispatch = useDispatch();
  const proposalMenu = useSelector(menuConstructorSelectors.proposalsMenu) as ProposalMenu;

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<MenuTemplateSaveAsFormInputs>({
    defaultValues: {
      name: `${proposalMenu.name} (COPY)`,
      description: proposalMenu.description,
    },
  });

  const { isOpen, data, handleClose } = useModal(ModalsKeys.AftermarketSaveAsDialog);
  const { hasWriteAccess } = useAftermarketPermissions();

  const handleSave = (formData: MenuTemplateSaveAsFormInputs) => {
    const menuToSave = {
      ...proposalMenu,
      ...formData,
      proposalMenuId: "",
    };
    dispatch(menuConstructorActions.setMenu(menuToSave));
    if (data?.onSave) data?.onSave(menuToSave);
    handleClose();
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth="sm" fullWidth={true}>
      <DialogTitle>Save As</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <InputField
              label="Name"
              control={control}
              name="name"
              rules={{
                required: true,
              }}
              inputProps={{
                required: true,
                error: !!errors.name,
                helperText: errors.name && "Required",
                disabled: !hasWriteAccess,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <InputField
              label="Description"
              control={control}
              name="description"
              inputProps={{ disabled: !hasWriteAccess }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" variant="outlined">
          Cancel
        </Button>
        <Button onClick={handleSubmit(handleSave)} color="primary" variant="contained">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
