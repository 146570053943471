import React, { useEffect } from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Grid, Button, makeStyles, Typography, Tooltip, IconButton } from "@material-ui/core";
import { CheckboxField, MultiSelect, SwitchField } from "components/form";
import { Controller, useForm } from "react-hook-form";
import HelpIcon from "@material-ui/icons/Help";

import {
  PortalConfiguration,
  PortalConfigurationType,
  UpsertPortalConfigurationInput,
  UserProfile,
  VendorContactRole,
  VendorProfile,
} from "@trnsact/trnsact-shared-types";
import {
  getLocationsSelectOptions,
  getUserRolesSubSetSelectOptions,
  getUsersSelectOptions,
} from "utils/selectOptionsHelper";
import { useDispatch, useSelector } from "react-redux";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { LocationsReponse, PortalConfigurationsReponse, SavePortalConfigurationReponse } from "../../api/types";
import { OpportunitiesNotificationsForm, OpportunitiesNotificationsStageUpdatesForm } from "../../types";
import { portalConfigurationsActions, portalConfigurationsSelectors } from "redux/portalConfigurationReducer";
import { getPortalConfigurationDefaultConfig } from "../../lib";
import { GET_LOCATIONS, GET_PORTAL_CONFIGURATIONS, SAVE_PORTAL_CONFIGURATION } from "../../api";
import { useNotifications } from "modules/notification";

const DEFAULT_STAGE_UPDATES_USER_ROLES = [
  VendorContactRole.SalesMgr,
  VendorContactRole.SalesRep,
  VendorContactRole.CreditMgr,
];

export const OpportunitiesNotifications = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { showNotification } = useNotifications();

  const vendorProfile = useSelector((state: any) => state.vp) as VendorProfile;
  const relatedUsers = useSelector((state: any) => state.relatedUsers) as UserProfile[];
  const voNotificationPortalConfig = useSelector(state =>
    portalConfigurationsSelectors.configDataByType(state, PortalConfigurationType.VoNotifications)
  ) as PortalConfiguration;

  const portalConfigurationDefaultConfig = getPortalConfigurationDefaultConfig(
    vendorProfile.id,
    PortalConfigurationType.VoNotifications
  );

  const { control, setValue, reset, handleSubmit, watch } = useForm<OpportunitiesNotificationsForm>({
    defaultValues: portalConfigurationDefaultConfig.jsonDefinition.config as OpportunitiesNotificationsForm,
  });

  useQuery<PortalConfigurationsReponse>(GET_PORTAL_CONFIGURATIONS, {
    fetchPolicy: "no-cache",
    skip: !vendorProfile?.id,
    variables: {
      vendorProfileId: vendorProfile?.id,
      types: [PortalConfigurationType.VoNotifications],
    },
    onCompleted(response) {
      const configToSet = response?.portalConfigurations?.[0] || portalConfigurationDefaultConfig;

      const { stageUpdates } = configToSet.jsonDefinition.config;
      console.log(stageUpdates);
      //If turned on but no user roles selected, set default user roles and default to current global logic
      if (stageUpdates.isTurnedOn == true && (!stageUpdates.userRoles || stageUpdates.userRoles.length === 0)) {
        stageUpdates.userRoles = DEFAULT_STAGE_UPDATES_USER_ROLES;
        stageUpdates.isNotifyAllRelatedUsers = true;
      }

      dispatch(portalConfigurationsActions.setConfigData([configToSet]));
      reset(configToSet.jsonDefinition.config as OpportunitiesNotificationsForm);
    },
  });

  const { data: locationsData } = useQuery<LocationsReponse>(GET_LOCATIONS);

  const [savePortalConfiguration] = useMutation<
    SavePortalConfigurationReponse,
    { input: UpsertPortalConfigurationInput }
  >(SAVE_PORTAL_CONFIGURATION, {
    onCompleted() {
      showNotification("Saved!");
    },
    onError() {
      showNotification("Error!", { type: "error" });
    },
  });

  const userRolesSelectOptions = getUserRolesSubSetSelectOptions();
  const usersSelectOptions = getUsersSelectOptions(relatedUsers);

  const handleMultiSelectChange = (
    selected: (string | number)[],
    key: keyof OpportunitiesNotificationsStageUpdatesForm
  ) => {
    setValue(`stageUpdates.${key}`, selected as string[]);
  };

  const handleSave = async (formData: OpportunitiesNotificationsForm) => {
    const configToSave: PortalConfiguration = {
      ...voNotificationPortalConfig,
      jsonDefinition: { config: formData },
    };
    const result = await savePortalConfiguration({
      variables: {
        input: configToSave,
      },
    });
    configToSave.portalConfigurationId = result.data?.savePortalConfiguration;
    dispatch(portalConfigurationsActions.setConfigData([configToSave]));
  };

  const isTurnedOn = watch("stageUpdates.isTurnedOn");
  const isNotifyAllRelatedUsers = watch("stageUpdates.isNotifyAllRelatedUsers");

  useEffect(() => {
    if (isNotifyAllRelatedUsers) {
      setValue("stageUpdates.userRoles", []);
      setValue("stageUpdates.locationIds", []);
      setValue("stageUpdates.userIds", []);
    }
  }, [isNotifyAllRelatedUsers]);
  return (
    <Grid container spacing={2}>
      <Typography component="p" variant="h6">
        Opportunities
      </Typography>
      <Grid item xs={12}>
        <Typography component="span" variant="subtitle2">
          Stage Updates
        </Typography>
        <SwitchField
          control={control}
          className={classes.isTurnedOnSwitch}
          name="stageUpdates.isTurnedOn"
          tooltip="Notify the below group(s) when an Opportunity stage changes"
        />
      </Grid>
      {isTurnedOn && (
        <>
          <Grid item xs={12} className={classes.byRoleContainer}>
            <Controller
              control={control}
              name="stageUpdates.userRoles"
              render={({ field }) => (
                <>
                  <CheckboxField
                    control={control}
                    name="stageUpdates.isNotifyAllRelatedUsers"
                    label={"By Assignment Only"}
                  />
                  <Tooltip
                    title={
                      "When enabled defaults to global configuration settings and notify all users assigned to the opportunity based on the above selected user roles."
                    }
                  >
                    <IconButton>
                      <HelpIcon />
                    </IconButton>
                  </Tooltip>
                  <MultiSelect
                    disabled={isNotifyAllRelatedUsers}
                    label="User role(s):"
                    options={userRolesSelectOptions}
                    className={classes.input}
                    defaultChecked={true}
                    formControlProps={{
                      variant: "standard",
                    }}
                    {...field}
                    onChange={(selected: (string | number)[]) => handleMultiSelectChange(selected, "userRoles")}
                  />
                </>
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Accordion disabled={isNotifyAllRelatedUsers}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                <Typography>Advanced</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container className={classes.stageUpdatesContainer}>
                  <Controller
                    control={control}
                    name="stageUpdates.locationIds"
                    render={({ field }) => (
                      <MultiSelect
                        label="Location - All users attributed to the following location(s):"
                        options={getLocationsSelectOptions(locationsData?.locations ?? [])}
                        className={classes.input}
                        formControlProps={{
                          variant: "standard",
                        }}
                        {...field}
                        onChange={(selected: (string | number)[]) => handleMultiSelectChange(selected, "locationIds")}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name="stageUpdates.userIds"
                    render={({ field }) => (
                      <MultiSelect
                        label="Specific User(s)"
                        options={usersSelectOptions}
                        className={classes.input}
                        formControlProps={{
                          variant: "standard",
                        }}
                        {...field}
                        onChange={(selected: (string | number)[]) => handleMultiSelectChange(selected, "userIds")}
                      />
                    )}
                  />
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </>
      )}

      <Grid item xs={12}>
        <Button variant="contained" onClick={handleSubmit(handleSave)}>
          Save
        </Button>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles(({ palette }) => ({
  stageUpdatesContainer: {
    paddingLeft: 20,
    marginTop: 10,
  },
  input: {
    margin: "16px 0",
  },
  isTurnedOnSwitch: {
    marginLeft: 12,
  },
  byRoleContainer: {
    border: "1px solid #e0e0e0",
    borderRadius: 4,
    margin: "12px 0",
  },
}));
