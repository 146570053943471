import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@material-ui/core";
import {
  MenuTemplateGeneralFormInputs,
  MenuTemplatePartnerLinkConfiguration,
} from "modules/aftermarketMenuConstructor/types";
import { InputField, MultiSelect, SwitchField } from "components/form";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { useQuery } from "@apollo/react-hooks";
import { PartnerLinksResponse } from "modules/aftermarketPartner/api/types";
import { GET_PARTNER_LINKS } from "modules/aftermarketPartner/api";
import { useDispatch, useSelector } from "react-redux";
import { SelectOption } from "global";
import { PartnerProfilePartnerLinks } from "@trnsact/trnsact-shared-types";
import { useAftermarketPermissions } from "modules/aftermarketMenuConstructor/hooks/useAftermarketPermissions";
import { menuConstructorActions, menuConstructorSelectors } from "modules/aftermarketMenuConstructor/model";
import { keyBy } from "utils/arrayUtils";

export const GeneralInformationFormAftermarketPartner = () => {
  const dispatch = useDispatch();

  const account = useSelector((state: any) => state.account);
  const partnerLinksById = useSelector(menuConstructorSelectors.partnerLinksById) as Record<
    string,
    PartnerProfilePartnerLinks
  >;

  const { hasWriteAccess } = useAftermarketPermissions();

  const [dealersOptions, setDealersOptions] = useState<SelectOption[]>([]);

  const { loading: loadingPartnerLinks } = useQuery<PartnerLinksResponse>(GET_PARTNER_LINKS, {
    skip: !account.dynamicsAccountId,
    variables: {
      dynamicsId: account.dynamicsAccountId,
    },
    onCompleted(data) {
      const dealersOptions: SelectOption[] = data.getPartnerLinks.map(partnerLink => ({
        value: partnerLink.partnerLinkId!,
        label: partnerLink.accountName!,
      }));
      setDealersOptions(dealersOptions);
      dispatch(menuConstructorActions.setPartnerLinksById(keyBy(data.getPartnerLinks, "partnerLinkId")));
    },
  });

  const {
    control,
    formState: { errors },
    watch,
    setValue,
    getValues,
  } = useFormContext<MenuTemplateGeneralFormInputs>();

  const { fields: linkedToCriteriasFields, replace: replaceLinkedToCriterias } = useFieldArray({
    control,
    name: "linkedToCriterias",
  });

  useEffect(() => {
    const subscription = watch(value => {
      dispatch(menuConstructorActions.setMenuGeneralFormValues(value as MenuTemplateGeneralFormInputs));
    });

    return subscription.unsubscribe;
  }, [watch]);

  const handleChangeDealers = (selected: (string | number)[]) => {
    const partnerLinksIds = selected.map(String);
    setValue("partnerLinksIdsToShareMenu", partnerLinksIds);

    const linkedToCriterias = getValues("linkedToCriterias");
    const linkedToCriteriasById = keyBy(linkedToCriterias, "partnerLinkId");

    const linkedToCriteriasToSet = partnerLinksIds.reduce<MenuTemplatePartnerLinkConfiguration[]>(
      (acc, selectedPartnerLinkId) => {
        if (linkedToCriteriasById[selectedPartnerLinkId]) {
          acc.push(linkedToCriteriasById[selectedPartnerLinkId]);
        } else {
          acc.push({ partnerLinkId: selectedPartnerLinkId, isReadOnly: true });
        }
        return acc;
      },
      []
    );

    replaceLinkedToCriterias(linkedToCriteriasToSet);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <InputField
          label="Name"
          control={control}
          name="name"
          rules={{
            required: true,
          }}
          inputProps={{
            required: true,
            error: !!errors.name,
            helperText: errors.name && "Required",
            disabled: !hasWriteAccess,
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <InputField
          label="Description"
          control={control}
          name="description"
          inputProps={{ disabled: !hasWriteAccess }}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          control={control}
          name="partnerLinksIdsToShareMenu"
          render={({ field }) => (
            <MultiSelect
              label="Dealers to share the menu with"
              options={dealersOptions}
              formControlProps={{
                variant: "standard",
                disabled: loadingPartnerLinks || !dealersOptions.length,
              }}
              {...field}
              onChange={handleChangeDealers}
            />
          )}
        />
      </Grid>

      {linkedToCriteriasFields?.map((linkingCriteria, idx) => (
        <Grid key={linkingCriteria.partnerLinkId} item container spacing={1} xs={12}>
          <Grid item container xs={3} alignItems="center">
            <Typography>{partnerLinksById[linkingCriteria.partnerLinkId]?.accountName}</Typography>
          </Grid>
          <Grid item container xs={8} alignItems="center">
            <SwitchField
              control={control}
              name={`linkedToCriterias.${idx}.isReadOnly`}
              label="Share in Read-Only mode"
              disabled
            />
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};
