import React from "react";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { PaymentOptionCard } from "./PaymentOptionCard";
import { MenuCards } from "../../../types";
import { useFinanceProgramTerms } from "../../../hooks";
import { PaymentOptionsInfo } from "./PaymentOptionsInfo";
import { TermsStepperManager } from "../TermsStepperManager";

export const PaymentOptionCards = ({ paymentOptions, isCalculating }: MenuCards) => {
  const classes = useStyles();

  const { currentTerm, ...termsManagementProps } = useFinanceProgramTerms();

  const paymentOptionsByTerm = paymentOptions.find(({ term }) => String(term) === currentTerm) ?? null;

  return (
    <Box className={classes.container}>
      <TermsStepperManager label={`${currentTerm} Months`} {...termsManagementProps} />

      {paymentOptionsByTerm && (
        <>
          <PaymentOptionCard paymentOption={paymentOptionsByTerm} isCalculating={isCalculating} />

          <PaymentOptionsInfo payment={paymentOptionsByTerm} />
        </>
      )}
    </Box>
  );
};

const useStyles = makeStyles({
  container: {
    gap: "12px",
    display: "flex",
    flexDirection: "column",
  },
});
