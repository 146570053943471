import gql from "graphql-tag";

export const GET_PROPOSAL_MENUS = gql`
  query ProposalMenus {
    proposalMenus(input: {}) {
      proposalMenuId
      name
      description
      menuOptions {
        name
        ordinal
        description
        proposalMenuOptionId
        titleBgColor
        titleColor
        products {
          cost
          retailCost
          title
          ordinal
          proposalProductId
          thirdPartyUrl
          accountIdCreatedBy
          aftermarketProductToPartnerLinkId
          aftermarketProduct {
            aftermarketProductId
            aftermarketVendorApiChannel
            productName
            productType
            productCategory
            productDescriptionExternal
            criteria
            config {
              criteriaValues
            }
            aftermarketProductCatalogDetails {
              productSummary
              limitationsOfLiability
              eligibilityAndInclusions
              legalDisclaimer
              paragraphDescription
              coverageDetails
            }
          }
          markup {
            type
            markup
          }
        }
      }
    }
  }
`;
