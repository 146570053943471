import gql from "graphql-tag";

export const GET_FINANCE_PROGRAMS = gql`
  query GetFinancePrograms($accountId: ID!) {
    financePrograms(accountId: $accountId) {
      accountId
      financeProgramId
      nameInternal
      paymentOptionsConfiguration
      validDateEnd
      validDateStart
      prescreenCriteria {
        prescreenCriteriaId
        accountId
        active
        jsonCriteria
        name
        guidelines
      }
      financeProgramModificationSettings {
        markup
      }
    }
  }
`;

export const GET_LENDER_WATERFALL_RESULTS = gql`
  query GetLenderWaterfallResults($vendorOpportunityId: ID!) {
    lenderWaterfallPrescreenResults(vendorOpportunityId: $vendorOpportunityId) {
      lenderWaterfallPrescreenCriteriaResultId
      result
      step
      financeProgramId
      lenderProfileId
      runtimeInput
      runtimeOutput
      vendorOpportunityId
      creditSubmissionId
      creditSubmissionAutoSubmitted
      createdDateTime
      modifiedDateTime
    }
  }
`;

export const GET_LAST_LENDER_WATERFALL_RESULTS = gql`
  query GetLatestLenderWaterfallResults($vendorOpportunityId: ID!) {
    latestLenderWaterfallPrescreenProcessResults(vendorOpportunityId: $vendorOpportunityId) {
      date
      processInstanceId
      results {
        lenderWaterfallPrescreenCriteriaResultId
        result
        step
        financeProgramId
        lenderProfileId
        runtimeInput
        runtimeOutput
        vendorOpportunityId
        creditSubmissionId
        creditSubmissionAutoSubmitted
        createdDateTime
        modifiedDateTime
      }
    }
  }
`;

export const RE_RUN_LENDER_WATERFALL = gql`
  mutation($vendorOpportunityId: ID!) {
    reRunPrescreenWorflow(vendorOpportunityId: $vendorOpportunityId)
  }
`;
