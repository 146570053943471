import React from "react";
import { Box, Button, Tooltip, Typography } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import { ArrowBack } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import EditIcon from "@material-ui/icons/Edit";
import { ReactComponent as FilePDF } from "../../../../assets/svg/file-pdf.svg";
import { ApplicationDateLayout } from "./ApplicationDateLayout";
import { OcaTemplateNameLayout } from "./OCATemplateNameLayout";
import { ApplicationTypeLayout } from "./ApplicationTypeLayout";

interface Props {
  title: string;
  userAdminRole: any;
  isEditMode: boolean;
  isLegacyOCA: boolean;
  creditAppModalData: any;
  onChangeMode: VoidFunction;
  isEditingDisabled: boolean;
  isIndividualCreditApp: boolean;
  isCreditAppNeedsRecertification?: boolean;
  onConvertToBusiness: VoidFunction;
  handleCreditAppPdfRequest: (pdfWindow: WindowProxy | null) => void;
  handleLegacyCreditAppRequest?: () => {};
  backToCommonView?: VoidFunction;
  handleRecertifyClick: VoidFunction;
}

export const CreditApplicationDetailsPanel = ({
  title,
  isEditMode,
  isLegacyOCA,
  onChangeMode,
  userAdminRole,
  isEditingDisabled,
  backToCommonView,
  creditAppModalData,
  onConvertToBusiness,
  isIndividualCreditApp,
  isCreditAppNeedsRecertification,
  handleCreditAppPdfRequest,
  handleLegacyCreditAppRequest,
  handleRecertifyClick,
}: Props) => {
  const classes = useStyles();

  return (
    <Box className={classes.panel}>
      <Box>
        <Box className={classes.title}>
          <IconButton
            size="small"
            onClick={() => {
              if (backToCommonView) backToCommonView();
            }}
          >
            <ArrowBack className="backIcon" />
          </IconButton>

          <Typography className="titleText" component="h6" variant="h6">
            {title}
          </Typography>
        </Box>

        <ApplicationDateLayout
          creditAppModalData={creditAppModalData}
          isCreditAppNeedsRecertification={isCreditAppNeedsRecertification}
        />
        <OcaTemplateNameLayout creditAppModalData={creditAppModalData} userAdminRole={userAdminRole} />
        <ApplicationTypeLayout
          isIndividualCreditApp={isIndividualCreditApp}
          onConvertToBusiness={onConvertToBusiness}
          isEditingDisabled={isEditingDisabled}
        />
      </Box>

      <Box className={classes.actions}>
        {!isEditMode && (
          <Tooltip title="Get file" placement="top">
            <FilePDF
              style={{ cursor: "pointer", margin: "5px", fill: "#2196F3" }}
              onClick={() => {
                handleCreditAppPdfRequest(window.open());
              }}
            />
          </Tooltip>
        )}

        {isLegacyOCA && !isEditMode && (
          <Button variant="outlined" size="small" color="primary" onClick={handleLegacyCreditAppRequest}>
            Legacy View
          </Button>
        )}

        {!isEditingDisabled && !isEditMode && (
          <Button variant="contained" size="small" color="primary" startIcon={<EditIcon />} onClick={onChangeMode}>
            Edit details
          </Button>
        )}

        {!isEditingDisabled && !isEditMode && (
          <Button variant="contained" color="primary" size="small" onClick={handleRecertifyClick}>
            Recertify
          </Button>
        )}
      </Box>
    </Box>
  );
};

const useStyles = makeStyles({
  panel: {
    display: "flex",
    paddingBottom: "8px",
    alignItems: "flex-start",
    justifyContent: "space-between",
  },
  title: {
    display: "flex",
    alignItems: "center",

    "& .backIcon": {
      color: "black",
    },

    "& .titleText": {
      textTransform: "capitalize",
    },
  },
  actions: {
    gap: "8px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  applicationTypeText: {
    margin: "20px 0 5px",
    textTransform: "none",
    fontSize: "12px",
  },
});
