import React, { useState, useEffect } from "react";
import { useLazyQuery } from "react-apollo";
import gql from "graphql-tag";
import { makeStyles } from "@material-ui/core/styles";
import ReactTable, { Column, Filter, SortingRule } from "react-table";
import DragIndicator from "assets/img/drag-indicator.svg";
import AccessTime from "@material-ui/icons/AccessTime";
import { Grid, LinearProgress, TextField, Checkbox, ListItemText, MenuItem, Select, Popper } from "@material-ui/core";
import Tile from "components/Tile/Tile";
import _ from "lodash";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Skeleton } from "@material-ui/lab";
import { NavLink } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  root: {
    "& .ReactTable .rt-table": {
      borderRadius: theme.shape.borderRadius,
    },
  },
  fixReactTable: {
    "& .ReactTable .rt-thead .rt-th, .ReactTable .rt-thead .rt-td": {
      display: "flex",
      alignItems: "center",
      lineHeight: "1rem !important",
      minWidth: "80px !important",
      maxWidth: "350px !important",
      marginRight: "12px !important",
      fontWeight: 500,
    },
    "& .ReactTable .pagination-bottom .-pageJump input::-webkit-outer-spin-button, input::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },

    "& .ReactTable .rt-td": {
      minWidth: "80px !important",
      maxWidth: "350px !important",
      marginRight: "12px !important",
      fontWeight: 500,
    },
    "& .ReactTable .rt-thead .rt-resizable-header-content": {
      position: "relative",
      paddingRight: "1rem",
      overflow: "visible",
      display: "flex",
      alignItems: "center",
    },
    "& .ReactTable .rt-thead .rt-th.-cursor-pointer > div:first-of-type:after": {
      position: "absolute",
      right: "0",
      top: "50%",
      transform: "translateY(-50%)",
      bottom: "unset",
    },
    "& .ReactTable .rt-thead.-filters input, & .ReactTable .-pagination .-pageJump input": {
      height: "26px",
    },
    "& .ReactTable .rt-thead.-filters input:focus, & .ReactTable .-pagination .-pageJump input:focus": {
      backgroundImage: `linear-gradient(${theme.palette.primary.main}, ${theme.palette.primary.main}), linear-gradient(#d2d2d2, #d2d2d2)`,
    },
    "& .ReactTable .rt-resizer": {
      backgroundImage: `url(${DragIndicator})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundSize: "26px",
    },
  },
  tableContainer: {
    padding: theme.spacing(2),

    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[3],
  },
  header: {
    backgroundColor: theme.palette.primary.contrastText,
    color: theme.palette.common.black,
    fontWeight: "bolder",
    fontSize: "0.775rem",
    whiteSpace: "nowrap",
    padding: "8px 16px",
  },

  cell: {
    padding: theme.spacing(1),
    fontSize: "0.875rem",
  },
  low: { backgroundColor: "#FFF3E0", textAlign: "center" },
  mid: { backgroundColor: "#E8F5E9", textAlign: "center" },
  high: { backgroundColor: "#FEEBEE", textAlign: "center" },
  notAvailable: { backgroundColor: "#dedede", color: "black", textAlign: "center" },
  pagination: {
    display: "flex",
    justifyContent: "center",
    padding: theme.spacing(2),
  },
  searchContainer: {
    display: "flex",
    justifyContent: "flex-start",
    marginBottom: theme.spacing(2),
  },
  filterOptions: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "wrap",
    paddingBottom: "10px",
  },
  filterSelect: {
    height: "1rem !important",
    boxSizing: "content-box",
    fontSize: "14px !important",
    "&:focus": {
      backgroundImage: `linear-gradient(${theme.palette.primary.main}, ${theme.palette.primary.main}), linear-gradient(#d2d2d2, #d2d2d2) !important`,
    },
  },
  filterAutocomplete: {
    "& .MuiAutocomplete-inputRoot": {
      height: "16px",
      flexWrap: "nowrap",
    },
    "& .MuiTextField-root input": {
      backgroundImage: "none !important",
    },
    "& .autocompleteRenderTags": {
      fontSize: "14px",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
      flex: "1 0 100%",
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    },
    "& .Mui-focused .autocompleteRenderTags": {
      display: "none",
    },
    "& .Mui-focused .MuiAutocomplete-inputRoot": {
      //flexWrap: "nowrap",
    },
  },
}));

const CREDIT_SUBMISSIONS_QUERY = gql`
  query CreditSubmissionsHubQuery($limit: Int, $offset: Int, $sortBy: String, $sortOrder: String, $searchTerm: JSON) {
    creditSubmissionsHub(
      limit: $limit
      offset: $offset
      sortBy: $sortBy
      sortOrder: $sortOrder
      searchTerm: $searchTerm
    ) {
      total
      creditSubmissions {
        creditSubId
        submissionDate
        stage
        decision
        lenderName
        dealer
        applicantName
        applicationStage
        timeToReviewInHours
        timeToDecisionInHours
        timeDeltas {
          decisions {
            timeElapsed
            previous
            current
          }
          stages {
            timeElapsed
            previous
            current
          }
        }
      }
    }
  }
`;

interface Submission {
  id: number;
  submissionDate: string;
  salesRep: string;
  customerName: string;
  voStatusStage: string;
  decisionStatus: string;
  lenderName: string;
  stage: number;
  timeToReviewInHours: string;
  timeToDecisionInHours: string;
  timeDeltas: {
    decisions: { timeElapsed: number; previous: number; current: number }[];
    stages: { timeElapsed: number; previous: number; current: number }[];
  };
}

const stagesCodes: { [key: number]: string } = {
  804790000: "Submitted to Lender",
  804790001: "Lender Reviewing",
  804790002: "Lender - Additional Info Requested",
  804790003: "Lender Approved",
  804790004: "Lender Declined",
  804790005: "Dealer Accepted",
  804790006: "Dealer Cancelled",
  804790007: "Docs Out",
  804790008: "Docs in",
  804790009: "Funding Review",
  804790010: "Funding Items Requested",
  804790011: "Customer Cancelled",
  804790013: "Lender Pre-Approved",
  804790020: "Funded",
  105: "Draft Application Created",
  100: "Awaiting Transmission to Lender",
  300: "Submission Failed",
  101: "Awaiting PreQual Transmission to Lender",
};

const SubmissionsHub: React.FC = () => {
  const classes = useStyles();
  const [limit, setLimit] = useState(10);
  const [submissions, setSubmissions] = useState([]);
  const [offset, setOffset] = useState(0);
  const [total, setTotal] = useState(0);
  const [sortBy, setSortBy] = useState<string>("submissionDate");
  const [sortOrder, setSortOrder] = useState<string>("DESC");
  const [searchTerm, setSearchTerm] = useState<Filter[]>([]);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState({});

  const [columnsFilters, setColumnsFilters] = useState<{ header: string; id: string; value: any[] }[]>([
    {
      header: "Stage",
      id: "applicationStage",
      value: [""],
    },
    {
      id: "stage",
      header: "Status",
      value: [""],
    },

    {
      id: "lenderName",
      header: "Lender",
      value: [""],
    },
    {
      id: "salesRep",
      header: "Sales Rep",
      value: [""],
    },
    {
      header: "Decision",
      id: "decision",
      value: [""],
    },
  ]);
  const [stagesSel, setStagesSel] = useState<{ value: string; text: string }[]>([]);
  const [lenderNameSel, setLenderNameSel] = useState<{ value: string; text: string }[]>([]);
  const [salesRepSel, setSalesRepSel] = useState<{ value: string; text: string }[]>([]);
  const [decisionSel, setDecisionSel] = useState<{ value: string; text: string }[]>([]);
  const [statusSel, setStatusSel] = useState<{ value: string; text: string }[]>([]);

  const [fetchData, { data, loading, error }] = useLazyQuery(CREDIT_SUBMISSIONS_QUERY, {
    variables: { limit, offset, sortBy, sortOrder, searchTerm: debouncedSearchTerm },
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    const target = columnsFilters.map(arg => {
      const values = arg.value.filter((val: any) => {
        return val !== "";
      });
      return {
        id: arg.id,
        value: values,
      };
    });

    setDebouncedSearchTerm(_.reject(target, item => _.isEmpty(item.value)));
  }, [columnsFilters]);

  useEffect(() => {
    if (data?.creditSubmissionsHub) {
      setSubmissions(
        data.creditSubmissionsHub.creditSubmissions.map((submission: any) => {
          setDecisionSel(prev =>
            _.uniqBy([...prev, { value: submission.decision, text: submission.decision }], "value")
          );

          setStagesSel((prev: any) =>
            _.uniqBy([...prev, { value: submission.applicationStage, text: submission.applicationStage }], "value")
          );

          setLenderNameSel(prev =>
            _.uniqBy([...prev, { value: submission.lenderName, text: submission.lenderName }], "value")
          );

          setSalesRepSel(prev =>
            _.uniqBy([...prev, { value: submission.dealer, text: submission.dealer ?? "N/A" }], "value")
          );

          return {
            id: submission.creditSubId,
            submissionDate: submission.submissionDate,
            salesRep: submission.dealer,
            customerName: submission.applicantName,
            lenderName: submission.lenderName,
            voStatusStage: submission.applicationStage,
            decisionStatus: submission.decision,
            stage: submission.stage,
            timeDeltas: submission.timeDeltas,
            timeToReview: submission.timeToReviewInHours,
            timeToDecision: submission.timeToDecisionInHours,
          };
        }) || []
      );
      setTotal(data?.creditSubmissionsHub.total || 0);
    }
  }, [data]);

  if (error) return <p>Error: {error.message}</p>;

  const getClassByTime = (time: string): string => {
    const [hours, minutes] = time.split(":").map(Number);
    if (time === "00:00:00") return classes.notAvailable;
    const totalMinutes = hours * 60 + minutes;

    if (totalMinutes < 5) return classes.low;
    if (totalMinutes < 30) return classes.mid;
    return classes.high;
  };

  const columns: Column<Submission>[] = [
    {
      Header: "Created",
      accessor: "submissionDate",
      Cell: ({ value }: { value: string }) => {
        return (
          <div className={classes.cell}>
            {new Date(parseInt(value)).toLocaleDateString()} {new Date(parseInt(value)).toLocaleTimeString()}
          </div>
        );
      },

      filterable: false,
    },
    {
      Header: "Lender",
      accessor: "lenderName",
      className: classes.cell,
      Cell: ({ value }: { value: string }) => {
        return (
          <div className={classes.cell}>
            <NavLink to={"#"}>{value}</NavLink>
          </div>
        );
      },
    },
    {
      Header: "Sales Rep",
      accessor: "salesRep",
      Cell: ({ value }: { value: string }) => {
        return <div className={classes.cell}>{value ?? "N/A"}</div>;
      },
      className: classes.cell,
    },
    {
      Header: "Customer",
      accessor: "customerName",
      className: classes.cell,
      Cell: ({ value }: { value: string }) => {
        return (
          <div className={classes.cell}>
            <NavLink to={"#"}>{value}</NavLink>
          </div>
        );
      },
      filterable: false,
    },
    {
      Header: "Stage",
      accessor: "voStatusStage",
      id: "applicationStage",
      className: classes.cell,
      minWidth: 150,
      filterMethod: (filter: any, row: any) => filter.value.includes(row.applicationStage),
    },
    {
      Header: "Decision",
      accessor: "decisionStatus",
      id: "decision",
      className: classes.cell,
    },
    {
      Header: "Status",
      accessor: submission => stagesCodes[submission.stage],
      id: "stage",
      className: classes.cell,
      filterable: false,
    },
    {
      Header: "Time to Review",
      id: "timeToReview",
      accessor: "timeToReview",
      Cell: ({ value }: { value: string }) => {
        const className = getClassByTime(value);
        return (
          <div className={`${classes.cell} ${className}`}>
            {value == "00:00:00" ? (
              "N/A"
            ) : (
              <span>
                <AccessTime fontSize="small" /> {value}
              </span>
            )}
          </div>
        );
      },
      sortable: false,
      filterable: false,
    },
    {
      Header: "Time to Decision",
      id: "timeToDecision",
      accessor: "timeToDecision",
      Cell: ({ value }: { value: string }) => {
        const className = getClassByTime(value);
        return (
          <div className={`${classes.cell} ${className}`}>
            {value == "00:00:00" ? (
              "N/A"
            ) : (
              <span>
                <AccessTime fontSize="small" /> {value}
              </span>
            )}
          </div>
        );
      },
      sortable: false,
      filterable: false,
    },
  ];
  const handleSearchChange = (args: Filter[]) => {
    setOffset(0); // Reset to the first page when searching
  };
  const handleSortChange = (newSorted: SortingRule[]) => {
    if (newSorted.length > 0) {
      const { id, desc } = newSorted[0];
      setSortBy(id);
      setSortOrder(desc ? "DESC" : "ASC");
    } else {
      setSortBy("DESC");
      setSortOrder("submissionDate");
    }
  };

  const getSimpleSelect = (sel: any, header: any, onChange: any) => {
    return (
      <Select
        multiple
        className={classes.filterSelect}
        renderValue={() => {
          const currentFilter = _.find(columnsFilters, { header });
          if (_.get(currentFilter, "value[0]") === "") {
            return "Show All";
          }
          return _(currentFilter)
            .get("value")
            .map(value => {
              if (value === "") {
                return "N/A";
              }
              const selectedFilterOption = _.find(sel, { value: value });
              return selectedFilterOption?.text || value;
            })
            .join(", ");
        }}
        onChange={(e: any) => {
          onChange(e.target.value);
          let value: string[] =
            e.target.value.length > 1 && e.target.value[0] === ""
              ? e.target.value.filter((val: string) => val !== "")
              : e.target.value;

          if (value[value.length - 1] === "") {
            value = value.filter((val: any) => val === "");
          }
          setColumnsFilters(prevArray =>
            prevArray.map(col =>
              col.header === header
                ? {
                    ...col,
                    value,
                  }
                : col
            )
          );
        }}
        value={_.get(
          columnsFilters.find(col => col.header === header),
          "value",
          []
        )}
        style={{ width: "100%" }}
        MenuProps={{
          getContentAnchorEl: null,
          variant: "menu",
        }}
      >
        <MenuItem value="">
          <Checkbox
            checked={
              _.get(
                columnsFilters.find(col => col.header === header),
                "value",
                false
              ) &&
              _.get(
                columnsFilters.find(col => col.header === header),
                "value",
                [""]
              ).includes("")
            }
          />
          <ListItemText primary="Show All" />
        </MenuItem>
        {sel.map((item: any, index: any) => {
          return (
            <MenuItem key={"stage-" + index} value={item.value}>
              <Checkbox
                checked={
                  _.get(
                    columnsFilters.find(col => col.header === header),
                    "value",
                    false
                  ) &&
                  _.get(
                    columnsFilters.find(col => col.header === header),
                    "value",
                    ""
                  ).includes(item.value)
                }
              />
              <ListItemText primary={item.text} />
            </MenuItem>
          );
        })}
      </Select>
    );
  };

  function createFiltersColumns(sel: any, header: any) {
    const filter = ({ onChange }: { onChange: any }) => {
      if (_.isEmpty(sel)) {
        return null;
      }

      return getSimpleSelect(sel, header, onChange);
    };
    return filter;
  }
  function addFiltersToColumns(col: any) {
    switch (col.Header) {
      case "Stage": {
        col.Filter = createFiltersColumns(stagesSel, col.Header);
        return col;
      }
      case "Sales Rep": {
        col.Filter = createFiltersColumns(salesRepSel, col.Header);
        return col;
      }

      case "Lender": {
        col.Filter = createFiltersColumns(lenderNameSel, col.Header);
        return col;
      }
      case "Decision": {
        col.Filter = createFiltersColumns(decisionSel, col.Header);
        return col;
      }
      default: {
        return col;
      }
    }
  }

  const renderSkeletonTable = () => {
    const skeletonRows = 15;
    return (
      <div className={classes.fixReactTable}>
        <div style={{ display: "table", width: "100%" }}>
          {/* Header Skeleton */}
          <div style={{ display: "table-header-group" }}>
            <div style={{ display: "table-row" }}>
              {columns.map((_, colIndex) => (
                <div
                  style={{
                    display: "table-cell",
                    padding: "8px",
                    fontWeight: "bold",
                    textAlign: "left",
                  }}
                  key={colIndex}
                >
                  <Skeleton variant="text" width="80%" />
                </div>
              ))}
            </div>
          </div>
          {/* Rows Skeleton */}
          <div style={{ display: "table-row-group" }}>
            {Array.from(new Array(skeletonRows)).map((_, rowIndex) => (
              <div style={{ display: "table-row" }} key={rowIndex}>
                {columns.map((_, colIndex) => (
                  <div style={{ display: "table-cell", padding: "8px" }} key={colIndex}>
                    <Skeleton variant="text" width="100%" />
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };
  return (
    <Grid container className={classes.root}>
      <Grid item xs={12}>
        <Tile className={classes.tableContainer}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              {loading ? (
                renderSkeletonTable()
              ) : (
                <div className={classes.fixReactTable}>
                  <ReactTable<Submission>
                    data={submissions}
                    pages={Math.ceil(total / limit)}
                    page={Math.ceil(offset / limit)}
                    getTheadThProps={() => ({ className: classes.header })}
                    getTdProps={() => ({ className: classes.cell })}
                    onFilteredChange={(args: Filter[]) => {
                      handleSearchChange(args);
                    }}
                    columns={columns.map(addFiltersToColumns)}
                    filterable={true}
                    manual={true}
                    defaultPageSize={limit}
                    onSortedChange={handleSortChange}
                    sorted={[{ id: sortBy, desc: sortOrder === "DESC" }]}
                    onPageChange={pageIndex => setOffset(pageIndex * limit)}
                    onPageSizeChange={pageSize => setLimit(pageSize)}
                    className="-striped -highlight"
                  />
                </div>
              )}
            </Grid>
          </Grid>
        </Tile>
      </Grid>
    </Grid>
  );
};

export default SubmissionsHub;
