import { DeskingState } from "./types";
import { FormSections, ProductConfig } from "../types";
import { sortEntitiesByModifiedDate } from "../lib";
import { capitalizeFirstLetter } from "../../../formatters";
import { SelectOption } from "../../../global";

interface State {
  desking: DeskingState;
}

// Finance program

const financePrograms = (state: State) => state.desking.financePrograms;
const financeProgramsSelectors = (state: State): SelectOption[] =>
  sortEntitiesByModifiedDate(Object.values(state.desking.financePrograms)).map(program => ({
    label: program.nameInternal,
    value: program.financeProgramId,
  }));

// Proposal menu

const term = (state: State) => state.desking.term;

const proposalMenus = (state: State) => state.desking.proposalMenus ?? {};
const proposalMenuById = (state: State, id: string | undefined) => (id ? state.desking.proposalMenus?.[id] : null);
const proposalMenuByCurrentTerm = (state: State) => state.desking.menuBuilder?.[state.desking.term?.term] ?? null;

const menuTerms = (state: State) => Object.keys(state.desking.menuBuilder ?? {});
const menuByTerm = (state: State) => state.desking.menuBuilder?.[state.desking.term?.term]?.menu?.menuOptions ?? [];
const menuByTerms = (state: State) => state.desking.menuBuilder;
const allUniqProductsFromBuilder = (state: State) =>
  Object.values(state.desking.menuBuilder)
    .map((menuBuilder: any) => menuBuilder.menu.menuOptions.map((option: any) => option.products))
    .flat(2);

const selectedMenuOption = (state: any) => {
  let menuOptions = menuByTerm(state);

  if (menuOptions.length === 0) {
    menuOptions = state.aftermarketMenuConstructor?.proposalMenu?.menuOptions;
  }

  let selectedMenuOptionName: string;

  if (state.desking?.layout?.selectedMenuOptions) {
    selectedMenuOptionName = state.desking.layout.selectedMenuOptions;
  } else if (state.aftermarketMenuConstructor?.selectedMenuOption) {
    selectedMenuOptionName = state.aftermarketMenuConstructor.selectedMenuOption.name;
  }

  const menuOption = menuOptions.find((option: any) => option?.name === selectedMenuOptionName) ?? "";

  if (menuOption) {
    return {
      ...menuOption,
      products: menuOption.products.map((product: any) => {
        const foundedProposalProduct = state.desking.proposalProducts.products.find(
          (proposalProduct: any) =>
            proposalProduct.selectedProductId === product.selectedProductId && !!proposalProduct.selectedProductId
        );

        if (foundedProposalProduct) {
          return {
            ...product,
            aftermarketProduct: foundedProposalProduct?.aftermarketProduct,
          };
        }

        return product;
      }),
    };
  }

  return menuOption;
};

// Proposals products

const selectedProposalsProducts = (state: State) => state.desking.proposalProducts.selectedProducts;
const selectedProposalsProductsIds = (state: State) => Object.keys(state.desking.proposalProducts.selectedProducts);
const selectedProposalsProductsArray = (state: State) => Object.values(state.desking.proposalProducts.selectedProducts);

const selectedProductsFactsForCheck = (state: State) => state.desking.proposalProducts.productsFactsToCheck;
const productsFactsForCheckArray = (state: State) => {
  const selectedProducts = selectedProposalsProductsIds(state);
  const allProductsFromBuilder = allUniqProductsFromBuilder(state);

  return Object.entries(selectedProductsFactsForCheck(state))
    .filter(([productId]) => {
      const isSelected = selectedProducts.includes(productId);
      const isInMenu = allProductsFromBuilder.map(product => product.proposalProductId).includes(productId);

      return isInMenu || isSelected;
    })
    .map(([_, facts]) => facts)
    .flat();
};

const menuOptionsGroupedByName = (state: State) => {
  const menuOption = menuByTerm(state);

  return (menuOption.reduce((acc: any, option: any) => {
    acc[option.name] = option;

    return acc;
  }, {}) ?? {}) as Record<string, any[]>;
};

const isProposalsProductsLoading = (state: State) => state.desking.proposalProducts.isLoading;
const isNeedRunJsonEngine = (state: State) => state.desking.isNeedRunJsonEngine;
const proposalsProducts = (state: State) => state.desking.proposalProducts.products;

const proposalProductConfigurations = (state: State) => state.desking.proposalProducts.productsConfiguration ?? null;
const productConfigurationById = (state: State, productId: string) =>
  state.desking.proposalProducts.productsConfiguration[productId];
const selectedProductsConfigurations = (state: State) => {
  const selectedProductsIds = selectedProposalsProductsIds(state);

  return selectedProductsIds.reduce<Record<string, ProductConfig>>((acc, productId) => {
    acc[productId] = productConfigurationById(state, productId);
    return acc;
  }, {});
};
const proposalProductConfigurationsInMenu = (state: State) =>
  state.desking.proposalProducts.productsConfigurationInMenu?.[state.desking.term?.term] ?? null;

const proposalsProductsIds = (state: State) =>
  state.desking.proposalProducts.products.map(product => product.proposalProductId);

const commonProductsConfiguration = (state: State) => state.desking.proposalProducts.commonProductsConfiguration;

const isNeedRenderDynamicFields = (state: State) => !!productsFactsForCheckArray(state).length;

const proposalCreated = (state: State) => state.desking.isProposalCreated;

// Other

const recalculateStep = (state: State) => state.desking.recalculateStep;
const isSectionOpen = (state: State, section: FormSections) => state.desking.layout.sections?.[section]?.isOpen ?? true;
const sectionsStatuses = (state: State, sections: FormSections[]) =>
  sections.reduce<Partial<Record<FormSections, boolean>>>((acc, section) => {
    acc[section] = state.desking.layout.sections?.[section]?.isOpen ?? true;
    return acc;
  }, {});
const creditAppLocation = (state: State) => state.desking.creditAppLocation;
const equipment = (state: State) => state.desking.equipment.all;
const currentEquipment = (state: State) => state.desking.equipment.current;
const calculateMultiplePayments = (state: State) => state.desking.calculateMultiplePayments;
const equipmentDataById = (state: State, id: string) =>
  state.desking.equipment.all.find(equipment => equipment.equipmentId === id);
const equipmentSelectOptions = (state: State): SelectOption[] =>
  state.desking.equipment.all.map(equipment => ({
    label: [
      capitalizeFirstLetter(equipment?.newUsed ?? ""),
      equipment?.year,
      equipment?.make,
      equipment?.model,
      equipment?.serialNumberVin,
      equipment?.mileage,
    ]
      .filter(Boolean)
      .join(" "),
    value: equipment.equipmentId,
  })) ?? [];

export const deskingSelectors = {
  term,
  menuTerms,
  creditAppLocation,
  equipment,
  menuByTerm,
  menuByTerms,
  currentEquipment,
  isSectionOpen,
  financePrograms,
  proposalCreated,
  proposalMenus,
  recalculateStep,
  selectedProductsFactsForCheck,
  productsFactsForCheckArray,
  proposalMenuById,
  proposalsProducts,
  proposalsProductsIds,
  equipmentDataById,
  menuOptionsGroupedByName,
  selectedMenuOption,
  equipmentSelectOptions,
  financeProgramsSelectors,
  selectedProposalsProducts,
  isNeedRenderDynamicFields,
  calculateMultiplePayments,
  proposalMenuByCurrentTerm,
  sectionsStatuses,
  isProposalsProductsLoading,
  selectedProposalsProductsIds,
  selectedProposalsProductsArray,
  proposalProductConfigurations,
  selectedProductsConfigurations,
  proposalProductConfigurationsInMenu,
  isNeedRunJsonEngine,
  commonProductsConfiguration,
};
