import React from "react";
import { useSelector } from "react-redux";
import { CardContainer } from "components/shared/CardContainer/CardContainer";
import { menuConstructorSelectors } from "modules/aftermarketMenuConstructor/model";
import { GeneralInformationForm } from "../generalInformationForm/GeneralInformationForm";

export const GeneralInformationCard = () => {
  const isLoading = useSelector(menuConstructorSelectors.isLoading) as boolean;

  return (
    <CardContainer title="General" isLoading={isLoading}>
      <GeneralInformationForm />
    </CardContainer>
  );
};
