import React, { CSSProperties, ReactNode } from "react";
import clsx from "clsx";
import Tooltip from "@material-ui/core/Tooltip";
import { grey } from "@material-ui/core/colors";
import { Box, Typography } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { ArrowBackIos, ArrowForwardIos } from "@material-ui/icons";

interface Props {
  label: ReactNode;
  styles?: CSSProperties;
  forwardTooltip: string;
  backwardTooltip: string;
  isForwardDisabled: boolean;
  isBackwardDisabled: boolean;
  handleUpdateTerm: (action: "add" | "sub") => void;
}

export const TermsStepperManager = ({
  label,
  forwardTooltip,
  backwardTooltip,
  handleUpdateTerm,
  isForwardDisabled,
  isBackwardDisabled,
  styles = {},
}: Props) => {
  const classes = useStyles(styles);

  const isShowChangeTermArrows = !isBackwardDisabled || !isForwardDisabled;

  return (
    <Box className={classes.termPanel}>
      {isShowChangeTermArrows && (
        <Tooltip title={backwardTooltip}>
          <IconButton size="small" disabled={isBackwardDisabled} onClick={() => handleUpdateTerm("sub")}>
            <ArrowBackIos
              fontSize="small"
              className={clsx(classes.actionIcon, { [classes.disabledActionIcon]: isBackwardDisabled })}
            />
          </IconButton>
        </Tooltip>
      )}

      <Box style={{ width: "100%", textAlign: "center" }}>
        <Typography component="span" variant="subtitle2">
          {label}
        </Typography>
      </Box>

      {isShowChangeTermArrows && (
        <Tooltip title={forwardTooltip}>
          <IconButton size="small" disabled={isForwardDisabled} onClick={() => handleUpdateTerm("add")}>
            <ArrowForwardIos
              fontSize="small"
              className={clsx(classes.actionIcon, { [classes.disabledActionIcon]: isForwardDisabled })}
            />
          </IconButton>
        </Tooltip>
      )}
    </Box>
  );
};

const useStyles = makeStyles<Theme, CSSProperties>(({ palette }) => ({
  termPanel: {
    padding: "8px",
    display: "flex",
    minHeight: "42px",
    borderRadius: "2px",
    alignItems: "center",
    justifyContent: "space-between",
    color: ({ color }) => color ?? palette.common.black,
    backgroundColor: ({ backgroundColor }) => backgroundColor ?? grey["A100"],
  },
  actionIcon: {
    color: ({ color }) => color ?? palette.common.black,
  },
  disabledActionIcon: {
    opacity: "0.5",
  },
}));
