import React, { PropsWithChildren, ReactNode, useEffect } from "react";
import clsx from "clsx";
import { useDrag } from "react-dnd";
import { Controller, useForm, useWatch } from "react-hook-form";
import { blue, blueGrey, grey } from "@material-ui/core/colors";
import { Box, LinearProgress, Link, makeStyles, Typography } from "@material-ui/core";
import { CurrencyInputField, FormRadioGroup, InterestInputField } from "components/form";
import { MarkupType, ProposalProduct } from "@trnsact/trnsact-shared-types/dist/generated";
import { ModalsKeys } from "global";
import { parseCurrency } from "utils";
import { useModal } from "hooks/useModal";
import { CardHeader } from "./CardHeader";
import { markupTypesOptions } from "../../../../../constants";
import { ProductCatalogDetails } from "../../../catalogDetails/ProductCatalogDetails";
import { proposalProductUpdateDependentFields } from "../../../../../lib";
import { CommonMenuPriceValues, DnDTypes, ProductConfig, ProposalProductCardModes } from "../../../../../types";

interface Props {
  logo?: string;
  type: "simple" | "forEdit";
  isPricingEditable?: boolean;
  mode: ProposalProductCardModes;
  proposalProduct: ProposalProduct;
  isDynamicPricingLoading?: boolean;
  productConfiguration: ProductConfig;
  updateProductConfiguration: (updateValues: Partial<ProductConfig>) => void;
}

export const ProposalCardContainer = ({
  logo,
  type,
  children,
  proposalProduct,
  productConfiguration,
  updateProductConfiguration,
  isDynamicPricingLoading = false,
  isPricingEditable = true,
  mode,
}: PropsWithChildren<Props>) => {
  const classes = useStyles();

  const { handleOpen } = useModal(ModalsKeys.ProductCatalogDialog);

  const { control, setValue } = useForm<CommonMenuPriceValues>({
    defaultValues: {
      cost: productConfiguration.cost,
      markup: productConfiguration.markup,
      retailCost: productConfiguration.retailCost,
    },
  });

  const price = useWatch<CommonMenuPriceValues>({ control });

  useEffect(() => {
    setValue("cost", productConfiguration.cost);
    setValue("retailCost", productConfiguration.retailCost);
    setValue("markup.type", productConfiguration.markup?.type);
    setValue("markup.markup", productConfiguration.markup?.markup ?? 0);
  }, [productConfiguration]);

  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: DnDTypes.ProposalProduct,
      item: proposalProduct,
      collect: monitor => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [proposalProduct]
  );

  const handleChangeCost = (nextCost: unknown, forceMarkupType?: MarkupType) => {
    const cost = price.cost!;
    const markup = price.markup?.markup!;
    const retailCost = price.retailCost!;
    const markupType = forceMarkupType ?? price.markup?.type!;

    const { markupNextValue, costNextValue, retailCostNextValue } = proposalProductUpdateDependentFields(
      "cost",
      Number(nextCost ?? 0),
      proposalProduct,
      {
        cost,
        markup,
        markupType,
        retailCost,
      }
    );

    setValue("retailCost", retailCostNextValue);
    setValue("cost", costNextValue);
    setValue("markup.markup", markupNextValue);

    updateProductConfiguration({
      customPrice: true,
      cost: Number(nextCost),
      retailCost: +retailCostNextValue,
    });
  };

  const handleChangeMarkup = (nextMarkup: number, forceMarkupType?: MarkupType) => {
    const cost = Number(price.cost!);
    const markup = price.markup?.markup!;
    const retailCost = price.retailCost!;
    const markupType = forceMarkupType ?? price.markup?.type!;

    const { retailCostNextValue, costNextValue, markupNextValue } = proposalProductUpdateDependentFields(
      "markup.markup",
      nextMarkup,
      proposalProduct,
      {
        cost,
        markup,
        markupType,
        retailCost,
      }
    );

    setValue("retailCost", retailCostNextValue);
    setValue("cost", costNextValue);
    setValue("markup.markup", markupNextValue);

    updateProductConfiguration({
      customPrice: true,
      retailCost: +retailCostNextValue,
      markup: { markup: +nextMarkup, type: markupType },
    });
  };

  const handleChangeRetailCost = (nextRetailCost: unknown, forceMarkupType?: MarkupType) => {
    const cost = Number(price.cost!);
    const markup = price.markup?.markup!;
    const retailCost = price.retailCost!;

    const markupType = forceMarkupType ?? price.markup?.type!;

    const { markupNextValue, costNextValue, retailCostNextValue } = proposalProductUpdateDependentFields(
      "retailCost",
      Number(nextRetailCost ?? 0),
      proposalProduct,
      {
        cost,
        markup,
        markupType,
        retailCost,
      }
    );

    setValue("retailCost", retailCostNextValue);
    setValue("cost", costNextValue);
    setValue("markup.markup", markupNextValue);

    updateProductConfiguration({
      customPrice: true,
      retailCost: Number(nextRetailCost),
      markup: { markup: +markupNextValue, type: markupType },
    });
  };

  const handleChangeMarkupType = (nextType: MarkupType) => {
    const retailCost = price.retailCost!;
    const cost = Number(price.cost!);
    const markup = price.markup?.markup!;

    let markupNextValue: number = 0;

    if (nextType === MarkupType.Flat) {
      markupNextValue = retailCost - cost;
    }

    if (nextType === MarkupType.Percentage) {
      markupNextValue = (markup / cost) * 100;
    }

    setValue("markup.type", nextType);
    setValue("markup.markup", markupNextValue);

    updateProductConfiguration({
      markup: {
        markup: +markupNextValue,
        type: nextType,
      },
    });
  };

  const markupField: Record<MarkupType, ReactNode> = {
    [MarkupType.Flat]: (
      <CurrencyInputField
        label="Markup ($)"
        control={control}
        name="markup.markup"
        textFieldProps={{
          onChange: event => handleChangeMarkup(Number(parseCurrency(event.target.value))),
        }}
      />
    ),
    [MarkupType.Percentage]: (
      <InterestInputField
        label="Markup (%)"
        control={control}
        name="markup.markup"
        textFieldProps={{
          onChange: event => handleChangeMarkup(parseFloat(event.target.value)),
        }}
      />
    ),
  };

  return (
    <Box
      className={clsx(classes.card, {
        [classes.draggingCard]: isDragging,
        [classes.editCard]: type === "forEdit",
      })}
    >
      <Box className={classes.titleAndLogo} {...({ ref: drag } as any)}>
        <CardHeader
          logo={logo}
          proposalProduct={proposalProduct}
          onOpenProductCatalogDialog={() => {
            handleOpen({ proposalProduct });
          }}
        />
      </Box>

      <ProductCatalogDetails product={proposalProduct} />

      {proposalProduct?.thirdPartyUrl && (
        <Typography>
          <Link href={proposalProduct.thirdPartyUrl} target="_blank">
            {proposalProduct.thirdPartyUrl}
          </Link>
        </Typography>
      )}

      {children}

      {isDynamicPricingLoading && <LinearProgress style={{ width: "100%" }} />}

      {!isDynamicPricingLoading && (
        <Box className={classes.price}>
          <CurrencyInputField
            name="cost"
            label="Cost"
            control={control}
            textFieldProps={{
              onChange: event => handleChangeCost(parseCurrency(event.target.value)),
              disabled: mode === ProposalProductCardModes.Constructor || isPricingEditable === false,
            }}
          />

          {isPricingEditable && (
            <Box>
              {markupField[price.markup?.type!]}

              <Controller
                control={control}
                name="markup.type"
                render={({ field }) => (
                  <FormRadioGroup
                    row
                    options={markupTypesOptions}
                    {...field}
                    onChange={(event, value) => handleChangeMarkupType(value as MarkupType)}
                  />
                )}
              />
            </Box>
          )}

          {isPricingEditable && (
            <CurrencyInputField
              control={control}
              name="retailCost"
              label="Retail Price"
              textFieldProps={{
                onChange: event => handleChangeRetailCost(parseCurrency(event.target.value)),
              }}
            />
          )}
        </Box>
      )}
    </Box>
  );
};

const useStyles = makeStyles({
  card: {
    gap: "8px",
    display: "flex",
    padding: "12px",
    borderRadius: "2px",
    flexDirection: "column",
    backgroundColor: blueGrey["50"],
  },
  editCard: {
    backgroundColor: blue["50"],
  },
  draggingCard: {
    backgroundColor: grey["50"],
  },
  titleAndLogo: {
    display: "flex",
    flexDirection: "column",

    "&:hover": {
      cursor: "grab",
    },
  },
  price: {
    gap: "4px",
    display: "flex",

    "& > *": {
      flex: 1,
    },
  },
});
