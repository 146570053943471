import React, { PropsWithChildren, useCallback, useRef } from "react";
import { Engine } from "json-rules-engine";
import { useDispatch } from "react-redux";
import { CustomOperators } from "@trnsact/business-criteria";
import { ProposalProduct } from "@trnsact/trnsact-shared-types/dist/generated";
import { deskingActions } from "../model";
import { Criteria, MarkupType, ProductConfig } from "../types";
import { jsonRulesEngineContext as JsonRulesEngineContext } from "./jsonRulesEngineContext";

export const RulesEngineProvider = ({ children }: PropsWithChildren) => {
  const dispatch = useDispatch();

  const productConfigurationRulesEngine = useRef<Engine>(new Engine());

  productConfigurationRulesEngine.current.addOperator("length", CustomOperators.length);

  const handleAddProductsRules = useCallback(
    (product: ProposalProduct, updateProductConfiguration?: (updateValues: Partial<ProductConfig>) => void) => {
      const rules = (product.aftermarketProduct?.criteria ?? []) as Criteria[];

      rules.forEach(rule => {
        productConfigurationRulesEngine.current.addRule({
          ...rule,
          conditions: { all: rule.conditions.jsonRules.conditions.all },
        });

        if (rules.length === 1 && !rule.conditions.jsonRules.conditions.all.length) {
          updateProductConfiguration?.({
            isPricingValid: !!rule.event,
            cost: rule.event?.params?.DEALER_COST ?? 0,
            retailCost: rule.event?.params?.SUGGESTED_RETAIL_PRICE ?? 0,
            markup: {
              markup: rule.event?.params?.MARKUP?.AMOUNT ?? 0,
              type: rule.event?.params?.MARKUP?.TYPE === "fixed" ? MarkupType.Flat : MarkupType.Percentage,
            },
          });
        }
      });
    },
    []
  );

  const handleRunEngin = useCallback(() => {
    dispatch(deskingActions.runJsonEngine());
  }, [dispatch]);

  return (
    <JsonRulesEngineContext.Provider
      value={{ engine: productConfigurationRulesEngine.current, handleAddProductsRules, handleRunEngin }}
    >
      {children}
    </JsonRulesEngineContext.Provider>
  );
};
