import React, { FC } from "react";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  EquipmentDynamicFieldsProps,
  FactToCheck,
  FactToCheckTypes,
  ProductConfig,
  ProposalProductCardModes,
} from "modules/desking/types";
import { useRulesEngine } from "../../../../../context/useRulesEngine";
import { BooleanField, NumericField, StringField } from "../../../equipmentDynamicFielda";

interface Props {
  factsToCheck: FactToCheck[];
  mode: ProposalProductCardModes;
  productConfiguration: ProductConfig;
  updateProductConfiguration: (updateValues: Partial<ProductConfig>) => void;
}

export const DynamicFields = ({ factsToCheck, productConfiguration, updateProductConfiguration }: Props) => {
  const classes = useStyles();

  const { engine, handleRunEngin } = useRulesEngine();

  const componentsByType: Record<FactToCheckTypes, FC<EquipmentDynamicFieldsProps>> = {
    string: StringField,
    numeric: NumericField,
    boolean: BooleanField,
  };

  const handelChange = (factKey: string, nextValue: string) => {
    engine.addFact(factKey, nextValue);
    updateProductConfiguration({ [factKey]: nextValue });

    handleRunEngin();
  };

  return (
    <Box className={classes.fields}>
      {factsToCheck
        .filter(fact => fact.factKey !== "DEAL_SIZE")
        .map(fact => {
          const Field = componentsByType[fact.type];
          return (
            <Field
              fact={fact}
              key={fact.factKey}
              value={productConfiguration?.[fact.factKey] ?? ""}
              options={fact.options?.map(value => ({ label: value, value })) ?? []}
              onChange={nextValue => {
                handelChange(fact.factKey, nextValue);
              }}
            />
          );
        })}
    </Box>
  );
};

const useStyles = makeStyles({
  fields: {
    gap: "4px",
    display: "flex",
    flexDirection: "column",
  },
});
