import React from "react";
import { useSelector } from "react-redux";
import { Box } from "@material-ui/core";
import { useFormContext } from "react-hook-form";
import { CurrencyInputField } from "components/form";
import { deskingSelectors } from "../../../../model";
import { FormSectionHeader } from "../../../components";
import { FinanceProgramFormValues, FormSectionProps } from "../../../../types";

export const SaleDetails = ({ sectionName, onUpdateCollapse, updateInvoiceAction }: FormSectionProps) => {
  const { control } = useFormContext<FinanceProgramFormValues>();

  const isSectionOpen = useSelector((state: any) => deskingSelectors.isSectionOpen(state, sectionName));

  return (
    <Box className="section">
      <FormSectionHeader
        name="Sale Details"
        sectionName={sectionName}
        isSectionOpen={isSectionOpen}
        extraAction={updateInvoiceAction}
        onUpdateCollapse={onUpdateCollapse}
      />

      <Box className="sectionFields">
        <CurrencyInputField
          control={control}
          label="Equipment Subtotal"
          name="saleDetails.equipmentSubtotal"
          textFieldProps={{ disabled: true }}
        />

        <Box className="row">
          <CurrencyInputField
            control={control}
            label="Sales Tax"
            name="saleDetails.salesTax"
            textFieldProps={{ disabled: true }}
          />

          <CurrencyInputField
            control={control}
            label="Total Fees"
            name="saleDetails.fees"
            textFieldProps={{ disabled: true }}
          />

          <CurrencyInputField
            control={control}
            label="Net trade"
            name="saleDetails.netRate"
            textFieldProps={{ disabled: true }}
          />
        </Box>

        <Box className="row">
          <CurrencyInputField
            control={control}
            label="Invoice Total"
            name="saleDetails.invoiceTotal"
            textFieldProps={{ disabled: true }}
          />

          <CurrencyInputField
            control={control}
            label="Down Payment"
            name="saleDetails.downPayment"
            textFieldProps={{ disabled: true }}
          />

          <CurrencyInputField
            control={control}
            label="Net finance amount"
            name="saleDetails.financeAmount"
            textFieldProps={{ disabled: true }}
          />
        </Box>
      </Box>
    </Box>
  );
};
