import React, { useMemo, useRef } from "react";
import clsx from "clsx";
import { useDrag, useDrop } from "react-dnd";
import { Box, Typography } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { ProposalProduct } from "@trnsact/trnsact-shared-types/src/generated";
import { CardPriceBlock } from "../cardPriceBlock";
import { formatCurrency } from "../../../../../utils";
import { AftermarketProductTypes, DnDTypes, MarkupType, ProductConfig, ProposalProductCardModes } from "../../../types";
import { ProposalProductCard } from "./ProposalProductCard";
import { MenuOptionCardHeader } from "./MenuOptionCardHeader";
import { vendorsLogoByType } from "../../../constants";

interface Props {
  menuOption: any;
  menuOptionIdx: number;
  isCalculating: boolean;
  selectedMenuOption?: any;
  mode?: ProposalProductCardModes;
  onEditOption?: (currentMenuOption: any) => void;
  onRemoveOption?: (currentMenuOption: any) => void;
  onSelectMenuOption?: (optionName: string) => void;
  productsConfiguration?: Record<string, ProductConfig>;
  moveMenuOption: (dragIndex: number, hoverIndex: number) => void;
  onOpenProductDetailsDialog?: (product: ProposalProduct) => void;
  onRemoveProduct?: (productId: string, currentMenuOption: any) => void;
  onDropProduct?: (product: ProposalProduct, menuOptionName: string) => void;
  onRemoveAddon?: (product: ProposalProduct, optionName: string, title: string) => void;
  onSwitchProducts: (options: { menuName: string; from: number; to: number }) => void;
}

export const MenuOptionCard = ({
  menuOption,
  menuOptionIdx,
  isCalculating,
  onEditOption,
  onRemoveOption,
  onDropProduct,
  onRemoveAddon,
  onRemoveProduct,
  moveMenuOption,
  onSwitchProducts,
  selectedMenuOption,
  onSelectMenuOption,
  onOpenProductDetailsDialog,
  productsConfiguration = {},
  mode = ProposalProductCardModes.Desking,
}: Props) => {
  const classes = useStyles({
    cardPanelTitleColor: menuOption?.titleColor ?? "",
    cardPanelBg: menuOption?.titleBgColor ?? "",
    isSelectable: !!onSelectMenuOption,
  });

  const menuOptionRef = useRef<HTMLDivElement>(null);

  const commonPrice = useMemo(
    () =>
      menuOption?.products.reduce(
        (acc: any, { proposalProductId }: any) => {
          const config = productsConfiguration[proposalProductId];

          acc.cost += +config?.cost;
          acc.markup.markup += +config?.retailCost - +config?.cost;
          acc.markup.type = MarkupType.Flat;
          acc.retailCost += +config?.retailCost;

          return acc;
        },
        { cost: 0, markup: { markup: 0, type: null }, retailCost: 0 }
      ),
    [menuOption?.products, productsConfiguration]
  );

  const [{ isOver }, productsDropContainer] = useDrop<ProposalProduct, void, { isOver: boolean }>(
    () => ({
      accept: DnDTypes.ProposalProduct,
      drop: product => onDropProduct!(product, menuOption.name),
      collect: monitor => ({
        isOver: monitor.isOver(),
      }),
    }),
    [onDropProduct, menuOption]
  );

  const [{ handlerId }, menuOptionDropContainer] = useDrop(
    () => ({
      accept: DnDTypes.MenuOption,
      collect: monitor => ({
        handlerId: monitor.getHandlerId(),
      }),
      hover(item: any, monitor) {
        if (!menuOptionRef.current) {
          return;
        }
        const dragIndex = item.menuOptionIdx;
        const hoverIndex = menuOptionIdx;
        // Don't replace items with themselves
        if (dragIndex === hoverIndex) {
          return;
        }
        // Determine rectangle on screen
        const hoverBoundingRect = menuOptionRef.current?.getBoundingClientRect();
        // Get horizontal middle
        const hoverMiddleX = (hoverBoundingRect.left - hoverBoundingRect.right) / 2;
        // Determine mouse position
        const clientOffset = monitor.getClientOffset();
        // Get pixels to the right
        const hoverClientX = clientOffset!.x - hoverBoundingRect.right;
        // Only perform the move when the mouse has crossed half of the items width
        if (dragIndex < hoverIndex && hoverClientX < hoverMiddleX) {
          return;
        }
        if (dragIndex > hoverIndex && hoverClientX > hoverMiddleX) {
          return;
        }
        // Time to actually perform the action
        moveMenuOption(dragIndex, hoverIndex);
        item.menuOptionIdx = hoverIndex;
      },
    }),
    [moveMenuOption]
  );

  const [{ isMenuOptionDragging }, drag] = useDrag(
    () => ({
      type: DnDTypes.MenuOption,
      item: { menuOptionIdx },
      collect: monitor => ({
        isMenuOptionDragging: monitor.isDragging(),
      }),
    }),
    [menuOptionIdx]
  );

  drag(menuOptionDropContainer(menuOptionRef));

  return (
    <Box
      className={clsx(classes.card, {
        [classes.overCard]: isOver,
        [classes.draggingCard]: isMenuOptionDragging,
        [classes.selectedCard]: menuOption.name === selectedMenuOption?.name,
        [classes.selectedOverCard]: isOver && menuOption.name === selectedMenuOption?.name,
      })}
      onClick={() => {
        if (onSelectMenuOption) onSelectMenuOption(menuOption.name);
      }}
      {...({ ref: menuOptionRef } as any)}
      data-handler-id={handlerId}
    >
      <MenuOptionCardHeader menuOption={menuOption} onEditOption={onEditOption} onRemoveOption={onRemoveOption} />

      <Box className={classes.dnd} {...({ ref: productsDropContainer } as any)}>
        {!menuOption?.products.length ? (
          <Typography component="span" variant="body1" color="textSecondary">
            Drag & drop options here
          </Typography>
        ) : (
          <Box className={classes.proposalProductCards}>
            {menuOption?.products.length &&
              menuOption?.products.map((product: any, index: number) => (
                <ProposalProductCard
                  key={index}
                  mode={mode}
                  index={index}
                  product={product}
                  onOpenProductDetailsDialog={() => onOpenProductDetailsDialog?.(product)}
                  productConfiguration={productsConfiguration?.[product.proposalProductId]}
                  onRemoveAddon={title => onRemoveAddon?.(product, menuOption.name, title)}
                  onSwitchProducts={(from, to) => onSwitchProducts({ menuName: menuOption.name, from, to })}
                  onRemoveProduct={() => onRemoveProduct?.(product.proposalProductId, menuOption)}
                  logo={
                    vendorsLogoByType?.[
                      product?.aftermarketProduct?.aftermarketVendorApiChannel as AftermarketProductTypes
                    ]
                  }
                />
              ))}
          </Box>
        )}
      </Box>

      <CardPriceBlock type="withBg" isTotal {...commonPrice} />

      <Box className={classes.cardMonthPrice}>
        <Box className={clsx("monthPrice", { monthPriceCalculating: isCalculating })}>
          <Typography component="span" variant="h6">
            {formatCurrency(menuOption?.payment?.paymentAmountPerPeriod)}
          </Typography>

          <Typography component="span" variant="caption" color="textSecondary">
            per month
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles<Theme, { cardPanelBg: string; cardPanelTitleColor: string; isSelectable: boolean }>(
  ({ palette }) => ({
    card: {
      gap: "8px",
      padding: "4px",
      display: "flex",
      minHeight: "260px",
      minWidth: "300px",
      width: "300px",
      flexDirection: "column",
      border: "2px dashed lightgrey",
      justifyContent: "space-between",
      cursor: ({ isSelectable }) => (isSelectable ? "pointer" : "default"),
    },
    overCard: {
      border: "2px solid lightgrey",
    },
    selectedCard: {
      border: `2px dashed ${palette.primary.main}`,
    },
    selectedOverCard: {
      border: `2px solid ${palette.primary.main}`,
    },
    draggingCard: {
      opacity: 0,
    },
    dnd: {
      flex: 1,
      display: "flex",
      padding: "4px 0",
      alignItems: "center",
      justifyContent: "center",
    },
    cardMonthPrice: {
      padding: "4px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",

      "& .monthPrice": {
        gap: "4px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },

      "& .monthPriceCalculating": {
        filter: "blur(2px)",
      },
    },
    proposalProductCards: {
      gap: "4px",
      width: "100%",
      display: "flex",
      flexDirection: "column",
    },
  })
);
