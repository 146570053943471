import React, { useEffect, useState } from "react";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import { Task } from "@trnsact/trnsact-shared-types";
import { ReferenceTaskCompletionDataItem, ReferenceTaskCompletionData } from "types";
import { keyBy } from "utils/arrayUtils";
import moment from "moment";

interface Props {
  task: Task;
}

export const TradeReferenceResults = ({ task }: Props) => {
  const classes = useStyles();

  const [completionDataByKey, setCompletionDataByKey] = useState<Record<string, ReferenceTaskCompletionDataItem>>({});

  useEffect(() => {
    const tasksCompletionData = task.reference?.tasksCompletionData as ReferenceTaskCompletionData[];
    const completionData = tasksCompletionData?.find(i => i.taskId === task.taskId)
      ?.data as ReferenceTaskCompletionDataItem[];

    if (!completionData) {
      return;
    }

    setCompletionDataByKey(keyBy(completionData, "fieldKey"));
  }, []);

  return (
    <Grid item container spacing={1}>
      <Grid item xs={4}>
        <Typography variant="subtitle2" component="p">
          Customer Since:{" "}
        </Typography>
        <Typography variant="body1">{completionDataByKey.customerSince?.value || "-"}</Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography variant="subtitle2" component="p">
          Last Sale:{" "}
        </Typography>
        <Typography variant="body1">{completionDataByKey.lastSale?.value || "-"}</Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography variant="subtitle2" component="p">
          Credit Limit:{" "}
        </Typography>
        <Typography variant="body1">{completionDataByKey.creditLimit?.value || "-"}</Typography>
      </Grid>

      <Grid item xs={4}>
        <Typography variant="subtitle2" component="p">
          High Credit (12 months):{" "}
        </Typography>
        <Typography variant="body1">{completionDataByKey.highCredit?.value || "-"}</Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography variant="subtitle2" component="p">
          Credit Terms:{" "}
        </Typography>
        <Typography variant="body1">{completionDataByKey.creditTerms?.value || "-"}</Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography variant="subtitle2" component="p">
          Current Balance:{" "}
        </Typography>
        <Typography variant="body1">{completionDataByKey.currentBalance?.value || "-"}</Typography>
      </Grid>

      <Grid item xs={4}>
        <Typography variant="subtitle2" component="p">
          Amount past due:{" "}
        </Typography>
        <Typography variant="body1">{completionDataByKey.amountPastDue?.value || "-"}</Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography variant="subtitle2" component="p">
          Days Past Due:{" "}
        </Typography>
        <Typography variant="body1">{completionDataByKey.daysPastDue?.value || "-"}</Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography variant="subtitle2" component="p">
          Pay History:{" "}
        </Typography>
        <Typography variant="body1">{completionDataByKey.payHistory?.value || "-"}</Typography>
      </Grid>

      <Grid item xs={12}>
        <Typography className={classes.sectionSubtitle}>
          <strong>Manner of payment</strong>
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography variant="subtitle2" component="p">
          Discount:{" "}
        </Typography>
        <Typography variant="body1">{completionDataByKey.discount?.value || "-"}</Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography variant="subtitle2" component="p">
          Prompt:{" "}
        </Typography>
        <Typography variant="body1">{completionDataByKey.prompt?.value || "-"}</Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography variant="subtitle2" component="p">
          Slow (days):{" "}
        </Typography>
        <Typography variant="body1">{completionDataByKey.slow?.value || "-"}</Typography>
      </Grid>

      <Grid item xs={12}>
        <Typography className={classes.sectionSubtitle}>
          <strong>Security</strong>
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography variant="subtitle2" component="p">
          Security:{" "}
        </Typography>
        <Typography variant="body1">{completionDataByKey.security?.value || "-"}</Typography>
      </Grid>
      {completionDataByKey.securityInfo?.value && (
        <Grid item xs={4}>
          <Typography variant="subtitle2" component="p">
            {completionDataByKey.securityInfo.label}
          </Typography>
          <Typography variant="body1">{completionDataByKey.securityInfo.value}</Typography>
        </Grid>
      )}

      {completionDataByKey.comments?.value && (
        <>
          <Grid item xs={12}>
            <Typography className={classes.sectionSubtitle}>
              <strong>Comments</strong>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">{completionDataByKey.comments.value}</Typography>
          </Grid>
        </>
      )}

      <Grid item xs={12}>
        <Typography className={classes.sectionSubtitle}>
          <strong>Reference Completed By:</strong>
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography variant="subtitle2" component="p">
          Name:{" "}
        </Typography>
        <Typography variant="body1">
          {completionDataByKey.name?.value}
          {`${completionDataByKey.title?.value ? ", " + completionDataByKey.title?.value : ""}`}
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography variant="subtitle2" component="p">
          Phone:{" "}
        </Typography>
        <Typography variant="body1">{completionDataByKey.phone?.value || "-"}</Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography variant="subtitle2" component="p">
          Date:{" "}
        </Typography>
        <Typography variant="body1">{moment(task.completedDateTime).format("ll")}</Typography>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles(() => ({
  sectionSubtitle: {
    marginTop: 15,
  },
}));
