import _ from "lodash";
import { ContactFormValues, ContactPayloadInput, ContactType, RecertificationFormValues } from "../types";
import { getDateTimeDisplayValue } from "services/creditAppModalService";
import {
  BusinessOwnerInput,
  CreditApplicationOwner,
  UserProfile,
  VendorOpportunity,
} from "@trnsact/trnsact-shared-types";
import { iniitialContactFormValues } from "../constants";
import { differenceInDays } from "date-fns";

export const processFormValuesToPayload = (formValues: ContactFormValues, initialValues: any): ContactPayloadInput => {
  return Object.keys(formValues).reduce((result: any, key: string) => {
    let value: any = formValues[key as keyof ContactFormValues];
    if (!value) {
      // to skip not filled values
      return result;
    }
    if (key === "percOwner") {
      // ambig mapping
      value = parseFloat(value);
    }
    result[key] = value;
    return result;
  }, initialValues);
};

export const getContactToSave = (
  creditApplication: any,
  contactFormValues: ContactFormValues,
  contactType: ContactType
): ContactPayloadInput => {
  const allAvailableOwners = [...[creditApplication.primaryContact], ...creditApplication.creditApplicationOwner];
  const ownerNumber = _.max(_.map(allAvailableOwners, "ownerNumber")) + 1;
  const initialValues = {
    creditApplicationId: creditApplication.id,
    isOwnerPc: false,
    isOwnerPg: contactType === "signor-and-guarantor",
    isOwnerPcOnly: false,
    ownerNumber,
  };
  return processFormValuesToPayload(contactFormValues, initialValues);
};

export const getCreditAppInputForDocumentOrVerbalAuthorization = (
  creditApplication: any,
  recertificationFormValues: RecertificationFormValues,
  currentUserProfile: UserProfile
): any => {
  const recertificationDate = new Date(recertificationFormValues.recertificationDate).toISOString();
  const newRecertificationHistoryRow = `Recertification for Credit App on ${getDateTimeDisplayValue(
    new Date().getTime()
  )} by ${currentUserProfile.fullName}. Recertification method: ${recertificationFormValues.recertificationOption}`;
  const owners = (creditApplication.creditApplicationOwner as CreditApplicationOwner[]).reduce<BusinessOwnerInput[]>(
    (owners: any, owner: any) => {
      if (!owner.signatureDate) {
        return owners;
      }
      return [
        ...owners,
        {
          ownerPgId: owner.ownerPgId!,
          signatureDate: recertificationDate,
        },
      ];
    },
    []
  );
  return {
    owners,
    recertificationDate,
    recertificationHistory: !_.isEmpty(creditApplication.recertificationHistory)
      ? [...creditApplication.recertificationHistory, newRecertificationHistoryRow]
      : [newRecertificationHistoryRow],
  };
};

export const getRecertifiedCreditAppUploadFileInput = (vo: VendorOpportunity): any => {
  return {
    vendorOpportunityId: vo.vendorOpportunityId,
    dynamicsVendorOpportunityId: vo.dynamicsVendorOpportunityId,
    source: "will_be_changed_by_server",
    createdBy: "will_be_changed_by_server",
    docName: "will_be_changed_by_server",
    docType: "Recertified Credit Application",
    docDescription: "Recertified Credit Application",
  };
};

export const getContactToAddGuaranteeFormValues = (contact: any): ContactFormValues => {
  return Object.keys(iniitialContactFormValues).reduce(
    (result: any, key: string) => {
      const valueFromContact =
        key === "percOwner"
          ? contact["ownerPercentage"] // ambig mapping
          : contact[key];
      result[key] = valueFromContact;
      return result;
    },
    { ...iniitialContactFormValues }
  );
};

export const calculateDaysAfterLastRecertification = (creditApplication: any): number => {
  const lastRecertificationDate: Date = creditApplication.recertificationDate
    ? new Date(+creditApplication.recertificationDate)
    : new Date(creditApplication.createdDateTime);
  const duration: number = differenceInDays(new Date(), lastRecertificationDate);
  return duration;
};

export const getOwnerFullName = (owner: CreditApplicationOwner): string => {
  return _([owner.firstName || owner.firstName, owner.lastName || owner.lastName])
    .compact()
    .join(" ");
};

export const checkIfOwnerIsSimpleContact = (owner: CreditApplicationOwner) => {
  return !owner.pc && !owner.pg && !owner.pcOnly && !owner.signatureDate && !owner.personalGuaranteeSignatureDate;
};
