import { FinanceProgramFormValues, MarkupMethods, Structures } from "../types";
import { FlatFee } from "@trnsact/trnsact-shared-types";
import { ValueOf } from "../../../global";

export function convertProgramConfigToFormValues(
  programConfig: any
): Partial<ValueOf<FinanceProgramFormValues, "financeQuote">> {
  const isCommonRate = new Set(programConfig.terms.map((termValue: any) => termValue?.rate)).size === 1;

  let markupMethod: MarkupMethods | undefined = undefined;
  let dealerMarkup: number | undefined = undefined;
  let structure: Structures | undefined = undefined;

  if (programConfig.customerRate) {
    markupMethod = MarkupMethods.CustomerRate;
    dealerMarkup = programConfig.customerRate;
    if (dealerMarkup && dealerMarkup < programConfig.terms[0].rate) {
      structure = Structures.Subsidy;
    } else {
      structure = Structures.Markup;
    }
  } else if (programConfig.dealerMarkup) {
    markupMethod = MarkupMethods.MarkupPercentage;
    dealerMarkup = programConfig.dealerMarkup;
    structure = Structures.Markup;
  } else {
    structure = Structures.None;
  }

  return {
    rateType: programConfig.rateType,
    programName: programConfig.financeProgramName,
    paymentCalculationMethod: programConfig.paymentPeriodTiming,
    dealerMarkup: dealerMarkup ? dealerMarkup.toFixed(2).toString() : "",
    markupRate: dealerMarkup ? dealerMarkup.toFixed(2).toString() : "",
    advancePayment: programConfig.numberOfAdvancePayments,
    markupMethod,
    structure,
    terms: programConfig.terms
      .map((termValue: any) => (termValue?.term ? String(termValue?.term) : ""))
      .filter(Boolean),
    commonRate: isCommonRate ? String(programConfig.terms[0].rate) : "",
    specifyRates: !isCommonRate
      ? programConfig.terms.map((termValue: any) => ({ term: String(termValue?.term), rate: String(termValue?.rate) }))
      : [],
    fee: programConfig.flatFees.map((feeValue: FlatFee) => {
      return { ...feeValue };
    }),
    specifyRate: !isCommonRate,
  };
}
