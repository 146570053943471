import { ActionTypes, Nullable } from "global";
import { immutableUtils } from "utils/immutable";
import { ProposalProduct } from "@trnsact/trnsact-shared-types/src/generated";
import { DeskingState } from "./types";
import { deskingActions } from "./actions";
import { factKeyMapToEquipment } from "../constants";
import { collectFactsToCheckFromProduct } from "../lib";
import { FactToCheck, FormSections, ProductConfig } from "../types";
import { FinanceProgram } from "@trnsact/trnsact-shared-types";

type DeskingActions = ActionTypes<typeof deskingActions>;

const initialState: DeskingState = {
  recalculateStep: 0,
  isProposalCreated: false,
  isNeedRunJsonEngine: 0,
  financePrograms: {},
  proposalMenus: {},
  term: { index: 0, term: "" },
  proposalProducts: {
    products: [],
    isLoading: false,
    selectedProducts: {},
    productsFactsToCheck: {},
    productsConfiguration: {},
    productsConfigurationInMenu: {},
    commonProductsConfiguration: {},
  },
  menuBuilder: {},
  layout: {
    sections: {
      [FormSections.MenuBuilder]: {
        isOpen: false,
      },
    },
    selectedMenuOptions: null,
  },
  creditAppLocation: null,
  equipment: {
    all: [],
    current: null,
  },
  calculateMultiplePayments: [],
};

export const deskingReducer = (state = initialState, action: DeskingActions): DeskingState => {
  switch (action.type) {
    case "DESKING_SET_PROPOSALS_PRODUCTS":
      return setProducts(state, action.payload.products);

    case "DESKING_SET_PRODUCTS_CONFIGURATION_FROM_EXISTING_MENU":
      return {
        ...state,
        proposalProducts: {
          ...state.proposalProducts,
          productsConfigurationInMenu: {
            ...action.payload.terms.reduce((acc: any, term) => {
              acc[term] = {
                ...action.payload.data.reduce((optionConfigAcc: any, option: any) => {
                  optionConfigAcc[option.name] = collectConfigFromProducts({
                    state,
                    products: option.products.filter(({ proposalProductId }: any) =>
                      state.proposalProducts.products
                        .map(availableProduct => availableProduct.proposalProductId)
                        .includes(proposalProductId)
                    ),
                  });

                  return optionConfigAcc;
                }, {}),
              };

              return acc;
            }, {}),
          },
        },
      };

    case "DESKING_UPDATE_PRODUCT_CONFIGURATION":
      return {
        ...state,
        proposalProducts: {
          ...state.proposalProducts,
          productsConfiguration: {
            ...state.proposalProducts.productsConfiguration,
            [action.payload.productId]: {
              ...(state.proposalProducts.productsConfiguration?.[action.payload.productId] ?? {}),
              ...action.payload.data,
            },
          },
        },
      };

    case "DESKING_UPDATE_ALL_PRODUCTS_CONFIGURATION": {
      return {
        ...state,
        isNeedRunJsonEngine: state.isNeedRunJsonEngine + 1,
        proposalProducts: {
          ...state.proposalProducts,
          commonProductsConfiguration: {
            ...state.proposalProducts.commonProductsConfiguration,
            ...action.payload.data,
          },
          productsConfiguration: {
            ...state.proposalProducts.productsConfiguration,
            ...Object.keys(state.proposalProducts.selectedProducts).reduce<Record<string, ProductConfig>>(
              (acc, productId) => {
                const [ruleKey] = Object.keys(action.payload.data);

                const isCurrentProductSupportRule = !!state.proposalProducts.productsFactsToCheck[productId].find(
                  ({ factKey }) => factKey === ruleKey
                );

                if (isCurrentProductSupportRule) {
                  acc[productId] = state.proposalProducts.productsConfiguration[productId] = {
                    ...state.proposalProducts.productsConfiguration[productId],
                    ...action.payload.data,
                  };
                }

                return acc;
              },
              {}
            ),
          },
          productsConfigurationInMenu: {
            ...Object.entries(state.proposalProducts.productsConfigurationInMenu).reduce(
              (termsAcc: any, [term, configByOption]) => {
                termsAcc[term] = Object.entries(configByOption).reduce(
                  (configByOptionAcc: any, [optionName, configByProductId]) => {
                    configByOptionAcc[optionName] = Object.entries(configByProductId).reduce(
                      (configByProductIdAcc: any, [productId, config]) => {
                        configByProductIdAcc[productId] = {
                          ...config,
                          ...action.payload.data,
                        };
                        return configByProductIdAcc;
                      },
                      {}
                    );

                    return configByOptionAcc;
                  },
                  {}
                );

                return termsAcc;
              },
              {}
            ),
          },
        },
      };
    }

    case "DESKING_UPDATE_PRODUCT_CONFIGURATION_IN_MENU":
      return {
        ...state,
        proposalProducts: {
          ...state.proposalProducts,
          productsConfigurationInMenu: {
            ...Object.entries(state.proposalProducts.productsConfigurationInMenu).reduce(
              (acc: any, [term, optionConfig]) => {
                acc[term] = {
                  ...optionConfig,
                  [action.payload.menuName]: {
                    ...(optionConfig?.[action.payload.menuName] ?? {}),
                    [action.payload.productId]: {
                      ...(optionConfig?.[action.payload.menuName]?.[action.payload.productId] ?? {}),
                      ...action.payload.data,
                    },
                  },
                };

                return acc;
              },
              {}
            ),
          },
        },
      };

    case "DESKING_TOGGLE_PROPOSALS_PRODUCTS_LOADING":
      return {
        ...state,
        proposalProducts: {
          ...state.proposalProducts,
          isLoading: action.payload.isLoading,
        },
      };

    case "DESKING_UPDATE_RECALCULATE":
      return {
        ...state,
        recalculateStep: state.recalculateStep + 1,
      };

    case "DESKING_SET_CREDIT_APP_LOCATION_DATA":
      return {
        ...state,
        creditAppLocation: action.payload.creditAppLocationData,
      };

    case "DESKING_SET_EQUIPMENT_DATA":
      return {
        ...state,
        equipment: {
          ...state.equipment,
          all: action.payload.equipments,
          current: action.payload.equipments[0],
        },
      };

    case "DESKING_SET_CURRENT_EQUIPMENT_DATA":
      return {
        ...state,
        equipment: {
          ...state.equipment,
          current: state.equipment.all.find(equipment => equipment.equipmentId === action.payload.equipmentId) ?? null,
        },
      };

    case "DESKING_SET_FINANCE_PROGRAMS":
      return {
        ...state,
        financePrograms: action.payload.programs.reduce<Record<string, FinanceProgram>>((acc, product) => {
          acc[product.financeProgramId] = product;
          return acc;
        }, {}),
      };

    case "DESKING_SET_PROPOSAL_MENUS":
      return {
        ...state,
        proposalMenus: action.payload.menuOptions.reduce((acc: any, menu: any) => {
          acc[menu.proposalMenuId] = {
            ...menu,
            menuOptions: menu.menuOptions.map((option: any) => ({
              ...option,
              products: option.products.toSorted((a: any, b: any) => a.ordinal - b.ordinal),
            })),
          };
          return acc;
        }, {}),
      };

    case "DESKING_RESET_MENU_BUILDER":
      return {
        ...state,
        proposalProducts: {
          ...state.proposalProducts,
          productsConfigurationInMenu: {},
        },
        calculateMultiplePayments: [],
        menuBuilder: {},
      };

    case "DESKING_UPDATE_PROPOSALS_PRODUCTS":
      return {
        ...state,
        proposalProducts: {
          ...state.proposalProducts,
          selectedProducts: action.payload.products.reduce<Record<string, ProposalProduct>>((acc, product) => {
            acc[product.proposalProductId] = product;
            return acc;
          }, {}),
          productsConfiguration: {
            ...state.proposalProducts.productsConfiguration,
            ...collectConfigFromProducts({ state, products: action.payload.products }),
          },
        },
      };

    case "DESKING_UNSELECT_PROPOSALS_PRODUCTS":
      return unselectProduct(state, action.payload.productId);

    case "DESKING_SET_PROPOSAL_CREATED":
      return {
        ...state,
        isProposalCreated: action.payload.isCreated,
      };

    case "DESKING_SET_MENU_RESULTS":
      return {
        ...state,
        menuBuilder: action.payload.menuBuilder,
        proposalProducts: {
          ...state.proposalProducts,
          productsConfigurationInMenu: {
            ...Object.entries(action.payload.menuBuilder).reduce((termsAcc: any, [term, option]: any) => {
              if (state.proposalProducts.productsConfigurationInMenu[term]) {
                termsAcc[term] = state.proposalProducts.productsConfigurationInMenu[term];
              } else {
                termsAcc[term] = option.menu.menuOptions.reduce((optionConfig: any, menuOption: any) => {
                  optionConfig[menuOption.name] = collectConfigFromProducts({
                    products: menuOption.products,
                    state,
                  });

                  return optionConfig;
                }, {});
              }

              return termsAcc;
            }, {}),
          },
        },
      };

    case "DESKING_SET_MENU_TERM":
      return {
        ...state,
        term: {
          ...state.term,
          ...action.payload.data,
        },
      };

    case "DESKING_SET_MENU_OPTIONS":
      return {
        ...state,
        menuBuilder: Object.entries(state.menuBuilder).reduce<Record<string, any>>((acc, [term, builder]) => {
          acc[term] = {
            ...state.menuBuilder[state.term.term],
            menu: {
              ...builder,
              menuOptions: action.payload.menuOptions,
            },
          };

          return acc;
        }, {}),
      };

    case "DESKING_SET_ORDER_OF_PRODUCT_IN_MENU":
      return {
        ...state,
        menuBuilder: {
          ...state.menuBuilder,
          [state.term.term]: {
            ...state.menuBuilder[state.term.term],
            menu: {
              ...state.menuBuilder[state.term.term].menu,
              menuOptions: state.menuBuilder[state.term.term].menu.menuOptions.reduce((acc: any, option: any) => {
                if (option.name === action.payload.menuName) {
                  acc.push({
                    ...option,
                    products: immutableUtils.swapItems(option.products, action.payload.from, action.payload.to),
                  });
                } else acc.push(option);

                return acc;
              }, []),
            },
          },
        },
      };

    case "DESKING_ADD_PRODUCT":
      return {
        ...state,
        recalculateStep: state.recalculateStep + 1,
        proposalProducts: {
          ...state.proposalProducts,
          productsConfigurationInMenu: {
            ...state.proposalProducts.productsConfigurationInMenu,
            ...Object.entries(state.menuBuilder).reduce((termsAcc: any, [term, builder]) => {
              termsAcc[term] = {
                ...builder.menu.menuOptions.reduce((optionsAcc: any, option: any) => {
                  const isProductAlreadyInMenu = option.products
                    .map(({ proposalProductId }: any) => proposalProductId)
                    .includes(action.payload.product.proposalProductId);

                  if (option.name === action.payload.menuType && !isProductAlreadyInMenu) {
                    optionsAcc[option.name] = {
                      ...state.proposalProducts.productsConfigurationInMenu?.[term]?.[option.name],
                      [action.payload.product.proposalProductId]:
                        state.proposalProducts.productsConfiguration[action.payload.product.proposalProductId],
                    };
                  } else {
                    optionsAcc[option.name] =
                      state.proposalProducts.productsConfigurationInMenu?.[term]?.[option.name] ?? {};
                  }

                  return optionsAcc;
                }, {}),
              };

              return termsAcc;
            }, {}),
          },
        },
        menuBuilder: Object.entries(state.menuBuilder).reduce<Record<string, any>>((acc, [term, builder]) => {
          acc[term] = {
            ...state.menuBuilder[state.term.term],
            menu: {
              ...builder,
              menuOptions: builder.menu.menuOptions.map((option: any) => {
                const isProductAlreadyInMenu = option.products
                  .map(({ proposalProductId }: any) => proposalProductId)
                  .includes(action.payload.product.proposalProductId);

                if (option.name === action.payload.menuType && !isProductAlreadyInMenu) {
                  return {
                    ...option,
                    products: [...option.products, action.payload.product],
                  };
                }

                return option;
              }),
            },
          };

          return acc;
        }, {}),
      };

    case "DESKING_REMOVE_PRODUCT":
      return removeProductFromMenu(state, action.payload.menuName, action.payload.productId);

    case "DESKING_LAYOUT_UPDATE_SECTION_IS_OPEN":
      return {
        ...state,
        layout: {
          ...state.layout,
          sections: {
            ...state.layout.sections,
            [action.payload.section]: {
              ...(state.layout.sections?.[action.payload.section] ?? {}),
              isOpen: action.payload.isOpen,
            },
          },
        },
      };

    case "DESKING_LAYOUT_SELECT_MENU_OPTION":
      return {
        ...state,
        layout: {
          ...state.layout,
          selectedMenuOptions:
            state.layout.selectedMenuOptions === action.payload.optionName ? null : action.payload.optionName,
        },
      };

    case "DESKING_CLEAR":
      return initialState;

    case "DESKING_RESET_STRUCTURE_AND_MENU_OPTIONS":
      return {
        ...initialState,
        proposalProducts: {
          ...initialState.proposalProducts,
          products: state.proposalProducts.products,
          productsFactsToCheck: state.proposalProducts.productsFactsToCheck,
          productsConfiguration: state.proposalProducts.productsConfiguration,
        },
        equipment: state.equipment,
        proposalMenus: state.proposalMenus,
        financePrograms: state.financePrograms,
      };

    case "DESKING_SET_CALCULATING":
      return {
        ...state,
        calculateMultiplePayments: action.payload.results,
      };

    case "DESKING_ADD_SELECTED_PRODUCTS_TO_MENU":
      return {
        ...state,
        recalculateStep: state.recalculateStep + 1,
        proposalProducts: {
          ...state.proposalProducts,
          productsConfigurationInMenu: {
            ...state.proposalProducts.productsConfigurationInMenu,
            ...Object.entries(state.menuBuilder).reduce((termsAcc: any, [term, builder]) => {
              termsAcc[term] = {
                ...builder.menu.menuOptions.reduce((optionsAcc: any, option: any) => {
                  optionsAcc[option.name] = {
                    ...(state.proposalProducts.productsConfigurationInMenu?.[term]?.[option.name] ?? {}),
                    ...Object.values(state.proposalProducts.selectedProducts).reduce(
                      (productsAcc: any, product: any) => {
                        const existingProductConfig =
                          state.proposalProducts.productsConfigurationInMenu?.[term]?.[option.name]?.[
                            product.proposalProductId
                          ];

                        if (!!existingProductConfig) {
                          productsAcc[product.proposalProductId] = existingProductConfig;
                        } else {
                          productsAcc[product.proposalProductId] =
                            state.proposalProducts.productsConfiguration[product.proposalProductId];
                        }

                        return productsAcc;
                      },
                      {}
                    ),
                  };
                  return optionsAcc;
                }, {}),
              };

              return termsAcc;
            }, {}),
          },
        },
        menuBuilder: Object.entries(state.menuBuilder).reduce<Record<string, any>>((acc, [term, builder]) => {
          acc[term] = {
            ...state.menuBuilder[state.term.term],
            menu: {
              ...builder,
              menuOptions: builder.menu.menuOptions.map((option: any) => ({
                ...option,
                products: [
                  ...option.products,
                  ...state.proposalProducts.products
                    .filter(({ proposalProductId }) =>
                      Object.keys(state.proposalProducts.selectedProducts).includes(proposalProductId)
                    )
                    .filter(
                      ({ proposalProductId }) =>
                        !option.products
                          .map(({ proposalProductId }: any) => proposalProductId)
                          .includes(proposalProductId)
                    ),
                ],
              })),
            },
          };

          return acc;
        }, {}),
      };

    case "DESKING_REMOVE_TERM_FROM_MENU_BUILDER":
      return {
        ...state,
        menuBuilder: immutableUtils.removeKeyFromObj(state.menuBuilder, action.payload.term),
        proposalProducts: {
          ...state.proposalProducts,
          productsConfigurationInMenu: immutableUtils.removeKeyFromObj(
            state.proposalProducts.productsConfigurationInMenu,
            action.payload.term
          ),
        },
      };

    case "DESKING_RUN_ENGINE":
      return {
        ...state,
        isNeedRunJsonEngine: state.isNeedRunJsonEngine + 1,
      };

    case "DESKING_RESET_COMMON_CONFIGURATION":
      return {
        ...state,
        proposalProducts: {
          ...state.proposalProducts,
          commonProductsConfiguration: {},
        },
      };

    default:
      return state;
  }
};

function setProducts(state: DeskingState, products: ProposalProduct[]): DeskingState {
  const productsFactsToCheck = products.reduce<Record<string, FactToCheck[]>>((acc, product) => {
    acc[product.proposalProductId] = collectFactsToCheckFromProduct(product);
    return acc;
  }, {});

  return {
    ...state,
    proposalProducts: {
      ...state.proposalProducts,
      productsFactsToCheck,
      products,
      productsConfiguration: collectConfigFromProducts({ state, products, productsFactsToCheck }),
    },
  };
}

function collectConfigFromProducts(params: {
  state: DeskingState;
  products: ProposalProduct[];
  productsFactsToCheck?: Nullable<Record<string, FactToCheck[]>>;
}): Record<string, ProductConfig> {
  const { state, products, productsFactsToCheck = null } = params;

  const commonConfigurationFromEquipment = !state.equipment.current
    ? {}
    : factKeyMapToEquipment.reduce<Partial<ProductConfig>>((commonConfig, { key, fact }) => {
        if (state.equipment.current?.[key]) commonConfig[fact] = state.equipment.current[key];
        return commonConfig;
      }, {});

  return products.reduce<Record<string, ProductConfig>>((acc, product) => {
    const dynamicConfigValues = Object.entries({
      ...commonConfigurationFromEquipment,
      ...state.proposalProducts.commonProductsConfiguration,
    }).reduce<Record<string, ProductConfig>>((config, [key, value]) => {
      const isCurrentProductSupportRule = !!(productsFactsToCheck ?? state.proposalProducts.productsFactsToCheck)[
        product.proposalProductId
      ].find(({ factKey }) => factKey === key);

      if (!isCurrentProductSupportRule) return config;

      config[key] = value;

      return config;
    }, {});

    acc[product.proposalProductId] = {
      cost: product?.cost ?? 0,
      contractTerm: state.term.term,
      coverageTerm: state.term.term,
      retailCost: product?.retailCost ?? 0,
      markup: product?.markup ?? { markup: 0, type: "FLAT" },
      ...(state.proposalProducts.productsConfiguration[product.proposalProductId] ?? {}),
      ...dynamicConfigValues,
      ...(product?.aftermarketProduct?.config?.criteriaValues?.[0] ?? {}),
    };

    return acc;
  }, {});
}

function unselectProduct(state: DeskingState, unselectProductId: string): DeskingState {
  const product = state.proposalProducts.products.find(
    ({ proposalProductId }) => proposalProductId === unselectProductId
  );

  const amountOfSelectedProducts = Object.keys(state.proposalProducts.selectedProducts).length;
  const amountOfProductsInMenu = Object.values(state.menuBuilder)
    .map((menuBuilder: any) => menuBuilder.menu.menuOptions.map((option: any) => option.products))
    .flat(2).length;

  const isAllProductsUnselect = amountOfSelectedProducts + amountOfProductsInMenu === 1;

  return {
    ...state,
    proposalProducts: {
      ...state.proposalProducts,
      commonProductsConfiguration: isAllProductsUnselect ? {} : state.proposalProducts.commonProductsConfiguration,
      selectedProducts: Object.entries(state.proposalProducts.selectedProducts).reduce<any>(
        (acc, [productId, product]) => {
          if (productId === unselectProductId) return acc;
          acc[productId] = product;
          return acc;
        },
        {}
      ),
      productsConfiguration: {
        ...state.proposalProducts.productsConfiguration,
        [unselectProductId]: {
          cost: product?.cost ?? 0,
          contractTerm: state.term.term,
          coverageTerm: state.term.term,
          retailCost: product?.retailCost ?? 0,
          markup: (product as any)?.markup ?? { markup: 0, type: "FLAT" },
        },
      },
    },
  };
}

function removeProductFromMenu(state: DeskingState, menuType: string, productId: string): DeskingState {
  const amountOfSelectedProducts = Object.keys(state.proposalProducts.selectedProducts).length;
  const amountOfProductsInMenu = Object.values(state.menuBuilder)
    .map((menuBuilder: any) => menuBuilder.menu.menuOptions.map((option: any) => option.products))
    .flat(2).length;

  const isAllProductsUnselect = amountOfSelectedProducts + amountOfProductsInMenu === 1;

  return {
    ...state,
    recalculateStep: state.recalculateStep + 1,
    proposalProducts: {
      ...state.proposalProducts,
      commonProductsConfiguration: isAllProductsUnselect ? {} : state.proposalProducts.commonProductsConfiguration,
      productsConfigurationInMenu: {
        ...state.proposalProducts.productsConfigurationInMenu,
        [state.term.term]: {
          ...state.proposalProducts.productsConfigurationInMenu?.[state.term.term],
          [menuType]: immutableUtils.removeKeyFromObj(
            state.proposalProducts.productsConfigurationInMenu[state.term.term][menuType],
            productId
          ),
        },
      },
    },
    menuBuilder: {
      ...state.menuBuilder,
      [state.term.term]: {
        ...state.menuBuilder[state.term.term],
        menu: {
          ...state.menuBuilder[state.term.term].menu,
          menuOptions: state.menuBuilder[state.term.term].menu.menuOptions.map((option: any) => {
            if (option.name === menuType) {
              return {
                ...option,
                products: option.products.filter((product: any) => product.proposalProductId !== productId),
              };
            }

            return option;
          }),
        },
      },
    },
  };
}
