import React from "react";
import { Box, Typography } from "@material-ui/core";

interface Props {
  value: any;
  label: string;
  formatter?: (value: any) => string;
}

export const PaymentOptionsInfoItem = ({ formatter, value, label }: Props) => {
  return (
    <Box className="infoItem">
      <Typography component="span" variant="body1" color="textSecondary">
        {label}
      </Typography>

      <Typography component="span" variant="body2">
        {formatter ? formatter(value) : value}
      </Typography>
    </Box>
  );
};
