import { SET_PARTNER_LINKS, SET_IS_NED_ACCOUNT, CLEAR } from "./actionTypes";
import { AftermarketPartnerState } from "./types";

const initialState: AftermarketPartnerState = {
  partnerLinks: [],
  IsNTPAccount: false,
};

export const aftermarketPartnerReducer = (state = initialState, { type, payload }: any) => {
  switch (type) {
    case SET_PARTNER_LINKS:
      return {
        ...state,
        partnerLinks: payload,
      };
    case SET_IS_NED_ACCOUNT:
      return {
        ...state,
        isNTPAccount: payload,
      };
    case CLEAR:
      return initialState;
    default:
      return state;
  }
};
