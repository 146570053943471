import React from "react";
import { FormCheckbox, FormInputSelect } from "components/form";
import { EquipmentDynamicFieldsProps } from "../../../types";

export const BooleanField = ({ fact, value, onChange, error, helperText }: EquipmentDynamicFieldsProps) => (
  <FormInputSelect
    value={value}
    error={error}
    variant="standard"
    helperText={error ? helperText : ""}
    label={fact.factKey === "DEAL_SIZE" ? "AMOUNT ($)" : fact.factKey.replace(/_/g, " ")}
    onChange={nextValue => onChange(nextValue)}
    options={[
      { label: "Yes", value: "true" },
      { label: "No", value: "false" },
    ]}
  />
);
