import { Fact } from "../types";

export const allCheckFactsForSimpleProduct: Fact[] = [
  "EQUIPMENT_CONDITION",
  "EQUIPMENT_TYPE",
  "EQUIPMENT_YEAR",
  "DEAL_SIZE",
  "COVERAGE_TERM",
  "ODOMETER_MILEAGE",
  "AFTERMARKET_PRODUCT_SOURCE",
  "EQUIPMENT_MAKE",
  "EQUIPMENT_HAS_ATS",
  "EQUIPMENT_CLASS",
] as const;

export const equipmentFactsForSimpleProduct: Fact[] = [
  "EQUIPMENT_TYPE",
  "EQUIPMENT_MAKE",
  "EQUIPMENT_HAS_ATS",
  "EQUIPMENT_CLASS",
  "AFTERMARKET_PRODUCT_SOURCE",
] as const;

// Igor: Please see `collectFactsToCheckFromProduct` function in `src/modules/desking/lib/collectFactsToCheckFromProduct.ts`
//       to see why this is necessary as a quick fix.
export const equipmentFactsForSimpleProductToNotShowOnMenuConstructor: Fact[] = [
  "EQUIPMENT_CONDITION",
  "EQUIPMENT_TYPE",
  "EQUIPMENT_YEAR",
  "ODOMETER_MILEAGE",
  "EQUIPMENT_MAKE",
  "EQUIPMENT_HAS_ATS",
  "EQUIPMENT_CLASS",
  "AFTERMARKET_PRODUCT_SOURCE",
] as const;
