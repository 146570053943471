import React from "react";
import { SimpleTextCell } from "components/Table/Cells/SimpleTextCell";
import { AftermarketPartnerDealer, CellArgs } from "modules/aftermarketPartner/types";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import { Column } from "react-table";
import { makeStyles, Tooltip } from "@material-ui/core";
import { useSelector } from "react-redux";

interface Options {
  tableData: AftermarketPartnerDealer[];
}

export function createTableColumns({ tableData }: Options): Column<AftermarketPartnerDealer>[] {
  const classes = useStyles();

  const isNTPAccount = useSelector((state: any) => state.aftermarketPartner.isNTPAccount);

  return [
    {
      sortable: true,
      Header: "Dealer Name",
      accessor: "accountName",
      Cell: ({ original }: CellArgs) => <SimpleTextCell value={original.accountName} />,
    },
    {
      show: isNTPAccount,
      sortable: true,
      Header: (
        <>
          Dealer ID
          <Tooltip title="Dealer’s Unique ID (API-Integrated Partners)" placement="top">
            <HelpOutlineIcon className={classes.tooltipIcon} />
          </Tooltip>
        </>
      ),
      accessor: "dealerNumber",
      Cell: ({ original }: CellArgs) => (
        <SimpleTextCell value={original?.integrationSettingsValues?.integrationSettingsValues?.dealerNumber} />
      ),
    },
    {
      sortable: true,
      Header: "Status",
      accessor: "status",
      Cell: ({ original }: CellArgs) => <SimpleTextCell value={original.status} />,
    },
    {
      sortable: true,
      Header: "Active Menus",
      accessor: "activeMenus",
      Cell: ({ original }: CellArgs) => <SimpleTextCell value={original.activeMenus} />,
    },
    {
      sortable: true,
      Header: "Recent Quotes",
      accessor: "recentQuotes",
      Cell: ({ original }: CellArgs) => <SimpleTextCell value={original.recentQuotes} />,
    },
    {
      sortable: true,
      Header: "Recent Contracts",
      accessor: "recentContracts",
      Cell: ({ original }: CellArgs) => <SimpleTextCell value={original.recentContracts} />,
    },
    {
      sortable: true,
      Header: "Products Sold",
      accessor: "productsSold",
      Cell: ({ original }: CellArgs) => <SimpleTextCell value={original.productsSold} />,
    },
    {
      sortable: true,
      Header: "Total Volume",
      accessor: "totalVolume",
      Cell: ({ original }: CellArgs) => <SimpleTextCell value={original.totalVolume} />,
    },
  ].map(header => ({
    ...header,
    resizable: false,
    headerClassName: "customHeader",
  }));
}

const useStyles = makeStyles({
  tooltipIcon: {
    fontSize: 19,
    verticalAlign: "bottom",
    marginLeft: 6,
  },
});
