import { portalConfigurationTypes } from "./pages/Prequal/constants";

export const SESSION_TIMEOUT_COOKIE_NAME = "userSession";

export const initialPortalConfigTypes = [
  portalConfigurationTypes.scorecard,
  portalConfigurationTypes.leads,
  portalConfigurationTypes.opportunities,
  portalConfigurationTypes.userManagement,
  portalConfigurationTypes.securityManagement,
  portalConfigurationTypes.healthCheck,
  portalConfigurationTypes.creditReports,
  portalConfigurationTypes.documents,
  portalConfigurationTypes.portalSetup,
  portalConfigurationTypes.prescreen,
  portalConfigurationTypes.voChecklistTemplate,
  portalConfigurationTypes.voManagement,
  portalConfigurationTypes.aftermarket,
  portalConfigurationTypes.submissionsHubManagement,
];

export const CADMPortalConfigTypes = [portalConfigurationTypes.CADMCommercial, portalConfigurationTypes.CADMIndividual];

export const lenderTypesSettings = [
  { code: 100, title: "Bank", letter: "A", color: "#458e46" },
  { code: 200, title: "Captive", letter: "A+", color: "#458e46" },
  { code: 300, title: "Non-Bank Lender", letter: "B", color: "#eeba45" },
  { code: 400, title: "Broker", letter: "C", color: "#8f1b14" },
];

export const DateFormats = {
  Full: "dddd, MMMM D, YYYY", // Full date (e.g., "Monday, November 12, 2024")
  Long: "MMMM D, YYYY", // Long date (e.g., "November 12, 2024")
  Medium: "MMM D, YYYY", // Medium date (e.g., "Nov 12, 2024")
  Short: "MM/DD/YYYY", // Short date (e.g., "11/12/2024")
  TimeWithFullDate: "dddd, MMMM D, YYYY [at] h:mm A", // Full date with time (e.g., "Monday, November 12, 2024 at 9:30 AM")
  TimeWithShortDate: "MM/DD/YYYY h:mm A", // Short date with time (e.g., "11/12/2024 9:30 AM")
  YearMonth: "YYYY-MM", // Year and month (e.g., "2024-11")
  MonthDay: "MMMM D", // Month and day (e.g., "November 12")
  MonthDayYear: "MM/DD/YYYY", // Month, day, and year in short format (e.g., "11/12/2024")
  ISO: "YYYY-MM-DDTHH:mm:ssZ", // ISO 8601 format (e.g., "2024-11-12T09:30:00Z")
  Time24Hour: "HH:mm", // 24-hour time (e.g., "13:45")
  Time12Hour: "hh:mm A", // 12-hour time with AM/PM (e.g., "01:45 PM")
  DateTimeShort24Hour: "MM/DD/YYYY HH:mm", // Short date with 24-hour time (e.g., "11/12/2024 13:45")
  DateTimeShort12Hour: "MM/DD/YYYY hh:mm A", // Short date with 12-hour time (e.g., "11/12/2024 01:45 PM")
  DateTimeMedium24Hour: "MMM D, YYYY HH:mm", // Medium date with 24-hour time (e.g., "Nov 12, 2024 13:45")
  DateTimeMedium12Hour: "MMM D, YYYY hh:mm A", // Medium date with 12-hour time (e.g., "Nov 12, 2024 01:45 PM")
  WeekdayDate: "dddd, MMM D", // Weekday and date (e.g., "Monday, Nov 12")
  DayMonthYear: "D MMMM YYYY", // Day, month, and year (e.g., "12 November 2024")
  MonthYear: "MMMM YYYY", // Month and year (e.g., "November 2024")
  DayShortMonthYear: "D MMM YYYY", // Day, short month, and year (e.g., "12 Nov 2024")
  FullWithSeconds: "dddd, MMMM D, YYYY [at] h:mm:ss A", // Full date with seconds (e.g., "Monday, November 12, 2024 at 9:30:45 AM")
  ShortWithSeconds: "MM/DD/YYYY hh:mm:ss A", // Short date with seconds (e.g., "11/12/2024 01:45:30 PM")
};
