import React, { useContext, useEffect, useState } from "react";
import { gql } from "apollo-boost";
import _ from "lodash";
import { useLazyQuery, useQuery } from "@apollo/react-hooks";
import { DocumentContext } from "../../contexts/DocumentContext";
import { checkIfIndividualCreditApp, convertToDate, getNested } from "../../utils";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Tile from "components/Tile/Tile";
import CustomerSummaryCard from "./CustomerSummaryCard";
import LenderSubmissionView from "./LenderSubmissionView";
import ProgressStepper from "./ProgressStepper";
import Documents from "./Documents";
import ContactCard from "./ContactCard";
import FinanceProgramCard from "../../components/FinanceProgramCard";
import NotesCard from "./NotesCard";
import { primaryColor } from "../../assets/jss/material-dashboard-pro-react";
import { InvoiceEquipmentDetailsCard } from "pages/VendorOpportunity/InvoiceEquipmentDetailsCard";
import moment from "moment";
import { connect, useDispatch, useSelector } from "react-redux";
import { setRefetch, setValue } from "../../redux/actions";
import LenderMatchingEngine from "./LenderMatchingEngine";
import DeliveryTimelineCard from "./DeliveryDetailCard";
import { VOChecklistCard } from "./VOChecklistCard";
import ScoreCard from "./ScoreCard";
import { Snackbar } from "@material-ui/core";
import { NavLink, useHistory } from "react-router-dom";

import { CHAT_V2_SET_CREDIT_APP, CHAT_V2_TASKS, SET_CHAT_RESET } from "../../redux/types";

import {
  QUERY_ACCOUNT_BY_VP_ID,
  QUERY_PORTAL_CONFIGURATION,
  Q_CREDIT_APP,
  Q_VENDOR_PROFILE,
  Q_VO,
} from "../../queries/common";

import SendDocsModal from "./SendDocsModal";
import Agreements from "./Agreements";
import SpecificTasks from "./SpecificTasks";
import VOProposalList from "./VOProposalsListView";
import { CreditLineCard } from "./CreditLineCard";
import { portalConfigurationTypes } from "../Prequal/constants";
import MuiAlert from "@material-ui/lab/Alert";
import {
  assignFieldsOCAConfigFromActiveTemplate,
  isItemHasRestrictionForRole,
} from "../../services/creditAppModalService";
import { getDefaultPortalConfigurationByType, rolesSettingsKeys } from "../../services/pcwService";
import HealthCheckCard from "./HealthCheckCard";
import { CommonDataContext } from "../../contexts/CommonDataContext";
import { theme } from "../../theme";
import { LenderWaterfallResults } from "./LenderWaterfallResults";
import { adminRoles } from "../Prequal/constants";

const config = require("../../config");

const Q_USER_DOCS = gql`
  query($docId: ID, $VOId: ID) {
    documents(docId: $docId, VOId: $VOId) {
      documentId
      vendorOpportunityId
      source
      docType
      docDescription
      createdBy
      createdDateTime
      docName
      documentCreditSubmission {
        creditSubmissionId
        lenderAccess
      }
    }
  }
`;
const Q_INVOICE_DOC = gql`
  query($VOId: ID) {
    invoiceDoc(VOId: $VOId) {
      documentId
      vendorOpportunityId
      source
      docType
      docDescription
      createdBy
      createdDateTime
      docName
      createdDateTime
    }
  }
`;

const DOC_SETS = gql`
  query($accountId: ID) {
    docSets(accountId: $accountId) {
      name
      docusignTemplateIds
      approvalTerms
      description
      status
      active
      createdBy
      docSetId
    }
  }
`;

const AGREEMENTS = gql`
  query($VOId: ID) {
    agreements(VOId: $VOId) {
      agreementId
      docusignEnvelopeId
      name
      agreementStatus
      signedOn
      owner
      accountId
      vendorOpportunityId
      createdDateTime
    }
  }
`;

const Q_EQUIPMENT = gql`
  query Equipments($id: ID, $VOId: ID) {
    equipments(id: $id, VOId: $VOId) {
      equipmentId
      vendorOpportunityId
      quantity
      newUsed
      year
      make
      model
      serialNumberVin
      additionalDescription
      stockId
      truckClass
      attachments
      wholesaleCost
      totalWholesaleCost
      retailPrice
      mileage
      assetTypeStr
      classType
      cabType
      term
      lenderProduct
      loanType
      hours
      type
      tradeInValue
      amountOwedOnTradeIn
      equipmentIdx
    }
  }
`;

const C_HISTORY = gql`
  query getConversationsForVendorOpportunity($vendorOpportunityId: ID!) {
    getConversationsForVendorOpportunity(vendorOpportunityId: $vendorOpportunityId) {
      sid
      vendorOpportunityId
      participants {
        sid
        identity
        userProfileId
        notifyByEmail
        notifyBySMS
        mugshot
        email
        mobilePhoneNumber
        notifyByWhatsapp
        isDealer
        isLender
        isApplicant
      }
      messages {
        participantSid
        userProfileId
        body
        author
        delivery {
          total
          read
          sent
          delivered
          failed
          undelivered
        }
      }
      task {
        regarding
      }
      topic
      createdAt
      taskId
    }
  }
`;

const START_FAST_TRACK_PROCESS = gql`
  query($vendorOpportunityDatabaseId: ID!, $fastTrackInputs: JSON) {
    startFastTrackProcess(vendorOpportunityDatabaseId: $vendorOpportunityDatabaseId, fastTrackInputs: $fastTrackInputs)
  }
`;

const QUERY_TASKS = gql`
  query VendorOpportunityTask($VOId: ID!, $limit: Int) {
    vendorOpportunityTask(VOId: $VOId, limit: $limit) {
      taskId
      vendorOpportunityId
      regarding
      status
      assignedToUserProfileId
      assignedToAccountId
      assignedByUserProfileId
      assignedByAccountId
      notes
      priority
      createdBy
      completedDateTime
      dueDateTime
      createdDateTime
      assignedByUser
      assignedByAccount
      assignedToUser
      assignedToAccount
      notifyByEmail
      notifyByText
      notifyAssignorOnReminder
      notificationContactIds
      lastReminderSent
      nextReminder
      assignedToApplicant
      assignedBySystem
      ownerPgId
      taskItems {
        taskItemId
        type
        docType
        taskAssignorNotes
        taskAssigneeNotes
        doc {
          documentId
          vendorOpportunityId
          source
          docType
          docDescription
          createdBy
          docName
        }
      }
      reference {
        referenceId
        tasksCompletionData
      }
    }
  }
`;

const QUERY_VENDOR_PROFILES_ASSOCIATED_TO_LENDER = gql`
  query vendorProfilesByDynamicsLenderAccountId($dynamicsAccountId: ID!) {
    vendorProfilesByDynamicsLenderAccountId(dynamicsAccountId: $dynamicsAccountId) {
      id
      dynamicsId
      name
    }
  }
`;

const queryContext = { authRequired: true };
const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1),
  },
  breadCrumbs: {
    fontWeight: 300,
  },
  defaultTextWeight: {
    fontWeight: 300,
  },
  a: {
    color: primaryColor[0],
    "&:hover": {
      color: primaryColor[1],
    },
  },
  gridContainer: {
    marginTop: "0",
    marginBottom: "15px",
  },
  tdTop: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "@media (max-width: 768px)": {
      flexWrap: "wrap",
    },
  },
  noMargins: {
    margin: 0,
  },
  noPaddingTop: {
    paddingTop: "0 !important",
  },
  vendorLogoWrapper: {
    display: "flex",
    justifyContent: "center",
    padding: "0px",
  },
  creditLineCard: {
    paddingTop: "40px",
  },
}));
let invoiceDetailAPIKey = [
  {
    label: "Equipment Cost (Subtotal)",
    apiKey: "equipmentCost",
    format: "currency",
  },
  {
    label: "Other Charges (Installation, shipping, etc)",
    apiKey: "freightLaborInstallation",
    format: "currency",
  },
  {
    label: "Trade-in / Down Payment",
    apiKey: "downPayment",
    format: "currency",
  },
  {
    label: "Sales Tax",
    apiKey: "salesTaxOnInvoice",
    format: "currency",
  },
  { label: "Invoice Total", apiKey: "invoiceTotal", format: "currency" },
];

const deliveryTimelineAPIKey = [
  { label: "Delivery Status", apiKey: "deliveryStatus", format: "upperCase" },
  { label: "Order Date", apiKey: "orderDate", format: "date" },
  {
    label: "Expected Delivery Date",
    apiKey: "expectedDeliveryDate",
    format: "date",
  },
  {
    label: "Equipment Acceptance Date",
    apiKey: "equipmentAcceptanceDate",
    format: "date",
  },
  { label: "Invoice Status", apiKey: "invoiceStatus" },
  { label: "Delivery Date", apiKey: "deliveryDate", format: "date" },
];
const contactsTimeLineAPIKey = [
  { label: "Sales Rep", apiKey: "salesRepresentative.fullName" },
  {
    label: "Sales Manager",
    apiKey: "salesManger.fullName",
  },
];

function Details({ match, reduxSetValue, account, vp, userProfile, refetch, setRefetch, chat }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const adminRolesAvailable = [adminRoles.super, adminRoles.singleAccountOnly];
  const userIsSuperAdmin = _.includes(adminRolesAvailable, userProfile.adminRole);

  const voId = match.params.id;
  // const [voStatusIcon, setStatusIcon] = useState(<PersonIcon />);
  // const [chipColor, setChipColor] = useState();
  const [lastDocument, setLastDocument] = useState();
  const [invoiceDoc, setInvoiceDoc] = useState(false);
  const [userDocs, setUserDocs] = useState(false);
  const [vendorProfile, setVendorProfile] = useState();
  const [show, setShow] = useState(false);
  const [vo, setVo] = useState(null);
  const [applicationStage, setApplicationStage] = useState("");
  const [invoiceStatus, setInvoiceStatus] = useState("");
  const [dateString, setDateString] = useState("");
  const [creditApp, setCreditApp] = useState({ creditApplication: {} });
  const [customerName, setCustomerName] = useState("");
  const [invoiceDetailTableBody, setInvoiceDetailTableBody] = useState([]);
  const [deliveryTimeLineTableBody, setDeliveryTimeLineTableBody] = useState([]);
  const [handleSubmitToLenderByProfileId, setHandleSubmitToLenderByProfileId] = useState(null);
  const [contactsTableBody, setContactsTableBody] = useState([]);
  const [openDocSetModal, setOpenDocSetModal] = useState(false);
  const [tasksLoading, setTasksLoading] = useState(true);

  const creditSubmissionsData = useSelector(state => state.creditSubmissions);
  const [openEquipmentInputModal, setOpenEquipmentInputModal] = useState(false);
  const [invoiceEquipmentTabPage, setInvoiceEquipmentTabPage] = useState(0);
  const [fastTrackProcessStarted, setFastTrackProcessStarted] = useState(false);
  const [fastTrackSnackBarClosed, setFastTrackSnackBarClosed] = useState(false);
  const [CADMPortalConfiguration, setCADMPortalConfiguration] = useState(null);
  const [portalConfigurationsByType, setPortalConfigurationsByType] = useState();
  const [creditSubmissionForScorecard, setCreditSubmissionForScorecard] = useState(null);

  const [isScorecardHiddenForUserRole, setIsScorecardHiddenForUserRole] = useState(true);
  const [isHealthCheckHiddenForUserRole, setIsHealthCheckHiddenForUserRole] = useState(true);
  const [isCreditReportsHiddenForUserRole, setIsCreditReportsHiddenForUserRole] = useState(true);
  const [isCADMEditingDisabledForUserRole, setIsCADMEditingDisabledForUserRole] = useState(true);
  const [isVOChecklistHidden, setIsVOChecklistHidden] = useState(true);

  const [vendorProfilesAssociatedToLender, setVendorProfilesAssociatedToLender] = useState();
  const [voLoaded, setVoLoaded] = useState(false);
  const [taskId, setTaskId] = useState(null);
  const { isDealerUser, isLenderUser } = useContext(CommonDataContext);

  const queryParams = new URLSearchParams(window.location.search);
  const startFastTrackProcessQueryParam = queryParams.get("startFastTrack");
  let startTaskId = queryParams.get("taskId");

  const [isFastTrackEnabled] = useState(
    vp.fastTrackSetting === "YES_SET_CAMUNDA_PROCESS_CODE" ||
      vp.fastTrackSetting === "YES_AUTOMATED_FOR_DEALER" ||
      vp.fastTrackSetting === "YES_BUT_DEALER_MUST_TRIGGER"
  );

  useEffect(() => {
    if (!voLoaded) {
      reduxSetValue("vo", {});
    } else {
      setVoLoaded(false);
    }
  }, [voLoaded]);

  const [
    startFastTrackProcess,
    { data: startFastTrackProcessResponse, loading: startFastTrackProcessLoading },
  ] = useLazyQuery(START_FAST_TRACK_PROCESS, {
    context: { authRequired: true },
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (startFastTrackProcessQueryParam === "true" && !fastTrackProcessStarted) {
      setFastTrackProcessStarted(true);
      if (queryParams.has("startFastTrack")) {
        queryParams.delete("startFastTrack");
        history.replace({
          search: queryParams.toString(),
        });
      }
      const amountRequested = queryParams.get("amount");
      const equipmentType = queryParams.get("type");
      const equipmentHours = queryParams.get("hours");

      const fastTrackProcessInputs = {
        variables: {
          vendorOpportunityDatabaseId: voId,
        },
        context: { authRequired: true },
      };

      if (amountRequested || equipmentType || equipmentHours) {
        fastTrackProcessInputs.fastTrackInputs = {};
        if (amountRequested) {
          fastTrackProcessInputs.fastTrackInputs.amountRequested = amountRequested;
        }
        if (equipmentType) {
          fastTrackProcessInputs.fastTrackInputs.equipmentType = equipmentType;
        }
        if (equipmentHours) {
          fastTrackProcessInputs.fastTrackInputs.equipmentHours = equipmentHours;
        }
      }

      startFastTrackProcess(fastTrackProcessInputs);

      queryParams.delete("amount");
      queryParams.delete("type");
      queryParams.delete("hours");
      console.log("STARTING FAST TRACK PROCESS");
    }
  }, [account]);

  const handleOpenDocSetModal = () => {
    setOpenDocSetModal(true);
  };
  const handleCloseDocSetModal = () => {
    setOpenDocSetModal(false);
  };
  const onTasksLoaded = loadedInfo => {
    setTasksLoading(loadedInfo.loading);
    dispatch({ type: CHAT_V2_TASKS, payload: loadedInfo.tasks });
  };

  const [getVendorProfile, { loading: vpLoading, error: vpError, data: vpData }] = useLazyQuery(Q_VENDOR_PROFILE, {
    context: { autRequired: true },
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
  });

  const [
    getVendorProfilesAssociatedToLender,
    { data: vendorProfilesAssociatedToLenderData, loading: loadingVendorProfilesAssociatedToLender },
  ] = useLazyQuery(QUERY_VENDOR_PROFILES_ASSOCIATED_TO_LENDER, {
    context: { autRequired: true },
    fetchPolicy: "cache-and-network",
  });

  const { data: creditAppData, refetch: creditAppRefetch } = useQuery(
    Q_CREDIT_APP,
    {
      variables: {
        VOId: voId,
      },
    },
    { fetchPolicy: "no-cache" }
  );

  const { loading: tasksQueryLoading, error: tasksError, data: tasksData, refetch: refetchTasks } = useQuery(
    QUERY_TASKS,
    {
      variables: {
        VOId: voId,
        csId: null,
        limit: 100,
      },
      context: { authRequired: true },
      fetchPolicy: "no-cache",
    }
  );

  const [
    getPortalConfigurations,
    { data: portalConfigurationData, loading: portalConfigurationDataLoading },
  ] = useLazyQuery(QUERY_PORTAL_CONFIGURATION, {
    context: { authRequired: true },
    fetchPolicy: "no-cache",
  });

  const [
    getCADMPortalConfigurations,
    { data: CADMPortalConfigurationData, loading: CADMPortalConfigurationDataLoading },
  ] = useLazyQuery(QUERY_PORTAL_CONFIGURATION, {
    context: { authRequired: true },
    fetchPolicy: "no-cache",
  });

  const [getOcaTemplateAccount, { data: ocaTemplateAccountData }] = useLazyQuery(QUERY_ACCOUNT_BY_VP_ID, {
    context: { authRequired: true },
  });

  useEffect(() => {
    if (chat.targetVoId !== voId) {
      dispatch({ type: SET_CHAT_RESET });
    }

    creditAppRefetch();
    if (window) window.dispatchEvent(new CustomEvent("voUpdated", { detail: { updated: false } }));
  }, []);

  useEffect(() => {
    if (isLenderUser && _.some(account)) {
      getVendorProfilesAssociatedToLender({
        variables: {
          dynamicsAccountId: account.dynamicsAccountId,
        },
      });
    }
  }, [isLenderUser, account]);

  useEffect(() => {
    if (_.isEmpty(vendorProfilesAssociatedToLenderData)) {
      return;
    }
    setVendorProfilesAssociatedToLender(vendorProfilesAssociatedToLenderData.vendorProfilesByDynamicsLenderAccountId);
  }, [vendorProfilesAssociatedToLenderData]);

  const { data: userDocsData, refetch: userDocsRefetch, loading: userDocsLoading, error: userDocsError } = useQuery(
    Q_USER_DOCS,
    {
      variables: {
        VOId: voId,
        docId: null,
      },
    }
  );
  const { data: invoiceDocData, refetch: invoiceDocRefetch } = useQuery(Q_INVOICE_DOC, {
    variables: {
      VOId: voId,
    },
  });

  const { data: docSets, refetch: docSetsRefetch, loading: docSetsLoading, error: docSetsError } = useQuery(DOC_SETS, {
    variables: {
      accountId: account.id,
    },
  });

  const { data: agreements, refetch: agreementsRefetch, loading: agreementsLoading, error: agreementsError } = useQuery(
    AGREEMENTS,
    {
      variables: {
        VOId: voId,
      },
    }
  );

  const {
    data: equipmentData,
    refetch: equipmentDataRefetch,
    loading: equipmentLoading,
    error: equipmentError,
  } = useQuery(Q_EQUIPMENT, {
    variables: {
      VOId: voId,
    },
  });

  useEffect(() => {
    if (!!account && !!account.vendorProfileId) {
      if (!vpData) {
        getVendorProfile({
          variables: {
            id: account.vendorProfileId,
          },
          context: { authRequired: true },
        });
      }
    }
    if (creditAppData) {
      setCreditApp(creditAppData.creditApplication);
      dispatch({
        type: CHAT_V2_SET_CREDIT_APP,
        payload: creditAppData.creditApplication,
      });
      reduxSetValue("creditApp", creditAppData.creditApplication);
      if (_.get(creditAppData, "creditApplication.ocaTemplate.vendorProfileId")) {
        getOcaTemplateAccount({
          variables: {
            vendorProfileId: creditAppData.creditApplication.ocaTemplate?.vendorProfileId,
          },
        });
      }
    }
    if (vpData) {
      reduxSetValue("vp", vpData.vendorProfile);
      reduxSetValue("lp", vpData.vendorProfile.lenderProfiles);
      setVendorProfile(vpData.vendorProfile);
    }

    if (startTaskId && document.getElementById("task-list")) {
      document.getElementById("task-list").scrollIntoView({ behavior: "smooth", block: "center" });
      console.log("START TASK ID", startTaskId);
      setTimeout(() => {
        setTaskId(startTaskId);
      }, 1000);
      queryParams.delete("taskId");
      history.replace({
        search: queryParams.toString(),
      });
    }
  }, [getVendorProfile, creditAppData, account, vpData, reduxSetValue]);

  useEffect(() => {
    if (userDocsData !== null && userDocsData !== undefined && userDocsData.documents !== null) {
      setUserDocs(userDocsData);
    }
  }, [userDocsData]);

  useEffect(() => {
    if (invoiceDocData !== null && invoiceDocData !== undefined) {
      if (invoiceDocData.invoiceDoc && invoiceDocData.invoiceDoc.length) {
        const date = invoiceDocData.invoiceDoc[0].createdDateTime;
        invoiceDocData.invoiceDoc[0].date = moment(date).format("ll hh:mma");
        setInvoiceDoc(invoiceDocData.invoiceDoc[0]);
      } else {
        setInvoiceDoc(false);
      }
    }
  }, [invoiceDocData]);

  const refetchDocs = () => {
    userDocsRefetch();
    invoiceDocRefetch();
  };

  const BoldText = ({ text }) => {
    return <b>{text}</b>;
  };

  const [getVo, { data: voData, loading: voLoading, refetch: voRefetch, error: voError }] = useLazyQuery(Q_VO, {
    context: queryContext,
    fetchPolicy: "no-cache",
    variables: { voId },
  });

  useEffect(() => {
    const parseTableData = ({ label, apiKey, format, checkIfExists }) => {
      const rowData = getNested(vo, apiKey);
      if (checkIfExists && (!rowData || parseInt(rowData) === "0")) {
        return undefined;
      }

      if (rowData) {
        if (format === "upperCase") {
          let formattedString;
          if (rowData.includes("_")) {
            formattedString = vo[apiKey]
              .toLowerCase()
              .split("_")
              .map(s => s.charAt(0).toUpperCase() + s.substring(1))
              .join(" ");
          } else {
            formattedString = rowData
              .toLowerCase()
              .split(" ")
              .map(s => s.charAt(0).toUpperCase() + s.substring(1))
              .join(" ");
          }
          if (rowData.includes("delivered_pending_da")) {
            formattedString = "Delivered (Pending Acceptance)";
          }
          if (rowData.includes("da_complete")) {
            formattedString = "Customer Accepted";
          }
          return [label, formattedString];
        } else if (format === "months") {
          const formattedString = (
            <div style={{ display: "flex", width: "100%", justifyContent: "flex-end" }}>{rowData} months</div>
          );
          return [label, formattedString];
        } else if (format === "currency") {
          const formattedString = (
            <div style={{ display: "flex", width: "100%", justifyContent: "flex-end" }}>
              {new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(rowData)}
            </div>
          );
          return [label, formattedString];
        } else if (format === "date") {
          let inputDate = new Date(rowData + "T00:00:00");
          const getDay = inputDate.getDate();
          const getMonth = inputDate.getMonth() + 1; //Months are zero based
          const getYear = inputDate.getFullYear();
          const outputDate = getMonth + "-" + getDay + "-" + getYear;
          return [label, outputDate];
        } else {
          return [label, rowData];
        }
      } else {
        return [label, null];
      }
    };

    if (!!voData && !!voData.vendorOpportunity) {
      setVo(voData.vendorOpportunity);
      reduxSetValue("vo", vo);
      setVoLoaded(true);
      setTimeout(() => {
        if (window) window.dispatchEvent(new CustomEvent("voUpdated", { detail: { updated: true } }));
      }, 1000);
    } else {
      getVo();
    }

    if (!!vo && Object.keys(vo).length) {
      const appStage =
        vo.applicationStage === "pre_qualified"
          ? "prequalified"
          : "dealer_cancelled"
          ? "dealer cancelled"
          : "customer_cancelled"
          ? "customer cancelled"
          : vo.applicationStage;
      setApplicationStage(appStage);
      setInvoiceStatus(vo.invoiceStatus);

      let invoiceDetailTableBodySource = invoiceDetailAPIKey.map(parseTableData).filter(i => i);
      invoiceDetailTableBodySource.forEach(item => (item[0] = <BoldText text={item[0]} />));
      const deliveryTimeLineTableBodySource = deliveryTimelineAPIKey.map(i => parseTableData(i, false));
      deliveryTimeLineTableBodySource.forEach(item => (item[0] = <BoldText text={item[0]} />));

      let contactsTableBodySource = [];

      contactsTableBodySource.push([
        <BoldText text="Sales Rep" />,
        vo.salesRepresentative ? (
          <span
            style={{ color: theme.palette.primary.main, cursor: "pointer" }}
            onClick={() => handleContactCardClick(vo.salesRepresentative.dynamicsContactId)}
          >
            {vo.salesRepresentative.fullName}
          </span>
        ) : (
          "N/A"
        ),
      ]);

      if (vo.salesManager) {
        contactsTableBodySource.push([
          <BoldText text="Sales Manager" />,
          <span
            style={{ color: theme.palette.primary.main, cursor: "pointer" }}
            onClick={() => handleContactCardClick(vo.salesManager ? vo.salesManager.dynamicsContactId : null)}
          >
            {vo.salesManager ? vo.salesManager.fullName : null}
          </span>,
        ]);
      }

      contactsTableBodySource.push([
        <BoldText text="Finance Manager" />,
        vo.financeManager ? (
          <span
            style={{ color: theme.palette.primary.main, cursor: "pointer" }}
            onClick={() => handleContactCardClick(vo.financeManager.dynamicsContactId)}
          >
            {vo.financeManager.fullName}
          </span>
        ) : (
          "N/A"
        ),
      ]);

      const handleContactCardClick = id => {
        history.push(`/profile/${id}`);
      };
      contactsTableBodySource.forEach(
        item => (item[0] = <BoldText onClick={() => handleContactCardClick()} text={item[0]} />)
      );
      setContactsTableBody([...contactsTableBodySource]);

      const createdOn = new Date(Date.parse(vo.createdOn));
      setDateString(convertToDate(createdOn));
      setCustomerName(_.get(vo, "potentialCustomer.name", ""));
      setInvoiceDetailTableBody(invoiceDetailTableBodySource);
      setDeliveryTimeLineTableBody(deliveryTimeLineTableBodySource);
      setContactsTableBody(contactsTableBodySource);

      setShow(true);
    }
  }, [getVo, voData, vo, reduxSetValue]);

  useEffect(() => {
    if (
      _.some(equipmentData?.equipments) &&
      ((invoiceDetailTableBody[0] && invoiceDetailTableBody[0][0]?.props.text === "Equipment Cost (Subtotal)") ||
        (invoiceDetailTableBody[1] && invoiceDetailTableBody[1][0]?.props.text === "Equipment Cost (Subtotal)"))
    ) {
      let equipmentsInvoiceTable = [...invoiceDetailTableBody];
      const index = equipmentsInvoiceTable.findIndex(i => i[0].props.text === "Equipment Cost (Subtotal)");
      equipmentsInvoiceTable = equipmentsInvoiceTable.filter(i => i[0].props.text !== "Equipment Cost (Subtotal)");

      equipmentData.equipments.forEach(equipment => {
        const title = `${equipment.year} ${equipment.make} ${equipment.model}${
          equipment.quantity > 1 ? " (Qty: " + equipment.quantity + ")" : ""
        }`;
        equipmentsInvoiceTable.splice(index, 0, [
          <BoldText text={title} />,
          <div style={{ display: "flex", width: "100%", justifyContent: "flex-end" }}>
            {new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(equipment.retailPrice)}
          </div>,
        ]);
      });
      setInvoiceDetailTableBody(equipmentsInvoiceTable);
    }
  }, [equipmentData, invoiceDetailTableBody]);

  useEffect(() => {
    const vendorProfileId = _.get(account, "vendorProfileId");
    if (!vendorProfileId) {
      return;
    }

    getPortalConfigurations({
      variables: {
        vendorProfileId,
        types: [
          portalConfigurationTypes.scorecard,
          portalConfigurationTypes.healthCheck,
          portalConfigurationTypes.creditReports,
          portalConfigurationTypes.documents,
          portalConfigurationTypes.voManagement,
          portalConfigurationTypes.voChecklistTemplate,
        ],
      },
    });
  }, [account]);

  useEffect(() => {
    // We need to take a vp from oca related account since credit app could be submitted from both the lender's and dealer's templates
    const ocaTemplateAccount = _.get(ocaTemplateAccountData, "accountByVPId");
    if (_.isEmpty(ocaTemplateAccount)) {
      return;
    }
    /*     const ocaTemplateAccountType = _.get(ocaTemplateAccountData, "ocaTemplateAccount.accountType");
    if (isLenderUser && (ocaTemplateAccountType === "Vendor")) {
    }
    if (isDealerUser && (ocaTemplateAccountType === "Lender")) {
    } */
    const vpId = ocaTemplateAccount.vendorProfileId || account.vendorProfileId;
    getCADMPortalConfigurations({
      variables: {
        vendorProfileId: vpId,
        types: [portalConfigurationTypes.CADMIndividual, portalConfigurationTypes.CADMCommercial],
      },
    });
  }, [ocaTemplateAccountData]);

  useEffect(() => {
    if (!CADMPortalConfigurationData || !creditAppData) {
      return;
    }
    const applicationType = _.get(creditAppData, "creditApplication.applicationType");
    const portalConfigurations = _.get(CADMPortalConfigurationData, "portalConfigurations") || [];
    const ocaTemplateJsonDefinition = _.get(creditAppData, "creditApplication.ocaTemplate.jsonDefinition");
    const isIndividualCreditApp = checkIfIndividualCreditApp(applicationType);
    const CADMConfigurationTypeToUse = isIndividualCreditApp
      ? portalConfigurationTypes.CADMIndividual
      : portalConfigurationTypes.CADMCommercial;
    const CADMConfigurationToUse = _.find(portalConfigurations, { type: CADMConfigurationTypeToUse });
    if (_.some(CADMConfigurationToUse)) {
      assignFieldsOCAConfigFromActiveTemplate(CADMConfigurationToUse.jsonDefinition, ocaTemplateJsonDefinition);
    }
    setCADMPortalConfiguration(CADMConfigurationToUse);
  }, [CADMPortalConfigurationData, creditAppData]);

  useEffect(() => {
    if (!portalConfigurationData || !creditAppData) {
      return;
    }
    const portalConfigurations = _.get(portalConfigurationData, "portalConfigurations") || [];
    const portalConfigurationsToSetByDefault = [
      portalConfigurationTypes.documents,
      portalConfigurationTypes.voChecklistTemplate,
    ];
    _.forEach(portalConfigurationsToSetByDefault, portalConfigurationType => {
      if (!_.find(portalConfigurations, { type: portalConfigurationType })) {
        portalConfigurations.push(
          getDefaultPortalConfigurationByType(portalConfigurationType, account.vendorProfileId)
        );
      }
    });
    setPortalConfigurationsByType(_.keyBy(portalConfigurations, "type"));
  }, [portalConfigurationData, creditAppData]);

  useEffect(() => {
    const scorecardPortalConfiguration = _.get(portalConfigurationsByType, portalConfigurationTypes.scorecard);
    if (_.some([creditSubmissionsData, scorecardPortalConfiguration], _.isEmpty)) {
      if (vo && vo.fastTrackResults && vo.fastTrackResults[0] && vo.fastTrackResults[0].inputs) {
        console.log("Got scorecard data from VO");
      } else {
        return;
      }
    }
    const creditSubmissions = _.values(creditSubmissionsData);
    const scorecardLenderProfileId = _.get(scorecardPortalConfiguration, "jsonDefinition.config.lenderProfileId");
    const scorecardUserRolesHidden = _.get(scorecardPortalConfiguration, "jsonDefinition.config.rolesSettings.hidden");
    const creditSubmissionForScorecard = _.find(
      creditSubmissions,
      cs => cs.lenderProfileId === scorecardLenderProfileId && _.some(cs.fastTrackResults)
    );
    setCreditSubmissionForScorecard(creditSubmissionForScorecard);
    setIsScorecardHiddenForUserRole(_.includes(scorecardUserRolesHidden, userProfile.vendorContactRole));
  }, [creditSubmissionsData, portalConfigurationsByType]);

  useEffect(() => {
    if (_.isEmpty(portalConfigurationsByType)) {
      return;
    }

    const isHealthCheckHiddenForUserRole = isItemHasRestrictionForRole(
      _.get(portalConfigurationsByType, `${portalConfigurationTypes.healthCheck}.jsonDefinition`),
      rolesSettingsKeys.hidden,
      userProfile.vendorContactRole
    );

    const isCreditReportsHiddenForUserRole = isItemHasRestrictionForRole(
      _.get(portalConfigurationsByType, `${portalConfigurationTypes.creditReports}.jsonDefinition`),
      rolesSettingsKeys.hidden,
      userProfile.vendorContactRole
    );

    const isCADMEditingDisabled = isItemHasRestrictionForRole(
      _.get(portalConfigurationsByType, `${portalConfigurationTypes.voManagement}.jsonDefinition`),
      rolesSettingsKeys.cadmReadOnly,
      userProfile.vendorContactRole
    );

    const isVOChecklistHidden =
      _.get(
        portalConfigurationsByType,
        `${portalConfigurationTypes.voChecklistTemplate}.jsonDefinition.config.hidden`
      ) === true;

    setIsHealthCheckHiddenForUserRole(isHealthCheckHiddenForUserRole);
    setIsCreditReportsHiddenForUserRole(isCreditReportsHiddenForUserRole);
    setIsCADMEditingDisabledForUserRole(isCADMEditingDisabled);
    setIsVOChecklistHidden(isVOChecklistHidden);
  }, [portalConfigurationsByType]);

  useEffect(() => {
    console.log(`Executing submit to ${handleSubmitToLenderByProfileId}`);
  }, [handleSubmitToLenderByProfileId]);

  if (refetch.vo) {
    setRefetch("vo", false);
    voRefetch();
  }

  if (refetch.creditApp) {
    setRefetch("creditApp", false);
    creditAppRefetch();
  }

  let showHealthCheck = false;

  if (vendorProfile && vendorProfile.ofacRedFlagComplianceAccess) {
    showHealthCheck = true;
  }

  const showCreditLineCard = Boolean(
    (creditApp && creditApp.creditLineAmountRequested > 0) ||
      (vendorProfile && vendorProfile.amountRequested === "equipPlusCreditLineOptions")
  );

  const isCashSale = vo?.entityType === "CASH_SALE";

  const callbackSubmissionFinished = dynamicsCreditSubmissionId => {
    console.log(`Finished submission with ID ${dynamicsCreditSubmissionId}`);
    //Send mutation to update Lender Waterfall
    setHandleSubmitToLenderByProfileId(null);
  };

  return (
    <>
      {show ? (
        <DocumentContext.Provider value={{ lastDoc: lastDocument, setLastDocument }}>
          <div>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <div className={classes.tdTop}>
                  <Breadcrumbs aria-label="breadcrumb" style={{ flex: 1 }}>
                    <NavLink to="/vo" className={classes.defaultTextWeight + " " + classes.a}>
                      Opportunity View
                    </NavLink>
                    <span className={classes.defaultTextWeight}>Detail</span>
                  </Breadcrumbs>
                  <div className={classes.defaultTextWeight}>Created On: {dateString}</div>
                </div>
              </Grid>
              {!!vo && vo.partnerLogo && (
                <Grid item className={classes.noPaddingTop}>
                  <div className={classes.vendorLogoWrapper}>
                    <img
                      style={{
                        maxHeight: "50px",
                        maxWidth: "100%",
                      }}
                      src={
                        vo.partnerLogo && vo.partnerLogo.includes("/")
                          ? `https://${config.S3_BUCKET_NAME}.s3-us-west-2.amazonaws.com/${vo.partnerLogo}`
                          : `https://credit-app-images.s3-us-west-2.amazonaws.com/${vo.partnerLogo}`
                      }
                      alt="Partner Logo"
                    />
                  </div>
                </Grid>
              )}
            </Grid>
            <Grid container spacing={4}>
              {!isCashSale && (
                <Grid item xs={12}>
                  <ProgressStepper />
                </Grid>
              )}
              <Grid item xs={12} sm={12} md={12} lg={4}>
                <CustomerSummaryCard
                  name={customerName}
                  vo={vo}
                  refetchVO={voRefetch}
                  equipmentData={equipmentData}
                  refetchEquipment={equipmentDataRefetch}
                  equipmentLoading={equipmentLoading}
                  invoiceEquipmentTabPage={invoiceEquipmentTabPage}
                  creditApp={creditApp}
                  vp={vendorProfile}
                  userProfile={userProfile}
                  account={account}
                  refetchCreditApp={creditAppRefetch}
                  userDocs={userDocs}
                  userDocsRefetch={userDocsRefetch}
                  portalConfiguration={CADMPortalConfiguration}
                  isCADMEditingDisabled={isCADMEditingDisabledForUserRole}
                  tasksData={tasksData}
                  refetchTasks={refetchTasks}
                  portalConfigurationDataLoading={portalConfigurationDataLoading || CADMPortalConfigurationDataLoading}
                  isCreditReportsHiddenForUserRole={isCreditReportsHiddenForUserRole}
                  refetchDocs={refetchDocs}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={8}>
                {!!account && !!vo && (
                  <InvoiceEquipmentDetailsCard
                    vo={vo}
                    creditApp={creditApp}
                    equipmentData={equipmentData}
                    refetchEquipment={equipmentDataRefetch}
                    invoiceDetailTableBody={invoiceDetailTableBody}
                    invoiceDoc={invoiceDoc}
                    refetchDocs={() => refetchDocs()}
                    applicationStage={applicationStage}
                    invoiceStatus={invoiceStatus}
                    userProfile={userProfile}
                    account={account}
                    salesManager={vo.salesManager}
                    salesRepresentative={vo.salesRepresentative}
                    refetchVO={voRefetch}
                    vendorProfile={vp}
                    refetchCreditApp={creditAppRefetch}
                    openEquipmentInputModal={openEquipmentInputModal}
                    setOpenEquipmentInputModal={setOpenEquipmentInputModal}
                    vendorProfilesAssociatedToLender={vendorProfilesAssociatedToLender}
                    loadingVendorProfilesAssociatedToLender={loadingVendorProfilesAssociatedToLender}
                  />
                )}
              </Grid>
              {showCreditLineCard && vo.entityType !== "CASH_SALE" && (
                <Grid item xs={12} sm={12} md={12} lg={4} className={classes.creditLineCard}>
                  <Tile title="Credit Line" useCollapsing initCollapsed={!(vo && vo.creditLineAmountRequested)}>
                    <CreditLineCard vo={vo} creditApp={creditApp} vendorProfile={vp} userProfile={userProfile} />
                  </Tile>
                </Grid>
              )}
              {showHealthCheck && creditApp?.creditApplicationOwner?.length > 0 && !isHealthCheckHiddenForUserRole && (
                <Grid item xs={12} sm={12} md={12} lg={showCreditLineCard ? 8 : 12}>
                  <Tile title="Health Check" useCollapsing>
                    <HealthCheckCard
                      owners={creditApp.creditApplicationOwner}
                      refetchCreditApp={creditAppRefetch}
                      vo={vo}
                      vp={vendorProfile}
                      creditApp={creditApp}
                      full={!showCreditLineCard}
                    />
                  </Tile>
                </Grid>
              )}
              {!isScorecardHiddenForUserRole && ( // && creditSubmissionForScorecard
                <Grid item xs={12} sm={12} md={12} lg={8}>
                  <ScoreCard
                    cs={creditSubmissionForScorecard}
                    vo={vo}
                    scorecardConfig={_.get(
                      portalConfigurationsByType,
                      `${portalConfigurationTypes.scorecard}.jsonDefinition.config`
                    )}
                    userProfile={userProfile}
                  />
                </Grid>
              )}
              <Grid item xs={12} sm={12} md={12} lg={12}>
                {!vp ? (
                  "no VendorProfile loaded"
                ) : vp.dcrPrescreenStatus === "804790001" ||
                  vp.dcrPrescreenStatus === "804790002" ||
                  vp.dcrPrescreenStatus === "804790003" ? (
                  <LenderMatchingEngine />
                ) : (
                  <div />
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} container>
                {!!account && !!vo && !!vo.potentialCustomer && (
                  <Grid item xs={12}>
                    <ContactCard
                      contactsTableBody={contactsTableBody}
                      userProfile={userProfile}
                      account={account}
                      salesManager={vo.salesManager}
                      salesRepresentative={vo.salesRepresentative}
                      vo={vo}
                      refetchVO={voRefetch}
                      customerName={vo.potentialCustomer.name}
                      creditApp={creditApp}
                      refetchCreditApp={creditAppRefetch}
                      multiLocationManagement={vp.multiLocationManagement}
                      setContactsTableBody={setContactsTableBody}
                    />
                  </Grid>
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                {!!account && !!vo && (
                  <DeliveryTimelineCard
                    vo={vo}
                    deliveryTimeLineTableBody={deliveryTimeLineTableBody}
                    userProfile={userProfile}
                    account={account}
                    refetchVO={voRefetch}
                    vendorProfile={vendorProfile}
                  />
                )}
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12}>
                {vo && !isVOChecklistHidden && (
                  <VOChecklistCard voId={vo?.vendorOpportunityId} voChecklist={vo?.checklist} refetchVO={voRefetch} />
                )}
              </Grid>

              <Grid item xs={12}>
                {!vp ? (
                  "no VendorProfile loaded"
                ) : !!vp && vp.showDocMgt === 2 ? (
                  <Documents
                    vo={vo}
                    account={account}
                    vendorProfile={vp}
                    userDocs={userDocs}
                    userDocsLoading={userDocsLoading}
                    userDocsError={userDocsError}
                    refetchDocs={() => refetchDocs()}
                    refetchCreditApp={creditAppRefetch}
                    showDcrCreditAppRow={vo.transactionStage !== "Lead"}
                    CADMPortalConfiguration={CADMPortalConfiguration}
                    tasksData={tasksData}
                    documentsPortalConfiguration={_.get(portalConfigurationsByType, portalConfigurationTypes.documents)}
                    portalConfigurationDataLoading={
                      portalConfigurationDataLoading || CADMPortalConfigurationDataLoading
                    }
                    isCADMEditingDisabled={isCADMEditingDisabledForUserRole}
                  />
                ) : (
                  <div />
                )}
              </Grid>
              <Snackbar
                open={fastTrackProcessStarted && !fastTrackSnackBarClosed}
                autoHideDuration={null}
                anchorOrigin={{ horizontal: "center", vertical: "center" }}
                onClose={() => setFastTrackSnackBarClosed(true)}
              >
                <MuiAlert elevation="6" color="success">
                  This deal has been fast tracked! Check your email periodically for updates!
                </MuiAlert>
              </Snackbar>
              {!!vp && !!userProfile && vo.entityType !== "CASH_SALE" ? (
                vp.showLenderSubmission === "all_roles" ||
                vp.showLenderSubmission === "all_roles_full_access" ||
                (vp.showLenderSubmission === "credit_manager_only" && userProfile.vendorContactRole === "credit_mgr") ||
                (vp.showLenderSubmission === "all_managers" &&
                  (userProfile.vendorContactRole === "credit_mgr" ||
                    userProfile.vendorContactRole === "sales_mgr" ||
                    userProfile.vendorContactRole === "admin" ||
                    userProfile.vendorContactRole === "executive")) ? (
                  <>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      {!_.isEmpty(creditApp) ? (
                        <LenderSubmissionView
                          setInvoiceEquipmentTabPage={setInvoiceEquipmentTabPage}
                          equipmentData={equipmentData}
                          setOpenEquipmentInputModal={setOpenEquipmentInputModal}
                          portalConfiguration={CADMPortalConfiguration}
                          handleSubmitToLenderByProfileId={handleSubmitToLenderByProfileId}
                          callbackSubmissionFinished={callbackSubmissionFinished}
                        />
                      ) : null}
                    </Grid>
                  </>
                ) : (
                  <></>
                )
              ) : (
                <></>
              )}

              {vp && !_.isEmpty(vp.lenderProfiles) && userIsSuperAdmin && (
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <LenderWaterfallResults
                    creditSubmissions={creditSubmissionsData}
                    vendorOpportunityId={voId}
                    lenderProfiles={vp.lenderProfiles}
                    accountId={account.id}
                    handleSubmitToLenderByProfileId={setHandleSubmitToLenderByProfileId}
                  />
                </Grid>
              )}

              {vp && vp.showNotes && (
                <Grid item xs={12}>
                  <NotesCard vo={vo} userProfile={userProfile} />
                </Grid>
              )}
              {!vp ? (
                <div id="task-list"></div>
              ) : vp.showTasks === "show" ? (
                <Grid item xs={12}>
                  <div id="task-list">
                    <SpecificTasks
                      vo={vo}
                      taskId={taskId}
                      conversations={null}
                      refetchConversations={() => {}}
                      conversationsLoading={false}
                      voId={voId}
                      account={account}
                      vendorProfile={vp}
                      userProfile={userProfile}
                      onTasksLoaded={onTasksLoaded}
                      handleConversationCreation={() => {}}
                      documents={userDocs?.documents}
                      documentsPortalConfiguration={_.get(
                        portalConfigurationsByType,
                        portalConfigurationTypes.documents
                      )}
                      refetchDocs={refetchDocs}
                    />
                  </div>
                </Grid>
              ) : null}
              {vp.docusignDocMgmt === "show" &&
              (vo.applicationStage === "approved" || vo.applicationStage === "pre-approved") ? (
                <Grid item xs={12}>
                  <Agreements
                    vo={vo}
                    account={account}
                    vendorProfile={vp}
                    agreements={agreements ? agreements.agreements : null}
                    open={openDocSetModal}
                    handleClose={handleCloseDocSetModal}
                    handleOpen={handleOpenDocSetModal}
                    agreementsRefetch={agreementsRefetch}
                  />
                </Grid>
              ) : null}
              {account &&
                creditAppData &&
                creditAppData.creditApplication &&
                (vp?.showProposal === "show" || vp?.showProposal === "show_base_gbb") &&
                userProfile.vendorContactRole === "credit_mgr" && (
                  <>
                    <Grid item xs={12}>
                      <FinanceProgramCard account={account} vp={vp} isVOView={true} vo={vo} />
                    </Grid>
                    <Grid item xs={12}>
                      <VOProposalList
                        vo={vo}
                        applicant={`${creditAppData.creditApplication.firstName} ${creditAppData.creditApplication.lastName}`}
                        cl={_.find(vp.lenderProfiles, function(o) {
                          return o.submissionMethod === "ClickLease";
                        })}
                      />
                    </Grid>
                  </>
                )}
              {!vp ? "no VendorProfile loaded" : !!vp && vp.showChat === "show" ? null : null}
            </Grid>
            {!!creditAppData && !!creditAppData.creditApplication ? (
              <SendDocsModal
                open={openDocSetModal}
                handleClose={handleCloseDocSetModal}
                docSets={docSets ? docSets.docSets : null}
                vo={vo}
                creditApp={creditAppData.creditApplication}
                userProfile={userProfile}
                account={account}
                agreementsRefetch={agreementsRefetch}
              />
            ) : null}
          </div>
          {!vp ? "no VendorProfile loaded" : null}
        </DocumentContext.Provider>
      ) : (
        <>
          {voLoading && (
            <>
              <CircularProgress />
            </>
          )}
          {voError && <>Loading Error...</>}
        </>
      )}
    </>
  );
}

const mapStateToProps = state => ({
  account: state.account,
  userProfile: state.userProfile,
  vp: state.vp,
  refetch: state.refetch,
  chat: state.chat,
  creditApp: state.creditApp,
  refetchCreditApp: state.refetchCreditApp,
});

const mapDispatchToProps = {
  reduxSetValue: setValue,
  setRefetch,
};

export default connect(mapStateToProps, mapDispatchToProps)(Details);
