const proposalsMenu = (state: any) => state.aftermarketMenuConstructor.proposalMenu;
const menuOptionToAddEdit = (state: any) => state.aftermarketMenuConstructor.menuOptionToAddEdit;
const selectedMenuOption = (state: any) => state.aftermarketMenuConstructor.selectedMenuOption;
const proposalsProducts = (state: any) => state.aftermarketMenuConstructor.proposalProducts.products;
const archivedMenuOptions = (state: any) => state.aftermarketMenuConstructor.archivedMenuOptions;
const initialProposalMenu = (state: any) => state.aftermarketMenuConstructor.initialProposalMenu;
const selectedProposalsProducts = (state: any) => state.aftermarketMenuConstructor.selectedProposalsProducts;
const selectedProposalsProductsIds = (state: any) =>
  Object.keys(state.aftermarketMenuConstructor.selectedProposalsProducts);
const partnerLinksById = (state: any) => state.aftermarketMenuConstructor.partnerLinksById;
const isLoading = (state: any) => state.aftermarketMenuConstructor.isLoading;
const proposalProductConfigurations = (state: any) =>
  state.aftermarketMenuConstructor.proposalProducts.productsConfiguration;
const proposalProductConfigurationsInMenu = (state: any) =>
  state.aftermarketMenuConstructor.proposalProducts.productsConfigurationInMenu;

const selectedProductsFactsForCheck = (state: any) => {
  console.log(state);
  return state.aftermarketMenuConstructor.proposalProducts.productsFactsToCheck;
};
const selectedProductsFactsForCheckArray = (state: any) =>
  Object.entries(selectedProductsFactsForCheck(state))
    .filter(([productId]) => {
      return selectedProposalsProductsIds(state).includes(productId);
    })
    .map(([_, facts]) => facts)
    .flat();

export const menuConstructorSelectors = {
  isLoading,
  proposalsMenu,
  proposalsProducts,
  menuOptionToAddEdit,
  selectedMenuOption,
  archivedMenuOptions,
  initialProposalMenu,
  selectedProposalsProducts,
  selectedProductsFactsForCheck,
  selectedProductsFactsForCheckArray,
  partnerLinksById,
  proposalProductConfigurations,
  proposalProductConfigurationsInMenu,
};
