import { SET_CREDIT_APP } from "./types";

const initialState = {};

export const creditAppReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CREDIT_APP:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const contacts = state =>
  state.creditApp.creditApplicationOwner?.map(owner => ({
    ...owner,
    phone: owner?.phone || owner?.mobilePhone || owner?.homePhone || owner?.workPhone,
    firstAndLastName: owner.firstName + " " + owner.lastName,
  }));

const contact = (state, ownerId) => state.creditApp.creditApplicationOwner?.find(owner => owner.ownerPgId === ownerId);

const contactSelectOptions = state =>
  state.creditApp.creditApplicationOwner?.map(owner => ({
    label: `${owner.firstName} ${owner.lastName}, ${owner.title}`,
    value: owner.ownerPgId,
  }));

const creditAppSelectors = {
  contact,
  contacts,
  contactSelectOptions,
};

export { creditAppSelectors };
