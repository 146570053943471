import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import LinearProgress from "@material-ui/core/LinearProgress";
import Typography from "@material-ui/core/Typography";
import { useLazyQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import GetAppIcon from "@material-ui/icons/GetApp";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { CANADA_REGION_CODES, US_REGION_CODES } from "@trnsact/trnsact-shared-types";
import Paper from "@material-ui/core/Paper";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import "react-datepicker/dist/react-datepicker.css";
import {
  addDays,
  addMonths,
  endOfMonth,
  endOfQuarter,
  endOfWeek,
  endOfYear,
  format,
  startOfMonth,
  startOfQuarter,
  startOfWeek,
  startOfYear,
} from "date-fns";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import calendar from "assets/img/icons/calendar.png";
import Table from "../../components/Table/Table";
import Tab from "@material-ui/core/Tab";
import TabContext from "@material-ui/lab/TabContext";
import TabPanel from "@material-ui/lab/TabPanel";
import TabList from "@material-ui/lab/TabList";

import Box from "@material-ui/core/Box";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import LocationsTreeViewDropdown from "../../components/LocationsTreeViewDropdown/LocationsTreeViewDropdown";
import ChipInput from "../../components/Generic/ChipInput/ChipInput";
import { buildTree, getDropdownTreeSelectDefaultOptions } from "../../services/locationsService";
import _ from "lodash";
import zipcodes from "zipcodes";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
  Treemap,
  XAxis,
  YAxis,
} from "recharts";

const formatNames = name => {
  return name
    .replace(/([A-Z])/g, " $1")
    .replace(/And/g, "and")
    .trim();
};

const getFullStateName = code => {
  const matchCA = Object.entries(CANADA_REGION_CODES).find(([_, value]) => value === code);
  const matchUS = Object.entries(US_REGION_CODES).find(([_, value]) => value === code);
  if (matchCA) {
    return matchCA ? formatNames(matchCA[0]) : undefined;
  }
  if (matchUS) {
    return matchUS ? formatNames(matchUS[0]) : undefined;
  }
};
const listToRenderStates = Object.keys(US_REGION_CODES).map(key => ({
  label: formatNames(key),
  code: US_REGION_CODES[key],
}));

const Q_LOCATIONS = gql`
  query locations {
    locations {
      locationId
      locationName
      category
      accountDynamicsId
      ocaContactId
      ocaContact {
        fullName
      }
      contactIds
      locationParentId
      notificationAppReceipt
      childrenLocation {
        locationId
        locationName
      }
      locationParentName
    }
  }
`;

const Q_FETCH_REPORTS_LOCATION = gql`
  query reportsVoByLocation($accountId: ID!, $from: Date!, $to: Date!, $locationId: ID) {
    reportsVoByLocation(accountId: $accountId, from: $from, to: $to, locationId: $locationId) {
      day
      totalByLocation
    }
  }
`;

const Q_FETCH_REPORTS = gql`
  query reportVOsByAccount($accountId: ID!) {
    reportVOsByAccountPerWeek(accountId: $accountId) {
      total
      week
    }
    reportVOsByAccountPerMonth(accountId: $accountId) {
      total
      month
    }
  }
`;

const Q_FETCH_REPORTS_WITH_DATE = gql`
  query reportVOsByAccountWithDateRange($accountId: ID!, $from: Date!, $to: Date!, $locationId: ID) {
    reportVOStagesByAccountInDateRange(accountId: $accountId, from: $from, to: $to, locationId: $locationId) {
      day
      totalByStage
    }
  }
`;

const Q_FETCH_REPORTS_SALES_REP_WITH_DATE = gql`
  query reportVOsByAccountWithDateRange($accountId: ID!, $from: Date!, $to: Date!, $stage: String) {
    reportVOsByAccountPerSalesRep(accountId: $accountId, from: $from, to: $to, stage: $stage) {
      total
      salesRepresentativeId
      salesRepresentative {
        fullName
      }
    }
  }
`;

const Q_FETCH_PARTNER_REPORT = gql`
  query reportVOsForPartner($dynamicsAccountId: ID!, $from: Date!, $to: Date!) {
    reportVOsForPartner(dynamicsAccountId: $dynamicsAccountId, from: $from, to: $to) {
      total
      accountName
    }
  }
`;

const Q_FETCH_PARTNER_REPORTS_WITH_DATE = gql`
  query reportVOsByPartnerWithDateRange($partnerAccountDynamicsId: ID!, $from: Date!, $to: Date!) {
    reportVOStagesByPartnerProfileInDateRange(
      partnerAccountDynamicsId: $partnerAccountDynamicsId
      from: $from
      to: $to
    ) {
      day
      totalByStage
    }
  }
`;

const Q_FETCH_REPORTS_PARTNER_REP = gql`
  query reportVOsByPartner($dynamicsAccountId: ID!, $from: Date!, $to: Date!) {
    reportVOStagesByPartnerLinkContact(dynamicsAccountId: $dynamicsAccountId, from: $from, to: $to) {
      total
      partnerRepId
      partnerRep {
        fullName
      }
    }
  }
`;

const Q_FETCH_REPORTS_PARTNER_DEALERS_REP = gql`
  query PartnerDealersContactReport($dynamicsAccountId: ID!) {
    partnerDealersContactReport(dynamicsAccountId: $dynamicsAccountId) {
      totalMissingPrimaryContact
      totalDealers
      partnerRep {
        fullName
      }
    }
  }
`;

const Q_REPORT_APPS_BY_ZIP_CODE = gql`
  query GetReportCreditApplicationByZipCode($input: ReportCreditApplicationByZipCodeInput!) {
    reportCreditApplicationByZipCode(input: $input) {
      count
      zipCode
    }
  }
`;

const Q_REPORT_APPS_BY_STATE = gql`
  query ReportCreditApplicationByState($input: ReportCreditApplicationByStateInput!) {
    reportCreditApplicationByState(input: $input) {
      count
      state
    }
  }
`;

const useStyles = makeStyles({
  datePicker: {
    fontSize: "14px",
    width: "120px",
    textAlign: "right",
    padding: "5px",
    border: "1px solid #CCC",
    borderRadius: "5px",
    backgroundImage: `url(${calendar})`,
    backgroundPosition: "left",
    backgroundRepeat: "no-repeat",
  },
  calendarContainer: {
    marginBottom: "20px",
    borderBottom: "1px solid #ccc1c1",
    padding: "10px",
  },
  salesRepContainer: {
    marginTop: "30px",
  },
  noResults: {
    fontSize: "1rem",
    marginTop: "15px",
  },
  separator: {
    borderTop: "1px solid #a2adb3",
  },
  categoryHeader: {
    fontVariant: "small-caps",
    fontSize: "15px",
    color: "black",
  },
  indentItem: {
    paddingLeft: "2rem",
    fontSize: "14px",
  },
  left: {
    textAlign: "left",
  },
  header1stElement: {
    textAlign: "center",
    textShadow: "1px 1px 4px #CCC",
    fontSize: "1rem !important",
  },
  headerCentered: {
    textAlign: "center",
    textShadow: "1px 1px 4px #CCC",
    fontWeight: "bold",
  },
  cellsCentered: {
    textAlign: "center",
  },
  cellsCenteredFixedWidth: {
    textAlign: "center",
    minWidth: "120px",
  },
});

const ReportsPage = () => {
  const classes = useStyles();
  const account = useSelector(state => state.account);

  const [yesterday, setYesterday] = useState(new Date().setDate(new Date().getDate() - 1));
  const [today, setToday] = useState(new Date());
  const [isLoading, setIsLoading] = useState(false);
  const [filterByStage, setFilterByStage] = useState("all");
  const [VOPerWeek, setVOPerWeek] = useState([]);
  const [VOPerMonth, setVOPerMonth] = useState([]);
  const [VOPerSalesRep, setVOPerSalesRep] = useState([]);
  const [VOStagesInDateRange, setVOStagesInDateRange] = useState([]);
  const [VOPerLocationInDateRange, setVOPerLocationInDateRange] = useState([]);
  const [zipCodesFiltered, setZipCodesFiltered] = useState([]);
  const [zipCodesFilteredLocated, setZipCodesFilteredLocated] = useState([]);
  const [statesFiltered, setStatesFiltered] = useState([]);
  const [dealerPartnerPerSalesRepDataReport, setDealerPartnerPerSalesRepDataReport] = useState([]);
  const [startDate, setStartDate] = useState(new Date().setMonth(new Date().getMonth() - 3));
  const [endDate, setEndDate] = useState(new Date());
  const [partnerVOs, setPartnerVOs] = useState([]);
  const [locationsTree, setLocationsTree] = useState();
  const [locationChartLabels, setLocationChartLabels] = useState([]);
  const [locId, setLocId] = useState(null);
  const [showChart, setShowChart] = useState(true);

  const [getLocations, { data: locationsData, loading: loadingLocations }] = useLazyQuery(Q_LOCATIONS, {
    context: { authRequired: true },
  });

  const [getReportDataWithoutRange, { data: reportDataWithoutRange, loading: loadingReports }] = useLazyQuery(
    Q_FETCH_REPORTS,
    {
      context: { authRequired: true },
      variables: {
        accountId: account.id,
      },
    }
  );

  const [getReportDataInDateRange, { data: reportDataInDateRange, loading: loadingDataInRangeChart }] = useLazyQuery(
    Q_FETCH_REPORTS_WITH_DATE,
    {
      context: { authRequired: true },
      variables: {
        accountId: account.id,
        from: format(startDate, "yyyy-MM-dd"),
        to: format(addDays(endDate, 1), "yyyy-MM-dd"),
        locationId: locId,
      },
    }
  );

  const [getReportAppsByZipCode, { data: reportAppsByZipCode, loading: loadingAppsByZipCode }] = useLazyQuery(
    Q_REPORT_APPS_BY_ZIP_CODE,
    {
      context: { authRequired: true },
      variables: {
        input: {
          zipCode: _.isEmpty(zipCodesFiltered) ? null : zipCodesFiltered,
          zipStart: null,
          zipEnd: null,
          dateFrom: format(startDate, "yyyy-MM-dd"),
          dateTo: format(addDays(endDate, 1), "yyyy-MM-dd"),
        },
      },
    }
  );

  const [getReportAppsByState, { data: reportAppsBystate, loading: loadingAppsByState }] = useLazyQuery(
    Q_REPORT_APPS_BY_STATE,
    {
      context: { authRequired: true },
      variables: {
        input: {
          state: _.isEmpty(statesFiltered) ? null : statesFiltered,
          dateFrom: format(startDate, "yyyy-MM-dd"),
          dateTo: format(addDays(endDate, 1), "yyyy-MM-dd"),
        },
      },
    }
  );

  const [
    getReportDataByLocationInDateRange,
    { data: reportDataByLocationInDateRange, loading: loadingDataByLocationInRangeChart },
  ] = useLazyQuery(Q_FETCH_REPORTS_LOCATION, {
    context: { authRequired: true },
    variables: {
      accountId: account.id,
      from: format(startDate, "yyyy-MM-dd"),
      to: format(addDays(endDate, 1), "yyyy-MM-dd"),
      locationId: locId,
    },
  });

  const [
    getReportDataInDateRangeSalesRep,
    { data: reportDataSalesChart, loading: loadingDataSalesRepChart },
  ] = useLazyQuery(Q_FETCH_REPORTS_SALES_REP_WITH_DATE, {
    context: { authRequired: true },
    variables: {
      accountId: account.id,
      from: format(startDate, "yyyy-MM-dd"),
      to: format(addDays(endDate, 1), "yyyy-MM-dd"),
      stage: filterByStage !== "all" ? filterByStage : undefined,
    },
  });

  const [getPartnerReport, { data: partnerReportData, loading: loadingPartnerReportData }] = useLazyQuery(
    Q_FETCH_PARTNER_REPORT,
    {
      context: { authRequired: true },
      variables: {
        dynamicsAccountId: account.dynamicsAccountId,
        from: format(startDate, "yyyy-MM-dd"),
        to: format(endDate, "yyyy-MM-dd"),
      },
    }
  );

  const [
    getDealerPartnerPerSalesRepData,
    { data: dealerPartnerPerSalesRepData, loading: loadingDealersPerSalesRepReportData },
  ] = useLazyQuery(Q_FETCH_REPORTS_PARTNER_DEALERS_REP, {
    context: { authRequired: true },
    variables: {
      dynamicsAccountId: account.dynamicsAccountId,
    },
  });

  const [
    getPartnerReportDataInDateRange,
    { data: partnerReportDataInDateRange, loading: loadingPartnerDataInRangeChart },
  ] = useLazyQuery(Q_FETCH_PARTNER_REPORTS_WITH_DATE, {
    context: { authRequired: true },
    variables: {
      partnerAccountDynamicsId: account.dynamicsAccountId,
      from: format(startDate, "yyyy-MM-dd"),
      to: format(endDate, "yyyy-MM-dd"),
    },
  });

  const [
    getPartnerContactReport,
    { data: partnerContactReportData, loading: loadingPartnerContactReportData },
  ] = useLazyQuery(Q_FETCH_REPORTS_PARTNER_REP, {
    context: { authRequired: true },
    variables: {
      dynamicsAccountId: account.dynamicsAccountId,
      from: format(startDate, "yyyy-MM-dd"),
      to: format(endDate, "yyyy-MM-dd"),
    },
  });

  useEffect(() => {
    if (startDate && endDate && account) {
      if (!locationsData && !loadingLocations) {
        getLocations();
      }
      if (account.accountType === "Vendor") {
        getReportDataInDateRange();
        getReportDataInDateRangeSalesRep();
        getReportDataByLocationInDateRange();
      }
      if (account.accountType === "Partner") {
        getPartnerReportDataInDateRange();
      }
    }
  }, [account, startDate, endDate, filterByStage]);

  useEffect(() => {
    if (reportDataSalesChart) {
      setVOPerSalesRep(_.get(reportDataSalesChart, "reportVOsByAccountPerSalesRep", []));
    }

    if (reportDataInDateRange) {
      setVOStagesInDateRange(
        reportDataInDateRange.reportVOStagesByAccountInDateRange
          ? reportDataInDateRange.reportVOStagesByAccountInDateRange.map(item => {
              return {
                day: item.day,
                ...item.totalByStage,
              };
            })
          : []
      );
    }

    if (reportDataByLocationInDateRange) {
      const resultToRender = reportDataByLocationInDateRange.reportsVoByLocation.map(item => {
        const itemParsed = item.totalByLocation.map(loc => {
          return {
            [loc.locationId]: loc.total,
          };
        });

        return _.merge(
          {
            day: item.day,
          },
          ...itemParsed
        );
      });

      setVOPerLocationInDateRange(reportDataByLocationInDateRange.reportsVoByLocation ? resultToRender : []);
    }
    if (partnerReportDataInDateRange) {
      setVOStagesInDateRange(
        partnerReportDataInDateRange.reportVOStagesByPartnerProfileInDateRange
          ? partnerReportDataInDateRange.reportVOStagesByPartnerProfileInDateRange.map(item => {
              return {
                day: item.day,
                ...item.totalByStage,
              };
            })
          : []
      );
    }
  }, [reportDataInDateRange, reportDataSalesChart, partnerReportDataInDateRange, reportDataByLocationInDateRange]);

  useEffect(() => {
    if (account) {
      getReportDataWithoutRange();
    }
  }, [getReportDataWithoutRange, account]);

  useEffect(() => {
    if (reportDataWithoutRange) {
      setVOPerWeek(_.get(reportDataWithoutRange, "reportVOsByAccountPerWeek", []));
      setVOPerMonth(_.get(reportDataWithoutRange, "reportVOsByAccountPerMonth", []));
    }
  }, [reportDataWithoutRange]);

  const handleFilterByStage = e => {
    setFilterByStage(e.target.value);
  };

  useEffect(() => {
    if (account && account.accountType === "Partner") {
      getPartnerReport();
      getDealerPartnerPerSalesRepData();
      getPartnerContactReport();
    }
  }, [account]);

  useEffect(() => {
    if (account && account.accountType === "Vendor") {
      getReportAppsByState();
      getReportAppsByZipCode();
      if (zipCodesFiltered.length > 0) {
        setZipCodesFilteredLocated(zipCodesFiltered.map(zipCode => zipcodes.lookup(zipCode) ?? null));
        getReportAppsByZipCode();
      } else {
        setZipCodesFilteredLocated([]);
      }
    }
  }, [account, statesFiltered, zipCodesFiltered]);

  useEffect(() => {
    if (partnerReportData) {
      setPartnerVOs(partnerReportData.reportVOsForPartner);
    }
    if (partnerContactReportData) {
      setVOPerSalesRep(_.get(partnerContactReportData, "reportVOStagesByPartnerLinkContact", []));
    }

    if (dealerPartnerPerSalesRepData) {
      //Add data for Table Report

      const reportRows = dealerPartnerPerSalesRepData.partnerDealersContactReport.map(ppRep => {
        return [ppRep.partnerRep.fullName, ppRep.totalDealers, ppRep.totalMissingPrimaryContact];
      });

      //Summarize for last row
      const totalDealersSummarized = _.sumBy(dealerPartnerPerSalesRepData.partnerDealersContactReport, function(ppRep) {
        return ppRep.totalDealers;
      });
      const missingPrimaryContactsSummarized = _.sumBy(
        dealerPartnerPerSalesRepData.partnerDealersContactReport,
        function(ppRep) {
          return ppRep.totalMissingPrimaryContact;
        }
      );

      setDealerPartnerPerSalesRepDataReport([
        ...reportRows,
        ["TOTAL: ", totalDealersSummarized, missingPrimaryContactsSummarized],
      ]);
    }
  }, [partnerReportData, partnerContactReportData, dealerPartnerPerSalesRepData]);

  const treeSelectOptions = getDropdownTreeSelectDefaultOptions({
    onChange: selectedNode => setLocId(selectedNode.value),
    texts: { placeholder: "Choose Location" },
  });
  const simpleHash = input => {
    let hash = 0;
    for (let i = 0; i < input.length; i++) {
      const char = input.charCodeAt(i);
      hash = (hash << 5) - hash + char;
      hash = hash & hash;
    }
    return hash;
  };

  const stringToColorHex = inputString => {
    const hash = simpleHash(inputString);

    const hex = Math.abs(hash).toString(16);

    return "#" + hex.padEnd(6, "0").substring(0, 6);
  };
  useEffect(() => {
    if (locationsData) {
      const options = _.map(locationsData.locations, location => {
        return {
          ...location,
          checked: location.locationId == locId,
        };
      });
      setLocationChartLabels(
        locationsData.locations.map(l => {
          return {
            ...l,
            hexColor: stringToColorHex(l.locationId),
          };
        })
      );
      options.push({ locationId: null, locationName: "Unassigned", checked: locId == null });

      setLocationsTree(buildTree(options));
    }
  }, [locationsData, locId]);

  const reportTableHead = ["Sales Rep", "Total Dealers", "Missing Primary Contact"];
  const [tabSelected, setTabSelect] = useState("1");

  const handleTabChange = (event, newValue) => {
    setTabSelect(newValue);
    setLocId(null);
  };

  if (account.accountType === "Partner") {
    return (
      <>
        <Grid container>
          <Grid item lg={12} sm={12} xs={12} md={12}>
            <h4>Dealers by Sales Rep</h4>
            <Grid container>
              {loadingDealersPerSalesRepReportData ? (
                <Grid item lg={12} xs={12} md={12} sm={12}>
                  <LinearProgress />
                </Grid>
              ) : (
                <Grid container>
                  <Grid item lg={12} xs={12} md={12} sm={12}>
                    <Table
                      tableHead={reportTableHead}
                      tableData={dealerPartnerPerSalesRepDataReport}
                      tableHeaderColor="primary"
                      colorsColls={["primary"]}
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item lg={12} sm={12} xs={12} md={12}>
            <hr className={classes.separator} />
          </Grid>
          <Grid item lg={12} sm={12} xs={12} md={12}>
            <h4>Opportunities by Date Range</h4>
          </Grid>
          <Grid container className={classes.calendarContainer}>
            <Grid item lg={3} sm={6} xs={6} md={3}>
              <span>From: </span>
              <DatePicker
                selected={startDate}
                className={classes.datePicker}
                onChange={date => setStartDate(date)}
                selectsStart
                dateFormat="yyyy-MM-dd"
                startDate={startDate}
                endDate={endDate}
                maxDate={yesterday}
              />
            </Grid>
            <Grid item lg={3} sm={6} xs={6} md={3}>
              <span>To: </span>
              <DatePicker
                className={classes.datePicker}
                selected={endDate}
                dateFormat="yyyy-MM-dd"
                onChange={date => setEndDate(date)}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                maxDate={today}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item lg={12} sm={12} xs={12} md={12}>
              <h4>Opportunities By Partner Dealers</h4>
            </Grid>
            <Grid container>
              {loadingPartnerReportData ? (
                <Grid item lg={12} xs={12} md={12} sm={12}>
                  <LinearProgress />
                </Grid>
              ) : (
                <Grid container>
                  <Grid item lg={12} xs={12} md={12} sm={12}>
                    <ResponsiveContainer height={300}>
                      <BarChart data={partnerVOs}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="accountName" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Bar label="Total" dataKey="total" stackId="a" fill="#9ccc65" />
                      </BarChart>
                    </ResponsiveContainer>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid container>
            {loadingPartnerDataInRangeChart ? (
              <Grid item lg={12} xs={12} md={12} sm={12}>
                <LinearProgress />
              </Grid>
            ) : (
              <Grid container>
                <Grid item lg={12} sm={12} xs={12} md={12}>
                  <h4>Opportunities By Stages</h4>
                </Grid>
                <Grid item lg={12} xs={12} md={12} sm={12}>
                  <ResponsiveContainer height={300}>
                    <BarChart data={VOStagesInDateRange}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis
                        dataKey="day"
                        tickFormatter={date => {
                          return format(new Date(date), "yyyy-MM-dd");
                        }}
                      />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      <Bar label="Approved" dataKey="approved" stackId="a" fill="#9ccc65" />
                      <Bar dataKey="prospect" stackId="a" fill="#78909c" />
                      <Bar dataKey="cancelled" stackId="a" fill="#d32f2f" />
                      <Bar dataKey="pre_qualified" stackId="a" fill="#007ac1" />
                      <Bar dataKey="funded" stackId="a" fill="#039be5" />
                      <Bar dataKey="dealer_cancelled" stackId="a" fill="#bf360c" />
                      <Bar dataKey="declined" stackId="a" fill="#d50000" />
                    </BarChart>
                  </ResponsiveContainer>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
        {loadingPartnerContactReportData ? (
          <Grid item lg={12} xs={12} md={12} sm={12}>
            <LinearProgress />
          </Grid>
        ) : VOPerSalesRep ? (
          <Grid item lg={12} xs={12} md={12} sm={12}>
            <Grid item lg={12} sm={12} xs={12} md={12}>
              <h4>Opportunities By Rep</h4>
            </Grid>
            <ResponsiveContainer height={500}>
              <BarChart data={VOPerSalesRep} layout="vertical" margin={{ top: 15, right: 0, left: 50, bottom: 5 }}>
                <CartesianGrid strokeDasharray="1 1" />
                <XAxis type="number" />
                <YAxis type="category" dataKey="partnerRep.fullName" />
                <Tooltip />

                <Bar dataKey="total" stackId="a" fill="#435463"></Bar>
              </BarChart>
            </ResponsiveContainer>
          </Grid>
        ) : (
          <p className={classes.noResults}>No sales representatives found</p>
        )}
      </>
    );
  }
  const handleSetCalendar = e => {
    e.preventDefault();
    const range = e.currentTarget.id;
    let startDate, endDate;

    switch (range) {
      case "today":
        startDate = new Date();
        endDate = new Date();
        break;
      case "yesterday":
        startDate = addDays(new Date(), -1);
        endDate = addDays(new Date(), -1);
        break;
      case "this_week":
        startDate = startOfWeek(new Date());
        endDate = endOfWeek(new Date());
        break;
      case "last_week":
        startDate = startOfWeek(addDays(new Date(), -7));
        endDate = endOfWeek(addDays(new Date(), -7));
        break;
      case "this_month":
        startDate = startOfMonth(new Date());
        endDate = endOfMonth(new Date());
        break;
      case "last_month":
        startDate = startOfMonth(addDays(new Date(), -1 * new Date().getDate()));
        endDate = endOfMonth(addDays(new Date(), -1 * new Date().getDate()));
        break;
      case "this_quarter":
        startDate = startOfQuarter(new Date());
        endDate = endOfQuarter(new Date());
        break;
      case "last_quarter":
        startDate = startOfQuarter(addMonths(new Date(), -3));
        endDate = endOfQuarter(addMonths(new Date(), -3));
        break;
      case "this_year":
        startDate = startOfYear(new Date());
        endDate = endOfYear(new Date());
        break;
      case "last_year":
        startDate = startOfYear(addDays(new Date(), -365));
        endDate = endOfYear(addDays(new Date(), -365));
        break;
    }

    setStartDate(startDate);
    setEndDate(endDate);
  };
  // =============================================================================
  //   DATA TRANSFORMATIONS FOR EACH TAB'S TABLE
  // =============================================================================

  const getVOStagesTableData = () => {
    if (!VOStagesInDateRange?.length) return [];
    return VOStagesInDateRange.map(item => {
      return [
        item.day,
        item.approved || 0,
        item.prospect || 0,
        item.cancelled || 0,
        item.pre_qualified || 0,
        item.funded || 0,
        item.dealer_cancelled || 0,
        item.declined || 0,
      ];
    });
  };
  const VOStagesTableHead = [
    "Date",
    "Approved",
    "Prospect",
    "Cancelled",
    "Pre-Qualified",
    "Funded",
    "Dealer Cancelled",
    "Declined",
  ];

  // 2) By Sales Representative => VOPerSalesRep
  //    Example row: [ "Sales Rep Name", total ]
  const getVOPerSalesRepTableData = () => {
    if (!VOPerSalesRep?.length) return [];
    return VOPerSalesRep.map(item => [_.get(item, "salesRepresentative.fullName") || "Unknown Rep", item.total || 0]);
  };
  const VOPerSalesRepTableHead = ["Sales Representative", "Total"];

  const getByLocationTableHead = () => {
    // "Day" + each locationName + "Unassigned"
    // (We know each location from locationChartLabels,
    //  but also remember we can have locationId = null for "Unassigned".)
    const dynamicLabels = locationChartLabels.map(loc => loc.locationName) || [];
    // Insert "Unassigned" at the end
    const fullHeader = ["Day", ...dynamicLabels, "Unassigned"];
    return fullHeader;
  };

  const getByLocationTableData = () => {
    if (!VOPerLocationInDateRange?.length) return [];
    // We'll build each row as:
    // [ day, <locId1 total>, <locId2 total>, ..., <null> ]

    // For "Unassigned", the key is "null"
    const locIds = locationChartLabels.map(l => l.locationId);

    return VOPerLocationInDateRange.map(item => {
      const rowDay = item.day;
      const rowData = locIds.map(id => item[id] || 0);
      const unassigned = item["null"] || 0;
      return [rowDay, ...rowData, unassigned];
    });
  };

  // 4) Grouped By => VOPerWeek and VOPerMonth
  const getWeeklyTableData = () => {
    if (!VOPerWeek?.length) return [];
    return VOPerWeek.map(item => [format(new Date(item.week), "MM-dd-yyyy"), item.total || 0]);
  };
  const VOPerWeekTableHead = ["Week", "Total"];

  const getMonthlyTableData = () => {
    if (!VOPerMonth?.length) return [];
    return VOPerMonth.map(item => [format(new Date(item.month), "LLLL yyyy"), item.total || 0]);
  };
  const VOPerMonthTableHead = ["Month", "Total"];
  const quickDateActions = key => {
    const optionList = [
      { lbl: "Today", id: "today" },
      { lbl: "Yesterday", id: "yesterday" },
      { lbl: "This Week", id: "this_week" },
      { lbl: "Last Week", id: "last_week" },
      { lbl: "This Month", id: "this_month" },
      { lbl: "Last Month", id: "last_month" },
      { lbl: "This Quarter", id: "this_quarter" },
      { lbl: "Last Quarter", id: "last_quarter" },
      { lbl: "This Year", id: "this_year" },
      { lbl: "Last Year", id: "last_year" },
    ];
    return (
      <Box
        key={key}
        sx={{
          height: "100%",
          display: "flex",
          "& > *": {
            m: 1,
          },
        }}
      >
        <ButtonGroup orientation="vertical" variant="contained" style={{ position: "absolute", minWidth: "25vh" }}>
          {optionList.map(opt => (
            <Button size="small" id={opt.id} key={opt.id} onClick={handleSetCalendar}>
              {opt.lbl}
            </Button>
          ))}
        </ButtonGroup>
      </Box>
    );
  };

  // ----------------------------------------------------------------------
  // NEW: Data for "By Zip Code" table
  // ----------------------------------------------------------------------
  const getZipCodeTableHead = () => {
    return ["Zip Code", "City", "State", "Applications"];
  };

  const getZipCodeTableData = () => {
    const data = _.get(reportAppsByZipCode, "reportCreditApplicationByZipCode", []);
    if (!data.length) return [];
    // For each zip code, look up city/state
    return data.map(item => {
      const found = zipcodes.lookup(item.zipCode);
      if (found) {
        return [item.zipCode, found.city, found.state, item.count];
      } else {
        // If not found, just show the zip code and keep city/state blank
        return [item.zipCode, "", "", item.count];
      }
    });
  };

  // ----------------------------------------------------------------------
  // NEW: Data for "By State" table
  // ----------------------------------------------------------------------
  const getStateTableHead = () => {
    return ["State Code", "State Name", "Applications"];
  };

  const getStateTableData = () => {
    const data = _.get(reportAppsBystate, "reportCreditApplicationByState", []);
    if (!data.length) return [];
    return data.map(item => {
      return [item.state, getFullStateName(item.state) || "", item.count];
    });
  };

  const handleCopyToClipboard = (heads, data) => {
    // 1) Build a 2D array that includes the header row + data rows
    const rows = [heads, ...data];

    // 2) Convert rows to a tab-delimited string
    //    We join each cell with '\t' and then each row with '\n'
    const textToCopy = rows.map(row => row.join("\t")).join("\n");

    // 3) Use the Clipboard API to write the text
    navigator.clipboard.writeText(textToCopy).then(
      () => {
        alert("Copied to Clipboard!");
      },
      err => {
        console.error("Failed to copy!", err);
        alert("Failed to copy!");
      }
    );
  };

  const handleExportCSV = (heads, data) => {
    // 1) Start with an array that will hold lines of CSV
    const csvRows = [];

    // 2) Add the CSV header row (wrap each cell in quotes to handle commas)
    csvRows.push(heads.map(headerCell => `"${headerCell.replace(/"/g, '""')}"`).join(","));

    // 3) Add all data rows
    data.forEach(row => {
      // row is an array of cells (e.g. ["2024-01-01", 10, 3, ...])
      // Convert each cell to a string and wrap in quotes
      const csvRow = row
        .map(cell => {
          // Make sure we convert numbers to strings
          const cellStr = cell?.toString() ?? "";
          // Escape any existing quotes by doubling them
          return `"${cellStr.replace(/"/g, '""')}"`;
        })
        .join(",");
      csvRows.push(csvRow);
    });

    // 4) Join all rows with newlines
    const csvString = csvRows.join("\n");

    // 5) Create a Blob from the CSV string
    const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);

    // 6) Create a temporary <a> element to trigger download
    const link = document.createElement("a");
    link.style.display = "none";
    link.href = url;

    // 7) Generate a filename (e.g. "Applications_Report_2024-12-27.csv")
    const datePart = new Date().toISOString().split("T")[0];
    link.download = `Applications_Report_${datePart}.csv`;

    // 8) Append link, click, and remove
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    // 9) Cleanup the Blob URL
    URL.revokeObjectURL(url);
  };

  return (
    <Grid container spacing={2}>
      <Grid item lg={12} sm={12} xs={12} md={12}>
        <Typography variant="h6">Filters:</Typography>
      </Grid>
      <Grid container className={classes.calendarContainer} spacing={2}>
        <Grid item lg={4} sm={6} xs={6} md={6}>
          <Grid container>
            <Grid item lg={6} sm={6} xs={6} md={6}>
              <span>From: </span>
              <DatePicker
                selected={startDate}
                className={classes.datePicker}
                onChange={date => setStartDate(date)}
                selectsStart
                dateFormat="MM-dd-yyyy"
                startDate={startDate}
                endDate={endDate}
                maxDate={yesterday}
              >
                {quickDateActions("startDateCal")}
              </DatePicker>
            </Grid>
            <Grid item lg={6} sm={6} xs={6} md={6}>
              <span>To: </span>
              <DatePicker
                className={classes.datePicker}
                selected={endDate}
                dateFormat="MM-dd-yyyy"
                onChange={date => setEndDate(date)}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                maxDate={today}
              >
                {quickDateActions("endDateCal")}
              </DatePicker>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          lg={4}
          xs={12}
          md={8}
          sm={12}
          style={{ marginTop: "-20px", display: ["1", "2"].includes(tabSelected) ? "flex" : "none" }}
        >
          <FormControl fullWidth>
            <InputLabel htmlFor="repSelect">Filter by Application Stage</InputLabel>
            <Select
              value={filterByStage}
              id="repSelect"
              onChange={handleFilterByStage}
              inputProps={{
                name: "filterByStage",
                id: "filterByStage",
              }}
            >
              <MenuItem value={"all"}>-- All --</MenuItem>
              <MenuItem value={"approved"}>Approved</MenuItem>
              <MenuItem value={"prospect"}>Prospect</MenuItem>
              <MenuItem value={"cancelled"}>Cancelled</MenuItem>
              <MenuItem value={"pre_qualified"}>Pre Qualified</MenuItem>
              <MenuItem value={"funded"}>Funded</MenuItem>
              <MenuItem value={"dealer_cancelled"}>Dealer Canceled</MenuItem>
              <MenuItem value={"declined"}>Declined</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid
          item
          lg={4}
          xs={12}
          md={8}
          sm={12}
          style={{ display: ["2", "4", "6", "5"].includes(tabSelected) ? "none" : "block" }}
        >
          <FormControl fullWidth>
            <LocationsTreeViewDropdown tree={locationsTree} treeSelectOptions={treeSelectOptions} />
          </FormControl>
        </Grid>
        <Grid item lg={6} sm={6} xs={6} md={6} style={{ display: ["6"].includes(tabSelected) ? "block" : "none" }}>
          <Autocomplete
            id="usa-state-autocomplete"
            key={"usa-state-autocomplete"}
            size="small"
            multiple={true}
            options={listToRenderStates}
            getOptionSelected={(option, value) => option.code === value.code}
            onChange={(event, states) => {
              return setStatesFiltered(states.map(state => state.code));
            }}
            getOptionLabel={option => `${option.label} (${option.code})`}
            defaultValue={statesFiltered}
            renderInput={params => <TextField {...params} label="Select a State" variant="outlined" />}
          />
        </Grid>
        <Grid item lg={6} sm={6} xs={6} md={6} style={{ display: ["5"].includes(tabSelected) ? "block" : "none" }}>
          <ChipInput
            id="zipCodesChips"
            label="Filter by Zip Codes"
            onChange={(id, newValue) => {
              const allNumeric = newValue.every(chip => /^\d+$/.test(chip));

              if (allNumeric) {
                setZipCodesFiltered(newValue);
              }
            }}
            value={zipCodesFiltered}
          />
        </Grid>
        <Grid item lg={6} sm={6} xs={6} md={6} justifyContent="flex-end">
          <FormControlLabel
            control={<Switch defaultChecked color={"primary"} />}
            label="Toggle View"
            onChange={() => {
              setShowChart(prev => !prev);
            }}
          />
        </Grid>
      </Grid>
      <Grid item lg={12} sm={12} xs={12} md={12}>
        <TabContext value={tabSelected}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleTabChange} aria-label="Reporting" variant="fullWidth">
              <Tab label="By Stages" value="1" />
              <Tab label="By Sales Representative" value="2" />
              <Tab label="By Location" value="3" />
              <Tab label="By Zip Code" value="5" />
              <Tab label="By State" value="6" />
              <Tab label="Grouped By" value="4" />
            </TabList>
          </Box>

          {/* 1) BY STAGES */}
          <TabPanel value="1">
            <Paper>
              {loadingDataInRangeChart ? (
                <LinearProgress />
              ) : showChart ? (
                <ResponsiveContainer height={500}>
                  <BarChart data={VOStagesInDateRange}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="day" tickFormatter={date => format(new Date(date), "MM-dd-yyyy")} />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="approved" stackId="a" fill="#9ccc65" />
                    <Bar dataKey="prospect" stackId="a" fill="#78909c" />
                    <Bar dataKey="cancelled" stackId="a" fill="#d32f2f" />
                    <Bar dataKey="pre_qualified" stackId="a" fill="#007ac1" />
                    <Bar dataKey="funded" stackId="a" fill="#039be5" />
                    <Bar dataKey="dealer_cancelled" stackId="a" fill="#bf360c" />
                    <Bar dataKey="declined" stackId="a" fill="#d50000" />
                  </BarChart>
                </ResponsiveContainer>
              ) : (
                <Grid container>
                  <Grid xs={12}>
                    <Grid container style={{ padding: "10px" }} justifyContent="space-evenly">
                      <Grid xs={2}>
                        <Button
                          size="small"
                          variant="contained"
                          startIcon={<FileCopyIcon />}
                          onClick={() => {
                            handleCopyToClipboard(VOStagesTableHead, getVOStagesTableData());
                          }}
                        >
                          Copy to Clipboard
                        </Button>
                      </Grid>
                      <Grid xs={2}>
                        <Button
                          size="small"
                          variant="contained"
                          startIcon={<GetAppIcon />}
                          onClick={() => {
                            handleExportCSV(VOStagesTableHead, getVOStagesTableData());
                          }}
                        >
                          Download CSV
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid xs={12}>
                    <Table
                      tableHead={VOStagesTableHead}
                      tableData={getVOStagesTableData()}
                      customClassesForCells={[0, 1, 2, 3, 4, 5, 6, 7]}
                      customHeadClassesForCells={[0, 1, 2, 3, 4, 5, 6, 7]}
                      coloredColls={[0, 1, 2, 3, 4, 5, 6, 7]}
                      customCellClasses={[
                        classes.cellsCentered,
                        classes.cellsCentered,
                        classes.cellsCentered,
                        classes.cellsCentered,
                        classes.cellsCentered,
                        classes.cellsCentered,
                        classes.cellsCentered,
                        classes.cellsCentered,
                      ]}
                      customHeadCellClasses={[
                        classes.header1stElement,
                        classes.headerCentered,
                        classes.headerCentered,
                        classes.headerCentered,
                        classes.headerCentered,
                        classes.headerCentered,
                        classes.headerCentered,
                        classes.headerCentered,
                      ]}
                      tableShopping={true}
                      hover
                      striped
                    />
                  </Grid>
                </Grid>
              )}
            </Paper>
          </TabPanel>
          {/* 2) BY SALES REPRESENTATIVE */}
          <TabPanel value="2">
            <Paper>
              {loadingDataSalesRepChart ? (
                <LinearProgress />
              ) : showChart ? (
                <ResponsiveContainer height={500}>
                  <BarChart data={VOPerSalesRep} layout="vertical" margin={{ top: 15, right: 0, left: 50, bottom: 5 }}>
                    <CartesianGrid strokeDasharray="1 1" />
                    <XAxis type="number" />
                    <YAxis type="category" dataKey="salesRepresentative.fullName" />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="total" fill="#435463" />
                  </BarChart>
                </ResponsiveContainer>
              ) : (
                <Grid container>
                  <Grid xs={12}>
                    <Grid container style={{ padding: "10px" }} justifyContent="space-evenly">
                      <Grid xs={2}>
                        <Button
                          size="small"
                          variant="contained"
                          startIcon={<FileCopyIcon />}
                          onClick={() => {
                            handleCopyToClipboard(VOPerSalesRepTableHead, getVOPerSalesRepTableData());
                          }}
                        >
                          Copy to Clipboard
                        </Button>
                      </Grid>
                      <Grid xs={2}>
                        <Button
                          size="small"
                          variant="contained"
                          startIcon={<GetAppIcon />}
                          onClick={() => {
                            handleExportCSV(VOPerSalesRepTableHead, getVOPerSalesRepTableData());
                          }}
                        >
                          Download CSV
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid xs={12}>
                    <Table
                      tableHead={VOPerSalesRepTableHead}
                      tableData={getVOPerSalesRepTableData()}
                      customClassesForCells={[0, 1]}
                      customHeadClassesForCells={[0, 1]}
                      coloredColls={[0, 1]}
                      customCellClasses={[classes.cellsCentered, classes.cellsCentered]}
                      customHeadCellClasses={[classes.headerCentered, classes.headerCentered]}
                      tableShopping={true}
                      hover
                      striped
                    />
                  </Grid>
                </Grid>
              )}
            </Paper>
          </TabPanel>

          {/* 3) BY LOCATION */}
          <TabPanel value="3">
            <Paper>
              {loadingDataByLocationInRangeChart || loadingLocations ? (
                <LinearProgress />
              ) : showChart ? (
                <ResponsiveContainer height={500}>
                  <BarChart data={VOPerLocationInDateRange}>
                    <CartesianGrid strokeDasharray="1 1" />
                    <XAxis dataKey="day" tickFormatter={date => format(new Date(date), "MM-dd-yyyy")} />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    {/* A bar for unassigned */}
                    <Bar dataKey="null" name="Unassigned" stackId="a" fill="#435463" />
                    {locationChartLabels.map(location => (
                      <Bar
                        key={location.locationId}
                        dataKey={location.locationId}
                        name={location.locationName}
                        stackId="a"
                        fill={location.hexColor}
                      />
                    ))}
                  </BarChart>
                </ResponsiveContainer>
              ) : (
                <Grid container>
                  <Grid xs={12}>
                    <Grid container style={{ padding: "10px" }} justifyContent="space-evenly">
                      <Grid xs={2}>
                        <Button
                          size="small"
                          variant="contained"
                          startIcon={<FileCopyIcon />}
                          onClick={() => {
                            handleCopyToClipboard(getByLocationTableHead(), getByLocationTableData());
                          }}
                        >
                          Copy to Clipboard
                        </Button>
                      </Grid>
                      <Grid xs={2}>
                        <Button
                          size="small"
                          variant="contained"
                          startIcon={<GetAppIcon />}
                          onClick={() => {
                            handleExportCSV(getByLocationTableHead(), getByLocationTableData());
                          }}
                        >
                          Download CSV
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid xs={12}>
                    <Table
                      tableHead={getByLocationTableHead()}
                      tableData={getByLocationTableData()}
                      customClassesForCells={[0]}
                      customHeadClassesForCells={[0]}
                      coloredColls={[0]}
                      customHeadCellClasses={[classes.header1stElement]}
                      customCellClasses={[classes.cellsCenteredFixedWidth]}
                      tableShopping={true}
                      hover
                      striped
                    />
                  </Grid>
                </Grid>
              )}
            </Paper>
          </TabPanel>
          {/* 5) BY ZIP CODE */}
          <TabPanel value="5">
            <Paper>
              {loadingAppsByZipCode ? (
                <LinearProgress />
              ) : showChart ? (
                <ResponsiveContainer height={500}>
                  <Treemap
                    data={_.get(reportAppsByZipCode, "reportCreditApplicationByZipCode", []).map(values => {
                      return {
                        name: values.zipCode,
                        count: values.count,
                      };
                    })}
                    nameKey={"name"}
                    dataKey="count"
                    aspectRatio={4 / 3}
                  >
                    <Tooltip
                      formatter={(value, name) => {
                        const found = zipcodes.lookup(name);
                        if (found) {
                          return [value, `${found.city}, ${found.state}`];
                        }
                        return [value, `Zip Code`];
                      }}
                    />
                  </Treemap>
                </ResponsiveContainer>
              ) : (
                <Grid container>
                  <Grid xs={12}>
                    <Grid container style={{ padding: "10px" }} justifyContent="space-evenly">
                      <Grid xs={2}>
                        <Button
                          size="small"
                          variant="contained"
                          startIcon={<FileCopyIcon />}
                          onClick={() => {
                            handleCopyToClipboard(getZipCodeTableHead(), getZipCodeTableData());
                          }}
                        >
                          Copy to Clipboard
                        </Button>
                      </Grid>
                      <Grid xs={2}>
                        <Button
                          size="small"
                          variant="contained"
                          startIcon={<GetAppIcon />}
                          onClick={() => {
                            handleExportCSV(getZipCodeTableHead(), getZipCodeTableData());
                          }}
                        >
                          Download CSV
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid xs={12}>
                    <Table
                      tableHead={getZipCodeTableHead()}
                      tableData={getZipCodeTableData()}
                      customClassesForCells={[0, 1, 2, 3]}
                      customHeadClassesForCells={[0, 1, 2, 3]}
                      coloredColls={[0, 1, 2, 3]}
                      customCellClasses={[
                        classes.cellsCentered,
                        classes.cellsCentered,
                        classes.cellsCentered,
                        classes.cellsCentered,
                      ]}
                      customHeadCellClasses={[
                        classes.headerCentered,
                        classes.headerCentered,
                        classes.headerCentered,
                        classes.headerCentered,
                      ]}
                      tableShopping={true}
                      hover
                      striped
                    />
                  </Grid>
                </Grid>
              )}
            </Paper>
          </TabPanel>

          {/* 6) BY STATE */}
          <TabPanel value="6">
            <Paper>
              {loadingAppsByState ? (
                <LinearProgress />
              ) : showChart ? (
                <ResponsiveContainer height={500}>
                  <Treemap
                    data={_.get(reportAppsBystate, "reportCreditApplicationByState", []).map(value => ({
                      name: value.state,
                      count: value.count,
                    }))}
                    dataKey="count"
                    nameKey={"name"}
                    aspectRatio={4 / 3}
                  >
                    <Tooltip
                      formatter={(value, name) => {
                        return [value, getFullStateName(name)];
                      }}
                    />
                  </Treemap>
                </ResponsiveContainer>
              ) : (
                <Grid container>
                  <Grid xs={12}>
                    <Grid container style={{ padding: "10px" }} justifyContent="space-evenly">
                      <Grid xs={2}>
                        <Button
                          size="small"
                          variant="contained"
                          startIcon={<FileCopyIcon />}
                          onClick={() => {
                            handleCopyToClipboard(getStateTableHead(), getStateTableData());
                          }}
                        >
                          Copy to Clipboard
                        </Button>
                      </Grid>
                      <Grid xs={2}>
                        <Button
                          size="small"
                          variant="contained"
                          startIcon={<GetAppIcon />}
                          onClick={() => {
                            handleExportCSV(getStateTableHead(), getStateTableData());
                          }}
                        >
                          Download CSV
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid xs={12}>
                    <Table
                      tableHead={getStateTableHead()}
                      tableData={getStateTableData()}
                      customClassesForCells={[0, 1, 2]}
                      customHeadClassesForCells={[0, 1, 2]}
                      coloredColls={[0, 1, 2]}
                      customCellClasses={[classes.cellsCentered, classes.cellsCentered, classes.cellsCentered]}
                      customHeadCellClasses={[classes.headerCentered, classes.headerCentered, classes.headerCentered]}
                      tableShopping={true}
                      hover
                      striped
                    />
                  </Grid>
                </Grid>
              )}
            </Paper>
          </TabPanel>

          {/* 6) GROUPED BY */}
          <TabPanel value="4">
            <Paper>
              <Accordion defaultExpanded>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
                  <Typography variant="h5">Week</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {showChart ? (
                    <ResponsiveContainer height={500}>
                      <BarChart data={VOPerWeek}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="week" tickFormatter={date => format(new Date(date), "MM-dd-yyyy")} />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="total" fill="#007ac1" />
                      </BarChart>
                    </ResponsiveContainer>
                  ) : (
                    <Grid container>
                      <Grid xs={12}>
                        <Grid container style={{ padding: "10px" }} justifyContent="space-evenly">
                          <Grid xs={2}>
                            <Button
                              size="small"
                              variant="contained"
                              startIcon={<FileCopyIcon />}
                              onClick={() => {
                                handleCopyToClipboard(VOPerWeekTableHead, getWeeklyTableData());
                              }}
                            >
                              Copy to Clipboard
                            </Button>
                          </Grid>
                          <Grid xs={2}>
                            <Button
                              size="small"
                              variant="contained"
                              startIcon={<GetAppIcon />}
                              onClick={() => {
                                handleExportCSV(VOPerWeekTableHead, getWeeklyTableData());
                              }}
                            >
                              Download CSV
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid xs={12}>
                        <Table
                          tableHead={VOPerWeekTableHead}
                          tableData={getWeeklyTableData()}
                          customClassesForCells={[0, 1]}
                          customHeadClassesForCells={[0, 1]}
                          coloredColls={[0, 1]}
                          customCellClasses={[classes.cellsCentered, classes.cellsCentered]}
                          customHeadCellClasses={[classes.headerCentered, classes.headerCentered]}
                          tableShopping={true}
                          hover
                          striped
                        />
                      </Grid>
                    </Grid>
                  )}
                </AccordionDetails>
              </Accordion>

              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2-content" id="panel2-header">
                  <Typography variant="h5">Month</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {showChart ? (
                    <ResponsiveContainer height={500}>
                      <BarChart data={VOPerMonth}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="month" tickFormatter={date => format(new Date(date), "LLLL yyyy")} />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="total" fill="#67daff" />
                      </BarChart>
                    </ResponsiveContainer>
                  ) : (
                    <Grid container>
                      <Grid xs={12}>
                        <Grid container style={{ padding: "10px" }} justifyContent="space-evenly">
                          <Grid xs={2}>
                            <Button
                              size="small"
                              variant="contained"
                              startIcon={<FileCopyIcon />}
                              onClick={() => {
                                handleCopyToClipboard(VOPerMonthTableHead, getMonthlyTableData());
                              }}
                            >
                              Copy to Clipboard
                            </Button>
                          </Grid>
                          <Grid xs={2}>
                            <Button
                              size="small"
                              variant="contained"
                              startIcon={<GetAppIcon />}
                              onClick={() => {
                                handleExportCSV(VOPerMonthTableHead, getMonthlyTableData());
                              }}
                            >
                              Download CSV
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid xs={12}>
                        <Table
                          tableHead={VOPerMonthTableHead}
                          tableData={getMonthlyTableData()}
                          customClassesForCells={[0, 1]}
                          customHeadClassesForCells={[0, 1]}
                          coloredColls={[0, 1]}
                          customCellClasses={[classes.cellsCentered, classes.cellsCentered]}
                          customHeadCellClasses={[classes.headerCentered, classes.headerCentered]}
                          tableShopping={true}
                          hover
                          striped
                        />
                      </Grid>
                    </Grid>
                  )}
                </AccordionDetails>
              </Accordion>
            </Paper>
          </TabPanel>
        </TabContext>
      </Grid>
    </Grid>
  );
};

export default ReportsPage;
