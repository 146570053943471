import React, { FC } from "react";
import { Info } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { Box, Tooltip, Typography } from "@material-ui/core";
import { deskingActions, deskingSelectors } from "../../../../../model";
import { equipmentFactsForSimpleProduct } from "../../../../../constants/proposalProduct";
import { BooleanField, NumericField, StringField } from "../../../../components/equipmentDynamicFielda";
import { EquipmentDynamicFieldsProps, FactToCheckTypes, ProductsDynamicFieldsRender } from "../../../../../types";

export const ProductsDynamicFields = () => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const productsFactsForCheckArray = useSelector(deskingSelectors.productsFactsForCheckArray);
  const commonConfig = useSelector(deskingSelectors.commonProductsConfiguration);

  const handleChangeSelectedProductsConfiguration = (configField: string, nextValue: string) => {
    dispatch(
      deskingActions.updateAllSelectedProductsConfiguration({
        data: { [configField]: nextValue },
      })
    );
  };

  const componentsByType: Record<FactToCheckTypes, FC<EquipmentDynamicFieldsProps>> = {
    string: StringField,
    numeric: NumericField,
    boolean: BooleanField,
  };

  const renderConfig = productsFactsForCheckArray.reduce<ProductsDynamicFieldsRender>(
    (acc, fact) => {
      equipmentFactsForSimpleProduct.forEach(targetFactKey => {
        if (fact.factKey === targetFactKey) {
          acc.meta[targetFactKey] = {
            options: [...(acc.meta[targetFactKey]?.options ?? []), ...(fact.options ?? [])],
          };
        }

        if (fact.factKey === targetFactKey && !acc.render[targetFactKey]) {
          acc.render[targetFactKey] = {
            fact,
            fieldComponent: componentsByType[fact.type],
          };
        }

        return acc;
      });

      return acc;
    },
    { render: {}, meta: {} }
  );

  return (
    <Box className={classes.container}>
      <Box className="labelContainer">
        <Typography component="span" variant="subtitle2">
          Product Configuration Required
        </Typography>

        <Tooltip title="All values will be apply for all selected products">
          <Info color="primary" fontSize="small" />
        </Tooltip>
      </Box>

      <Box className="fieldsContainer">
        {Object.entries(renderConfig.render).map(([factType, { fieldComponent: FieldComponent, fact }]) => (
          <FieldComponent
            fact={fact}
            key={factType}
            error={!commonConfig?.[fact.factKey]}
            value={commonConfig?.[fact.factKey] ?? ""}
            helperText="Required Input"
            onChange={nextValue => handleChangeSelectedProductsConfiguration(fact.factKey, nextValue)}
            options={Array.from(new Set(renderConfig.meta[fact.factKey].options)).map(value => ({
              label: value,
              value,
            }))}
          />
        ))}
      </Box>
    </Box>
  );
};

const useStyles = makeStyles({
  container: {
    gap: "0.5rem",
    display: "flex",
    flexDirection: "column",

    "& .labelContainer": {
      gap: "1rem",
      display: "flex",
      alignItems: "center",
    },

    "& .fieldsContainer": {
      gap: "0.5rem",
      display: "grid",
      gridTemplateColumns: "repeat(4, 1fr)",
    },
  },
});
