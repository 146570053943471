import React, { useEffect } from "react";
import Search from "@material-ui/icons/Search";
import { Controller, useForm } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import { Box, InputAdornment } from "@material-ui/core";
import { ProposalProduct } from "@trnsact/trnsact-shared-types/dist/generated";
import { ProductsFiltersAftermarketPartner, ShowTypes } from "../../../types";
import { proposalProductsFiltersOptions } from "../../../constants";
import { FormRadioGroup, InputField } from "../../../../../components/form";
import { proposalProductsFiltersAftermarketPartner } from "modules/desking/lib";

interface Props {
  products: ProposalProduct[];
  onFiltered: (filteredProducts: ProposalProduct[]) => void;
}

export const ProposalProductsFiltersAftermarketPartner = ({ products, onFiltered }: Props) => {
  const classes = useStyles();

  const { control, watch } = useForm<ProductsFiltersAftermarketPartner>({
    defaultValues: {
      search: "",
      showOnly: ShowTypes.All,
    },
  });

  useEffect(() => {
    const subscription = watch(value => {
      const filteredProducts = products.filter(product =>
        proposalProductsFiltersAftermarketPartner(product, value as ProductsFiltersAftermarketPartner)
      );

      onFiltered(filteredProducts);
    });

    return subscription.unsubscribe;
  }, [watch, onFiltered, products]);

  return (
    <Box className={classes.container}>
      <InputField
        control={control}
        name="search"
        label="Search"
        inputProps={{
          variant: "outlined",
          placeholder: "Enter value...",
          InputProps: {
            endAdornment: (
              <InputAdornment position="end">
                <Search />
              </InputAdornment>
            ),
          },
        }}
      />

      <Controller
        name="showOnly"
        control={control}
        render={({ field }) => (
          <FormRadioGroup row label="Show only" options={proposalProductsFiltersOptions} {...field} />
        )}
      />
    </Box>
  );
};

const useStyles = makeStyles({
  container: {
    gap: "1rem",
    display: "flex",
    flexDirection: "column",
  },
});
