import React, { useEffect } from "react";
import { Grid } from "@material-ui/core";
import { MenuTemplateGeneralFormInputs } from "modules/aftermarketMenuConstructor/types";
import { InputField } from "components/form";
import { useFormContext } from "react-hook-form";
import { useAftermarketPermissions } from "modules/aftermarketMenuConstructor/hooks/useAftermarketPermissions";
import { menuConstructorActions } from "modules/aftermarketMenuConstructor/model";
import { useDispatch } from "react-redux";

export const GeneralInformationFormDealer = () => {
  const dispatch = useDispatch();

  const { hasWriteAccess } = useAftermarketPermissions();

  const {
    control,
    formState: { errors },
    watch,
  } = useFormContext<MenuTemplateGeneralFormInputs>();

  useEffect(() => {
    const subscription = watch(value => {
      dispatch(menuConstructorActions.setMenuGeneralFormValues(value as MenuTemplateGeneralFormInputs));
    });

    return subscription.unsubscribe;
  }, [watch]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <InputField
          label="Name"
          control={control}
          name="name"
          rules={{
            required: true,
          }}
          inputProps={{
            required: true,
            error: !!errors.name,
            helperText: errors.name && "Required",
            disabled: !hasWriteAccess,
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <InputField
          label="Description"
          control={control}
          name="description"
          inputProps={{ disabled: !hasWriteAccess }}
        />
      </Grid>
    </Grid>
  );
};
