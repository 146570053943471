import React, { useEffect, useRef, useState } from "react";
import {
  TextField,
  Switch,
  FormControlLabel,
  Button,
  Grid,
  Typography,
  Paper,
  Chip,
  Drawer,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Snackbar,
} from "@material-ui/core";
import { ArrowBackIosOutlined } from "@material-ui/icons";
import { EVENTS, SALES_GROUP, WORKFLOW_ACTIONS } from "@trnsact/trnsact-shared-types";
import { useParams, useHistory } from "react-router-dom";
import _ from "lodash";
import LinearProgress from "@material-ui/core/LinearProgress";

import SaveIcon from "@material-ui/icons/Save";
import { makeStyles } from "@material-ui/core/styles";

import Tile from "components/Tile/Tile";
import LenderTaskForm from "components/LenderTaskForm";
import { Rule } from "json-rules-engine";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { Alert } from "@material-ui/lab";
import { connect, useSelector } from "react-redux";

const useStyles = makeStyles(theme => ({
  backBtn: {
    fontSize: 25,
    marginRight: 4,
    cursor: "pointer",
  },
  title: {
    fontSize: 22,
    marginLeft: 4,
    fontWeight: "bolder",
  },
  nodeContainer: {
    padding: "5px",
    margin: "10px 0",
    border: "1px solid #90cbf9",
    borderRadius: "3px",
    cursor: "pointer",
  },
  nodeConnector: {
    margin: "-10px 0",
    textAlign: "center",
    fontSize: "20px",
    color: "#90cbf9",
  },
  nodeTitle: {
    background: "#E3F2FD",
    fontWeight: "bolder",
  },
  drawerContent: {
    width: "30vw",
    padding: theme.spacing(3),
  },
  conditionChipContainer: {
    display: "flex",
    flexWrap: "wrap",
    gap: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  addConditionButton: {
    marginTop: theme.spacing(1),
  },
  ruleContainer: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    border: "1px solid #ccc",
    borderRadius: "4px",
  },
  chosenConditionColor: {
    color: "#2196F3",
    fontWeight: "bolder",
  },
  taskRowSeparator: {
    borderBottom: "1px solid #CCC",
  },
  taskRow: {
    color: "#2196F3",
  },
  paperContainer: {
    padding: "16px",
    zIndex: 1050,
    marginTop: "-60px",
    position: "relative",
  },
}));

function formatConstantNames(key: string): string {
  return key
    .toLowerCase()
    .split("_")
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

const CREATE_WORKFLOW_MUTATION = gql`
  mutation CreateWorkflow($input: CreateWorkflowInput!) {
    createWorkflow(input: $input) {
      id
      success
      message
    }
  }
`;

const UPDATE_WORKFLOW_MUTATION = gql`
  mutation UploadWorkflow($input: UpdateWorkflowInput!) {
    updateWorkflow(input: $input) {
      success
      message
    }
  }
`;

const GET_WORKFLOW_BY_ID = gql`
  query GetWorkflowByID($id: ID!) {
    workflow(id: $id) {
      name
      active
      description
      json
      createdOn
      modifiedOn
      lastModifiedBy {
        fullName
      }
    }
  }
`;

enum VOStatus {
  Prospect = "Prospect",
  PreQualified = "Pre Qualified",
  Approved = "Approved",
  Funded = "Funded",
  Cancelled = "Cancelled",
  Declined = "Declined",
}

const stageOptions: { value: VOStatus; label: string }[] = [
  { value: VOStatus.Prospect, label: "Prospect" },
  { value: VOStatus.PreQualified, label: "Pre-Qualified" },
  { value: VOStatus.Approved, label: "Approved" },
  { value: VOStatus.Funded, label: "Funded" },
  { value: VOStatus.Cancelled, label: "Cancelled" },
  { value: VOStatus.Declined, label: "Declined" },
];

const WorkflowUpsertPage: React.FC = () => {
  const classes = useStyles();
  const account = useSelector((state: any) => state.account);
  const { id } = useParams<{ id: string | undefined }>();
  const history = useHistory();
  const drawerContainerRef = useRef<HTMLDivElement | null>(null);
  const [workflowName, setWorkflowName] = useState("");
  const [workflowDescription, setWorkflowDescription] = useState("");
  const [enabled, setEnabled] = useState(true);
  const [submitDisabled, setSubmitDisabled] = useState(true);

  const [selectedEvent, setSelectedEvent] = useState(EVENTS.VENDOR_OPPORTUNITY_UPDATED);
  const [selectedConditions, setSelectedConditions] = useState<VOStatus[]>([]);
  const [selectedAction, setSelectedAction] = useState<string>("CREATE_TASK");
  const [selectedActionConfiguration, setSelectedActionConfiguration] = useState<any>();

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [workflowId, setWorkflowId] = useState(id);
  const [editingBox, setEditingBox] = useState<"Event" | "Condition" | "Action" | null>(null);

  const [rules, setRules] = useState<Rule[]>([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">("success");
  const [getWorkflowByID, { data: workflowData, loading: loadingWorkflow, error: workflowError }] = useLazyQuery(
    GET_WORKFLOW_BY_ID,
    {
      context: { authRequired: true },
      fetchPolicy: "no-cache",
      onCompleted(data) {
        if (data) {
          setWorkflowName(data.workflow.name);
          setWorkflowDescription(data.workflow.description);
          setEnabled(data.workflow.active);
          setSelectedAction("CREATE_TASK");
          setSelectedActionConfiguration(_.get(data.workflow.json.actions, "[0]"));
          setSelectedConditions(data.workflow.json.conditions);
          setSelectedEvent(data.workflow.json.event);
        }
      },
    }
  );

  useEffect(() => {
    if (id) {
      setWorkflowId(id);
    }
  }, [id]);
  const [createWorkflow, { loading: loadingCreate, error: errorCreate, data: dataCreate }] = useMutation(
    CREATE_WORKFLOW_MUTATION
  );
  const [updateWorkflow, { loading: loadingUpdate, error: errorUpdate, data: dataUpdate }] = useMutation(
    UPDATE_WORKFLOW_MUTATION
  );

  useEffect(() => {
    if (id) {
      getWorkflowByID({ variables: { id } });
    }
  }, [id]);

  useEffect(() => {
    const isActionValid = !_.isEmpty(_.get(selectedActionConfiguration, "CREATE_TASK.taskItems", []));

    const isFormValid =
      workflowName.trim() !== "" &&
      workflowDescription.trim() !== "" &&
      isActionValid &&
      selectedConditions.length > 0 &&
      selectedEvent.trim() !== "";
    const loadingState = loadingCreate || loadingUpdate || loadingWorkflow;
    setSubmitDisabled(!isFormValid || loadingState);
  }, [
    workflowName,
    workflowDescription,
    selectedAction,
    selectedConditions,
    selectedActionConfiguration,
    selectedEvent,
    loadingCreate,
    loadingUpdate,
  ]);

  const handleBoxClick = (box: "Event" | "Condition" | "Action") => {
    setEditingBox(box);
    setDrawerOpen(true);
  };

  const handleSave = () => {
    setDrawerOpen(false);
  };

  const handleAddCondition = () => {
    setSelectedConditions([...selectedConditions, VOStatus.Prospect]);
  };

  const handleDeleteCondition = (index: number) => {
    const newConditions = selectedConditions.filter((_, i) => i !== index);
    setSelectedConditions(newConditions);
  };

  const handleAddRule = () => {
    const newRule = new Rule({
      conditions: {
        any: selectedConditions.map(condition => ({
          fact: "stage",
          operator: "equal",
          value: condition,
        })),
      },
      event: {
        type: "stageMatched",
        params: {
          message: `Stage is one of: ${selectedConditions.join(", ")}`,
        },
      },
    });
    setRules([...rules, newRule]);
  };
  const handleSaveTask = (payload: any) => {
    try {
      //parse and stringify to remove undefined references
      setSelectedActionConfiguration({ [selectedAction]: { ...JSON.parse(JSON.stringify(payload)), taskId: "-1" } });
    } catch (err) {
      console.log(err);
    }
  };

  const handleSaveWorkflow = async () => {
    try {
      const input = {
        json: {
          event: selectedEvent,
          conditions: selectedConditions,
          actions: [selectedActionConfiguration],
        },
        description: workflowDescription,
        active: enabled,
        name: workflowName,
      };
      let success;
      let response;
      if (id) {
        response = await updateWorkflow({ variables: { input: { ...input, id } } });
        success = _.get(response, "data.updateWorkflow.success", false);
      } else {
        response = await await createWorkflow({ variables: { input } });
        success = _.get(response, "data.createWorkflow.success", false);
      }

      if (success) {
        setSnackbarSeverity("success");
        setSnackbarMessage(`Workflow saved successfully! `);
        if (_.get(response, "data.createWorkflow.id", "")) {
          setTimeout(() => {
            history.push("/workflows/edit/" + _.get(response, "data.createWorkflow.id", ""));
          }, 1000);
        }
      } else {
        setSnackbarSeverity("error");
        setSnackbarMessage(`Failed to save workflow`);
      }
    } catch (err) {
      console.error("Error saving workflow:", err);
      setSnackbarSeverity("error");
      setSnackbarMessage("An error occurred while saving the workflow. Please try again.");
    } finally {
      setSnackbarOpen(true);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  if (loadingWorkflow || loadingUpdate) {
    return <LinearProgress variant="indeterminate" color="primary" />;
  }

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper className={classes.paperContainer}>
            <Grid container justifyContent="space-around">
              <Grid item xs={2}>
                <ArrowBackIosOutlined
                  className={classes.backBtn}
                  onClick={() => (window.location.href = "/workflows")}
                />
                <span className={classes.title}>{workflowId ? "Update Workflow" : "Add Workflow"}</span>
              </Grid>
              <Grid item xs={8}>
                <FormControlLabel
                  control={<Switch checked={enabled} onChange={e => setEnabled(e.target.checked)} />}
                  label="Enable the Workflow"
                />
              </Grid>
              <Grid item xs={2}>
                <Button
                  style={{ float: "right" }}
                  onClick={handleSaveWorkflow}
                  disabled={submitDisabled}
                  startIcon={<SaveIcon />}
                  color="primary"
                  variant="outlined"
                >
                  Save
                </Button>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  label="Name"
                  value={workflowName}
                  onChange={e => setWorkflowName(e.target.value)}
                  required
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Description"
                  value={workflowDescription}
                  onChange={e => setWorkflowDescription(e.target.value)}
                  fullWidth
                  margin="normal"
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5">Workflow Diagram</Typography>
        </Grid>
        <Grid item xs={12} ref={drawerContainerRef}>
          <Grid container justifyContent="center" spacing={1}>
            <Grid item xs={8} className={classes.nodeContainer} onClick={() => handleBoxClick("Event")}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Tile className={classes.nodeTitle}>Event</Tile>
                </Grid>
                <Grid item xs={12}>
                  <Chip label={formatConstantNames(selectedEvent)} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={8} className={classes.nodeConnector}>
              |
            </Grid>
            <Grid item xs={8} className={classes.nodeContainer} onClick={() => handleBoxClick("Condition")}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Tile className={classes.nodeTitle}>Condition</Tile>
                </Grid>
                <Grid item xs={12}>
                  {selectedConditions.length > 0 ? (
                    <div className={classes.conditionChipContainer}>
                      <Grid container spacing={1} justifyContent="space-evenly">
                        <Grid item xs={12}>
                          <Chip key={"initialCondition"} label={"IF"} color="primary" />
                        </Grid>

                        {selectedConditions.map((condition, index) => (
                          <>
                            <Grid item>
                              <Chip
                                key={index}
                                label={
                                  <div>
                                    Opportunity Stage ={" "}
                                    <span className={classes.chosenConditionColor}>{condition}</span>
                                  </div>
                                }
                                onDelete={() => handleDeleteCondition(index)}
                              />
                            </Grid>
                            {selectedConditions.length > 1 && index + 1 < selectedConditions.length ? (
                              <Grid item>
                                <Chip key={`${index}_CONNECTOR_OR`} label={`OR`} variant="outlined" />
                              </Grid>
                            ) : null}
                          </>
                        ))}
                      </Grid>
                    </div>
                  ) : (
                    <Typography variant="body2" color="textSecondary">
                      Click here to set Condition
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={8} className={classes.nodeConnector}>
              |
            </Grid>
            <Grid item xs={8} className={classes.nodeContainer} onClick={() => handleBoxClick("Action")}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Tile className={classes.nodeTitle}>Action</Tile>
                </Grid>

                <Grid item xs={12}>
                  {selectedActionConfiguration ? (
                    <Paper
                      style={{
                        padding: 16,
                        backgroundColor: "#F5F5F5",
                        marginTop: 16,
                        border: "1px solid #F5F5F5",
                        borderRadius: "5px",
                      }}
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={6} className={classes.taskRowSeparator}>
                          <Typography variant="body2">
                            Regarding ={" "}
                            <span className={classes.taskRow}>{selectedActionConfiguration.CREATE_TASK.regarding}</span>
                          </Typography>
                        </Grid>
                        <Grid item xs={6} className={classes.taskRowSeparator}>
                          <Typography variant="body2">
                            Due Date ={" "}
                            <span className={classes.taskRow}>
                              {new Date(selectedActionConfiguration.CREATE_TASK.dueDateTime).toLocaleDateString()}
                            </span>
                          </Typography>
                        </Grid>
                        <Grid item xs={6} className={classes.taskRowSeparator}>
                          <Typography variant="body2">
                            Status ={" "}
                            <span className={classes.taskRow}>{selectedActionConfiguration.CREATE_TASK.status}</span>
                          </Typography>
                        </Grid>
                        <Grid item xs={6} className={classes.taskRowSeparator}>
                          <Typography variant="body2">
                            Assigned to ={" "}
                            <span className={classes.taskRow}>
                              {selectedActionConfiguration.CREATE_TASK.assignedToUser}
                            </span>
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="subtitle2">Task Items:</Typography>
                        </Grid>
                      </Grid>

                      {selectedActionConfiguration.CREATE_TASK.taskItems &&
                        selectedActionConfiguration.CREATE_TASK.taskItems.length > 0 && (
                          <Grid container spacing={2}>
                            {selectedActionConfiguration.CREATE_TASK.taskItems.map((item: any, index: number) => (
                              <Grid item key={index} xs={12}>
                                <Grid container spacing={1}>
                                  <Grid item xs={12} className={classes.taskRowSeparator}>
                                    <Typography variant="body2">
                                      Task Item <strong>#{index + 1}</strong> Category ={" "}
                                      <span className={classes.taskRow}>{item.type}</span>
                                    </Typography>
                                  </Grid>

                                  {item.docType && item.type !== "info" ? (
                                    <Grid item xs={12} className={classes.taskRowSeparator}>
                                      <Typography variant="body2">
                                        Task Item <strong>#{index + 1}</strong> Document Type =
                                        <span className={classes.taskRow}>{item.docType}</span>
                                      </Typography>
                                    </Grid>
                                  ) : null}

                                  {item.taskAssignorNotes ? (
                                    <Grid item xs={12} className={classes.taskRowSeparator}>
                                      <Typography variant="body2">
                                        Task Item <strong>#{index + 1}</strong> Notes to Assignee =
                                        <span className={classes.taskRow}>{item.taskAssignorNotes}</span>
                                      </Typography>
                                    </Grid>
                                  ) : null}
                                </Grid>
                              </Grid>
                            ))}
                          </Grid>
                        )}
                    </Paper>
                  ) : (
                    <Typography variant="body2" color="textSecondary">
                      Click here to set Action
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Drawer anchor="right" variant="temporary" open={drawerOpen} onClose={() => setDrawerOpen(false)}>
          <Grid container className={classes.drawerContent}>
            <Grid item xs={12}>
              <div>
                {editingBox === "Event" && (
                  <>
                    <FormControl fullWidth margin="normal">
                      <InputLabel id="event-select-label">Condition</InputLabel>
                      <Select
                        labelId="event-select-label"
                        value={selectedEvent}
                        disabled
                        onChange={e => setSelectedEvent(EVENTS[e.target.value as keyof typeof EVENTS])}
                      >
                        {Object.entries(EVENTS).map(([key, value]) => (
                          <MenuItem key={key} value={value}>
                            {formatConstantNames(key)}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    <Button onClick={handleSave} color="primary" variant="outlined" startIcon={<SaveIcon />}>
                      Save Event
                    </Button>
                  </>
                )}
                {editingBox === "Condition" && (
                  <>
                    <FormControl fullWidth margin="normal">
                      <InputLabel id="condition-select-label">Condition</InputLabel>
                      <Select
                        labelId="condition-select-label"
                        multiple
                        value={selectedConditions}
                        onChange={e => setSelectedConditions(e.target.value as VOStatus[])}
                      >
                        {stageOptions.map(option => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <Button onClick={handleSave} color="primary" variant="outlined" startIcon={<SaveIcon />}>
                      Save Condition
                    </Button>
                  </>
                )}
                {editingBox === "Action" && (
                  <Grid container spacing={5}>
                    <Grid item xs={12}>
                      <FormControl fullWidth margin="normal">
                        <InputLabel id="condition-select-label">Action</InputLabel>
                        <Select
                          labelId="condition-select-label"
                          value={selectedAction}
                          onChange={e => setSelectedAction(e.target.value as string)}
                        >
                          {["CREATE_TASK"].map((option: string) => (
                            <MenuItem key={option} value={option}>
                              {formatConstantNames(option)}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>

                    {selectedAction === "CREATE_TASK" && (
                      <Grid item xs={12}>
                        <LenderTaskForm
                          tasksData={
                            _.get(selectedActionConfiguration, "CREATE_TASK")
                              ? [_.get(selectedActionConfiguration, "CREATE_TASK")]
                              : []
                          }
                          taskId={!_.isEmpty(_.get(selectedActionConfiguration, "CREATE_TASK", [])) ? "-1" : null}
                          drawerContained={true}
                          handleAddTask={handleSaveTask}
                          handleClose={() => {
                            setDrawerOpen(false);
                          }}
                          renderInWorkflowConstructor={true}
                          dynamicRecipients={[
                            {
                              id: "applicant",
                              ownerPgId: "will_be_changed_by_backend",
                              firstName: "Primary",
                              fullName: "Primary Contact",
                              lastName: "Contact",
                              email: "will_be_changed_by_backend",
                              company: "Customer",
                              role: "POC",
                              isApplicant: true,
                              category: "Customer",
                            },
                            {
                              id: "sales_mgr",
                              firstName: "Sales",
                              fullName: SALES_GROUP.sales_mgr,
                              lastName: "Manager",
                              email: "will_be_changed_by_backend",
                              company: account.name,
                              role: SALES_GROUP.sales_mgr,
                              isApplicant: false,
                              category: "Associated User By Role",
                            },
                            {
                              id: "sales_rep",
                              firstName: "Sales",
                              fullName: SALES_GROUP.sales_rep,
                              lastName: "Representative",
                              email: "will_be_changed_by_backend",
                              company: account.name,
                              role: SALES_GROUP.sales_rep,
                              isApplicant: false,
                              category: "Associated User By Role",
                            },
                            {
                              id: "finance_manager",
                              firstName: "Finance",
                              fullName: SALES_GROUP.finance_manager,
                              lastName: "Manager",
                              email: "will_be_changed_by_backend",
                              company: account.name,
                              role: SALES_GROUP.finance_manager,
                              isApplicant: false,
                              category: "Associated User By Role",
                            },
                          ]}
                        />
                      </Grid>
                    )}
                  </Grid>
                )}
              </div>
            </Grid>
          </Grid>
        </Drawer>
      </Grid>
      {/* Snackbar Component */}
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    account: state.account,
  };
};

export default connect(mapStateToProps, null)(WorkflowUpsertPage);
