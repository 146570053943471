import { Engine } from "json-rules-engine";
import { createContext } from "react";
import { ProposalProduct } from "@trnsact/trnsact-shared-types/dist/generated";
import { ProductConfig } from "../types";
import { Nullable } from "../../../global";

interface JsonRulesEngineContext {
  engine: Engine;
  handleRunEngin: VoidFunction;
  handleAddProductsRules: (
    product: ProposalProduct,
    updateProductConfiguration?: (updateValues: Partial<ProductConfig>) => void
  ) => void;
}

export const jsonRulesEngineContext = createContext<Nullable<JsonRulesEngineContext>>(null);
