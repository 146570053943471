import React from "react";
import { Box } from "@material-ui/core";
import { ApolloQueryResult } from "apollo-client";
import { makeStyles } from "@material-ui/core/styles";
import {
  CreditLineCard,
  HealthCheckCard,
  DocumentsCard,
  TasksCard,
  VendorOpportunity,
} from "modules/vendorOpportunity";

interface Props {
  creditApp: any;
  userName: string;
  accountId: string;
  vendorProfile: any;
  vo: VendorOpportunity;
  creditAppRefetch: () => Promise<ApolloQueryResult<void>>;
  permissions: Record<"creditLine" | "healthCheck" | "docs" | "tasks", boolean>;
}

export const CreditLineTab = ({
  vo,
  userName,
  accountId,
  creditApp,
  permissions,
  vendorProfile,
  creditAppRefetch,
}: Props) => {
  const classes = useStyles();

  return (
    <Box className={classes.creditLineTab}>
      {permissions.creditLine && (
        <CreditLineCard
          userName={userName}
          notes={vo?.creditLineNotes}
          status={vo?.creditLineStatus}
          VOId={vo?.vendorOpportunityId}
          VOAmount={vo?.creditLineAmountRequested}
          creditAppAmount={creditApp?.creditLineAmountRequested}
          isCreditLineRequested={!!creditApp?.creditLineAmountRequested}
        />
      )}

      {permissions.healthCheck && (
        <HealthCheckCard
          userName={userName}
          creditApp={creditApp}
          cardTitle="Compliance"
          accountId={accountId}
          refetchCreditApp={creditAppRefetch}
          softPullInDealerPortal={vendorProfile.softPullInDealerPortal}
        />
      )}

      {permissions.docs && <DocumentsCard VOId={vo.vendorOpportunityId} />}

      {permissions.tasks && <TasksCard VOId={vo.vendorOpportunityId} />}
    </Box>
  );
};

const useStyles = makeStyles({
  creditLineTab: {
    gap: "1rem",
    display: "flex",
    flexDirection: "column",
  },
});
