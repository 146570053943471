import React, { Fragment, ReactNode, useEffect, useMemo } from "react";
import { useQuery } from "@apollo/react-hooks";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles, Grid, Box, Button } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { CardContainer } from "components/shared/CardContainer/CardContainer";
import { DealerManagementTable } from "../../tables/dealerManagementTable/DealerManagementTable";
import { ModalsKeys } from "global";
import { useModal } from "hooks/useModal";
import { AddDealerDialog } from "../../dialogs/AddDealerDialog";
import { modalsSelectors } from "redux/modalsReducer";
import { GET_PARTNER_LINKS } from "modules/aftermarketPartner/api";
import { PartnerLinksResponse } from "modules/aftermarketPartner/api/types";
import { aftermarketPartnerActions, aftermarketPartnerSelectors } from "modules/aftermarketPartner/model";
import { environmentName } from "../../../../../config";

export const AftermarketPartnerDealerManagement = () => {
  const NTP_ACCOUNT_ID =
    environmentName === "dev" ? "aebc4dfe-71db-11ef-a943-db9302946276" : "af389c24-71db-11ef-b67d-438054f5b5e6";

  const classes = useStyles();

  const dispatch = useDispatch();

  const account = useSelector((state: any) => state.account);
  const partnerLinks = useSelector(aftermarketPartnerSelectors.partnerLinks);
  const openedModals = useSelector(modalsSelectors.openedModals) as ModalsKeys[];

  const { handleOpen: handleAddDealerDialogOpen } = useModal(ModalsKeys.AftermarketPartnerAddDealerDialog);

  const modalsWithKeys: Partial<Record<ModalsKeys, ReactNode>> = {
    [ModalsKeys.AftermarketPartnerAddDealerDialog]: <AddDealerDialog />,
  };

  const { loading: loadingPartnerLinks, refetch: refetchPartnerLinks } = useQuery<PartnerLinksResponse>(
    GET_PARTNER_LINKS,
    {
      skip: !account.dynamicsAccountId,
      variables: {
        dynamicsId: account.dynamicsAccountId,
      },
      onCompleted(data) {
        dispatch(aftermarketPartnerActions.setPartnerLinks(data.getPartnerLinks));
        dispatch(aftermarketPartnerActions.setIsNTPAccount(account.id === NTP_ACCOUNT_ID));
      },
    }
  );

  return (
    <>
      <CardContainer
        title="Dealer Management"
        actionBtn={
          <Button
            variant="contained"
            size="small"
            startIcon={<AddIcon />}
            onClick={() => handleAddDealerDialogOpen({ refetchPartnerLinks })}
          >
            Add Dealer
          </Button>
        }
      >
        <Box className={classes.container}>
          <DealerManagementTable loading={loadingPartnerLinks} tableData={partnerLinks} />
        </Box>
      </CardContainer>

      {openedModals.map(modalKey => (
        <Fragment key={modalKey}>{modalsWithKeys[modalKey]}</Fragment>
      ))}
    </>
  );
};

const useStyles = makeStyles(({ palette }) => ({
  container: {},
}));
