import React, { ReactNode } from "react";
import clsx from "clsx";
import { ContentViewType } from "global";
import { Skeleton } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Divider, Typography } from "@material-ui/core";

interface Props {
  isLoading?: boolean;
  data: [ReactNode, ReactNode][];
  contentViewType?: ContentViewType;
}

export const RowDataList = ({ data, contentViewType = ContentViewType.Desktop, isLoading }: Props) => {
  const classes = useStyles();

  return (
    <>
      {!!data?.length &&
        data.map(([label, value], index, arr) => (
          <Box key={index} className={classes.dataViewMain}>
            <Box
              className={clsx({
                [classes.dataViewContainerDesktop]: contentViewType === ContentViewType.Desktop,
                [classes.dataViewContainerMobile]: contentViewType === ContentViewType.Mobile,
              })}
            >
              <Box className={classes.boxLabel}>{label}</Box>
              <Box className={classes.boxValue}>
                {isLoading ? (
                  <Skeleton width="4rem" />
                ) : (
                  value ?? (
                    <Typography content="span" variant="body2" color="textPrimary">
                      —
                    </Typography>
                  )
                )}
              </Box>
            </Box>

            {arr.length - 1 !== index && (
              <Divider orientation="horizontal" variant="fullWidth" flexItem className={classes.divider} />
            )}
          </Box>
        ))}
    </>
  );
};

const useStyles = makeStyles({
  dataViewMain: {
    gap: "8px",
    display: "flex",
    flexDirection: "column",
  },
  dataViewContainerDesktop: {
    display: "flex",
    minHeight: "30px",
    alignItems: "center",
    justifyContent: "space-between",
  },
  dataViewContainerMobile: {
    display: "flex",
    minHeight: "30px",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "space-between",
  },
  boxLabel: {
    flex: 1,
  },
  boxValue: {
    flex: 1,
    "& > div": {
      justifyContent: "flex-start !important",
    },
  },
  divider: {
    height: "1px",
    marginBottom: "4px",
  },
});
