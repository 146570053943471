import React, { FC, useEffect, useMemo } from "react";
import clsx from "clsx";
import { ValueOf } from "global";
import { useQuery } from "react-apollo";
import InfoIcon from "@material-ui/icons/Info";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { useFormContext, useWatch } from "react-hook-form";
import { FieldValue } from "react-hook-form/dist/types/fields";
import { Box, Button, CircularProgress, Typography } from "@material-ui/core";
import { convertProgramToCalculateVariables } from "../../../../lib";
import { deskingActions, deskingSelectors } from "../../../../model";
import { CALCULATE_MULTIPLE_PAYMENTS, GET_PROPOSAL_MENUS } from "../../../../api";
import { FinanceFieldSelector } from "../../../components/finance/FinanceFieldSelector";
import { CalculatePaymentVariables, FinanceProgramFormValues, FormSectionProps, MenuCards } from "../../../../types";
import { FormSectionHeader, MenuOptionCards, MenuSelector, PaymentOptionCards } from "../../../components";
import {
  CalculatingResponse,
  CalculatingVariables,
  OptionsCardsRenderMode,
  ProposalMenusResponse,
} from "../../../../api/types";

export const MenuBuilder = ({ onUpdateCollapse, sectionName, onClearStructureAndMenuOptions }: FormSectionProps) => {
  const classes = useStyles();

  const { control, watch } = useFormContext<FinanceProgramFormValues>();
  const [financeQuote, saleDetails, financeProgramDetails] = useWatch({
    control,
    name: ["financeQuote", "saleDetails", "financeProgramDetails"],
  });

  const dispatch = useDispatch();
  const menuByTerms = useSelector(deskingSelectors.menuByTerms);
  const recalculateStep = useSelector(deskingSelectors.recalculateStep);
  const proposalMenuByCurrentTerm = useSelector(deskingSelectors.proposalMenuByCurrentTerm);
  const proposalsProductsIds = useSelector(deskingSelectors.proposalsProductsIds);
  const isSectionOpen = useSelector((state: any) => deskingSelectors.isSectionOpen(state, sectionName));
  const proposalMenu = useSelector((state: any) => deskingSelectors.proposalMenuById(state, financeQuote.menu));
  const proposalProductConfigurationsInMenu = useSelector(deskingSelectors.proposalProductConfigurationsInMenu);
  const calculateMultiplePayments = useSelector(deskingSelectors.calculateMultiplePayments);

  useQuery<ProposalMenusResponse>(GET_PROPOSAL_MENUS, {
    onCompleted(response) {
      if (!Array.isArray(response?.proposalMenus)) return;
      dispatch(deskingActions.setProposalMenus({ menuOptions: response.proposalMenus }));
    },
  });

  const { data: calculatingResults, loading: isCalculating, refetch: recalculateMultiplePayments } = useQuery<
    CalculatingResponse,
    CalculatingVariables<CalculatePaymentVariables>
  >(CALCULATE_MULTIPLE_PAYMENTS, {
    onCompleted(response) {
      if (!Array.isArray(response?.calculateMultiplePayments)) return;

      const sortedCalculate = response.calculateMultiplePayments.map(({ paymentOptions, ...rest }) => ({
        ...rest,
        paymentOptions: paymentOptions.sort((a, b) => b.term! - a.term!),
      }));

      dispatch(deskingActions.setCalculateMultiplePayments({ results: sortedCalculate }));
    },
    skip: !financeQuote.createProposalWithExistingProgram,
    notifyOnNetworkStatusChange: true,
    variables: {
      input: convertProgramToCalculateVariables({
        program: financeQuote,
        saleDetails,
        proposalMenu,
        proposalMenuByCurrentTerm,
        financeProgramDetails,
        proposalProductConfigurationsInMenu,
      })!,
    },
  });

  useEffect(() => {
    const formRefetchFields: FieldValue<ValueOf<FinanceProgramFormValues, "financeQuote" | "saleDetails">>[] = [
      "financeQuote.customTermTemporaryValue",
      "financeQuote.terms",
      "financeQuote.program",
      "financeQuote.commonRate",
      "saleDetails.financeAmount",
    ];

    const subscription = watch((value, { name }) => {
      if (!formRefetchFields.includes(name)) return;
      dispatch(deskingActions.updateRecalculate());
    });

    return subscription.unsubscribe;
  }, []);

  useEffect(() => {
    recalculateMultiplePayments();
  }, [recalculateStep]);

  const currentRenderMode = useMemo(() => {
    const isMenuExist = !!calculatingResults?.calculateMultiplePayments
      .map(({ paymentOptions }) => !!paymentOptions?.[0]?.menu)
      ?.filter(Boolean)?.length;

    if (isMenuExist) {
      const menuResults = calculatingResults.calculateMultiplePayments[0]?.paymentOptions?.reduce(
        (acc: any, data: any) => {
          if (menuByTerms?.[data.term]) {
            acc[data.term] = {
              ...data,
              menu: {
                ...data.menu,
                menuOptions: data.menu.menuOptions.map((menuOption: any, index: number) => {
                  const nextProductsValue = menuByTerms[data.term].menu.menuOptions[
                    index
                  ].products.filter((product: any) => proposalsProductsIds.includes(product.proposalProductId));

                  return { ...menuOption, products: nextProductsValue };
                }),
              },
            };
          } else acc[data.term] = data;

          return acc;
        },
        {}
      );

      dispatch(deskingActions.setMenuResults({ menuBuilder: menuResults }));
    }

    return isMenuExist ? OptionsCardsRenderMode.Menu : OptionsCardsRenderMode.Options;
  }, [calculatingResults]);

  const renderCardsByMode: Record<OptionsCardsRenderMode, FC<MenuCards>> = {
    [OptionsCardsRenderMode.Menu]: MenuOptionCards,
    [OptionsCardsRenderMode.Options]: PaymentOptionCards,
  };

  // TODO need to refactor
  const isShowMenuBuilder =
    !!financeQuote.amount && (!!financeQuote.terms?.length || !!financeQuote.customTermTemporaryValue);

  return (
    <Box className={clsx("section", classes.sectionContainer)}>
      <FormSectionHeader
        name="Menu Builder"
        sectionName={sectionName}
        isSectionOpen={isSectionOpen}
        onUpdateCollapse={onUpdateCollapse}
        extraAction={
          <>
            {isSectionOpen && (
              <Box className={classes.actionBtn}>
                <Button size="small" color="primary" variant="contained" onClick={onClearStructureAndMenuOptions}>
                  Reset
                </Button>
              </Box>
            )}
          </>
        }
      />

      {!isShowMenuBuilder && !isSectionOpen && (
        <Box className={classes.fillAlert}>
          <InfoIcon color="primary" />

          <Typography component="span" variant="subtitle2" color="primary">
            Missing Finance Inputs. Add Amount, Term and Buy Rate to render.
          </Typography>
        </Box>
      )}

      {isSectionOpen && (
        <Box className="row">
          <FinanceFieldSelector />

          <MenuSelector withAddProductsFeature={false} />
        </Box>
      )}

      <Box
        className={clsx("sectionFields", {
          [classes.sectionFieldsLoading]: isCalculating && !calculateMultiplePayments.length,
        })}
      >
        {!calculateMultiplePayments.length && isCalculating && (
          <Box className={classes.calculatingBox}>
            <Typography>Calculating...</Typography>

            <CircularProgress color="primary" />
          </Box>
        )}

        {isShowMenuBuilder &&
          !!calculateMultiplePayments.length &&
          calculateMultiplePayments.map(({ paymentOptions }, index) => {
            const Cards = renderCardsByMode[currentRenderMode];
            return (
              <Cards
                key={`${currentRenderMode}-${index}`}
                paymentOptions={paymentOptions}
                isSectionOpen={isSectionOpen}
                isCalculating={isCalculating}
              />
            );
          })}
      </Box>
    </Box>
  );
};

const useStyles = makeStyles({
  sectionContainer: {
    overflowX: "auto",
  },
  sectionFieldsLoading: {
    height: "100%",
    minHeight: "10rem",
    alignItems: "center",
    justifyContent: "center",
  },
  fillAlert: {
    gap: "0.5rem",
    display: "flex",
    alignItems: "center",
    paddingTop: "0.25rem",
  },
  calculatingBox: {
    gap: "12px",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
  },
  actionBtn: {
    top: "50%",
    right: "10px",
    position: "absolute",
    transform: "translateY(-50%)",
  },
});
