import { PartnerProfilePartnerLinks, ProposalMenu, ProposalMenuOption } from "@trnsact/trnsact-shared-types";
import { ProposalProduct } from "@trnsact/trnsact-shared-types/src/generated";

import {
  ADD_MENU_OPTION,
  ADD_MENU_OPTION_PRODUCT,
  CLEAR,
  EDIT_MENU_OPTION,
  REMOVE_MENU_OPTION,
  REMOVE_MENU_OPTION_PRODUCT,
  SELECT_MENU_OPTION,
  SET_INITIAL_PROPOSALS_MENU,
  SET_IS_LOADING,
  SET_MENU_GENERAL_FORM_VALUES,
  SET_MENU_OPTION_TO_ADD_EDIT,
  SET_PARTNER_LINKS_BY_ID,
  SET_PROPOSALS_MENU,
  SET_PROPOSALS_PRODUCTS,
  SET_SELECTED_PROPOSALS_PRODUCTS,
  UPDATE_PRODUCT_CONFIGURATION,
  UPDATE_PRODUCT_CONFIGURATION_IN_MENU,
} from "./actionTypes";
import { MenuOptionToAddEdit, MenuTemplateGeneralFormInputs } from "../types";

const setMenu = (payload: ProposalMenu) => ({
  type: SET_PROPOSALS_MENU,
  payload,
});

const setMenuGeneralFormValues = (payload: MenuTemplateGeneralFormInputs) => ({
  type: SET_MENU_GENERAL_FORM_VALUES,
  payload,
});

const setProducts = (payload: ProposalProduct[]) => ({
  type: SET_PROPOSALS_PRODUCTS,
  payload,
});

const setSelectedProducts = (payload: ProposalProduct[]) => ({
  type: SET_SELECTED_PROPOSALS_PRODUCTS,
  payload,
});

const setMenuOptionToAddEdit = (payload: { currentMenuOption: MenuOptionToAddEdit }) => ({
  type: SET_MENU_OPTION_TO_ADD_EDIT,
  payload,
});

const setPartnerLinksById = (payload: Record<string, PartnerProfilePartnerLinks>) => ({
  type: SET_PARTNER_LINKS_BY_ID,
  payload,
});

const addMenuOption = (payload: { menuOption: MenuOptionToAddEdit }) => ({
  type: ADD_MENU_OPTION,
  payload,
});

const editMenuOption = (payload: { menuOption: MenuOptionToAddEdit }) => ({
  type: EDIT_MENU_OPTION,
  payload,
});

const removeMenuOption = (payload: { currentMenuOption: MenuOptionToAddEdit }) => ({
  type: REMOVE_MENU_OPTION,
  payload,
});

const selectMenuOption = (payload: { menuOption: ProposalMenuOption | null }) => ({
  type: SELECT_MENU_OPTION,
  payload,
});

const addMenuOptionProduct = (payload: { menuType: string; product: ProposalProduct }) => ({
  type: ADD_MENU_OPTION_PRODUCT,
  payload,
});

const removeMenuOptionProduct = (payload: { menuType: string; productId: string }) => ({
  type: REMOVE_MENU_OPTION_PRODUCT,
  payload,
});

const updateProductConfiguration = (payload: { productId: string; data: Record<string, any> }) => ({
  type: UPDATE_PRODUCT_CONFIGURATION,
  payload,
});

const updateProductConfigurationInMenu = (payload: {
  productId: string;
  menuName: string;
  data: Record<string, any>;
}) => ({
  type: UPDATE_PRODUCT_CONFIGURATION_IN_MENU,
  payload,
});

const setOrderOfProduct = (payload: { menuName: string; from: number; to: number }) =>
  ({
    type: "MENU_CONSTRUCTOR_SWITCH_PRODUCTS",
    payload,
  } as const);

const setInitialMenu = (payload: ProposalMenu) => ({
  type: SET_INITIAL_PROPOSALS_MENU,
  payload,
});

const setIsLoading = (payload: boolean) => ({
  type: SET_IS_LOADING,
  payload,
});

const clearState = () => ({
  type: CLEAR,
});

export const menuConstructorActions = {
  setMenu,
  setProducts,
  setSelectedProducts,
  setMenuOptionToAddEdit,
  setMenuGeneralFormValues,
  setPartnerLinksById,
  addMenuOption,
  editMenuOption,
  removeMenuOption,
  selectMenuOption,
  addMenuOptionProduct,
  removeMenuOptionProduct,
  updateProductConfiguration,
  updateProductConfigurationInMenu,
  setInitialMenu,
  setIsLoading,
  clearState,
  setOrderOfProduct,
};
