import React from "react";
import { Box, Chip } from "@material-ui/core";
import { AftermarketProductCategory } from "@trnsact/trnsact-shared-types";

interface Props {
  productCategory: AftermarketProductCategory;
}

export const ProposalProductsCardTypeChip = ({ productCategory }: Props) => {
  const chipStylesByType = {
    [AftermarketProductCategory.Insurance]: {
      backgroundColor: "#4E89F3",
      color: "#FFFFFF",
    },
    [AftermarketProductCategory.Gap]: {
      backgroundColor: "#F564A9",
      color: "#FFFFFF",
    },
    [AftermarketProductCategory.Roadside]: {
      backgroundColor: "#3CC37A",
      color: "#FFFFFF",
    },
    [AftermarketProductCategory.ServiceContract]: {
      backgroundColor: "#FFB64E",
      color: "#FFFFFF",
    },
    [AftermarketProductCategory.None]: {
      backgroundColor: "#FFFFFF",
      color: "#000000",
    }
  };

  return (
    <Box>
      <Chip size="small" label={productCategory.replace(/_/g, ' ')} style={chipStylesByType?.[productCategory] ?? {}} />
    </Box>
  );
};
