import React, { ReactNode } from "react";
import { ModalsKeys } from "global";
import { useModal } from "hooks/useModal";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { useFormContext, useWatch } from "react-hook-form";
import { Box, Button, ButtonGroup, Tooltip } from "@material-ui/core";
import { Menu } from "./Menu";
import { Finance } from "./finance/Finance";
import { FormSectionHeader } from "../../../../components";
import { deskingActions, deskingSelectors } from "../../../../../model";
import { FinanceProgramFormValues, FinanceQuoteMode, FormSectionProps } from "../../../../../types";

export const Structure = ({ sectionName }: FormSectionProps) => {
  const classes = useStyles();

  const { handleOpen } = useModal(ModalsKeys.Confirmation);

  const dispatch = useDispatch();
  const isSectionOpen = useSelector((state: any) => deskingSelectors.isSectionOpen(state, sectionName));

  const { setValue, control, resetField } = useFormContext<FinanceProgramFormValues>();
  const financeQuote = useWatch({ control, name: "financeQuote" });

  const isMenuSectionDisabled =
    financeQuote.createProposalWithExistingProgram === null || !financeQuote.amount || !financeQuote.terms.length;

  const handleChangeMode = (nextValue: FinanceQuoteMode) => {
    setValue("financeQuote.mode", nextValue);
  };

  const handleClearStructureAndMenuOptions = () => {
    handleOpen({
      title: "Are you sure you want to reset the proposal builder?",
      message: "This action will remove all configurations from the Finance Program and Menu.",
      onConfirm: () => {
        resetField("financeQuote");
        resetField("displaySettings");
        dispatch(deskingActions.resetStructureAndMenuOptions());
      },
    });
  };

  const renderNestedSectionByMode: Record<FinanceQuoteMode, ReactNode> = {
    [FinanceQuoteMode.Finance]: <Finance />,
    [FinanceQuoteMode.Menu]: <Menu />,
  };

  return (
    <Box className="section">
      <FormSectionHeader
        name="Structure"
        sectionName={sectionName}
        isSectionOpen={isSectionOpen}
        extraAction={
          <Button size="small" color="primary" variant="contained" onClick={handleClearStructureAndMenuOptions}>
            Reset
          </Button>
        }
      />

      <Box className="sectionFields">
        <ButtonGroup fullWidth>
          <Button
            color="primary"
            onClick={() => handleChangeMode(FinanceQuoteMode.Finance)}
            variant={financeQuote.mode === FinanceQuoteMode.Menu ? "outlined" : "contained"}
          >
            Finance
          </Button>

          <Tooltip
            arrow
            disableHoverListener={!isMenuSectionDisabled}
            title="Please fill in Terms in Months and Buy Rate section first to access the Menu section"
          >
            <span className={classes.disableTooltipWrapper}>
              <Button
                color="primary"
                disabled={isMenuSectionDisabled}
                onClick={() => handleChangeMode(FinanceQuoteMode.Menu)}
                variant={financeQuote.mode === FinanceQuoteMode.Finance ? "outlined" : "contained"}
              >
                Menu
              </Button>
            </span>
          </Tooltip>
        </ButtonGroup>

        <Box className="sectionFields">{renderNestedSectionByMode[financeQuote.mode]}</Box>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles({
  disableTooltipWrapper: {
    width: "100%",
    display: "block",

    "& > button": {
      width: "100%",
    },
  },
});
