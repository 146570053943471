import React from "react";
import { FormInput } from "components/form";
import { EquipmentDynamicFieldsProps } from "../../../types";
import { factsKeyMapOfFormatters } from "../../../constants";

export const NumericField = ({ fact, value, onChange }: EquipmentDynamicFieldsProps) => (
  <FormInput
    variant="standard"
    onChange={value => onChange(value)}
    label={fact.factKey === "DEAL_SIZE" ? "AMOUNT ($)" : fact.factKey.replace(/_/g, " ")}
    value={factsKeyMapOfFormatters[fact.factKey] ? factsKeyMapOfFormatters[fact.factKey](value) : value}
  />
);
