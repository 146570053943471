import _ from "lodash";

export function prepareMenuOptionsForAPI(
  menuOptions: any[],
  financeProgramDetails: any,
  productsConfigInMenuForFirstTerm: any
) {
  if (!menuOptions) return null;

  return menuOptions?.map((menuOption: any) => ({
    name: menuOption.name,
    description: menuOption.description,
    titleColor: menuOption.titleColor,
    titleBgColor: menuOption.titleBgColor,
    products: menuOption.products
      .map((product: any) => {
        const productConfig = productsConfigInMenuForFirstTerm?.[menuOption.name]?.[product.proposalProductId];

        if (!productConfig) return null;

        const aftermarketProduct = product.aftermarketProduct ? { ...product.aftermarketProduct } : undefined;

        if (product.markup) {
          delete product.markup.__typename;
          product.markup.markup = _.round(product.markup.markup, 2);
        }

        if (product.configuration) {
          // aftermarketProduct.configuration = { ...product.configuration };
          delete product.configuration;
        }

        if (aftermarketProduct && aftermarketProduct.__typename) {
          delete aftermarketProduct.productDescriptionInternal;
          delete aftermarketProduct.createdDateTime;
          delete aftermarketProduct.modifiedDateTime;
          delete aftermarketProduct.__typename;

          if (aftermarketProduct && aftermarketProduct.aftermarketProductCatalogDetails) {
            delete aftermarketProduct.aftermarketProductCatalogDetails.__typename;
          }

          if (aftermarketProduct && aftermarketProduct.config) {
            delete aftermarketProduct.config.__typename;
          }
        }

        return {
          title: product.title,
          cost: productConfig?.cost ? Number(productConfig.cost) : Number(product.cost),
          retailCost: productConfig?.retailCost ? Number(productConfig.retailCost) : Number(product.retailCost),
          thirdPartyId: product.thirdPartyId,
          proposalProductId: product.proposalProductId,
          markup: productConfig
            ? {
                markup: Number(productConfig?.markup?.markup ?? 0),
                type: productConfig?.markup?.type ?? "FLAT",
              }
            : {
                markup: product?.markup?.markup,
                type: product?.markup?.type,
              },
          aftermarketProductToPartnerLinkId: product.aftermarketProductToPartnerLinkId,
          addons: productConfig?.addons ?? product?.addons ?? [],
          aftermarketProduct,
        };
      })
      .filter(Boolean),
    menuOptionDetail:
      financeProgramDetails && financeProgramDetails[menuOption.name] ? financeProgramDetails[menuOption.name] : [],
  }));
}
