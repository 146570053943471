const config = require("dotenv");

// by default we are loading dev env variables

config.config();

// export const DCR_API_BASE_SERVER_URL = "http://localhost:3001";
export const DCR_API_BASE_SERVER_URL = process.env.REACT_APP_DCR_API_BASE_SERVER_URL || "https://api.dev.dcrportal.com";
export const USER_PILOT_TOKEN = process.env.REACT_USER_PILOT_TOKEN || "NX-ff27a995";
export const DCR_GRAPHQL_ENDPOINT = `${DCR_API_BASE_SERVER_URL}/v1/graphql`;

export const COGNITO_REGION = process.env.REACT_APP_COGNITO_REGION || "us-west-2";
export const COGNITO_USER_POOL_ID = process.env.REACT_APP_COGNITO_USER_POOL_ID || "us-west-2_2qeXDmOLP";
export const COGNITO_CLIENT_ID = process.env.REACT_APP_COGNITO_CLIENT_ID || "9ndee6ujfkhb40lptu6igc966";
export const S3_BUCKET_NAME = process.env.REACT_APP_S3_BUCKET_NAME || "dcr-dev-api";
export const COGNITO_USER_POOL_URL =
  process.env.REACT_APP_COGNITO_USER_POOL_URL || "dcr-dev.auth.us-west-2.amazoncognito.com";
export const COGNITO_REDIRECT_SIGNIN_URL =
  process.env.REACT_APP_COGNITO_REDIRECT_SIGNIN_URL || "http://localhost:3000/";

export const DCR_LENDER_PORTAL_URL = process.env.REACT_APP_DCR_LENDER_PORTAL_URL || false;
export const environmentName = process.env.REACT_APP_ENV_NAME || "localhost";

const AWS_COGNITO_OAUTH_ENABLED = {
  aws_project_region: COGNITO_REGION,
  aws_cognito_region: COGNITO_REGION,
  aws_user_pools_id: COGNITO_USER_POOL_ID,
  aws_user_pools_web_client_id: COGNITO_CLIENT_ID,
  oauth: {
    domain: COGNITO_USER_POOL_URL,
    scope: ["phone", "email", "openid", "profile", "aws.cognito.signin.user.admin"],
    redirectSignIn: COGNITO_REDIRECT_SIGNIN_URL,
    redirectSignOut: COGNITO_REDIRECT_SIGNIN_URL,
    responseType: "token",
    client_id: COGNITO_CLIENT_ID,
  },
  federationTarget: "COGNITO_USER_POOLS",
};

const AWS_CONFIG_STANDALONE = {
  Auth: {
    region: COGNITO_REGION,
    userPoolId: COGNITO_USER_POOL_ID,
    userPoolWebClientId: COGNITO_CLIENT_ID,
  },
};

//Over simplistic logic to enable or disable oAuthLogin based on env vars
export const oAuthLoginEnabled = COGNITO_REDIRECT_SIGNIN_URL !== "disabled" && COGNITO_USER_POOL_URL !== "disabled";

export const AWS_CONFIG = oAuthLoginEnabled ? AWS_COGNITO_OAUTH_ENABLED : AWS_CONFIG_STANDALONE;

export const REACT_APP_OCA_BASE_URL = process.env.REACT_APP_OCA_BASE_URL || "https://app.dev.trnsact.com";

export const MIXPANEL_TOKEN = process.env.REACT_APP_MIXPANEL_TOKEN || ""; // the token is set in the prod env only

export const EXPIRATION_VALUES_PER_USER_ROLE = process.env.REACT_APP_EXPIRATION_VALUES_PER_USER_ROLE || "{}";

export const INLINE_RUNTIME_CHUNK = process.env.INLINE_RUNTIME_CHUNK || false;
