import React from "react";
import { FormRadioGroup } from "components/form";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Controller, useFormContext } from "react-hook-form";
import { existingProgramTypeOptions, styleFormRadioGroupProps } from "../../../../../../constants";
import { CreateProposalFinanceExistingOptions, FinanceProgramFormValues } from "../../../../../../types";

export const InitializeWorkflow = () => {
  const classes = useStyles();

  const { control, resetField } = useFormContext<FinanceProgramFormValues>();

  return (
    <Box className={classes.container}>
      <Typography variant="subtitle2" component="span">
        Start with Saved Program?
      </Typography>

      <Controller
        control={control}
        name="financeQuote.createProposalWithExistingProgram"
        render={({ field }) => (
          <FormRadioGroup
            row
            options={existingProgramTypeOptions}
            {...styleFormRadioGroupProps}
            {...field}
            onChange={(event, value) => {
              if (value === CreateProposalFinanceExistingOptions.No) resetField("financeQuote");
              field.onChange(event, value);
            }}
          />
        )}
      />
    </Box>
  );
};

const useStyles = makeStyles({
  container: {
    gap: "1rem",
    display: "flex",
    alignItems: "center",
  },
});
