import React, { ReactNode, useEffect } from "react";
import { ModalsKeys } from "global";
import { useDispatch } from "react-redux";
import { ModalsContainer } from "components/shared/Modals";
import { deskingActions } from "../../model";
import { UserActionsEvents } from "../../types";
import { PanelFormContainer } from "./panelForm/PanelFormContainer";
import {
  ConfirmSaveDraftDialog,
  NotifyAboutRemovedProductsDialog,
  ProductCatalogDialog,
  ProposalProductsDialog,
} from "../dialogs";

export const ConfigurationPanel = (props: UserActionsEvents) => {
  const dispatch = useDispatch();

  const modalsWithKeys: Partial<Record<ModalsKeys, ReactNode>> = {
    [ModalsKeys.ProductCatalogDialog]: <ProductCatalogDialog />,
    [ModalsKeys.ProposalProductsDialog]: <ProposalProductsDialog />,
    [ModalsKeys.DeskingConfirmSaveDraftDialog]: <ConfirmSaveDraftDialog />,
    [ModalsKeys.DeskingNotifyAboutRemovedProductsDialog]: <NotifyAboutRemovedProductsDialog />,
  };

  useEffect(
    () => () => {
      dispatch(deskingActions.clearState());
    },
    [dispatch]
  );

  return (
    <>
      <PanelFormContainer {...props} />
      <ModalsContainer modalsWithKeys={modalsWithKeys} />
    </>
  );
};
