import React, { ReactNode } from "react";
import { ContentViewType, Nullable } from "global";
import { Divider, Typography } from "@material-ui/core";
import { RowDataList } from "components/shared/RowDataList/RowDataList";
import { Invoice } from "../../types";
import { invoiceRenderFieldLabels } from "../../constants";

interface Props {
  invoice: Invoice;
  isLoading?: boolean;
  contentViewType: ContentViewType;
  equipmentDetails?: Nullable<ReactNode>;
}

export const InvoiceDetails = ({ invoice, contentViewType, equipmentDetails, isLoading = false }: Props) => {
  const render = Object.entries(invoice).reduce<[ReactNode, ReactNode][]>((acc, [key, value]) => {
    const typedKey = key as keyof Invoice;
    if (!invoiceRenderFieldLabels[typedKey]) return acc;

    const configItem = invoiceRenderFieldLabels[typedKey];

    acc[configItem.order] = [
      <Typography component="span" variant="body2">
        {configItem.label}
      </Typography>,
      configItem?.formatter ? configItem.formatter(invoice) : value,
    ];

    return acc;
  }, []);

  return (
    <>
      {!!equipmentDetails && (
        <>
          {equipmentDetails}

          <Divider
            flexItem
            variant="fullWidth"
            orientation="horizontal"
            style={{ height: "1px", marginBottom: "4px" }}
          />
        </>
      )}

      <RowDataList contentViewType={contentViewType} data={render} isLoading={isLoading} />
    </>
  );
};
