import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { Close, AddCircleOutline } from "@material-ui/icons";
import { Box, Button, IconButton, Typography } from "@material-ui/core";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { CurrencyInputField, FormSwitch, InputField } from "components/form";
import { FinanceProgramFormValues } from "../../../../../../types";

export const FeeArrayFields = () => {
  const classes = useStyles();

  const { control } = useFormContext<FinanceProgramFormValues>();
  const { remove, append, fields } = useFieldArray({ control, name: "financeQuote.fee" });

  return (
    <Box className={classes.arrayContainer}>
      {!!fields?.length &&
        fields.map((field, index) => (
          <Box key={field.id}>
            <Box className={classes.array}>
              <Typography component="span" variant="subtitle2">
                Fee: {index + 1}
              </Typography>

              <Box className={clsx("row", classes.arrayFields)}>
                <InputField control={control} label="Description" name={`financeQuote.fee.${index}.identifier`} />

                <CurrencyInputField control={control} label="Fee Amount" name={`financeQuote.fee.${index}.fee`} />
              </Box>

              <IconButton size="small" onClick={() => remove(index)}>
                <Close fontSize="small" />
              </IconButton>
            </Box>

            <Controller
              control={control}
              name={`financeQuote.fee.${index}.financed`}
              render={({ field }) => (
                <FormSwitch label="Financed?" defaultChecked={field.value ? field.value : false} {...field} />
              )}
            />
          </Box>
        ))}

      <Box>
        <Button
          size="small"
          color="primary"
          variant="outlined"
          startIcon={<AddCircleOutline />}
          onClick={() => append({ financed: true, fee: 0, identifier: "" })}
        >
          Add Fee
        </Button>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles({
  arrayContainer: {
    gap: "12px",
    display: "flex",
    flexDirection: "column",
  },
  array: {
    gap: "12px",
    display: "flex",
  },
  arrayFields: {
    flex: 1,
  },
});
