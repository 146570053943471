import BusinessIcon from "@material-ui/icons/Business";
import PersonIcon from "@material-ui/icons/Person";
import ViewListIcon from "@material-ui/icons/ViewList";
import NoteAddIcon from "@material-ui/icons/NoteAdd";
import StorefrontIcon from "@material-ui/icons/Storefront";
import Group from "@material-ui/icons/Group";
import FormatListNumberedIcon from "@material-ui/icons/FormatListNumbered";
import InsertDriveFileOutlinedIcon from "@material-ui/icons/InsertDriveFileOutlined";
import PageList from "./pages/VendorOpportunity/PageOpportunitiesList";
import SubmissionsHub from "./pages/SubmissionsHub";
import CompanyProfile from "./pages/CompanyProfile/CompanyProfile";
import Receipt from "./pages/CreditApp/Receipt";
import AppError from "./pages/CreditApp/AppError";
import { Auth } from "aws-amplify";
import PQTView from "./pages/PaymentQuoteTool/PQT.js";
import PQT from "./pages/PQT";
import SendLink from "./pages/PaymentQuoteTool/SendLink.js";
import PrequalApp from "./pages/Prequal/PrequalApp";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import SendIcon from "@material-ui/icons/Send";
import DeviceHubIcon from "@material-ui/icons/DeviceHub";
import PrequalReceipt from "./pages/Prequal/PrequalReceipt";
import AdminPageList from "./pages/VendorOpportunity/PageOpportunitiesListAdmin";
import AdminDetailsPage from "./pages/VendorOpportunity/PageOpportunityDetailsAdmin";
import AdminDocList from "./pages/VendorOpportunity/AdminDocsList";
import ChatPage from "./pages/ChatPage";
import PageRepList from "./pages/DealerAdmin/RepList";
import UserProfile from "./pages/UserProfile";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import BarChartIcon from "@material-ui/icons/BarChart";
import ReportsPage from "./pages/Reports";
import ConsolidatedTasks from "./pages/ConsolidatedTasks";
import PageLeadList from "./pages/LeadSheet/LeadsList";
import DnsIcon from "@material-ui/icons/Dns";
import LenderAdmin from "./pages/LenderAdmin";
import LenderDetails from "./pages/LenderAdmin/LenderDetails";
import LeadDetails from "./pages/LeadSheet/LeadDetails";
import CreditAppv2 from "./pages/CreditApp/CreditApp";
import ChatIcon from "@material-ui/icons/Chat";
import PageProposalList from "./pages/Proposals/Proposals";
import ProposalDetails from "./pages/Proposals/ProposalDetails";
import { PrescreenModuleContainer as LenderWaterfall } from "./pages/LenderWaterfall/PrescreenModuleContainer";
import PageCreditPrograms from "./pages/CreditPrograms/CreditPrograms";
import CreditProductDetails from "./pages/CreditPrograms/CreditProduct";
import WebsiteIntegration from "./pages/WebsiteIntegration";
import WebIcon from "@material-ui/icons/Web";
import TuneIcon from "@material-ui/icons/Tune";
import DocList from "./pages/DocMgmt/DocList";
import PostAddIcon from "@material-ui/icons/PostAdd";
import AccountDetails from "./pages/VendorOpportunity/DetailPages/AccountDetails";
import OwnerDetails from "./pages/VendorOpportunity/DetailPages/OwnerDetails";
import DealerList from "./pages/PartnerAdmin/DealerList";
import AddDealer from "./pages/PartnerAdmin/AddDealer";
import PartnerDealerAccountDetails from "./pages/PartnerAdmin/PartnerDealerDetail";
import LocationList from "pages/LocationMgmt/LocationList";
import LocationDetail from "pages/LocationMgmt/LocationDetail";
import DealerDashboards from "pages/DealerDashboards/DealerDashboards";
import AppsIcon from "@material-ui/icons/Apps";
import ShowChart from "@material-ui/icons/ShowChart";
import ListAlt from "@material-ui/icons/ListAlt";
import { Constructor } from "pages/DynamicOCA/Constructor";
import CustomersPage from "pages/Customers";
import PortalConfigurationWizard from "pages/PortalConfigurationWizard/PortalConfigurationWizard";
import PortalSetup from "pages/PortalSetup/PortalSetup";
import { WorkflowUpsertPage, WorkflowsListPage } from "pages/Workflows";
import FinanceProgramMgmt from "pages/FinanceProgramMgmt";
import { AddAftermarketProduct, EditAftermarketProduct, ListAftermarketProducts } from "pages/AftermarketProductMgmt";
import CADMPDFGeneration from "components/CADMPDFGeneration";
import { LocalAtmRounded, Work as WorkIcon } from "@material-ui/icons";
import Cookies from "js-cookie";
import { SESSION_TIMEOUT_COOKIE_NAME } from "./constants";
import mixpanel from "./mixpanel";
import { PageOpportunityDetailsContainer } from "./pages/VendorOpportunity/DetailPages";
import { AftermarketAddEditMenuTemplatePage } from "modules/aftermarketMenuConstructor";
import { PrescreenPage } from "./modules/prescreen";
import { ProposalsPage } from "./modules/proposals";

async function handleLogout() {
  await Auth.signOut();
  Cookies.remove(SESSION_TIMEOUT_COOKIE_NAME);
  window.localStorage.clear();
  sessionStorage.clear();
  mixpanel.reset();
  document.location.pathname = "";
}

//0-23 elements
export const appRoutes = [
  {
    id: "dealerDashboard",
    showInSidebarNav: false,
    exact: true,
    path: "/dashboard",
    name: "Dashboard",
    icon: ShowChart,
    component: DealerDashboards,
    layout: "/dashboard",
  },
  {
    id: "vo",
    showInSidebarNav: true,
    exact: true,
    path: "/vo",
    name: "Opportunities",
    icon: ViewListIcon,
    component: PageList,
    layout: "/dashboard",
  },
  {
    id: "proposals-hub",
    showInSidebarNav: true,
    exact: true,
    path: "/proposals-hub",
    isNewFeature: true,
    name: "Proposals",
    icon: InsertDriveFileOutlinedIcon,
    component: ProposalsPage,
    layout: "/dashboard",
  },
  {
    id: "submissionsHub",
    showInSidebarNav: false,
    exact: true,
    path: "/submissions",
    isNewFeature: true,
    name: "Submissions Hub",
    icon: DeviceHubIcon,
    component: SubmissionsHub,
    layout: "/dashboard",
  },
  {
    id: "workflows",
    showInSidebarNav: false,
    exact: true,
    path: "/workflows",
    isNewFeature: true,
    name: "Workflows",
    icon: WorkIcon,
    component: WorkflowsListPage,
    layout: "/dashboard",
  },
  {
    id: "workflowsEdit",
    showInSidebarNav: false,
    exact: false,
    path: "/workflows/edit/:id",
    isNewFeature: true,
    name: "Edit Workflow",
    icon: WorkIcon,
    component: WorkflowUpsertPage,
    layout: "/dashboard",
  },
  {
    id: "workflowsAdd",
    showInSidebarNav: false,
    exact: true,
    path: "/workflows/add",
    isNewFeature: true,
    name: "Create Workflows",
    icon: WorkIcon,
    component: WorkflowUpsertPage,
    layout: "/dashboard",
  },

  {
    id: "customers",
    showInSidebarNav: false,
    exact: true,
    path: "/customers",
    name: "Customers",
    icon: Group,
    component: CustomersPage,
    layout: "/dashboard",
  },
  {
    id: "voId",
    showInSidebarNav: false,
    exact: false,
    path: "/vo/:id",
    name: "Opportunity Detail",
    type: "OpportunityDetail",
    icon: ViewListIcon,
    component: PageOpportunityDetailsContainer,
    layout: "/dashboard",
    fastTrackOption: true,
  },
  /*   {
    id: "voId",
    showInSidebarNav: false,
    exact: false,
    path: "/vo/:id",
    name: "Opportunity Detail",
    type: "OpportunityDetail",
    icon: ViewListIcon,
    component: DetailsPage,
    layout: "/dashboard",
    fastTrackOption: true,
  },
  {
    id: "voId",
    showInSidebarNav: false,
    exact: false,
    path: "/vo2/:id",
    name: "Opportunity Detail",
    type: "OpportunityDetail",
    icon: ViewListIcon,
    component: PageOpportunityDetails_V2,
    layout: "/dashboard",
    fastTrackOption: true,
   }, */
  {
    id: "creditapp",
    showInSidebarNav: false,
    exact: true,
    path: "/creditapp",
    name: "Create Application",
    icon: NoteAddIcon,
    component: CreditAppv2,
    layout: "/dashboard",
  },
  {
    id: "receipt",
    showInSidebarNav: false,
    exact: true,
    path: "/receipt",
    name: "Success",
    icon: NoteAddIcon,
    component: Receipt,
    layout: "/dashboard",
  },
  {
    id: "appError",
    showInSidebarNav: false,
    exact: true,
    path: "/app-error",
    name: "Error",
    icon: NoteAddIcon,
    component: AppError,
    layout: "/dashboard",
  },
  {
    id: "prequal-receipt",
    showInSidebarNav: false,
    exact: true,
    path: "/prequal-receipt",
    name: "Success",
    icon: NoteAddIcon,
    component: PrequalReceipt,
    layout: "/dashboard",
  },

  {
    id: "pqt",
    showInSidebarNav: false,
    exact: true,
    path: "/pqt",
    name: "Payment Quote Tool",
    icon: MonetizationOnIcon,
    component: PQT,
    layout: "/dashboard",
  },
  {
    id: "pqtold",
    showInSidebarNav: false,
    exact: true,
    path: "/pqtold",
    name: "Payment Quote Tool",
    icon: MonetizationOnIcon,
    component: PQTView,
    layout: "/dashboard",
  },
  {
    id: "sendoca",
    showInSidebarNav: true,
    exact: true,
    path: "/sendoca",
    name: "Send Application",
    icon: SendIcon,
    component: SendLink,
    layout: "/dashboard",
  },
  {
    id: "prequal",
    showInSidebarNav: false,
    exact: true,
    path: "/prequal",
    name: "Prequal App",
    icon: NoteAddIcon,
    component: PrequalApp,
    layout: "/dashboard",
  },
  {
    id: "adminvo",
    showInSidebarNav: false,
    exact: true,
    path: "/adminvo/",
    name: "Admin List",
    icon: ViewListIcon,
    component: AdminPageList,
    layout: "/dashboard",
  },
  {
    id: "adminvoId",
    showInSidebarNav: false,
    exact: false,
    path: "/adminvo/:id",
    name: "Admin Detail",
    icon: ViewListIcon,
    component: AdminDetailsPage,
    layout: "/dashboard",
  },
  {
    id: "docs",
    showInSidebarNav: false,
    exact: true,
    path: "/docs",
    name: "Doc List",
    icon: ViewListIcon,
    component: AdminDocList,
    layout: "/dashboard",
  },
  {
    id: "chat",
    showInSidebarNav: false,
    exact: true,
    path: "/chat",
    name: "Conversations",
    icon: ChatIcon,
    component: ChatPage,
    layout: "/dashboard",
  },
  {
    id: "reports",
    showInSidebarNav: true,
    exact: true,
    path: "/reports",
    name: "Reports",
    icon: BarChartIcon,
    component: ReportsPage,
    layout: "/dashboard",
  },
  {
    id: "consolidatedTasks",
    showInSidebarNav: false,
    exact: true,
    path: "/consolidatedTasks",
    name: "Tasks",
    icon: FormatListNumberedIcon,
    component: ConsolidatedTasks,
    layout: "/dashboard",
  },
  {
    id: "leads",
    showInSidebarNav: true,
    exact: true,
    path: "/leads",
    name: "Leads",
    icon: DnsIcon,
    component: PageLeadList,
    layout: "/dashboard",
  },
  {
    id: "leadDetailLeadId",
    showInSidebarNav: false,
    exact: false,
    path: "/leadDetail/:leadId",
    name: "Lead Detail",
    icon: ViewListIcon,
    component: LeadDetails,
    layout: "/dashboard",
  },
  {
    id: "proposals",
    showInSidebarNav: false,
    exact: true,
    path: "/proposals",
    name: "Proposals",
    icon: ListAlt,
    component: PageProposalList,
    layout: "/dashboard",
  },
  {
    id: "proposalDetailPropId",
    showInSidebarNav: false,
    exact: false,
    path: "/proposalDetail/:propId",
    name: "Proposal Detail",
    icon: ViewListIcon,
    component: ProposalDetails,
    layout: "/dashboard",
  },
  {
    id: "creditProgramDetailCreditProdId",
    showInSidebarNav: false,
    exact: false,
    path: "/creditProgramDetail/:creditProdId",
    name: "Credit Product Detail",
    icon: ViewListIcon,
    component: CreditProductDetails,
    layout: "/dashboard",
  },
  {
    id: "templates",
    showInSidebarNav: false,
    exact: true,
    path: "/templates",
    name: "Docs",
    icon: PostAddIcon,
    component: DocList,
    layout: "/dashboard",
  },
  {
    id: "accountDetailsAccountId",
    showInSidebarNav: false,
    exact: false,
    path: "/accountDetails/:accountId",
    name: "Account Details",
    icon: ViewListIcon,
    component: AccountDetails,
    layout: "/dashboard",
  },
  {
    id: "ownerPgDetailsOwnerPgId",
    showInSidebarNav: false,
    exact: false,
    path: "/ownerPgDetails/:ownerPgId",
    name: "Owner/PG Details",
    icon: ViewListIcon,
    component: OwnerDetails,
    layout: "/dashboard",
  },
  {
    id: "delearMgmt",
    showInSidebarNav: false,
    exact: true,
    path: "/dealerMgmt",
    name: "Dealer Management",
    icon: ViewListIcon,
    component: DealerList,
    layout: "/dashboard",
  },
  {
    id: "addDealer",
    showInSidebarNav: false,
    exact: true,
    path: "/addDealer",
    name: "Add Dealer",
    icon: ViewListIcon,
    component: AddDealer,
    layout: "/dashboard",
  },
  {
    id: "partnerAccountDetailsDynamicsId",
    showInSidebarNav: false,
    exact: false,
    path: "/partnerAccountDetails/:dynamicsId",
    name: "Partner Dealer Account Details",
    icon: ViewListIcon,
    component: PartnerDealerAccountDetails,
    layout: "/dashboard",
  },
];
//0-11 elements
export const userRoutes = [
  {
    showInSidebarNav: true,
    exact: false,
    path: "/profile/:dynamicsContactId",
    name: "User Profile",
    icon: PersonIcon,
    component: UserProfile,
    layout: "/dashboard",
    dynamic: true,
    dynamicKey: "dynamicsContactId",
    dynamicEntity: "userProfile",
  },
  {
    id: "company-profile",
    showInSidebarNav: true,
    exact: true,
    path: "/company-profile",
    name: "Company Profile",
    icon: BusinessIcon,
    component: CompanyProfile,
    layout: "/dashboard",
  },
  {
    id: "acctadmin",
    showInSidebarNav: false,
    exact: true,
    path: "/acctadmin",
    name: "User Management",
    icon: SupervisorAccountIcon,
    component: PageRepList,
    layout: "/dashboard",
  },
  {
    id: "creditPrograms",
    showInSidebarNav: false,
    exact: true,
    path: "/creditPrograms",
    name: "Credit Programs",
    icon: DnsIcon,
    component: PageCreditPrograms,
    layout: "/dashboard",
  },
  {
    id: "lenderadmin",
    showInSidebarNav: false,
    exact: true,
    path: "/lenderadmin",
    name: "Lender Management",
    icon: PersonAddIcon,
    component: LenderAdmin,
    layout: "/dashboard",
  },
  {
    id: "lender",
    showInSidebarNav: false,
    exact: false,
    path: "/lenderadmin/:dynamicLenderProfileId",
    type: "LenderDetail",
    name: "Lender Details",
    icon: PersonAddIcon,
    component: LenderDetails,
    layout: "/dashboard",
  },
  {
    id: "financePrograms",
    showInSidebarNav: false,
    exact: true,
    path: "/finance-programs",
    name: "Finance Programs",
    icon: LocalAtmRounded,
    component: FinanceProgramMgmt,
    layout: "/dashboard",
  },
  {
    id: "locationMgmt",
    showInSidebarNav: false,
    exact: true,
    path: "/locationMgmt",
    name: "Location Management",
    icon: ViewListIcon,
    component: LocationList,
    layout: "/dashboard",
  },
  {
    id: "pcw",
    showInSidebarNav: false,
    exact: true,
    path: "/pcw",
    name: "Portal Configuration Wizard",
    icon: TuneIcon,
    component: PortalConfigurationWizard,
    layout: "/dashboard",
  },
  {
    id: "portalSetup",
    showInSidebarNav: true,
    exact: true,
    path: "/portal-setup",
    name: "Portal Setup",
    icon: TuneIcon,
    component: PortalSetup,
    layout: "/dashboard",
  },
  {
    id: "aftermarket",
    showInSidebarNav: false,
    exact: true,
    path: "/aftermarket",
    name: "Aftermarket Products",
    icon: StorefrontIcon,
    component: ListAftermarketProducts,
    layout: "/dashboard",
  },
  {
    id: "aftermarketSubMenues-List",
    showInSidebarNav: false,
    exact: true,
    path: "/aftermarket/menu",
    name: "Aftermarket Menu",
    icon: StorefrontIcon,
    component: ListAftermarketProducts,
    layout: "/dashboard",
  },
  {
    id: "aftermarketSubMenues-Dashboard",
    showInSidebarNav: false,
    exact: true,
    path: "/aftermarket/dashboard",
    name: "Aftermarket Dashboard",
    icon: StorefrontIcon,
    component: ListAftermarketProducts,
    layout: "/dashboard",
  },
  {
    id: "aftermarketMenuNEdit",
    showInSidebarNav: false,
    exact: false,
    path: "/aftermarket/menu/edit/:proposalMenuId",
    name: "Menu Details",
    component: AftermarketAddEditMenuTemplatePage,
    layout: "/dashboard",
  },
  {
    id: "aftermarketMenuNew",
    showInSidebarNav: false,
    exact: true,
    path: "/aftermarket/menu/new",
    name: "Menu Details",
    component: AftermarketAddEditMenuTemplatePage,
    layout: "/dashboard",
  },
  {
    id: "aftermarketAdd",
    showInSidebarNav: false,
    exact: true,
    path: "/aftermarket/add",
    name: "Add Aftermarket Product",
    icon: StorefrontIcon,
    component: AddAftermarketProduct,
    layout: "/dashboard",
  },
  {
    id: "aftermarketEdit",
    showInSidebarNav: false,
    exact: true,
    path: "/aftermarket/edit/:aftermarketProductId",
    name: "Edit Aftermarket Product",
    icon: StorefrontIcon,
    component: EditAftermarketProduct,
    layout: "/dashboard",
  },
  {
    id: "locationDetailLocationId",
    showInSidebarNav: false,
    exact: false,
    path: "/locationDetail/:locationId",
    name: "Location Details",
    icon: ViewListIcon,
    component: LocationDetail,
    layout: "/dashboard",
  },
  {
    id: "websiteIntegration",
    showInSidebarNav: false,
    exact: true,
    path: "/website-integration",
    name: "Website Integration",
    icon: WebIcon,
    component: WebsiteIntegration,
    layout: "/dashboard",
  },
  /*   {
    id: "setupWizard",
    showInSidebarNav: false,
    exact: true,
    action: () => {
      return false;
    },
    name: "Setup Wizard",
    icon: TuneIcon,
    layout: "/dashboard",
  }, */
  {
    id: "constructor",
    showInSidebarNav: false,
    exact: true,
    path: "/constructor",
    name: "OCA Constructor",
    icon: AppsIcon,
    component: Constructor,
    layout: "/dashboard",
  },
  {
    id: "lenderWaterfall",
    showInSidebarNav: false,
    exact: true,
    path: "/lender-waterfall",
    name: "Prescreen Tools",
    icon: ViewListIcon,
    component: LenderWaterfall,
    layout: "/dashboard",
  },
  {
    id: "dev",
    showInSidebarNav: false,
    exact: true,
    path: "/dev",
    name: "Prescreen Tools",
    icon: ViewListIcon,
    component: PrescreenPage,
    layout: "/dashboard",
  },
  {
    id: "logout",
    showInSidebarNav: true,
    exact: true,
    action: handleLogout,
    name: "Logout",
    icon: ExitToAppIcon,
    // component: CompanyProfile,
    layout: "/dashboard",
  },
];

export const publicRoutes = [
  {
    id: "cadmPDFGeneration",
    basePath: "/cadm-pdf-generation",
    path: "/cadm-pdf-generation/:vendorOpportunityId",
    component: CADMPDFGeneration,
  },
];
