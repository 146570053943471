import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@material-ui/core";
import { menuConstructorActions, menuConstructorSelectors } from "../../../model";
import { CardContainer } from "components/shared/CardContainer/CardContainer";
import { MenuOptions } from "./MenuOptions";
import { useModal } from "hooks/useModal";
import { initialMenuOptionOnAddEdit } from "modules/aftermarketMenuConstructor/constants";
import { useAftermarketPermissions } from "modules/aftermarketMenuConstructor/hooks/useAftermarketPermissions";
import { ModalsKeys } from "../../../../../global";

export const MenuOptionsCard = () => {
  const dispatch = useDispatch();

  const isLoading = useSelector(menuConstructorSelectors.isLoading) as boolean;

  const { handleOpen: handleAddEditMenuOptionDialogOpen } = useModal(ModalsKeys.AftermarketAddEditMenuOptionDialog);
  const { hasWriteAccess } = useAftermarketPermissions();

  const handleAddMenuOptionClick = () => {
    dispatch(
      menuConstructorActions.setMenuOptionToAddEdit({
        currentMenuOption: initialMenuOptionOnAddEdit,
      })
    );
    handleAddEditMenuOptionDialogOpen();
  };

  return (
    <CardContainer
      title="Menu Options"
      isLoading={isLoading}
      actionBtn={
        hasWriteAccess && (
          <Button size="small" color="primary" variant="outlined" onClick={handleAddMenuOptionClick}>
            Add Menu Option
          </Button>
        )
      }
    >
      <MenuOptions isCalculating={false} />
    </CardContainer>
  );
};
