import React, { useMemo } from "react";
import { Box } from "@material-ui/core";
import { checkIfQuillValueIsEmpty } from "utils";
import { makeStyles } from "@material-ui/core/styles";
import { ProposalProduct } from "@trnsact/trnsact-shared-types/dist/generated";
import { ReadOnlyQuill } from "./ReadOnlyQuill";

interface Props {
  product: ProposalProduct;
}

export const ProductCatalogDetails = ({ product }: Props) => {
  const classes = useStyles();

  const { isDataExist, productSummary } = useMemo(() => {
    const data = {
      productSummary: product?.aftermarketProduct?.aftermarketProductCatalogDetails?.productSummary,
    };

    return {
      ...data,
      isDataExist: data.productSummary,
    };
  }, [product]);

  return (
    <>
      {isDataExist && (
        <Box className={classes.container}>{productSummary && <ReadOnlyQuill value={productSummary} />}</Box>
      )}
    </>
  );
};

const useStyles = makeStyles({
  container: {
    gap: "6px",
    display: "flex",
    flexDirection: "column",
  },
});
