import React from "react";
import { Theme } from "@material-ui/core/styles";
import { Box, Checkbox, FormControlLabel, makeStyles, Radio, RadioGroup, Typography } from "@material-ui/core";
import { lossCodesFormatter } from "../../../../../lib";
import { formatCurrency } from "../../../../../../../utils";
import { FormattedLossCode } from "modules/desking/lib/lossCodesFormatter";

interface NtpAddonsOptions {
  dealerRemit: number;
  dealerCost: number;
  fiMarkup: number;
  suggestedRetailCost: number;
  customerValue: number;
  dealerPackAmount: number;
  deductible: {
    id: number;
    type: string; // "PerRepair",
    amount: number;
    paidAmount: number;
  };
  components: [
    {
      description: string; // "Surcharges",
      isSurcharge: boolean;
      lossCodes: [
        {
          coverageLossCodeId: number;
          isSelected: boolean;
          isSelectable: true;
          isCovered: boolean;
          laborHours: number;
          isSurcharge: true;
          partCost: number;
          dealerCost: number;
          suggestedRetailCost: number;
          termMonth: number;
          code: string; // aftermarket_product.external_id "TRANS_REAR",
          description: string; // "Transmission & Rear Differential"
        }
      ];
      lossCodeInputCriteria: "single-select" | "multi-select"; // "multi-select"
    }
  ];
  termMonths: number;
  termOdometer: number;
}

interface Props {
  addons: any[];
  addonsOptions: NtpAddonsOptions;
  handleCheckboxChange: (lossCode: FormattedLossCode[]) => void;
}

export const Addons = ({ addonsOptions, handleCheckboxChange, addons }: Props) => {
  const classes = useStyles();

  return (
    <Box className={classes.addonsCardContainer}>
      {addonsOptions.components.map(component => (
        <Box key={component.description} className={classes.addonsCard}>
          <Typography component="span" variant="subtitle2">
            {component.description}
          </Typography>

          <Box className="list">
            {component.lossCodeInputCriteria === "single-select" ? (
              <RadioGroup
                value={
                  addons?.find((addon: any) =>
                    component.lossCodes.some(lossCode => addon?.title === lossCode?.description)
                  )?.title || "None"
                }
                onChange={e => {
                  handleCheckboxChange(
                    e.target.value === "None"
                      ? component.lossCodes.map(lossCode => lossCodesFormatter(component, lossCode, false))
                      : component.lossCodes.map(lossCode =>
                          lossCodesFormatter(component, lossCode, e.target.value === lossCode.description)
                        )
                  );
                }}
              >
                <FormControlLabel
                  key={component.description}
                  value={`None`}
                  label={`None`}
                  control={<Radio size="small" color="primary" />}
                />
                {component.lossCodes.map(lossCode => (
                  <FormControlLabel
                    key={lossCode.description}
                    value={lossCode.description}
                    label={`${lossCode.description}: ${formatCurrency(lossCode.dealerCost, true)}`}
                    control={<Radio size="small" color="primary" />}
                  />
                ))}
              </RadioGroup>
            ) : (
              component.lossCodes.map(lossCode => {
                const checked = !!addons?.find((addon: any) => addon?.title === lossCode?.description);
                return (
                  <FormControlLabel
                    key={lossCode.description}
                    label={`${lossCode.description}: ${formatCurrency(lossCode.dealerCost, true)}`}
                    control={
                      <Checkbox
                        size="small"
                        disableRipple
                        color="primary"
                        checked={checked}
                        onChange={e =>
                          handleCheckboxChange([lossCodesFormatter(component, lossCode, e.target.checked)])
                        }
                      />
                    }
                  />
                );
              })
            )}
          </Box>
        </Box>
      ))}
    </Box>
  );
};

const useStyles = makeStyles<Theme>(({ palette }) => ({
  addonsCardContainer: {
    gap: "8px",
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
  },
  addonsCard: {
    gap: "8px",
    display: "flex",
    padding: "12px",
    flexDirection: "column",
    backgroundColor: palette.common.white,

    "& .list": {
      display: "flex",
      flexDirection: "column",
    },
  },
}));
