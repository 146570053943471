import React from "react";
import { ModalsKeys } from "global";
import { logError } from "utils/logger";
import { useModal } from "hooks/useModal";
import { makeStyles } from "@material-ui/core/styles";
import { Form, FormProvider, useForm } from "react-hook-form";
import { FormSubmitHandler } from "react-hook-form/dist/types/form";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@material-ui/core";
import { InvoiceFormValues } from "../../../model";
import { invoiceFormInitialValues } from "../../../constants";
import { getInitialValuesFromExistingInvoice } from "../../../lib";
import { CreateUpdateInvoiceForm } from "./CreateUpdateInvoiceForm";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

export const CreateUpdateInvoiceModal = () => {
  const classes = useStyles();

  const { isOpen, data, handleClose } = useModal(ModalsKeys.CreateUpdateInvoiceDialog);

  const form = useForm<InvoiceFormValues>({
    defaultValues: data.invoice ? getInitialValuesFromExistingInvoice(data.invoice) : invoiceFormInitialValues,
  });

  const handleConfirm: FormSubmitHandler<InvoiceFormValues> = async payload => {
    try {
      handleClose();
      await data.onSubmit(payload.data);
    } catch (error) {
      logError(error);
    }
  };

  return (
    <Dialog open={isOpen} fullWidth maxWidth="sm" onClose={handleClose}>
      <FormProvider {...form}>
        <Form<InvoiceFormValues> onSubmit={handleConfirm} className={classes.form}>
          <Box className={classes.dialogTitle}>
            <Typography component="span" variant="h6">
              Invoice Detail
            </Typography>

            <IconButton onClick={handleClose}>
              <CloseIcon style={{ color: "#000000" }} />
            </IconButton>
          </Box>

          <DialogContent classes={{ root: classes.fields }}>
            <CreateUpdateInvoiceForm />
          </DialogContent>

          <DialogActions>
            <Button size="small" onClick={handleClose}>
              Cancel
            </Button>

            <Button size="small" type="submit" variant="contained">
              Confirm
            </Button>
          </DialogActions>
        </Form>
      </FormProvider>
    </Dialog>
  );
};

const useStyles = makeStyles({
  dialogTitle: {
    display: "flex",
    alignItems: "center",
    padding: "0 0.5rem 0 1.5rem",
    justifyContent: "space-between",
  },
  form: {
    display: "grid",
    gridTemplateRows: "4rem 1fr 4rem",
  },
  fields: {
    gap: "1rem",
    display: "flex",
    flexDirection: "column",
  },
});
